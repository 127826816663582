<template>
  <form @submit.prevent="onLogin()">
    <h2 v-if="!isHideHeader" class="title is-2">Login</h2>
    <section class>
      <b-field label="Email">
        <b-input
          v-model="formEmail"
          :disabled="isLoading"
          type="email"
          placeholder="Your email"
          required
        />
      </b-field>

      <b-field label="Password">
        <b-input
          v-model="formPassword"
          type="password"
          password-reveal
          placeholder="Your password"
          required
          :disabled="isLoading"
        />
      </b-field>
      <b-field>
        <div class="level">
          <b-checkbox v-model="formRememberMe" class="level-left">Remember me</b-checkbox>
          <router-link class="level-right" :to="{ name: 'reset' }">Reset Password</router-link>
        </div>
      </b-field>
      <b-button
        v-show="!isHideLoginButton"
        id="sign-in-button"
        class="button is-primary is-pulled-right"
        :loading="isLoading"
        native-type="submit"
      >Login</b-button>
    </section>
  </form>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({

})
export default class VFormLoginView extends Vue {
  @Prop() public readonly isHideHeader!: boolean
  @Prop() public readonly isHideLoginButton!: boolean

  public formEmail = ''
  public formPassword = ''
  public formRememberMe = false

  public isLoading = false

  public async onLogin() {
    if (!this.formEmail || !this.formPassword)
      return

    this.isLoading = true

    await this.$auth.setPersistence(this.formRememberMe)


    await this.$auth.loginEmailPassword(this.formEmail, this.formPassword)
      .then(() => {
        this.formEmail = ''
        this.formPassword = ''
        this.formRememberMe = false
      })
      .catch((error) => {
        this.$helpers.notification.Error(String(error))
      })
      .finally(() =>

        this.isLoading = false
      )
  }
}
</script>

<style lang="scss">
</style>
