<template>
  <section>
    <b-button
      class="is-pulled-right"
      type="is-text"
      icon-right="cog"
      @click="isSettingsModalActive = true"
    >Settings</b-button>

    <b-modal
      :active.sync="isSettingsModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div class="settings">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Settings</p>
            <!-- <div>Settings are stored locally on device.</div> -->
          </header>
          <section class="modal-card-body">
            <b-field v-for="(conf,i) in config" :key="i">
              <template slot="label">
                {{ conf.title }}
                <VTooltipIconHelp position="is-right" :text="conf.description" />
              </template>
              <b-switch
                v-if="conf.type ==='boolean'"
                v-model="formSettings[conf.accessorKey]"
                :rounded="false"
              />
              <b-input
                v-if="conf.type ==='text'"
                v-model="formSettings[conf.accessorKey]"
                :rounded="false"
              />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="isSettingsModalActive = false">Close</button>
            <button class="button is-success" @click="onSaveSettings()">Save</button>
          </footer>
        </div>
      </div>
    </b-modal>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop, PropSync } from 'vue-property-decorator'
import { SettingsConfig } from '@/types/typeLocalSettings'

@Component({})
export default class VButtonSettingsModal extends Vue {
  public isSettingsModalActive = false

  @Prop({ required: true })
  public config!: SettingsConfig[]

  @PropSync('settings', { required: true })
  public formSettings!: any

  public onSaveSettings() {
    this.$emit('save')
    this.isSettingsModalActive = false
  }
}
</script>

<style lang="scss">
.tos {
  max-height: 60vh;
  overflow-y: scroll;
  margin: 1em 0;
  border-bottom: 1px solid grey;
  border-top: 1px solid grey;

  .terms-container {
    min-height: 62vh;
    margin: 1em;
  }
}
</style>
