import '@/class-component-hooks'
import '@/firebase'

import Vue from 'vue'
import Buefy from 'buefy'
import { firestorePlugin } from 'vuefire'
import vueDebounce from 'vue-debounce'
import vueSanitize from 'vue-sanitize'
import { DynamicReactiveRefs } from 'vue-reactive-refs'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import auth from '@/helpers/authUtil'
import i18nGlobal from '@/modules/_globals/i18nGlobals'
import CategoriesGlobal from '@/modules/_globals/categoryGlobals'
import LocalSettingsGlobals from '@/modules/_globals/localSettingsGlobals'
import helpers from '@/helpers/globalHelpers'
import router from './router'
import App from '@/App.vue'

import '@/main.scss'
import './backend.scss'
import 'floating-vue/dist/style.css'

import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import backendConfigGlobals from '@/modules/_globals/backendConfigGlobals'

// #region global component registration
import '@/components/global/globalComponentRegistration'
// #endregion global component registration

import * as Sentry from '@sentry/vue'

import '@/types/typePluginShims'
import protectionGlobals from '@/modules/_globals/protectionGlobals'
import '@/types/typeVuefire'
import userGlobals from '@/modules/_globals/userGlobals'
import vueShortkey from 'vue-shortkey'
import planGlobals from '@/modules/_globals/planGlobals'
import dayjs from 'dayjs'

console.log('NODE_ENV', process.env.NODE_ENV)
console.log('VUE_APP_ENV', process.env.VUE_APP_ENV)

import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(utc)
dayjs.extend(relativeTime)

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: 'https://4958dc2a888e441694dc1fe1634bb6a4@o1296785.ingest.sentry.io/6524107',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration()
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    debug: ['local', 'dev'].includes(process.env.VUE_APP_ENV || ''),
    environment: process.env.VUE_APP_ENV,
    release: `${process.env.VUE_APP_VERSION}_${(+(process.env.VUE_APP_BUILD_TIME || 1)).toString(36)}`,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    logErrors: true
  })
}

Vue.use(vueDebounce)
Vue.use(vueSanitize)
Vue.use(vueShortkey)

// if (process.env.NODE_ENV !== 'development')
//   Vue.use(VueFreshchat, {
//     appToken: 'f587088f-c6a1-448a-8582-3bd409dd1a8a',
//     host: 'https://echoprm-org.freshchat.com'
//   }) // config object is optional

library.add(fas)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
// Vue.component('VTooltipIconHelp', VTooltipIconHelp)
// Vue.component('VTooltipIconExperimental', VTooltipIconExperimental)
// Vue.component('VPrintButton', VPrintButton)
// Vue.component('VBreadcrumb', VBreadcrumb)
// Vue.component('VHelpCenterView', VHelpCenterView)
// Vue.component('VCrudControl', VCrudControl)

Vue.use(DynamicReactiveRefs)

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconComponent: 'font-awesome-icon',
  defaultNotificationPosition: 'is-top',
  defaultNotificationDuration: 10000
})

Vue.use(firestorePlugin as any, { wait: true })
// Vue.use(VueFire, { modules: [VueFireFirestoreOptionsAPI] })
// Vue.use(VueFire, {
//   modules: [
//     // to use the `firestore` option
//     VueFireFirestoreOptionsAPI()
//   ]
// })

// Vue.mixin(VCustomVueFireBindMixin)
// declare here, since mixin is not a .ts file and therefore types are not available globally
declare module 'vue/types/vue' {
  interface Vue {
    $bindSnapshot<T, K>(
      varName: string,
      ref: firebase.default.firestore.DocumentReference<firebase.default.firestore.DocumentData>,
      beforeSet?: (data: T) => K,
      onError?: (e: any) => void
    ): Promise<unknown>
    $unbindHandle<T extends SnapshotUnbindHandle | SnapshotUnbindHandle[]>(unbindHandle: T): T
    $disposeSnapshots(): number
  }
}

Vue.use(helpers)
Vue.use(auth)
Vue.use(i18nGlobal)
Vue.use(CategoriesGlobal)
Vue.use(LocalSettingsGlobals)
Vue.use(backendConfigGlobals)
Vue.use(protectionGlobals)
Vue.use(userGlobals)
Vue.use(planGlobals)

Vue.filter('lowercase', function (value: string) {
  if (!value) return ''
  return value.toLowerCase()
})

Vue.config.productionTip = false
; (window as any).VueInst = new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
