<template>
  <section>
    <div v-if="$route.name === 'settings-overview'" class="settings-overview-container">
      <div v-for="(setting,i) in settings" :key="i" class="box module">
        <router-link :to="{name: setting.name}">
          <h1 class="title is-5">
            <b-icon :icon="setting.meta.icon" size="is-small" />
            {{ setting.meta.label }}
            <b-tag v-if="setting.meta.staffOnly" type="is-info">Staff Only</b-tag>
          </h1>
          <div class="description">{{ setting.meta.description }}</div>
        </router-link>
      </div>
    </div>
    <router-view />
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faUsers,
  faExclamationTriangle,
  faClipboardList,
  faBalanceScale,
  faTable,
  faWallet,
  faBuilding
} from '@fortawesome/free-solid-svg-icons'


import { configChildRoutes } from './routerConfigChildRoutes'
import { intersectEvery } from '@/helpers/arrayHelper'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faBalanceScale, faWallet, faBuilding,
  faTable, faUsers, faExclamationTriangle, faClipboardList, faBalanceScale)


@Component({
  components: {
    SlickList,
    SlickItem
  },
  directives: {
    handle: HandleDirective
  }
})
export default class BackendConfigList extends Vue {
  get settings() {
    return configChildRoutes
      .filter((route) => intersectEvery(route.meta.privileges, this.$auth.userPrivileges))
      .filter((route) => (!route.meta.staffOnly || this.$auth.isEchoPrmStaff))
  }

  public created() {
    //
  }
}
</script>

<style lang="scss">
@import '@/variables.scss';

.settings-overview-container {
  display: flex;
  flex-wrap: wrap;

  .box.module {
    display: flex;
    margin: 0.5em;
    height: 8em;
    width: calc(100% - 1em);

    span.icon.is-small {
      margin-right: 0.5rem;
    }

    .title.is-5 {
      margin-top: 0;
    }

    @include tablet {
      width: calc(50% - 1em);
    }

    a {
      width: 100%;
    }
  }
}

.identifier-field {
  margin-bottom: 1em;

  .handle {
    cursor: row-resize;
  }
}
</style>
