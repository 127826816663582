<template>
  <!-- dropdown to select columns -->
  <b-dropdown
    v-model="selectedColumns"
    multiple
    aria-role="list"
    title="Select the columns to be displayed in the table"
    scrollable
    :max-height="300"
    @input="onSelectedColumnsChanged"
  >
    <template #trigger>
      <b-button v-if="buttonText" icon-left="columns">
        {{ buttonText }}
        <b-tag
          v-if="selectedColumns.length !== columns.length"
          rounded
          size="is-small"
        >{{ selectedColumns.length }} / {{ columns.length }}</b-tag>
      </b-button>
      <b-button v-else icon-left="columns">
        <b-tag
          v-if="selectedColumns.length !== columns.length"
          rounded
          size="is-small"
        >{{ selectedColumns.length }} / {{ columns.length }}</b-tag>
      </b-button>
    </template>

    <b-dropdown-item
      v-for="(column, i) in columns"
      :key="i"
      :value="column"
      aria-role="listitem"
    >{{ column }}</b-dropdown-item>

    <!-- separator -->
    <b-dropdown-item aria-role="listitem" separator />

    <!-- select all columns -->
    <b-dropdown-item aria-role="listitem" @click="selectedColumns = columns; onSelectedColumnsChanged()">
      <b-icon icon="check-square" />Select All
    </b-dropdown-item>

    <!-- unselect all columns -->
    <b-dropdown-item
      aria-role="listitem"
      @click="selectedColumns = []; onSelectedColumnsChanged()"
    >
      <b-icon icon="minus-square" />Deselect All
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { Component, Vue, ModelSync, Prop, Watch } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faColumns, faMinusSquare, faSquareFull } from '@fortawesome/free-solid-svg-icons'


library.add(faMinusSquare, faCheckSquare, faSquareFull, faColumns)

@Component({
})
export default class VTableColumnsDropdown extends Vue {
  // v-model for selected columns
  @ModelSync('hiddenColumns', 'updateColumns', { type: Array, default: () => [] }) hiddenColumnsSync!: string[]

  @Prop({ type: Array, default: () => [] }) readonly columns!: string[]

  @Prop({ type: String, default: () => '' }) readonly buttonText!: string


  // hiddenColumnsSync is the inverse of selectedColumns
  selectedColumns: string[] = []

  @Watch('hiddenColumnsSync', { immediate: true })
  @Watch('columns')
  private onHiddenColumnsSyncChanged() {
    this.selectedColumns = this.columns.filter((column) => !this.hiddenColumnsSync.includes(column))
  }

  private onSelectedColumnsChanged() {
    this.hiddenColumnsSync = this.columns.filter((column) => !this.selectedColumns.includes(column))
  }

  // if a hiddenColumnSync is set whcih is not in the columns array, remove it
  // todo this ends up in an infintite loop for some reason
  // @Watch('columns')
  // private updateColumns() {
  //   this.hiddenColumnsSync = this.hiddenColumnsSync.filter((column) => this.columns.includes(column))
  // }
}
</script>

<style lang="scss">
.v-table-columns-dropdown {
  display: initial;
}
</style>
