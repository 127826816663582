<template>
  <node-view-wrapper>
    <div
      ref="youtube"
      data-drag-handle
      draggable="true"
      content-editable="false"
      class="youtube-frame editor-youtube-wrapper"
    >
      <!--   <p
        class="control">
        <b-button
          class="drag"
          size="is-small"
          icon-left="grip-vertical"
        />
      </p>-->
      <iframe :src="createEmbedUrl(node.attrs.src)" />
      <b-field class="edit-video-controls">
        <p class="control">
          <b-button class="edit-video is-control" size="is-small">Select Video</b-button>
        </p>
        <p class="control edit-video">
          <b-button
            class="is-control"
            expanded
            size="is-small"
            @click="editVideo"
          >Edit Youtube Video</b-button>
        </p>
        <!-- <p class="control">
        <b-button
          size="is-small"
          has-icon-left="grip-vertical"
          expanded
        />
        </p>-->
      </b-field>
    </div>
    <b-modal
      :active.sync="videoModalActive"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">YouTube Url</p>
        </header>
        <section class="modal-card-body">
          <b-field :message="videoMessage" :type="videoType" label="Url" horizontal>
            <b-input v-model="videoLinkUrl" />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <div>
            <b-button
              @click=";(videoModalActive = false), (videoType = ''), (videoMessage = '')"
            >Cancel</b-button>

            <b-button @click="onRemoveYoutubeLink()">Remove Video</b-button>
            <b-button type="is-primary" @click="onUpdateYoutubeLink">Update Video</b-button>
          </div>
        </footer>
      </div>
    </b-modal>
  </node-view-wrapper>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { NodeViewWrapper } from '@tiptap/vue-2'

@Component({
  components: {
    NodeViewWrapper
  }
})
export default class VYoutubeExtension extends Vue {
  @Prop()
  readonly nodeViewProps!: object

  public isDraggable = false
  public videoLinkUrl = ''
  public videoModalActive = false
  public videoType = ''
  public videoMessage = ''
  public regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/

  get addNode() {
    return (this as any).$parent.$parent.addNode
  }

  @Watch('videoLinkUrl', { immediate: true })
  onImageChangeUrl() {
    this.videoMessage = ''
    this.videoType = ''
  }

  public youtubeParser(url: string) {
    const match = url.match(this.regExp)
    url = match && match[7].length === 11 ? match[7] : ''
    return url
  }

  public createEmbedUrl(urlOrId: string) {
    return 'https://www.youtube.com/embed/' + (urlOrId.length === 11 ? urlOrId : this.youtubeParser(urlOrId))
  }

  public onRemoveYoutubeLink() {
    (this as any).deleteNode()
  }

  public onUpdateYoutubeLink() {
    if (this.videoLinkUrl.match(this.regExp)) {
      (this as any).updateAttributes({ src: this.videoLinkUrl })
      this.videoModalActive = false
    } else {
      this.videoType = 'is-danger'
      this.videoMessage = 'please enter a valid youtube url'
    }
    if (this.videoLinkUrl == '') {
      this.videoType = 'is-danger'
      this.videoMessage = 'field is empty'
    }
  }

  public editVideo() {
    this.videoLinkUrl = (this as any).node.attrs.src
    this.videoModalActive = true
  }
}
</script>


<style lang="scss">
.edit-video-controls {
  p.control {
    margin-top: 0;
  }

  .edit-video {
    flex-grow: 1;
  }
}

.youtube-frame {
  position: relative;
}
</style>
