<template>
  <section class="element-base-config">
    <b-notification
      v-if="formBaseData.publishingState === 'deleted'"
      type="is-danger"
      aria-close-label="Close notification"
      role="alert"
    >
      This {{ elementNameString }} is subject to be deleted and will soon be automatically removed.
      <b-button
        @click="formBaseData.publishingState = 'archived'; $emit('save')"
      >Restore {{ elementNameString }}</b-button>
    </b-notification>

    <h3
      v-if="selectableFormGroups.length > 0"
      class="title is-5"
    >{{ capitalizeFirstLetter(elementNameString) }} "{{ formBaseData.name }}" in {{ widgetString }} "{{ groupName }}"</h3>

    <b-field grouped expanded>
      <b-field v-if="selectableFormGroups.length > 0" :label="widgetString">
        <b-select v-model="formBaseData.public.groupID" :disabled="disabled">
          <option
            v-for="option in selectableFormGroups"
            :key="option.value"
            :value="option.value"
          >{{ option.key }}</option>
        </b-select>
      </b-field>

      <b-field :label="`Internal ${elementNameString} name`" expanded>
        <b-input v-model="formBaseData.name" placeholder="Name" required :disabled="disabled" />
      </b-field>

      <b-field
        label="State"
        :type="{'is-success':formBaseData.publishingState == 'published','is-warning':formBaseData.publishingState == 'draft'}"
      >
        <b-select v-model="formBaseData.publishingState" :disabled="disabled">
          <option value="draft">Draft</option>
          <option v-if="!isGroupArchived && !isGroupDeleted" value="published">Published</option>
          <option value="archived">Archive</option>
        </b-select>
      </b-field>

      <b-field>
        <template slot="label">
          Info
          <VTooltipIconHelp
            position="is-left"
            text="Information regarding this database document."
          />
        </template>
        <VRecordMeta
          position="is-bottom-left"
          :document-path="documentPath"
          :record-meta="formBaseData._meta"
          :required-privileges="documentPrivileges"
          :disallow-reset-to-revision="disallowResetToRevision"
        />
      </b-field>

      <b-field v-if="showOrder">
        <template slot="label">
          Order / Priority
          <VTooltipIconHelp
            position="is-left"
            text="When multiple Elements match a category, the higher priority one is used."
          />
        </template>
        <b-numberinput
          v-model="formBaseData.public.order"
          max="999"
          min="-999"
          controls-position="compact"
        />
      </b-field>
    </b-field>

    <!-- <b-field label="Internal Description">
      <b-input
        v-model="formBaseData.description"
        type="textarea"
        minlength="0"
        maxlength="500"
        placeholder="Optional Category Description"
      />
    </b-field>-->

    <VModuleElementReferences v-model="formBaseData" :disabled="disabled" />
  </section>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator'
import { BaseElementDB, BaseGroupDB, ModuleType } from '../modules/typeModules'

import VRecordMeta from '@/components/VRecordMeta.vue'
import VModuleElementReferences from '@/components/VModuleElementReferences.vue'
import { ModuleManager } from '@/modules/moduleManager'
import { hasDBid } from '@/types/typeGeneral'
import { AppPreview } from './VBackendAppPreview.vue'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { sortGroups } from '@/shared/appDataHelper'

@Component({
  components: {
    VRecordMeta,
    VModuleElementReferences
  }
})
export default class VModuleElementBaseConfig extends Vue {
  @Model()
  public formBaseData!: BaseElementDB & hasDBid

  @Prop({ type: Boolean, required: false, default: () => false })
  public readonly disabled!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public readonly showOrder!: boolean

  @Prop({ type: Array, required: false, default: () => [] })
  private readonly groups!: Array<hasDBid & BaseGroupDB>

  @Prop({ type: String, required: false, default: () => '' })
  private readonly moduleType!: ModuleType

  @Prop({ type: Boolean, required: false, default: () => false })
  private readonly disallowResetToRevision!: boolean

  @Prop({ type: String, required: false, default: 'element' })
  private readonly elementNameString!: string

  @Prop({ type: String, required: false, default: 'Widget' })
  public readonly widgetString!: string

  public get selectableFormGroups() {
    const selectedGroup = this.groups.find((g) => g.id === this.formBaseData.public.groupID)

    // clone, as sorting mutates the array. map id explicitely, as cloning remove non-enumerable properties
    return cloneObject(this.groups.map((g) => ({ ...g, id: g.id })))
      .sort(sortGroups)
      .filter((d) => d.publishingState !== 'deleted')
      // filter same group type
      .filter((d) => d.public.groupType === selectedGroup?.public.groupType)
      .map((el) => ({
        key: el.name,
        value: el.id
      }))
  }

  get moduleClass() {
    return ModuleManager.getModuleClassByType(this.moduleType)
  }

  get documentPath() {
    return this.moduleClass.getElementDocDbReference(this.$auth.tenant.id, this.formBaseData.id).path
  }

  get documentPrivileges() {
    return this.moduleClass.authPrivileges
  }

  get groupName() {
    return this.selectableFormGroups.find((e) => e.value == this.formBaseData.public.groupID)?.key || ''
  }

  get isGroupArchived() {
    return this.groups.find((e) => e.id == this.formBaseData.public.groupID)?.publishingState === 'archived'
  }

  get isGroupDeleted() {
    return this.groups.find((e) => e.id == this.formBaseData.public.groupID)?.publishingState === 'deleted'
  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }


  public mounted() {
    // this.initIdentifierDefinitions()
  }

  @Watch('formBaseData', { immediate: true, deep: true })
  @Watch('groups', { immediate: false, deep: false })
  private onElementChange(old: any, newD: any) {
    const group = this.groups.find((g) => g.id === this.formBaseData.public.groupID)
      || { ...this.moduleClass.groupDB, id: this.formBaseData.public.groupID }

    // cloning removed the non-enumerable properties, so we add the id back
    const groupClone = cloneObject({ ...group, id: group.id })


    // ci has its custom logic to show some preview groups
    // when previewing custom or script untrusted js might steal credentials
    if (this.moduleType !== 'Ci' && this.moduleType !== 'Script' && this.moduleType !== 'Custom')
      AppPreview.setElements(
        this.moduleType,
        [this.formBaseData],
        groupClone
      )

    if (this.moduleType === 'Data')
      AppPreview.setMode('references', false)

    // if a groupID is part of the query, apply it
    if (this.$route.query.groupID) {
      this.formBaseData.public.groupID = this.$route.query.groupID as string
    }

    // if an order is part of the query, apply it
    if (this.$route.query.order) {
      this.formBaseData.public.order = parseInt(this.$route.query.order as string)
    }
  }
}
</script>

<style lang="scss">
.element-base-config {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-content: space-between;
  // flex: 1;
  /* flex-grow: 1; */
  width: 100%;
}

.tabs-menu {
  a {
    text-decoration: none !important;
  }

  section.tab-content {
    padding: 0 !important;

    .tab-item {
      padding: 1em 0 !important;
    }
  }
}
</style>
