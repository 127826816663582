<template>
  <form id="register-form" @submit.prevent="onRegister()">
    <slot name="header">
      <h2 class="title is-2">Register</h2>
      <h3 v-if="formCompany" class="subtitle is-5">User registration for {{ formCompany }}</h3>
    </slot>
    <slot name="main">
      <section class>
        <b-field
          :type="{ 'is-danger': hasErrorRegistrationCode }"
          :message="{ 'Registration Code does not match email adress.': hasErrorRegistrationCode }"
        >
          <template slot="label">
            Registration Code
            <VTooltipIconHelp
              text="To register you must input a Registration Code which you receive from the company you want to register for."
            />
          </template>
          <b-input
            v-model="formRegistrationCode"
            :disabled="formRegistrationCodeDisabled"
            type="text"
            placeholder="Registration Code"
            required
          />
        </b-field>

        <b-field label="Email">
          <b-input
            v-model="formEmail"
            :disabled="formEmailDisabled"
            type="email"
            placeholder="Your email"
            required
          />
        </b-field>

        <b-field label="Password">
          <b-input
            v-model="formPassword"
            type="password"
            password-reveal
            placeholder="Your password"
            required
          />
        </b-field>
        <b-field
          label="Repeat Password"
          :type="{ 'is-danger': hasErrorPasswordMissmatch }"
          :message="{ 'Passwords do not match': hasErrorPasswordMissmatch }"
        >
          <b-input
            v-model="formPassword2"
            type="password"
            password-reveal
            placeholder="Your password"
            required
            minlength="6"
          />
        </b-field>
      </section>
    </slot>
    <slot name="footer" :is-loading="isLoading">
      <b-button
        class="button is-primary is-pulled-right"
        :loading="isLoading"
        native-type="submit"
      >Register</b-button>
    </slot>
  </form>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({

})
export default class VFormRegisterView extends Vue {
  public formRegistrationCode = ''
  public formRegistrationCodeDisabled = false
  public formEmail = ''
  public formEmailDisabled = false
  public formPassword = ''
  public formPassword2 = ''
  public formRememberMe = false

  public isLoading = false
  public formCompany = ''

  public onClose() {
    console.log('on close')

    if (this.$parent && (this.$parent as any).close)
      (this.$parent as any).close()
  }

  get hasErrorPasswordMissmatch() {
    return this.formPassword != this.formPassword2
  }

  get hasErrorRegistrationCode() {
    return !this.$auth.validateRegistrationCode(this.formRegistrationCode, this.formEmail, this.formCompany)
  }

  public async onRegister() {
    if (!this.formEmail || !this.formPassword || this.hasErrorPasswordMissmatch || this.hasErrorRegistrationCode)
      return

    this.isLoading = true
    await this.$auth.registerEmailPassword(this.formEmail, this.formPassword)
      .then(async () => {
        this.formEmail = ''
        this.formPassword = ''
        this.formRememberMe = false
        this.onClose()
        await this.$router.push({ name: 'backend' })
      })
      .catch((e) => this.$helpers.notification.Error('Auth failure. ' + e))
      .finally(() => {
        this.isLoading = false
      })
  }

  @Watch('$route', { immediate: true })
  public onRouteChanged() {
    this.formRegistrationCode = this.$route.query.registrationcode as string || ''
    this.formEmail = this.$route.query.email as string || ''
    this.formCompany = this.$route.query.companyname as string || ''

    if (this.formRegistrationCode)
      this.formRegistrationCodeDisabled = true
    if (this.formEmail)
      this.formEmailDisabled = true
  }
}
</script>

<style lang="scss">
</style>
