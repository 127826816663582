<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import deepmerge from 'deepmerge'
import deepDiff, { diff, applyDiff } from 'deep-diff'
import { convertTimestamps } from 'convert-firebase-timestamp'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { firebase } from '@/firebase'
import { throttle } from 'throttle-debounce'
import VModalSaveChanges from '../VModalSaveChanges.vue'
import { NavigationGuardNext, Route } from 'vue-router'
import { handlePromiseError } from '@/helpers/notificationHelper'

// todo add vComponent for basic sortable table including view
@Component({})
export default class VCustomVueFireBindMixin extends Vue {
  private snapshotUnbindHandles: SnapshotUnbindHandle[] = []
  /** set to false to prevent "confirm leave page prompt" */
  protected firestore_isUnsavedChangesTrapActive = true

  // add an unbindhandle which will be automatically disposed when the component is destroyed
  public $unbindHandle<T extends SnapshotUnbindHandle | SnapshotUnbindHandle[]>(unbindHandle: T): T {
    this.snapshotUnbindHandles = Array.isArray(unbindHandle)
      ? [...this.snapshotUnbindHandles, ...unbindHandle]
      : [...this.snapshotUnbindHandles, unbindHandle]
    return unbindHandle
  }

  private changeDBMemory?: {
    [key: string]: {
      path: string
      unbindHandle: SnapshotUnbindHandle
      memoryDocData: any
      initialDocData: any // used to determine if any changes occured
      reset: boolean
    }
  } = undefined

  // selectively applies DB changes to the local object, keeping the other props unchanged
  public $bindSnapshot<T>(
    varName: string,
    ref: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
    beforeSet = (data: T) => data,
    onError: (e: any) => void = (e) => e,
    options = { keepAlive: false } // todo keeps the listener alive on component distroy
  ) {
    return new Promise((res, rej) => {
      if (!(varName in this)) throw `${varName} not found in class`

      if (!this.changeDBMemory) this.changeDBMemory = {}

      // todo make it possible to identify the document by reference and keep the listener alive acros different components
      if (varName in this.changeDBMemory) {
        this.changeDBMemory[varName].unbindHandle()
        // this.changeDBMemory[varName].memoryDocData = {}
        console.debug('unbindHandle called', varName)
      }

      this.changeDBMemory[varName] = {
        path: ref.path,
        unbindHandle: () => {
          //
        },
        memoryDocData: {},
        initialDocData: null,
        reset: true
      }

      // let onceCalled = false
      // const helper = (d?: T & hasDBid) => {
      //   if (!onceCalled && d) {
      //     onceCalled = true
      //     onOnce(d)
      //   }
      // }

      // throttle snapshot updates to only take effect every 1 seconds.
      const onSnapshotThrottle = throttle(1000, (d: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
        if (!d.exists) {
          onError(`No doc found for path ${ref.path}`)
          rej(`No doc found for path ${ref.path}`)
          return
        }

        const newDoc = beforeSet({
          ...(convertTimestamps(d.data({
            serverTimestamps: 'estimate'
          })) as T), id: d.id
        })

        if (this.changeDBMemory?.[varName]?.initialDocData === null) {
          this.changeDBMemory[varName].initialDocData = newDoc
        }

        // set the variable with db_ suffix so the component may check what the current value in the db doc
        if ((this as any)[varName + '_db'])
          (this as any)[varName + '_db'] = newDoc

        console.debug('onSnapshotThrottle', this.changeDBMemory?.[varName].memoryDocData, newDoc, (this as any)[varName])

        this.applySelectiveUpdate(this.changeDBMemory?.[varName].memoryDocData, newDoc, (this as any)[varName])

        res(newDoc)
      })


      const snapshotUnbindHandle = ref.onSnapshot(
        (d) => {
          console.debug('onSnapshot ------------------------------------------ ', d.data())
          onSnapshotThrottle(d)
        },
        (e) => {
          onError(e)
          rej(e)
        }
      )

      this.changeDBMemory[varName].unbindHandle = () => {
        snapshotUnbindHandle()
        onSnapshotThrottle.cancel()
      }
    })
  }

  private applySelectiveUpdate<T extends Record<string, unknown>>(memoryObj: T | any, newObj: T, targetObj: T) {
    const arrayEquals = (a: any[], b: any[]) => a.length === b.length && a.every((v, i) => v === b[i])

    // changes in DB in respect to last data
    const dbChanges = diff(memoryObj, newObj)
    console.debug('applySelectiveUpdate', dbChanges)

    let copyOfTargetDoc = deepmerge<T>(targetObj as T, {} as T, { clone: true })

    const isEmptyObject = (obj: any) => {
      for (const i in obj) return false
      return true
    }

    if (isEmptyObject(memoryObj)) {
      // set memory and target to the same values initially
      copyOfTargetDoc = deepmerge<T>(newObj as T, {} as T, { clone: true })
    } else if (dbChanges) {
      // all changes in respect to last snapshot (DB <> newObj) will be overidden
      // this trick is used to accomplish: changes memoryDoc/newObj > applied to targetDoc
      applyDiff(copyOfTargetDoc, newObj, (a, b, diff) => {
        console.log(diff)
        const changeInDbInRespectToPreviousSnapshot = dbChanges.some((dbDiff) =>
          arrayEquals(dbDiff.path || [], diff.path || [])
        )
        return changeInDbInRespectToPreviousSnapshot
      })
    }

    Object.assign(targetObj, copyOfTargetDoc)
    Object.assign(memoryObj, newObj)
  }

  beforeDestroy() {
    Object.keys(this.changeDBMemory || {}).forEach((key) => {
      this.changeDBMemory?.[key].unbindHandle()
      delete this.changeDBMemory?.[key]
    })
    this.$disposeSnapshots()
  }

  beforeRouteUpdate(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    handlePromiseError(this.handleUnsavedChanges(to, from, next))
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    handlePromiseError(this.handleUnsavedChanges(to, from, next))
  }


  public async handleUnsavedChanges(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
    // if is new item allow navigation so it can be created
    if (((from?.params?.id || '') === 'new')) {
      next()
      return
    }

    // do nothing if only the hash changed
    if (to.path === from.path && to.hash !== from.hash) {
      next()
      return
    }

    // do nothing if only query params changed
    if (to.path === from.path && to.query !== from.query) {
      next()
      return
    }


    let dataHasBeenChanged = this.hasDataChanged()

    let doLeavePage = true
    let doSave = false

    const hasSaveFunc = typeof (this as any).$save === 'function'

    if (dataHasBeenChanged && this.firestore_isUnsavedChangesTrapActive) {
      const result = await new Promise((res, rej) =>
        this.$buefy.modal.open({
          parent: this,
          component: VModalSaveChanges,
          hasModalCard: true,
          customClass: 'custom-class custom-class-2',
          trapFocus: true,
          props: {
            displaySaveOption: hasSaveFunc
          },
          events: {
            'cancel': () => {
              console.log('cancel')

              res('cancel')
            },
            'save-and-leave': () => {
              console.log('save-and-leave')

              res('save-and-leave')
            },
            'discard-and-leave': () => {
              console.log('discard-and-leave')

              res('discard-and-leave')
            }
          }
        })
      )
      if (result === 'cancel') {
        doSave = false
        doLeavePage = false
      } else if (result === 'save-and-leave') {
        doSave = true
        doLeavePage = true
      } else if (result === 'discard-and-leave') {
        doSave = false
        doLeavePage = true
      }
    }

    let errorWhileSaving = false

    // save changes to Db when leaving and changes are present
    if (doSave && dataHasBeenChanged && hasSaveFunc) {
      try {
        const returnValue = await (this as any).$save()
        // if the save function returns false, dont leave the page
        if (returnValue === false) {
          errorWhileSaving = true
        }
      } catch (error) {
        // dont leave page if saving was not successful
        errorWhileSaving = true
        this.$helpers.notification.Error(error)
      }
    }

    if (errorWhileSaving) {
      this.$buefy.toast.open({
        indefinite: false,
        pauseOnHover: true,
        message: 'Error while saving',
        type: 'is-danger',
        queue: false
      })
    }

    if ((doLeavePage || doSave) && !errorWhileSaving) {
      next()
    } else {
      next(false)
    }
  }

  /**
   * Custom data change function to be overwritten by the component
   * Filter out changes that should not be considered as data changes
   *
   * @param diffArray
   * @param memoryDoc
   * @param currentDoc
   *
   * @returns true if data has changed
   */
  public $ignoreDataChanged<T>(diffArray: deepDiff.Diff<any, any>[], memoryDoc: T, currentDoc: T) {
    return diffArray
  }

  public hasDataChanged() {
    let dataHasBeenChanged = false

    Object.keys(this.changeDBMemory || {}).forEach((varName) => {
      const memoryDoc = this.changeDBMemory?.[varName].memoryDocData || {}
      const currentDoc = (this as any)[varName] || {}
      const diffArray = diff(memoryDoc, currentDoc) || []

      let filteredDiffArray = diffArray.filter((diff) => {
        const onlyNewLocales = (diff.kind === 'N'
          && (diff.path?.length || 0 >= 2)
          && diff.path?.[diff.path?.length - 2] === 'locales'
          && diff.rhs === '')
        || ( // form locale was removed
          diff.kind === 'D'
          && diff.path?.[diff.path?.length - 1] === 'default'
          && diff.lhs === ''
        )

        if (onlyNewLocales) {
          console.log('Ignoring new locale change:', diff)
          return false
        }

        const ignorePathSegment = ['title', 'description', 'labelTrue', 'labelFalse', 'columns', 'rows', 'choices', 'rateValues', 'items']
        const ignoreChange = ignorePathSegment.some((ignorePathSegment) => diff.path?.includes(ignorePathSegment)
          && diff.kind === 'D'
        )

        if (ignoreChange) {
          console.log('Ignoring change in path segment:', diff)
          return false
        }

        const ignoreMeta = diff.path?.includes('_meta')
        if (ignoreMeta) {
          console.log('Ignoring _meta change:', diff)
          return false
        }

        return true
      })

      filteredDiffArray = this.$ignoreDataChanged(filteredDiffArray, memoryDoc, currentDoc)

      if (filteredDiffArray.length > 0) {
        console.log(filteredDiffArray)
        console.log('currentDoc', currentDoc)
        console.log('memoryDoc', memoryDoc)
        dataHasBeenChanged = true
      }
    })
    return dataHasBeenChanged
  }

  public $disposeSnapshots() {
    const numberOfDisposedSnapsots = this.snapshotUnbindHandles.length
    this.snapshotUnbindHandles.forEach((unbindHandle) =>
      unbindHandle()
    )
    this.snapshotUnbindHandles = []
    return numberOfDisposedSnapsots
  }
}
</script>
