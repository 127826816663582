<template>
  <section>

    <b-field label="Email">
      <b-input v-model="$auth.userEmail" type="email" placeholder="Your email" disabled />
    </b-field>

    <b-field label="Name">
      <b-input v-model="$auth.userName" type disabled />
    </b-field>

    <b-field label="Privileges">
      <b-taginput
        v-model="$auth.userPrivileges"
        ellipsis
        disabled
        placeholder=""
      />
    </b-field>

    <b-field label="Password">
      <router-link class="button" :to="{ name: 'reset' }">Reset Password</router-link>
    </b-field>

    <b-button
      class="button is-danger"
      :loading="isLoading"
      native-type="submit"
      @click="onDelete()"
    >Delete User</b-button>
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({

})
export default class VFormRegisterView extends Vue {
  public isLoading = false


  public async onDelete() {
    this.$buefy.dialog.confirm({
      title: 'Deleting User',
      message: `Are you sure you want to <b>delete user ${this.$auth.userEmail}</b>? This action cannot be made undone.`,
      confirmText: 'Delete User',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: async () => {
        this.isLoading = true
        await this.$auth.deleteUser()
          .then(() => {
            this.$helpers.notification.Error('User removed.')
          })
          .catch((e) => this.$helpers.notification.Error('Could not remove user. ' + e))
        this.isLoading = false
      }
    })
  }
}
</script>

<style lang="scss">
</style>
