<template>
  <section class="is-limited-width-content backend-config-legal">
    <div v-if="!isLoading" class="level record-meta">
      <div class="level-left" />
      <VRecordMeta
        position="is-bottom-left"
        class="level-right"
        :record-meta="appConfig._meta"
        :document-path="documentPath"
        :required-privileges="documentPrivileges"
        :disallow-reset-to-revision="false"
      />
    </div>

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">Legal Pages</h3>
        <span
          class="setting-description"
        >Links to legal pages are shown at the bottom of each App page.</span>
      </div>

      <div class="column is-three-quarters setting-column">
        <div class="setting-box card">
          <h3 class="title is-5">Imprint</h3>
          <b-field label="Link Text">
            <VInputLocalizedText
              v-model="appConfig.public.legal.imprint.linkTitle"
              is-translateable
              placeholder="title"
            />
          </b-field>
          <VInputLocalizedEditor
            v-model="appConfig.public.legal.imprint.text"
            is-translateable
            limit-height="30em"
          />
        </div>

        <div class="setting-box card">
          <h3 class="title is-5">Terms of service</h3>
          <b-field label="Link Text">
            <VInputLocalizedText
              v-model="appConfig.public.legal.terms.linkTitle"
              is-translateable
              placeholder="title"
            />
          </b-field>
          <VInputLocalizedEditor
            v-model="appConfig.public.legal.terms.text"
            is-translateable
            limit-height="30em"
          />
        </div>

        <div class="setting-box card">
          <h3 class="title is-5">Privacy policy</h3>
          <b-field label="Link Text">
            <VInputLocalizedText
              v-model="appConfig.public.legal.privacy.linkTitle"
              is-translateable
              placeholder="title"
            />
          </b-field>
          <VInputLocalizedEditor
            v-model="appConfig.public.legal.privacy.text"
            is-translateable
            limit-height="30em"
          />
        </div>

        <div class="setting-box card">
          <h3 class="title is-5">Other</h3>
          <b-field label="Link Text">
            <VInputLocalizedText
              v-model="appConfig.public.legal.other.linkTitle"
              is-translateable
              placeholder="title"
            />
          </b-field>
          <VInputLocalizedEditor
            v-model="appConfig.public.legal.other.text"
            is-translateable
            limit-height="30em"
          />
        </div>
      </div>
    </div>
    <hr />

    <div class="notification is-grey" style="margin-top: 50px;">
      <div class="buttons is-right">
        <b-button type="is-success" @click="$save">Update</b-button>

        <b-button @click="initialise">Cancel</b-button>
      </div>
    </div>

    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'


import VInputLocalizedText from '@/components/VInputLocalizedText.vue'
import VInputLocalizedEditor from '@/components/editor/VInputEditorLocalized.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'

import TenantManager from '../../database/tenantManager'
import databaseSchema from '@/database/databaseSchema'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import VFormDataDefinition from '@/components/VFormDataDefinition.vue'


@Component({
  components: {
    VInputLocalizedEditor,
    VInputLocalizedText,
    VRecordMeta,
    VFormDataDefinition
  }
})
export default class BackendConfigLegal extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public isLoading = true

  private appConfig = { ...TenantManager.defaultAppConfigDB }

  get documentPath() {
    return TenantManager.getDbAppConfigDocReference(this.$auth.tenant.id).path
  }

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.DATA.APP_CONFIG.__PRIVILEGES__
  }

  public async initialise() {
    this.isLoading = true
    await this.$bindSnapshot('appConfig', TenantManager.getDbAppConfigDocReference(this.$auth.tenant.id))
    this.isLoading = false
  }

  // @Watch('tenant')
  // onComapynChanged(){
  //   this.appConfig = merge(this.tenant.public.config.appConfig, TenantManager.defaultDocDB.public.config.appConfig)
  // }

  public async $save() {
    try {
      this.isLoading = true

      await TenantManager.updateAppConfig(this.$auth.tenant.id, this.$auth.userEmail, this.appConfig)
      this.$helpers.notification.Success('Data updated')
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading = false
    }
  }

  async created() {
    await this.initialise()
  }
}
</script>

<style lang="scss">
.backend-config-legal {
  .colors .field.is-grouped .field {
    flex-shrink: 1;
  }

  .cropper {
    height: 50vh;
    background: #ddd;
  }

  .editor-max-width {
    max-height: 30em;
  }
}
</style>
