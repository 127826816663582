<template>
  <section v-shortkey="['ctrl', 's']" class="is-grey crud-wrapper" @shortkey="onClickSave">
    <div class="crud-inner-wrapper">
      <div class="button-group">
        <b-button
          v-if="!hideRemove"
          :loading="isRemoving"
          :disabled="disabled"
          :type="deleteButtonType"
          :icon-right="hideDeleteIcon ? '' : 'trash'"
          @click="onClickRemove"
        >{{ deleteButtonText }}</b-button>
      </div>
      <div class="button-group">
        <b-button
          v-if="!hideCancel"
          :loading="isCanceling"
          :disabled="disabled"
          @click="onClickCancel"
        >{{ cancelButtonText }}</b-button>

        <!-- default slot -->
        <slot />

        <b-button
          v-if="!hideSave"
          v-shortkey="['meta', 's']"
          :loading="isSaving"
          :disabled="disabled"
          :title="isAutosave ? 'Data is saved automatically upon change. Clicking this button is not required' : `click to ${saveButtonText}`"
          type="is-success"
          @click="onClickSave"
          @shortkey="onClickSave"
        >
          <span v-if="isAutosave" class="blob white" />
          {{ isAutosave ? 'autosave active' : saveButtonText }}
        </b-button>
      </div>
    </div>
  </section>
</template>

<script lang='ts'>
import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit, Prop } from 'vue-property-decorator'

@Component
export default class VCrudControl extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public disabled!: boolean

  @Prop({ type: String, required: false, default: 'Save' })
  public saveButtonText!: string

  @Prop({ type: String, required: false, default: 'is-danger' })
  public deleteButtonType!: string

  @Prop({ type: String, required: false, default: 'Cancel' })
  public cancelButtonText!: string

  @Prop({ type: String, required: false, default: 'Remove' })
  public deleteButtonText!: string

  @Prop({ type: Boolean, required: false, default: () => false })
  public isSaving!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public isCanceling!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public isRemoving!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public hideSave!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public hideCancel!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public hideRemove!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isAutosave!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public hideDeleteIcon!: boolean

  @Emit('save')
  onClickSave() {
    if (this.isAutosave) this.$helpers.notification.Info('Data on this page is automatically saved when changed. It is not required to click the Autosave button.')
    return true
  }

  @Emit('cancel')
  onClickCancel() {
    return true
  }

  @Emit('remove')
  onClickRemove() {
    return true
  }
}
</script>


<style lang="scss">
.crud-wrapper {
  //to move to bottom
  margin-top: auto;

  .crud-inner-wrapper {
    margin-top: 2rem;
    margin-bottom: 1rem;
    background-color: whitesmoke;
    border-radius: 4px;
    padding: 1em;
    display: flex;
    justify-content: space-between;

    .button-group {
      display: flex;

      button {
        margin: 0 0.3em;
      }
    }

    .blob {
      background: black;
      display: inline-block;
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgb(0 0 0 / 100%);
      margin-right: 0.3em;
      height: 0.7em;
      width: 0.7em;
      transform: scale(1);
      animation: pulse-black 2s infinite;
    }

    .blob.white {
      background: white;
      box-shadow: 0 0 0 0 rgb(255 255 255 / 100%);
      animation: pulse-white 2s infinite;
    }

    @keyframes pulse-white {
      0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(255 255 255 / 70%);
      }

      70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgb(255 255 255 / 0%);
      }

      100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
      }
    }
  }
}
</style>
