<template>
  <div class="modal-card" style="width: auto;">
    <header class="modal-card-head">
      <h1 class="modal-card-title">Accept updated {{ termsName }}</h1>
    </header>
    <section class="modal-card-body">
      <div class="title is-4">{{ termsName }}</div>
      <div class="label">{{ daysToAcceptTOS }} days left to accept {{ termsName }}</div>
      <div class="tos" @scroll="scrolledToBottom">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="terms-container" v-html="termsHtml" />
      </div>
      <b-checkbox
        v-model="formAccepted"
        :disabled="!reachedBottom"
      >Accept {{ termsName }} {{ !reachedBottom? '(scroll down to be able to accept)':'' }}</b-checkbox>
    </section>
    <footer class="modal-card-foot">
      <b-button class="button" type="is-text" @click="onClose">Close</b-button>
      <b-button
        type="is-success "
        :disabled="!formAccepted"
        native-type="submit"
        @click="onAccept()"
      >Accept {{ !formAccepted ? '(check checkbox above)' : '' }}</b-button>
    </footer>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'


import TosManager from '@/database/tosManager'
import { Terms } from '@/types/typeTenant'

@Component({})
export default class VModalTOS extends Vue {
  @Prop({ required: true })
  public terms!: Terms

  @Prop({ required: true })
  public type!: 'terms' | 'odp'

  get accepted() {
    return TosManager.areTOSAccepted(this.terms, this.type)
  }

  get termsHtml() {
    return TosManager.getTermsHtml(this.terms, this.type)
  }

  get termsName() {
    return this.type === 'terms' ? 'Terms Of Service / AGB' : 'Order Data Processing'
  }

  public onClose() {
    this.$emit('closed')
    ; (this.$parent as any).close()
  }

  public onAccept() {
    if (this.formAccepted) this.$emit('accepted')

    ; (this.$parent as any).close()
  }

  public formAccepted = false
  public reachedBottom = false

  @Watch('accepted', { immediate: true })
  onAcceptedChanged() {
    this.formAccepted = this.accepted
  }

  get daysToAcceptTOS() {
    return TosManager.daysUntilToAcceptTOS(this.terms)
  }

  public scrolledToBottom(event: any) {
    const el = event.srcElement
    // console.log(el.scrollTop + ' ' + el.scrollHeight + ' ' + el.clientHeight)
    if (!this.reachedBottom) {
      if (el.scrollTop >= (el.scrollHeight - el.clientHeight) - 10) {
        this.reachedBottom = true
      }
    }
  }
}
</script>

<style lang="scss">
.tos {
  max-height: 60vh;
  overflow-y: scroll;
  margin: 1em 0;
  border-bottom: 1px solid grey;
  border-top: 1px solid grey;

  .terms-container {
    min-height: 62vh;
    margin: 1em;
  }
}
</style>
