<template>
  <section>
    <b-field label="TenantName">
      <b-input
        v-model="formAddress.tenantName"
        placeholder="TenantName"
        required
        @input="valueChanged"
      />
    </b-field>

    <b-field grouped group-multiline>
      <b-field label="Zip" expanded>
        <b-input
          v-model.number="formAddress.zip"
          type="number"
          min="0"
          max="999999"
          message="just a message"
          placeholder="Zip"
          required
          @input="valueChanged"
        />
      </b-field>
      <b-field label="City" expanded>
        <b-input v-model="formAddress.city" placeholder="City" required @input="valueChanged" />
      </b-field>
      <b-field label="Street" expanded>
        <b-input v-model="formAddress.street" placeholder="Street" required @input="valueChanged" />
      </b-field>
      <b-field label="Number" expanded>
        <b-input v-model="formAddress.number" placeholder="Number" required @input="valueChanged" />
      </b-field>
    </b-field>

    <b-field label="Country">
      <b-input v-model="formAddress.country" placeholder="Country" required @input="valueChanged" />
    </b-field>

    <b-field label="Notes">
      <b-input
        v-model="formAddress.notes"
        type="textarea"
        placeholder="Notes"
        minlength="0"
        maxlength="1000"
        @input="valueChanged"
      />
    </b-field>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { Address } from '@/types/typeTenant'

@Component({
  components: {},
  model: {
    prop: 'address',
    event: 'input'
  },
  props: {
    address: {
      type: Object,
      required: true
    }
  }
})
export default class VFormAddress extends Vue {
  // needed for proper reactivity
  public formAddress: Address = {} as Address


  @Watch('address', { immediate: true })
  public addressChanged(val: Address, oldVal: Address) {
    this.formAddress = { ...this.$props.address }
  }

  public valueChanged() {
    this.$emit('input', this.formAddress)
  }
}
</script>

<style scoped>
</style>
