import Vue from 'vue'

import BaseGlobals from './baseGlobals'

import { VueConstructor } from 'vue/types/umd'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'

import {
  CategoryCollection,
  CategoryCollectionWithHierarchy,
  CategoryTree,
  CategoryID,
  CategoryItem
} from '@/types/typeCategory'
import CategoryHelper from '@/database/categoryHelper'
import { hasDBid, objectID } from '@/types/typeGeneral'
import notificationHelper from '@/helpers/notificationHelper'
import { intersectSome } from '@/helpers/arrayHelper'
import { UserPrivilegeIdDB } from '@/types/typeUser'

class CategoryGlobals extends BaseGlobals {
  constructor() {
    super()
  }

  // init is only called in backend
  init(tenantID: objectID, userPrivileges: UserPrivilegeIdDB[]): Promise<SnapshotUnbindHandle> {
    if (!intersectSome([...CategoryHelper.requiredPrivileges.r], userPrivileges)) {
      notificationHelper.Warn('no rights to read categories. The backend functionality may be limited.')
      return new Promise((resolve) =>
        resolve(() => {
          /** */
        })
      )
    } else {
      return new Promise((resolve, reject) => {
        const unbindSnapshot = CategoryHelper.getCategoriesCollectionSnapshot(
          tenantID,
          (cats) => {
            for (const id in this.obersvableData.categories) {
              if (Object.prototype.hasOwnProperty.call(this.obersvableData.categories, id)) {
                Vue.delete(this.obersvableData.categories, id)
              }
            }

            for (const id in cats) {
              if (Object.prototype.hasOwnProperty.call(cats, id)) {
                const element = cats[id]

                if (element.publishingState === 'published' || element.publishingState === 'draft')
                  Vue.set(this.obersvableData.categories, id, element)
              }
            }

            // create categories list including id
            for (const index in this.obersvableData.categoriesListWithID) {
              Vue.delete(this.obersvableData.categoriesListWithID, index)
            }

            let i = 0
            for (const id in this.obersvableData.categories) {
              if (Object.prototype.hasOwnProperty.call(this.obersvableData.categories, id)) {
                const element = this.obersvableData.categories[id]
                Vue.set(this.obersvableData.categoriesListWithID, i++, { ...element, id })
              }
            }

            // #region sorted with hierarchy
            const tree = CategoryHelper.buildCategoryTree(this.obersvableData.categories)

            const sortedCategoryData: CategoryCollectionWithHierarchy = {}
            let order = 0

            function iterateTree(
              tree: CategoryTree,
              sortedCategoryData: CategoryCollectionWithHierarchy,
              nestingLevel: number
            ) {
              sortedCategoryData[tree.id] = { ...tree, nestingLevel, order }

              nestingLevel++
              order++

              tree.children.forEach((child) => {
                iterateTree(child, sortedCategoryData, nestingLevel)
              })
            }
            // to get rid of root category
            tree.children.forEach((child) => iterateTree(child, sortedCategoryData, 0))

            for (const id in this.obersvableData.categoriesWithHierarchy) {
              if (Object.prototype.hasOwnProperty.call(this.obersvableData.categoriesWithHierarchy, id)) {
                Vue.delete(this.obersvableData.categoriesWithHierarchy, id)
              }
            }

            for (const id in sortedCategoryData) {
              if (Object.prototype.hasOwnProperty.call(sortedCategoryData, id)) {
                const element = sortedCategoryData[id]

                if (element.publishingState === 'published' || element.publishingState === 'draft')
                  Vue.set(this.obersvableData.categoriesWithHierarchy, id, element)
              }
            }

            // resolve the snapshot after first result is present
            resolve(unbindSnapshot)
            // #endregion
          },
          (err) => {
            notificationHelper.Error(`Error while loading categories [20230311]: ${err}`)
            reject(err)
          }
        )
      })
    }
  }

  public getCategoryName(catId: CategoryID) {
    return this.categories[catId] ? this.categories[catId].name : `category not found (id:${catId})`
  }

  public getCategoryID(catName: string) {
    let catId = ''
    for (const key in this.categories) {
      if (Object.prototype.hasOwnProperty.call(this.categories, key)) {
        if (this.categories[key].name === catName) {
          if (catId)
            throw `Multiple categories exists with the same name ${catName}. A reference using a name is ambiguous.`
          catId = key
        }
      }
    }

    if (!catId) throw `No category with the name "${catName}" was found`
    return catId
  }

  private obersvableData: {
    categories: CategoryCollection
    categoriesWithHierarchy: CategoryCollectionWithHierarchy
    categoriesListWithID: Array<CategoryItem & hasDBid>
  } = Vue.observable({ categories: {}, categoriesWithHierarchy: {}, categoriesListWithID: [] })

  public get categories() {
    return this.obersvableData.categories
  }

  public get categoriesWithHierarchy() {
    return this.obersvableData.categoriesWithHierarchy
  }

  public get categoriesListWithID() {
    return this.obersvableData.categoriesListWithID
  }
}

export const CategoryGlobalsInst = new CategoryGlobals()

declare module 'vue/types/vue' {
  interface Vue {
    $categories: CategoryCollection
    $categoriesWithHierarchy: CategoryCollectionWithHierarchy
    $categoriesListWithID: Array<CategoryItem & hasDBid>
    $getCategoryName: (catId: CategoryID | string) => string
    $getCategoryID: (catName: string) => CategoryID
  }
}

export default {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$categories = CategoryGlobalsInst.categories
    Vue.prototype.$categoriesWithHierarchy = CategoryGlobalsInst.categoriesWithHierarchy
    Vue.prototype.$categoriesListWithID = CategoryGlobalsInst.categoriesListWithID
    Vue.prototype.$getCategoryName = (id: string) => CategoryGlobalsInst.getCategoryName(id)
    Vue.prototype.$getCategoryID = (name: string) => CategoryGlobalsInst.getCategoryID(name)
  }
}
