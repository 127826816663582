<template>
  <section class="data-definition-wrapper">
    <!-- show error message -->
    <b-message v-if="errorMessage" type="is-danger">{{ errorMessage }}</b-message>
    <b-table
      :paginated="Object.keys(categoryDefinitionObjectLocal).length > 10"
      pagination-simple
      :per-page="10"
      :data="Object.keys(categoryDefinitionObjectLocal).sort((a, b) => a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }))"
    >
      <b-input
        v-if="!props.column.numeric"
        slot="searchable"
        v-model="props.filters[props.column.field]"
        slot-scope="props"
        placeholder="Search..."
        icon="search"
        size="is-small"
      />

      <b-table-column
        v-for="(column) in categoryDefinitionTableColumns"
        :key="column.field"
        :visible="column.visible"
        :label="column.label"
        :sortable="column.sortable"
      >
        <template v-if="column.tooltip" #header>
          {{ column.label }}
          <VTooltipIconHelp :text="column.tooltip" />
        </template>

        <template #default="props">
          <template v-if="editedElement === (props.row) && column.field !== '__key__' ">
            <b-field v-if="column.type !== 'validator'">
              <b-input
                v-if="column.field === 'name'"
                :value="accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field)"
                size="is-small"
                pattern="[a-zA-Z0-9_\-]*"
                validation-message="Only letters, numbers, '_' and '-' are allowed"
                :placeholder="categoryDefinitionNameSuggestions[props.row]"
                @input="v=>assignValueBasedOnAccessorString(categoryDefinitionObjectLocal[props.row],column.field,v)"
              />

              <VInputMultiCategorySelection
                v-else-if="column.field === 'validator.pivotCategory'"
                class="is-small category-selection"
                :selected-category-i-ds="[accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field)]"
                :multiple="false"
                :categories-doc="$categories"
                @selected="(value) => { assignValueBasedOnAccessorString(categoryDefinitionObjectLocal[props.row],column.field,value[0]) }"
              />

              <b-input
                v-else-if="column.type === 'text'"
                :value="accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field)"
                size="is-small"
                :placeholder="`[${column.field}]`"
                @input="v=>assignValueBasedOnAccessorString(categoryDefinitionObjectLocal[props.row],column.field,v)"
              />

              <!--  if type is number -->

              <b-input
                v-else-if="column.type === 'number'"
                :value="accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field)"
                size="is-small"
                type="number"
                min="0"
                max="100"
                :placeholder="`[${column.field}]`"
                @input="v=>assignValueBasedOnAccessorString(categoryDefinitionObjectLocal[props.row],column.field,+v)"
              />

              <b-select
                v-else-if="column.field === 'validator.constraint'"
                :value="accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field)"
                size="is-small"
                placeholder="text"
                @input="v=>assignValueBasedOnAccessorString(categoryDefinitionObjectLocal[props.row],column.field,v)"
              >
                <option
                  v-for="([key,title]) in Object.entries(column.options)"
                  :key="key"
                  :value="key"
                >{{ title }}</option>
              </b-select>

              <b-select
                v-else-if="column.type === 'dropdown'"
                :value="accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field)"
                size="is-small"
                placeholder="text"
                @input="v=>assignValueBasedOnAccessorString(categoryDefinitionObjectLocal[props.row],column.field,v)"
              >
                <option
                  v-for="([key,title]) in Object.entries(column.options)"
                  :key="key"
                  :value="key"
                >{{ title }}</option>
              </b-select>
            </b-field>
          </template>

          <template v-else>
            <span v-if="column.field === '__key__'" @click="toggleEditElement(props.row)">
              <b-tooltip
                v-if="+props.row[1] > 3 && showIdentifierWarning"
                label="Only the first 3 variables may be used for querying in the list ECHO CODEs view. Use this variable for data you don't want to filter by"
                multilined
                animated
                dashed
              >{{ props.row }}</b-tooltip>
              <span v-else>{{ props.row }}</span>
            </span>

            <span
              v-else-if="column.field === 'validator.pivotCategory'"
              @click="toggleEditElement(props.row)"
            >{{ accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field) ? $getCategoryName(accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field)) : '' }}</span>

            <span v-else-if="column.field === 'validator'" @click="toggleEditElement(props.row)">
              <span
                v-for="([key,value]) in Object.entries(categoryDefinitionObjectLocal[props.row].validator)"
                :key="key"
              >
                {{ key }}
                {{ [value].flat().join(',') }}
              </span>
            </span>

            <span
              v-else-if="column.type === 'dropdown'"
              @click="toggleEditElement(props.row)"
            >{{ column.options[accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field)] }}</span>

            <span
              v-else
              @click="toggleEditElement(props.row)"
            >{{ accessorStringToValue(categoryDefinitionObjectLocal[props.row],column.field) }}</span>
          </template>
        </template>
      </b-table-column>

      <b-table-column field="actions" label="Actions">
        <template #default="props">
          <b-field>
            <!-- <p v-if="editedElement === (props.row)" class="control">
                  <b-button
                    size="is-small"
                    icon-right="save"
                    type="is-success"
                    @click="toggleEditElement(props.row)"
                  />
            </p>-->
            <p class="control">
              <b-button
                size="is-small"
                :icon-right="(editedElement === (props.row))?'save':'edit'"
                @click="toggleEditElement(props.row)"
              />
            </p>
            <p v-show="editedElement === (props.row)" class="control">
              <!-- clear -->
              <b-button
                size="is-small"
                icon-right="trash"
                title="Reset to default values"
                @click="resetElement(props.row)"
              />
            </p>
          </b-field>
        </template>
      </b-table-column>
    </b-table>
  </section>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'


import VRecordMeta from '@/components/VRecordMeta.vue'

import { cloneObject } from '@/helpers/dataShapeUtil'
import { CategoryEntryDefinition, CategoryEntryDefinitionObject } from '@/types/typeBackendConfig'
import { accessorStringToValue, assignValueBasedOnAccessorString } from '@/database/dbHelper'
import VInputMultiCategorySelection from './VInputMultiCategorySelection.vue'
import { ROOT_CATEGORY_ID } from '@/businessLogic/sharedConstants'


@Component({
  components: {
    SlickList,
    SlickItem,
    VRecordMeta,
    VInputMultiCategorySelection
  },
  directives: {
    handle: HandleDirective
  }
})
export default class VFormCategoryEntryDefinition extends Vue {
  @Model('update', { type: Object }) readonly categoryDefinitionObject!: CategoryEntryDefinitionObject

  @Prop({ type: Boolean, default: () => false }) readonly showIdentifierWarning!: boolean

  public categoryDefinitionObjectLocal: CategoryEntryDefinitionObject = { ...this.categoryDefinitionObject }

  /** name suggestions based on title and removed special chars */
  public categoryDefinitionNameSuggestions: { [key: string]: string } = {}

  @Watch('categoryDefinitionObject', { deep: true, immediate: true })
  private oncategoryDefinitionChange() {
    this.categoryDefinitionObjectLocal = cloneObject(this.categoryDefinitionObject)
  }

  public accessorStringToValue(obj: any, acessor: string) {
    return accessorStringToValue(obj, acessor)
  }

  public assignValueBasedOnAccessorString(obj: any, acessor: string, value: any) {
    assignValueBasedOnAccessorString(obj, acessor, value)
  }


  // #region categoryDefinition

  public editedElement: keyof CategoryEntryDefinitionObject | null = null

  public resetElement(elementID: keyof CategoryEntryDefinitionObject) {
    this.categoryDefinitionObjectLocal[elementID].title = ''
    this.categoryDefinitionObjectLocal[elementID].validator = {
      pivotCategory: '',
      constraint: 'categoryEntryConstraint_anyNode',
      minCount: 0,
      maxCount: 10
    }

    this.stopEditElement(elementID)

    // toast
    this.$helpers.notification.InfoToast('Element reset to default values')
  }

  public toggleEditElement(elementID: keyof CategoryEntryDefinitionObject) {
    // stop editing if there is currently an element being edited
    if (this.editedElement === elementID) {
      this.stopEditElement(this.editedElement)
    } else if (this.editedElement !== null) {
      this.stopEditElement(this.editedElement)

      // if stopping was indeed successful, start editing the new element
      if (this.editedElement === null) {
        this.editedElement = elementID
      }
    } else {
      this.editedElement = elementID
    }
  }

  // fn to be used from external to save the local data
  public $externalSave() {
    if (this.editedElement !== null) {
      this.stopEditElement(this.editedElement)
    }
    return this.errorMessage
  }

  public errorMessage = ''

  public stopEditElement(element: keyof CategoryEntryDefinitionObject) {
    console.debug('stopEditElement', element)

    // check that atleast one title is set
    const anyTitleSet = Object.entries(this.categoryDefinitionObjectLocal).some(([key, value]) => value.title !== '')
    if (!anyTitleSet) {
      this.errorMessage = 'At least one title must be set'
      return
    }

    Object.entries(this.categoryDefinitionObjectLocal).forEach(([key, value]) => {
      if (value.title && !value.validator.pivotCategory) {
        // if a title is set but no pivot category is set, set the pivot category to ROOT_CATEGORY_ID
        value.validator.pivotCategory = ROOT_CATEGORY_ID
        // toast
        this.$helpers.notification.InfoToast('Category branch set to root category')
      } else if (!value.title && value.validator.pivotCategory) {
        // if no title is set, remove the pivot category
        value.validator.pivotCategory = ''
        // toast
        this.$helpers.notification.InfoToast('Category branch removed as no title is set')
      }
    })

    this.errorMessage = ''
    this.editedElement = null
    this.$emit('update', this.categoryDefinitionObjectLocal)
  }

  get valueTypeName() {
    return 'category'
  }


  public CATEGORY_ENTRY_DEFINITON_CONSTRAINT: { key: CategoryEntryDefinition['validator']['constraint'], value: string }[] = [
    { key: 'categoryEntryConstraint_leafNode', value: 'Leaf Category' },
    // { key: 'categoryEntryConstraint_parentNode', value: 'Parent Node' },
    { key: 'categoryEntryConstraint_anyNode', value: 'Any Category' }
  ]

  public categoryDefinitionTableColumns = [
    //   {
    //   field: '__key__',
    //   label: 'Data ID',
    //   visible: true,
    //   type: 'text'
    //   // tooltip: 'The data ID is used '
    // },
    {
      field: 'title',
      label: 'Title',
      visible: true,
      type: 'text',
      tooltip: 'The title is used for displaying purposes and as a column header in the list codes view'
    }, {
      field: 'validator.pivotCategory',
      label: 'Category Branch',
      visible: true,
      type: 'text',
      tooltip: 'A subset of the categories to be used for this entry definition. It includes all categories below the selected category, including the selected category itself.'
    },
    {
      field: 'validator.constraint',
      label: 'Constraint',
      visible: true,
      type: 'dropdown',
      options: this.CATEGORY_ENTRY_DEFINITON_CONSTRAINT.map(({ key, value }) => [key, value]).reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {})
    }, {
      field: 'validator.minCount',
      label: 'Min',
      type: 'number',
      tooltip: 'The minimum number of categories that must be selected'
    },
    {
      field: 'validator.maxCount',
      label: 'Max',
      type: 'number',
      tooltip: 'The maximum number of categories that can be selected'
    }]
  // #endregion categoryDefinition
}
</script>

<style lang="scss">
.data-definition-wrapper {
  .category-selection {
    max-width: 12rem;
  }

  .table-wrapper {
    overflow: visible;
  }

  // only applies for data-label="validator" column
  td[data-label='validator'] span {
    word-break: break-all;
  }
}
</style>
