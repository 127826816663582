import { RouteConfig } from 'vue-router'

import {
  ModuleType,
  BaseModuleDB,
  ALL_MODULE_TYPE_NAMES,
  BaseElementDB,
  ElementWithTypeAndID,
  BaseGroupDB,
  hasModuleType
} from './typeModules'
import db, { DocNotFound } from '@/firebase'

import { AsidDB, asidID, IdentifierValue } from '@/types/typeAsid'
import { CategoryCollection, CategoryID, CategoryItem } from '@/types/typeCategory'
import CategoryHelper from '@/database/categoryHelper'
import { firebase } from '@/firebase'
import {
  ParallelCallbackHelper,
  SnapshotSequence,
  SnapshotParallel,
  SnapshotMultiListener,
  typedWhere
} from '@/database/dbHelper'
import notificationHelper from '@/helpers/notificationHelper'

import { UserPrivilegeIdDB, UserPrivileges } from '@/types/typeUser'
import { TenantID } from '@/types/typeTenant'
import { SUPER_ADMIN } from '@/helpers/privileges'
import AsidManager from '@/database/asidManager'

import FormModule from './form/formModule'

import CiModule from './ci/ciModule'
import HtmlModule from './html/htmlModule'
import ScriptModule from './script/scriptModule'
import I18nModule from './i18n/i18nModule'
import FileModule from './file/fileModule'
import ProtectionModule from './protection/protectionModule'
import CustomModule from './custom/customModule'
import databaseSchema from '@/database/databaseSchema'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
// import NotificationModule from './notification/notificationModule'
import { hasDBid, objectID } from '@/types/typeGeneral'
import { moduleOrder } from '@/shared/general'
import DataModule from './data/dataModule'
import { filterElementsMatchingReferences, getAllPublishedParentCategories } from '@/shared/appDataHelper'
import ServiceModule from './service/serviceModule'
import { getChunkedArray } from '@/helpers/arrayHelper'
import LinkModule from './link/linkModule'

export const AllModuleClasses = [
  FormModule,
  FileModule,
  CiModule,
  HtmlModule,
  ScriptModule,
  CustomModule,
  // NotificationModule,
  I18nModule,
  ProtectionModule,
  DataModule,
  ServiceModule,
  LinkModule
]

export type ModuleClass = typeof AllModuleClasses[number]

export abstract class ModuleManager {
  public static readonly moduleClasses = AllModuleClasses.sort((a, b) => moduleOrder[a.type] - moduleOrder[b.type])

  private static readonly moduleClassesByType = AllModuleClasses
    .sort((a, b) => moduleOrder[a.type] - moduleOrder[b.type])
    .reduce(
      (res, mod) => {
        res[mod.type] = mod
        return res
      },
      {} as {
        [key: string]: typeof AllModuleClasses[0]
      }
    )
  // {
  //        [FormModule.type]: FormModule,
  //        [FileModule.type]: FileModule,
  //        [CiModule.type]: CiModule,
  //        [HtmlModule.type]: HtmlModule
  //      }

  public static availableModules: readonly ModuleType[] = ALL_MODULE_TYPE_NAMES

  public static getRoutes(): RouteConfig[] {
    const routes: RouteConfig[] = []

    for (const module of ModuleManager.moduleClasses) {
      routes.push(...module.getRoutes())
    }
    return routes
  }

  public static async activateModules(tenantID: TenantID, authEmail: string, types: ModuleType[]) {
    const promises: Promise<any>[] = []
    types.forEach((T) => {
      const modClass = this.getModuleClassByType(T)

      if (modClass) {
        promises.push(modClass.activateOrCreateModule(tenantID, authEmail))
      } else {
        throw `module type ${T} not found [20230101]`
      }
    })
    return Promise.all(promises)
  }

  public static async deactivateModules(tenantID: TenantID, authEmail: string, types: ModuleType[]) {
    const promises: Promise<void>[] = []
    types.forEach((T) => {
      const modClass = this.getModuleClassByType(T)
      if (modClass) promises.push(modClass.deactivateModule(tenantID, authEmail))
    })

    return Promise.all(promises)
  }

  public static getModuleClassByType(type: ModuleType) {
    return ModuleManager.moduleClassesByType[type]
  }

  /**
   * filters by privileges if specified
   *
   * @param tenantId
   * @param userPrivileges
   */
  private static getActivatedModulesQuery(tenantId: TenantID, userPrivileges: UserPrivilegeIdDB[] = [SUPER_ADMIN]) {
    const modTypeNames = this.moduleClasses.map((mc) => mc.type)
    let query = db
      .collection(databaseSchema.COLLECTIONS.TENANTS.MODULES.__COLLECTION_PATH__(tenantId))
      .where('activated', '==', true)

    if (!userPrivileges.includes(SUPER_ADMIN)) {
      const moduleNamesToQuery = [
        ...new Set( // set makes it unique
          userPrivileges
            .filter((ab) => ab.split(':')[1] === 'read') // get only read privs
            .map((ab) => this.capitalize(ab.split(':')[0])) // get only first (module) part
            .filter((ab) => modTypeNames.includes(ab as ModuleType))
        )
      ]

      if (moduleNamesToQuery.length === 0) return null
      // if (userPrivileges.includes(privileges.MODULES_MANAGE_VIEW)) moduleNamesToQuery = modTypeNames this would also show all modules in sidebar. call without privs when needing all moduels

      console.log('privileges', userPrivileges)

      query = query.where(firebase.firestore.FieldPath.documentId(), 'in', moduleNamesToQuery) // previously queried for 'none' to not get error but dont return any results > does not work since sec rules return perm denies then
    } else {
      query = query.where(firebase.firestore.FieldPath.documentId(), 'in', modTypeNames)
    }

    return query
  }

  public static async getActivatedModules(tenantId: TenantID, userPrivileges?: UserPrivilegeIdDB[]) {
    const query = this.getActivatedModulesQuery(tenantId, userPrivileges)
    if (!query) return []

    const modulesSnapshot = await query.get()

    console.warn('deprecated, use onSnapshot version')
    console.log(modulesSnapshot)

    const tmpActiveModules: typeof ModuleManager.moduleClasses = []
    modulesSnapshot.docs.forEach((doc) => {
      const ModuleDB = doc.data() as BaseModuleDB
      const ModuleClass = this.getModuleClassByType(ModuleDB.public.type)
      if (ModuleClass) tmpActiveModules.push(ModuleClass)
    })
    return tmpActiveModules
  }

  // onsnapshot only gets listened to once and the monitored items are returned for every request
  private static getActivatedModulesSnapshotCBHnoPrivileges: SnapshotMultiListener<BaseModuleDB[]> // store instance for query without privileges
  private static getActivatedModulesSnapshotCBH: SnapshotMultiListener<BaseModuleDB[]>
  public static onSnapshotActivatedModules(
    tenantId: TenantID,
    userPrivileges: UserPrivilegeIdDB[] = [SUPER_ADMIN],
    cb: (moduleDBs: BaseModuleDB[]) => void,
    errCb?: (e: any) => void,
    debugName = 'onSnapshotActivatedModules'
  ) {
    let instance
      = userPrivileges[0] === SUPER_ADMIN
        ? this.getActivatedModulesSnapshotCBHnoPrivileges
        : this.getActivatedModulesSnapshotCBH

    if (!instance || !instance.isInitialized()) {
      if (userPrivileges[0] === SUPER_ADMIN) {
        console.log('--- no privs')
        instance = this.getActivatedModulesSnapshotCBHnoPrivileges = new SnapshotMultiListener() // must be keepalive. otherwise instance is set, but fb query will be removed
        instance.debugName = 'onSnapshotActivatedModules no privs'
      } else {
        console.log('--- privs')
        instance = this.getActivatedModulesSnapshotCBH = new SnapshotMultiListener()
        instance.debugName = 'onSnapshotActivatedModules with privs'
      }

      const query = this.getActivatedModulesQuery(tenantId, userPrivileges)

      if (!query) {
        cb([])
        return () => {
          //
        }
      }

      instance.setInputSnapshot((cb) => {
        return query.onSnapshot(
          // todo this may be called with and without user privileges, resulting in different queries
          (modulesSnapshot: firebase.firestore.QuerySnapshot) => {
            const moduleDBs = modulesSnapshot.docs.map((doc) => {
              const moduleDB = doc.data() as BaseModuleDB
              return moduleDB
            })

            cb(moduleDBs.sort((a, b) => moduleOrder[a.public.type] - moduleOrder[b.public.type]))
          },
          errCb
            ? errCb
            : (e) => notificationHelper.Error(`${debugName} Error fetching Module Elements: ${e.toString()}`)
        )
      })
    }
    return instance.onSnapshot((data) => cb(data))
  }

  public static onSnapshotActivatedModuleClasses(
    tenantId: TenantID,
    userPrivileges: UserPrivilegeIdDB[] = [SUPER_ADMIN],
    cb: (moduleClasses: typeof ModuleManager.moduleClasses) => void,
    errCb?: (e: any) => void,
    debugName = 'none'
  ) {
    return this.onSnapshotActivatedModules(
      tenantId,
      userPrivileges,
      (moduleDBs) => {
        const tmpActiveModules: typeof ModuleManager.moduleClasses = []
        moduleDBs.forEach((moduleDB) => {
          const ModuleClass = this.getModuleClassByType(moduleDB.public.type)
          if (ModuleClass) tmpActiveModules.push(ModuleClass)
        })
        cb(tmpActiveModules.sort((a, b) => moduleOrder[a.type] - moduleOrder[b.type]))
      },
      errCb,
      debugName
    )
  }

  // public static async getActivatedModulesData(tenantID: TenantID, userPrivileges: UserPrivilegeIdDB[]) {
  //   const modulesSnapshot = await this.getActivatedModulesQuery(tenantID, userPrivileges).get()

  //   const tmpActiveModules: BaseModuleDB[] = []
  //   modulesSnapshot.docs.forEach(doc => {
  //     const ModuleDB = doc.data() as BaseModuleDB
  //     tmpActiveModules.push(ModuleDB)
  //   })

  //   return tmpActiveModules
  // }

  public static onSnapshotModuleElements(
    // todo make sure this function when invoked multiple times does not result in query overhead
    tenantID: TenantID,
    userPrivileges: UserPrivilegeIdDB[] = [SUPER_ADMIN],
    { includeDeleted = false },
    cb: (moduleElements: Array<ElementWithTypeAndID>) => void,
    errCb: (e: any) => void,
    debugName = 'onSnapshotModuleElements'
  ) {
    const onSnapshotModuleElementsInstance: SnapshotMultiListener<ElementWithTypeAndID[]> = new SnapshotMultiListener()

    const snapS = new SnapshotSequence()
      .addSnapShotSeq((data, cb) => {
        console.log('called onSnapshotActivatedModuleClasses')

        return this.onSnapshotActivatedModuleClasses(tenantID, userPrivileges, cb, errCb, debugName)
      })
      .addSnapShotSeq((d: typeof ModuleManager.moduleClasses, cb) => {
        // will be newly created when the first chainlink changes
        const par = new SnapshotParallel(true, debugName)
        d.forEach((M) => {
          par.addSnapShotPar((cb, snapErr) => {
            return M.getElementsQuery(tenantID, includeDeleted).onSnapshot(
              (snapshot: firebase.firestore.QuerySnapshot) => {
                // todo refactor cbProxy so it can dispose the snapshot its listening on
                cb(
                  snapshot.docs.map((D) => ({
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    type: D.ref.parent.parent!.id as ModuleType,
                    ...(D.data() as BaseElementDB),
                    id: D.id
                  }))
                )
              },
              snapErr
            )
          })
        })
        return par.onSnapshot(cb, errCb)
      })

    onSnapshotModuleElementsInstance.setInputSnapshot(snapS.onSnapshot.bind(snapS))

    return onSnapshotModuleElementsInstance.onSnapshot((data) => cb(data.flat()))
  }

  // private static onSnapshotModulesInstance: CallbackHelper<Array<ElementWithTypeAndID>>
  // public static async onSnapshotModules(
  //   // todo make sure this function when invoked multiple times does not result in query overhead
  //   tenantID: TenantID,
  //   cb: (moduleElements: Array<ElementWithTypeAndID>) => void,
  //   errCb?: (e: any) => void
  // ) {
  //   console.warn('TODO check logic')

  //   if (!this.onSnapshotModulesInstance) {
  //     this.onSnapshotModulesInstance = new CallbackHelper()

  //     this.getActivatedModulesQuery(tenantID).onSnapshot(
  //       this.onSnapshotModulesInstance.cbProxy((snapshot: firebase.firestore.QuerySnapshot) => {
  //         return snapshot.docs.map(D => ({
  //           // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  //           type: D.ref.parent.parent!.id as ModuleType,
  //           ...(D.data() as BaseElementDB),
  //           id: D.id
  //         }))
  //       }),
  //       errCb ? errCb : e => notificationHelper.Error(`Error fetching Module Elements: ${e.toString()}`)
  //     )
  //   }
  //   return this.onSnapshotModulesInstance.onSnapshot(data => cb(data.flat()))
  // }

  private static onSnapshotModuleGroupsInstance: SnapshotMultiListener<Array<BaseGroupDB & hasDBid & hasModuleType>>
  public static onSnapshotModuleGroups(
    // todo make sure this function when invoked multiple times does not result in query overhead
    tenantID: TenantID,
    userPrivileges: UserPrivilegeIdDB[] = [SUPER_ADMIN],
    { includeDeleted = false },
    cb: (moduleGroups: Array<BaseGroupDB & hasDBid & hasModuleType>) => void,
    errCb: (e: any) => void,
    debugName = 'onSnapshotModuleGroups'
  ) {
    if (!this.onSnapshotModuleGroupsInstance || !this.onSnapshotModuleGroupsInstance.isInitialized()) {
      this.onSnapshotModuleGroupsInstance = new SnapshotMultiListener()

      const snapS = new SnapshotSequence()
        .addSnapShotSeq((data, cb) => {
          console.log('called onSnapshotModuleGroups')

          return this.onSnapshotActivatedModuleClasses(
            tenantID,
            userPrivileges,
            cb,
            () => {
              /** */
            },
            debugName
          )
        })
        .addSnapShotSeq((d: typeof ModuleManager.moduleClasses, cb) => {
          // will be newly created when the first chainlink changes
          const par = new SnapshotParallel(true, 'onSnapshotModuleGroups')
          d.forEach((M) => {
            par.addSnapShotPar((cb) => {
              return M.getGroupsQuery(tenantID, includeDeleted).onSnapshot(
                (snapshot: firebase.firestore.QuerySnapshot) => {
                  // todo refactor cbProxy so it can dispose the snapshot its listening on
                  cb(
                    snapshot.docs.map((D) => ({
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      type: D.ref.parent.parent!.id as ModuleType,
                      ...(D.data() as BaseGroupDB),
                      id: D.id
                    }))
                  )
                },
                errCb
              )
            })
          })
          return par.onSnapshot(cb, errCb)
        })

      this.onSnapshotModuleGroupsInstance.setInputSnapshot(snapS.onSnapshot.bind(snapS))
    }
    return this.onSnapshotModuleGroupsInstance.onSnapshot((data) => cb(data.flat()))
  }

  public static async onSnapshotElementsForAsid(
    asid: objectID,
    tenantID: TenantID,
    userPrivileges: UserPrivilegeIdDB[] = [SUPER_ADMIN],
    { includeDeleted = false, debugName = 'no name' },
    cb: (elements: Array<ElementWithTypeAndID>) => void,
    once = false
  ): Promise<SnapshotUnbindHandle> {
    const asidDoc = await AsidManager.getDbDocReference(asid).get()
    if (!asidDoc.exists) throw new DocNotFound(asidDoc)

    const asidObj = asidDoc.data() as AsidDB

    return this.onSnapshotElementsForReference(
      { asid, categoryIDs: asidObj.categoryIDs, identifierValue: asidObj.identifierValue },
      tenantID,
      userPrivileges,
      { includeDeleted, debugName },
      cb,
      once
    )
  }

  public static async onSnapshotElementsForReference(
    reference: {
      categoryIDs: Array<CategoryID>
      identifierValue: IdentifierValue
      asid: asidID
    },
    tenantID: TenantID,
    userPrivileges: UserPrivilegeIdDB[] = [SUPER_ADMIN],
    { includeDeleted = false, debugName = 'no name' },
    cb: (elements: Array<ElementWithTypeAndID>) => void,
    once = false
  ): Promise<SnapshotUnbindHandle> {
    return new Promise((resolve, rej) => {
      // todo check that tenant id and privileges are the same

      const onSnapshotElementsForReferenceListeners: SnapshotUnbindHandle[] = []
      // reusing the same instance brings a lot of trouble. e.g. beforeDestroy of a component is called after the new component is created, so the old instance is still used and afterwards destroyed...
      // if (!this.getElementsForReferenceInstance) {
      console.debug(`onSnapshotElementsForReference ${debugName} init`)
      const getElementsForReferenceInstance: ParallelCallbackHelper<
        | {
          type: 'category'
          data: CategoryCollection
        }
        | {
          type: 'elements'
          data: ElementWithTypeAndID[]
        }
      > = new ParallelCallbackHelper()

      onSnapshotElementsForReferenceListeners.push(
        CategoryHelper.getCategoriesCollectionSnapshot(
          tenantID,
          getElementsForReferenceInstance.cbProxy((data) => ({ type: 'category', data })),
          rej
        )
      )

      // todo selectively listen for ME based on a query
      onSnapshotElementsForReferenceListeners.push(
        this.onSnapshotModuleElements(
          tenantID,
          userPrivileges,
          { includeDeleted },
          getElementsForReferenceInstance.cbProxy((data) => ({ type: 'elements', data })),
          rej
        )
      )
      // }

      let onSnapUnbindHandle: SnapshotUnbindHandle

      const unbindHandle: SnapshotUnbindHandle = () => {
        console.debug(`onSnapshotElementsForReference ${debugName} unbind`)

        onSnapUnbindHandle()
        // if last instance is unbound, also unbind the listeners
        if (getElementsForReferenceInstance.listenersCount === 0) {
          onSnapshotElementsForReferenceListeners.forEach((uh) => uh())
          getElementsForReferenceInstance.removeAllListeners()
        }
      }

      // do this trick to make sure the unbind handle is availabe if on snapshot directly (sync) returns values
      new Promise((resolve2, reject2) => {
        if (!getElementsForReferenceInstance) throw 'getElementsForReferenceInstance is undefined'

        onSnapUnbindHandle = getElementsForReferenceInstance.onSnapshot((res) => {
          console.debug(`onSnapshotElementsForReference ${debugName} onSnap`)
          let categoriesCollection!: CategoryCollection
          let elements: ElementWithTypeAndID[] = []
          res.forEach((R) => {
            if (R.type === 'category') {
              categoriesCollection = R.data
            }
            if (R.type === 'elements') {
              elements = R.data
            }
          })

          cb(
            this.filterElementsMatchingReferences(
              elements,
              categoriesCollection,
              reference.categoryIDs,
              reference.asid,
              reference.identifierValue
            )
          )

          if (once) unbindHandle()

          resolve2(0)
        }, once)
      })
        .then(() => {
          resolve(unbindHandle)
        })
        .catch(rej)
    })
  }

  /**
   * function to get all elements from firebase that have a given category or a child of the given category
   *
   * @param param0
   * @returns
   */
  public static async getModuleElementsForCategories({
    tenantID,
    userPrivileges = [SUPER_ADMIN],
    filterCategoryIDs,
    allCategories,
    includeChildCategories = false,
    includeParentCategories = false,
    includeDeleted = false
  }: {
    tenantID: TenantID
    userPrivileges: UserPrivilegeIdDB[]
    filterCategoryIDs: string[]
    allCategories: CategoryCollection
    includeChildCategories?: boolean
    includeParentCategories?: boolean
    includeDeleted?: boolean
  }) {
    const allFilterCtaegoryIDs = [...filterCategoryIDs]

    // add child categories if needed
    if (includeChildCategories)
      allFilterCtaegoryIDs.push(...CategoryHelper.getAllChildCategoriesArray(filterCategoryIDs, allCategories))

    // add parent categories if needed
    if (includeParentCategories)
      allFilterCtaegoryIDs.push(...CategoryHelper.getAllParentCategoriesArray(filterCategoryIDs, allCategories))

    const categoriesChunkArray = getChunkedArray(allFilterCtaegoryIDs, 10) // firestore only acceps 10 elements in IN queries

    const activeModules = await this.getActivatedModules(tenantID, userPrivileges)

    const promiseArray: Promise<firebase.firestore.QuerySnapshot>[] = []

    activeModules.forEach((M) => {
      // get all module elements for category tree
      categoriesChunkArray.forEach((catChunk, catID) => {
        console.log('catChunk', catChunk)

        let query = typedWhere<BaseElementDB>(
          db
            .collection(databaseSchema.COLLECTIONS.TENANTS.MODULES.ELEMENTS.__COLLECTION_PATH__(tenantID, M.type))
            .orderBy('public.order', 'asc'),
          { _computed: { queryCategoryIDs: [] } },
          'array-contains-any',
          catChunk
        )

        if (!includeDeleted)
          query = typedWhere<BaseElementDB>(query, { publishingState: 'published' }, '==', 'published')

        promiseArray.push(query.get())
      })
    })

    const elementsQueryDocs = (await Promise.all(promiseArray)).flatMap((D) => D.docs)

    return elementsQueryDocs.map((D) => ({
      ...D.data(),
      id: D.id,
      type: D.ref.parent.parent?.id as ModuleType
    })) as ElementWithTypeAndID[]
  }

  public static filterElementsMatchingReferences<T extends BaseElementDB>(
    elements: T[],
    categoryCollenction: CategoryCollection,
    categoryIDs: CategoryID[] = [],
    asidID: asidID,
    identifierValue: IdentifierValue = {
      i1: null,
      i2: null,
      i3: null,
      i4: null,
      i5: null,
      i6: null
    }
  ) {
    const categoriesMap: Map<string, CategoryItem> = getAllPublishedParentCategories(categoryIDs, categoryCollenction)
    return filterElementsMatchingReferences(elements, categoriesMap, { categoryIDs, asidID, identifierValue })
  }

  private static capitalize(s: string) {
    return s[0].toUpperCase() + s.slice(1)
  }

  public static getModulePrivileges(): UserPrivileges {
    const allPrivileges: UserPrivileges = {}
    this.moduleClasses.forEach((mc) => {
      mc.authPrivileges.r.forEach((priv: any) => (allPrivileges[priv] = priv))
      mc.authPrivileges.w.forEach((priv: any) => (allPrivileges[priv] = priv))
      mc.authPrivileges.view.forEach((priv: any) => (allPrivileges[priv] = priv))
    })
    return allPrivileges
  }

  public async getCategoryReferences() {
    // await db
    //   .collection('Tenants')
    //   .doc(this.tenantID)
    //   .collection('Modules')
    //   .where('type', '==', type)
  }

  public async getModule(type: ModuleType) {
    // await db
    //   .collection('Tenants')
    //   .doc(this.tenantID)
    //   .collection('Modules')
    //   .where('type', '==', type)
  }
}
