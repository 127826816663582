<template>
  <nodeViewWrapper class="editor-image-wrapper">
    <div data-drag-handle draggable="true">
      <img
        v-if="imageDescriptorType === 'variable'"
        ref="resizableImg"
        :width="width+'%'"
        class="img__embed"
        :src="variableUrl"
      />
      <img
        v-else
        v-bind="node.attrs"
        ref="resizableImg"
        :width="width+'%'"
        class="img__embed"
      />

      <b-field>
        <b-slider
          v-model="sliderWidths"
          class="image-width-slider"
          :custom-formatter="val => 'width: ' + width.toFixed(2) + '%'"
        />
      </b-field>
      <b-field class="edit-image-controls">
        <p class="control">
          <b-button class="edit-image is-control" size="is-small">Select Image</b-button>
        </p>
        <p class="control edit-image">
          <b-button expanded size="is-small" class="is-control" @click="editImage">Edit Image</b-button>
        </p>
      <!-- <p class="control">
        <b-button
          size="is-small"
          has-icon-left="grip-vertical"
          expanded
        />
      </p> -->
      </b-field>
    </div>
    <VImageUploadModal
      :active.sync="isImageUploadModalActive"
      name-prefix
      :max-filesize="1024 * 1024"
      :max-image-width="700*2"
      :url="imageDescriptor"
      :upload-path="uploadPath"
      target-format="image/png"
      disable-remove-image
      @input="onUpdateImage"
    />
  </nodeviewwrapper>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { NodeViewWrapper } from '@tiptap/vue-2'


import VImageUploadModal, { inferImgDescriptorType } from '@/components/image/VImageUploadModal.vue'

@Component({
  components: {
    NodeViewWrapper,
    VImageUploadModal
  }
})
export default class VResizableImage extends Vue {
  // #region tiptap props passed to component
  // https://tiptap.dev/guide/node-views/vue

  @Prop({ type: Object, required: true })
  readonly node!: any

  @Prop({ type: Object, required: true })
  readonly extension!: any

  @Prop({ type: Function, required: true })
  readonly updateAttributes!: (d: any) => void
  // #endregion tiptap props


  get uploadPath() {
    return this.extension.options.uploadPath
  }

  public isImageUploadModalActive = false
  public variableUrl = require('@/assets/editor/placeholderImage.jpeg')

  public get imageDescriptor() {
    return (this as any).node.attrs.src
  }

  public onRemoveImage() {
    (this as any).deleteNode()
  }

  public onUpdateImage(imageDescriptor: string) {
    this.updateAttributes({ src: imageDescriptor })
  }

  public editImage() {
    this.isImageUploadModalActive = true
  }

  get imageDescriptorType() {
    return inferImgDescriptorType(this.imageDescriptor)
  }

  get isDraggable() {
    return this.node?.attrs?.isDraggable
  }

  get width() {
    const widthAttr = this.node.attrs.width || '100%'
    // if its number use it, if it includes %, use the number before the % sign
    if (typeof widthAttr === 'number') {
      return widthAttr
    } else if (typeof widthAttr === 'string' && widthAttr.includes('%')) {
      return parseFloat(widthAttr.split('%')[0])
    }
    return 100
  }

  set width(width: number) {
    console.log('set', {
      width: '' + width + '%'
    });
    (this as any).updateAttributes({
      width: '' + width + '%'
    })
  }

  get sliderWidths() {
    const leftPadding = (100 - (this as any).width) / 2
    return [leftPadding, leftPadding + (this as any).width]
  }

  set sliderWidths(sliderWidths: number[]) {
    (this as any).width = sliderWidths[1] - sliderWidths[0]
  }
}
</script>

<style lang="scss">
.editor-image-wrapper {
  .image-width-slider {
    margin-top: 0.5em;
  }

  img {
    display: block;
    margin: auto;
  }
}

.edit-image-controls {
  p.control {
    margin-top: 0;
  }

  .edit-image {
    user-select: none;
    flex-grow: 1;
  }
}

</style>
