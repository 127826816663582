<template>
  <section class="module-menu-body">
    <b-field>
      <b-input
        v-model="$localSettings.modules.filter"
        :icon-right="($localSettings.modules.filter !== '') ? 'times-circle' : ''"
        icon-right-clickable
        expanded
        placeholder="Filter"
        @icon-right-click="() => $localSettings.modules.filter = ''"
      />

      <p class="control">
        <b-dropdown class="setting-dropdown" append-to-body>
          <template #trigger>
            <b-button icon-left="filter" title="Filter">
              <b-tag
                v-if="$localSettings.modules.filters.categories.length > 0"
                rounded
                class="badge"
                size="is-small"
              >{{ $localSettings.modules.filters.categories.length }}</b-tag>
            </b-button>
          </template>

          <b-dropdown-item @click="isFilterModalActive = true">
            <!-- <b-icon size="is-small" /> -->
            Filter by categories
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$localSettings.modules.filters.categories.length > 0"
            @click="$localSettings.modules.filters.categories = []"
          >
            <b-icon size="is-small" icon="times" />Reset filter
          </b-dropdown-item>

          <!-- <b-dropdown-item>Another action</b-dropdown-item>
          <b-dropdown-item>Something else</b-dropdown-item>-->
        </b-dropdown>
      </p>

      <p class="control">
        <b-dropdown class="setting-dropdown" append-to-body>
          <template #trigger>
            <b-button title="Display and grouping settings" icon-left="ellipsis-h">
              <b-tag
                v-if="filterAndGroupingCount > 0"
                rounded
                class="badge"
                size="is-small"
              >{{ filterAndGroupingCount }}</b-tag>
            </b-button>
          </template>

          <b-dropdown-item
            @click="$localSettings.modules.hideReferencesInSidebar = !$localSettings.modules.hideReferencesInSidebar"
          >
            <b-icon
              :icon="($localSettings.modules.hideReferencesInSidebar) ? 'eye' : 'eye-slash'"
              size="is-small"
            />
            {{ $localSettings.modules.hideReferencesInSidebar ? 'show': 'hide' }} references
          </b-dropdown-item>

          <b-dropdown-item separator />

          <b-dropdown-item
            :class="{'is-active': $localSettings.modules.sortBy === 'element-name-asc' || $localSettings.modules.sortBy === 'element-name-desc'}"
            @click="$localSettings.modules.sortBy = ($localSettings.modules.sortBy === 'element-name-asc') ? 'element-name-desc' : 'element-name-asc'"
          >
            <b-icon
              :icon="($localSettings.modules.sortBy === 'element-name-asc') ? 'sort-alpha-down' : 'sort-alpha-down-alt'"
              size="is-small"
            />sort by Element name
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$localSettings.modules.sortBy !== ''"
            @click="$localSettings.modules.sortBy = ''"
          >
            <b-icon icon="times" size="is-small" />reset sort by
          </b-dropdown-item>

          <b-dropdown-item separator />

          <b-dropdown-item
            :class="{'is-active': $localSettings.modules.groupedBy === 'categories'}"
            @click="$localSettings.modules.groupedBy = 'categories'"
          >
            <b-icon icon="layer-group" size="is-small" />group by categories
          </b-dropdown-item>
          <b-dropdown-item
            :class="{'is-active': $localSettings.modules.groupedBy === 'identifier'}"
            @click="$localSettings.modules.groupedBy = 'identifier'"
          >
            <b-icon icon="layer-group" size="is-small" />group by identifier
          </b-dropdown-item>
          <b-dropdown-item
            :class="{'is-active': $localSettings.modules.groupedBy === 'name'}"
            @click="$localSettings.modules.groupedBy = 'name'"
          >
            <b-icon icon="layer-group" size="is-small" />group by name
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$localSettings.modules.groupedBy !== ''"
            @click="$localSettings.modules.groupedBy = ''"
          >
            <b-icon icon="times" size="is-small" />reset group by
          </b-dropdown-item>

          <b-dropdown-item v-if="$localSettings.modules.groupedBy !== ''" separator />

          <b-dropdown-item
            v-if="$localSettings.modules.groupedBy !== ''"
            @click="onCollapseAllDisplayGroups"
          >
            <b-icon icon="chevron-right" size="is-small" />collapse all Groups
          </b-dropdown-item>
          <b-dropdown-item
            v-if="$localSettings.modules.groupedBy !== ''"
            @click="onExpandAllDisplayGroups"
          >
            <b-icon icon="chevron-down" size="is-small" />expand all Groups
          </b-dropdown-item>

          <b-dropdown-item v-if="!hideGroups" separator />

          <b-dropdown-item v-if="!hideGroups" @click="onCollapseAllWidgets">
            <b-icon icon="chevron-right" size="is-small" />
            collapse all {{ widgetText }}s
          </b-dropdown-item>
          <b-dropdown-item v-if="!hideGroups" @click="onExpandAllWidgets">
            <b-icon icon="chevron-down" size="is-small" />
            expand all {{ widgetText }}s
          </b-dropdown-item>

          <!-- <b-dropdown-item>Another action</b-dropdown-item>
          <b-dropdown-item>Something else</b-dropdown-item>-->
        </b-dropdown>
      </p>
    </b-field>
    <!-- <hr /> -->

    <div class="module-element-container">
      <b-message
        v-if="$localSettings.modules.filter !== '' || $localSettings.modules.filters.categories.length > 0"
        type="is-info"
        size="is-small"
        class="filter-message"
      >
        Some Elements may be hidden
        <b-button
          title="Clear Filter"
          size="is-small"
          @click="$localSettings.modules.filter = ''; $localSettings.modules.filters.categories = []"
        >Clear Filter</b-button>
      </b-message>

      <div v-if="hasData">
        <div
          v-for="(elementsByPage, indexII) in elementsByPages"
          :key="indexII"
          class="page-wrapper group-container"
        >
          <div
            v-if="elementsByPages.length > 1 || elementsByPages[0].pageID !== ''"
            class="page-header"
          >
            <b-icon
              :icon="(!displayGroupCollapsed(elementsByPage.pageID, elementsByPage.name)) ? 'chevron-down' : 'chevron-right'"
              size="is-small"
              class="expand-collapse"
              @click.native.prevent="onToggleCollapsedDisplayGroup(elementsByPage.pageID, elementsByPage.name)"
            />
            <span class="page-name">{{ elementsByPage.name }}</span>
          </div>

          <div
            v-for="(moduleGroup, indexI) in elementsByPage.elementsByGroups"
            :key="indexI"
            class="element-widget-group group-container"
          >
            <template
              v-if="!displayGroupCollapsed(elementsByPage.pageID, elementsByPage.name)"
            >
              <router-link
                v-if="!hideGroups"
                :to="toSingleGroupLinkKeepHash(moduleGroup.id, Module, groupType)"
                class="group-header level"
                :class="'is-' + moduleGroup.publishingState"
              >
                <span class="group-name level-left">
                  <b-icon
                    v-if="moduleGroup.protectionGroupID !== ''"
                    icon="lock"
                    size="is-small"
                    class="is-protected"
                  />
                  <!-- show form icon if groupType is not widget -->
                  <b-icon
                    v-if="groupType !== 'group-type_widget'"
                    icon="file-invoice"
                    size="is-small"
                    class="is-protected"
                  />
                  <b-icon
                    :icon="(!groupCollapsed(moduleGroup.id)) ? 'chevron-down' : 'chevron-right'"
                    size="is-small"
                    class="expand-collapse"
                    @click.native.prevent="onToggleCollapsedGroup(moduleGroup.id)"
                  />
                  {{ moduleGroup.name }}
                  <!-- {{moduleGroup.public.order}} -->
                  <small
                    v-if="groupCollapsed(moduleGroup.id)"
                    class="element-count"
                  >({{ moduleGroup.displayGroups.flatMap(dg=>dg.elements).filter(e=>!['archived','deleted'].includes(e.publishingState)).length }})</small>
                </span>

                <b-field class="hover-menu">
                  <p class="control">
                    <b-button class="is-small" title="Edit" icon-right="edit" />
                  </p>
                  <p class="control">
                    <b-button
                      icon-right="plus"
                      class="is-small"
                      title="Add Element"
                      @click.prevent="onAddElement(moduleGroup.id)"
                    />
                  </p>
                  <b-dropdown position="is-bottom-left">
                    <template #trigger>
                      <b-button
                        title="Bulk actions"
                        size="is-small"
                        class="options-button"
                        icon-left="ellipsis-h"
                      />
                    </template>

                    <b-dropdown-item
                      @click="onChangePublishElements(moduleGroup.id,'published')"
                    >Publish all</b-dropdown-item>
                    <b-dropdown-item
                      @click="onChangePublishElements(moduleGroup.id,'draft')"
                    >Draft all</b-dropdown-item>
                    <!-- <b-dropdown-item>Another action</b-dropdown-item>
                    <b-dropdown-item>Something else</b-dropdown-item>-->
                  </b-dropdown>
                  <!-- <span class="group-order">#{{ moduleGroup.public.order }}</span> -->
                </b-field>
              </router-link>

              <div v-if="!groupCollapsed(moduleGroup.id)" :class="{'group-items': !hideGroups}">
                <div
                  v-for="(displayGroup, indexDg) in moduleGroup.displayGroups"
                  :key="indexDg"
                  class="display-group"
                  :class="{'group-container': isDisplayGroupsActive}"
                >
                  <div v-if="isDisplayGroupsActive" class="group-header level">
                    <span class="group-name level-left">
                      <b-icon
                        :icon="(!displayGroupCollapsed(moduleGroup.id, displayGroup.name)) ? 'chevron-down' : 'chevron-right'"
                        size="is-small"
                        class="expand-collapse"
                        @click.native.prevent="onToggleCollapsedDisplayGroup(moduleGroup.id, displayGroup.name)"
                      />
                      {{ displayGroup.name }}
                      <small
                        v-if="displayGroupCollapsed(moduleGroup.id, displayGroup.name)"
                        class="element-count"
                      >({{ displayGroup.elements.filter(e=>!['archived','deleted'].includes(e.publishingState)).length }})</small>
                    </span>

                    <b-field class="hover-menu">
                      <p class="control">
                        <b-button
                          icon-right="plus"
                          class="is-small"
                          title="Add Element"
                          @click.prevent="onAddElementToDisplayGroup(moduleGroup.name, displayGroup.name)"
                        />
                      </p>

                      <!-- <span class="group-order">#{{ moduleGroup.public.order }}</span> -->
                    </b-field>

                    <b-field class="hover-menu">
                      <!-- <p class="control">
                      <b-button class="is-small" title="edit" icon-right="edit" />
                      </p>-->
                      <!-- <p class="control">
                    <b-button
                      icon-right="plus"
                      class="is-small"
                      title="Add Element"
                      @click.prevent="onAddElement(moduleGroup.id)"
                    />
                  </p>
                  <b-dropdown position="is-bottom-left">
                    <template #trigger>
                      <b-button size="is-small" class="options-button" icon-left="ellipsis-h" />
                    </template>

                    <b-dropdown-item
                      @click="onChangePublishElements(moduleGroup.id,'published')"
                    >Publish all</b-dropdown-item>
                    <b-dropdown-item
                      @click="onChangePublishElements(moduleGroup.id,'draft')"
                    >Draft all</b-dropdown-item>

                      </b-dropdown>-->
                    </b-field>
                  </div>

                  <div
                    v-if="!displayGroupCollapsed(moduleGroup.id, displayGroup.name)"
                    :class="{'group-items': isDisplayGroupsActive}"
                  >
                    <SlickList
                      :value="displayGroup.elements"
                      lock-axis="y"
                      append-to=".sortable-list"
                      :use-drag-handle="true"
                      helper-class="dragging"
                      class="sortable-list"
                      @sort-end="(data)=>onOrderChanged(data, displayGroup.elements)"
                    >
                      <SlickItem
                        v-for="(moduleElement, indexJ) in displayGroup.elements"
                        :key="indexJ"
                        :index="indexJ"
                      >
                        <router-link
                          v-if="!['archived','deleted'].includes(moduleElement.publishingState) || showArchivedElements[indexI]"
                          class="module-element-list-item"
                          :class="[moduleElement.publishingState]"
                          :to="toSingleElementLinkKeepHash(moduleElement.id, moduleGroup.id, Module, groupType)"
                        >
                          <slot
                            name="element"
                            :element="moduleElement"
                            :categories-doc="categoriesDoc"
                            :show-archived-elements="showArchivedElements"
                            :index="indexI"
                          >
                            <div class="element-container">
                              <span
                                class="publishing-state-indicator"
                                :class="moduleElement.publishingState"
                              />
                              <span class="element-name">
                                <slot
                                  name="name"
                                  :element="moduleElement"
                                >{{ moduleElement.name || $i18n.getLocalString(moduleElement.public.title || {locales: {en:''} }, $i18n.activeLocales) }} {{ moduleElement.version > 0 ? 'v'+moduleElement.version : '' }}</slot>
                              </span>
                            </div>
                            <!-- <div class="level-right order">#{{ moduleElement.public.order }}</div> -->
                            <div class="hover-menu">
                              <b-dropdown position="is-bottom-left">
                                <template #trigger>
                                  <b-button
                                    size="is-small"
                                    title="Options"
                                    class="options-button"
                                    icon-left="ellipsis-h"
                                    @click.native.prevent
                                  />
                                </template>

                                <b-dropdown-item
                                  @click.native.prevent="onCopyElement(moduleElement.id)"
                                >Duplicate Element</b-dropdown-item>
                                <!-- <b-dropdown-item>Another action</b-dropdown-item>
                                <b-dropdown-item>Something else</b-dropdown-item>-->
                                <!-- add element above -->
                                <b-dropdown-item
                                  @click.native.prevent="onAddElement(moduleGroup.id, moduleElement.id, 'above')"
                                >Add Element Above</b-dropdown-item>
                                <!-- add element below -->
                                <b-dropdown-item
                                  @click.native.prevent="onAddElement(moduleGroup.id, moduleElement.id, 'below')"
                                >Add Element Below</b-dropdown-item>
                              </b-dropdown>

                              <b-icon
                                v-handle
                                icon="grip-vertical"
                                size="is-normal"
                                class="handle"
                                title="Drag to reorder"
                              />
                            </div>

                            <template
                              v-if="moduleElement.publishingState != 'archived' && !$localSettings.modules.hideReferencesInSidebar"
                            >
                              <b-taglist
                                v-for="(categoryIDs,i) in getSortedCategoryReferenceValues(moduleElement)"
                                :key="i"
                                attached
                              >
                                <b-tag
                                  v-for="cat in categoryIDs"
                                  :key="cat"
                                >{{ getCategoryName(cat) }}</b-tag>
                              </b-taglist>
                              <b-taglist>
                                <template
                                  v-for="(identifierDefinition, identifierKey) in $backendConfig.asid.identifierDefinition"
                                >
                                  <b-tag
                                    v-for="(identifierValue, key) in moduleElement.reference.identifierValues[identifierKey]"
                                    v-show="identifierValue"
                                    :key="key+'_'+identifierKey"
                                    type="is-dark"
                                  >{{ identifierDefinition.title || identifierDefinition.name }}: {{ identifierValue }}</b-tag>
                                </template>
                                <b-tag
                                  v-for="asidID in moduleElement.reference.asidIDs"
                                  v-show="asidID"
                                  :key="asidID"
                                  type="is-dark"
                                >ID: {{ asidID }}</b-tag>
                              </b-taglist>
                            </template>
                          </slot>
                        </router-link>
                      </SlickItem>
                    </SlickList>
                  </div>
                </div>
                <b-button
                  v-if="hasArchivedElements[indexI]"
                  type="is-text"
                  expanded
                  :title="!showArchivedElements[indexI]? 'Show archived elements':'Hide archived elements'"
                  @click="toggleShowArchivedElementsForGroupIndex(indexI)"
                >{{ !showArchivedElements[indexI]? 'show':'hide' }} archived</b-button>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <b-button
      v-if="addWidgetText || widgetText"
      @click="onAddGroup()"
    >{{ addWidgetText ? addWidgetText : `Add ${widgetText}` }}</b-button>
    <b-button
      v-if="hasDeletedArchivedGroups"
      type="is-text"
      @click="showDeletedArchivedGroups = !showDeletedArchivedGroups"
    >{{ !showDeletedArchivedGroups? 'Show':'Hide' }} Archived {{ widgetText }}</b-button>

    <VModalCategoryFilter :active.sync="isFilterModalActive" />

    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'

import { CategoryCollection, CategoryID } from '@/types/typeCategory'

import { groupBy } from '@/database/dbHelper'
import { BaseElementDB, BaseGroupDB, ModuleType, ElementID, PublishingState, ElementReference } from './typeModules'
import { SortingOrderHelper } from '../helpers/sortingOrderHelper'
import { ModuleManager } from './moduleManager'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLock, faEllipsisV, faEllipsisH, faChevronDown, faChevronRight, faAngleDoubleLeft, faFilter, faTimes, faFileInvoice, faTimesCircle, faLayerGroup, faSortAlphaDown, faSortAlphaDownAlt, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import BaseModule from './baseModule'
import { RouteConfig } from 'vue-router'
import { asidID } from '@/types/typeAsid'

import { Dictionary, Location } from 'vue-router/types/router'
import { capitalize } from '@/helpers/stringHelper'
import { hasDBid, objectID } from '@/types/typeGeneral'
import CategoryHelper from '@/database/categoryHelper'
import { intersectSome } from '@/helpers/arrayHelper'
import VModalCategoryFilter from '@/components/VModalCategoryFilter.vue'
import { dbPrivileges } from '@/helpers/privileges'
import TenantManager from '@/database/tenantManager'
import { AppConfigDB, PageConfig } from '@/types/typeAppConfig'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { sortElements, sortGroups } from '@/shared/appDataHelper'


library.add(faLock, faTimes, faTimesCircle,
  faEllipsisV, faEllipsisH, faChevronDown, faChevronRight, faAngleDoubleLeft, faFilter,
  faFileInvoice, faTimes, faLayerGroup, faSortAlphaDown, faSortAlphaDownAlt,
  faEye, faEyeSlash)

@Component({
  components: {
    SlickList,
    SlickItem,
    VInputMultiCategorySelection,
    VModalCategoryFilter
  },
  directives: {
    handle: HandleDirective
  }
})
export default class VModuleMenuBody extends Vue {
  public isLoading = false

  @Prop({ type: Array, required: false, default: () => [] })
  readonly newElementAsidPresets!: asidID[]

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly newElementIdentifierPresets!: any

  @Prop({ type: Boolean, required: false, default: false })
  readonly filterElements!: boolean

  // set "filterElements" to true for the filterByElements to be taken into account
  @Prop({ type: Array, required: false, default: () => [] })
  readonly filterByElements!: string[]

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly hideGroups!: boolean

  @Prop({ type: String, required: false, default: () => '' })
  readonly moduleType!: ModuleType

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly hideHeader!: boolean

  @Prop({ type: Function, required: false, default: (module: typeof BaseModule) => ({ name: module.routeNameList }) })
  readonly toListLink!: () => RouteConfig

  @Prop({ type: Function, required: false, default: (id: string, groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget' = 'group-type_widget') => ({ name: module.routeNameElement, params: { id, groupID, groupType } }) })
  readonly toSingleElementLink!: (id: string, groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget') => Location

  public toSingleElementLinkWrapper(id: string, groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget' = 'group-type_widget', order?: number) {
    const rawLink = this.toSingleElementLink(id, groupID, module, groupType)
    // add order and groupID as query params
    if (id === 'new')
      rawLink.query = {
        ...rawLink.query,
        // add order if available
        order: order ? String(order) : undefined,
        groupID
      }

    return rawLink
  }

  /** keeps the same hash */
  public toSingleElementLinkKeepHash(id: string, groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget' = 'group-type_widget') {
    const rawLink = this.toSingleElementLinkWrapper(id, groupID, module, groupType)
    rawLink.hash = this.$route.hash
    return rawLink
  }

  @Prop({ type: Function, required: false, default: (groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget') => ({ name: module.routeNameGroup, params: { groupID, groupType } }) })
  readonly toSingleGroupLink!: (groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget') => Location

  /** keeps the same hash */
  public toSingleGroupLinkKeepHash(groupID: string, module: typeof BaseModule, groupType: 'group-type_group' | 'group-type_widget' = 'group-type_widget') {
    const rawLink = this.toSingleGroupLink(groupID, module, groupType)
    rawLink.hash = this.$route.hash
    return rawLink
  }

  @Prop({ type: String, required: false, default: () => 'group-type_widget' })
  readonly groupType!: 'group-type_widget' | 'group-type_group'

  // @Prop({ type: Array, required: false, default: () => [] })
  readonly groups: Array<hasDBid & BaseGroupDB> = []
  // @Prop({ type: Array, required: false, default: () => [] })
  readonly moduleElements: Array<hasDBid & BaseElementDB> = []
  // @Prop({ type: Object, required: false, default: () => ({}) })
  readonly categoriesDoc: CategoryCollection = this.$categories


  @Prop({ type: String, required: false, default: () => '' })
  readonly addWidgetText!: string

  @Prop({ type: String, required: false, default: () => 'Widget' })
  readonly widgetText!: string

  public Module!: typeof BaseModule


  public isFilterModalActive = false

  private appConfigDB: AppConfigDB = cloneObject(TenantManager.defaultAppConfigDB)

  public get pages(): PageConfig[] {
    return this.appConfigDB.public.pages
  }

  public get filterAndGroupingCount() {
    return [
      this.$localSettings.modules.sortBy !== '' ? 1 : 0,
      this.$localSettings.modules.groupedBy !== '' ? 1 : 0
    ].reduce((a, b) => a + b, 0)
  }

  @Watch('moduleType', { immediate: true })
  public async onModuleTypeChanges() {
    this.Module = ModuleManager.getModuleClassByType(this.moduleType)
    this.isLoading = true
    try {
      await this.$firestoreBind('moduleElements', this.Module.getElementsDbReference(this.$auth.tenant.id), { wait: true })
      await this.$firestoreBind('groups', this.Module.getGroupsDbReference(this.$auth.tenant.id), { wait: true })
      if (this.$auth.userHasPrivilege(dbPrivileges.CONFIG_READ)) {
        await this.$firestoreBind('appConfigDB', TenantManager.getDbAppConfigDocReference(this.$auth.tenant.id)) // TODO make this globally availabe?
      }
      this.onRouteChanged()
    } catch (error: any) {
      this.$helpers.notification.Error('You may be missing permissions to read this data. ' + error.toString())
    } finally {
      this.isLoading = false
    }
  }

  public showArchivedElements = []
  public toggleShowArchivedElementsForGroupIndex(i: number) {
    this.$set(this.showArchivedElements, i, !this.showArchivedElements[i])
  }

  public getSortedCategoryReferenceValues(moduleElement: BaseElementDB) {
    return Object.entries(moduleElement.reference.categoryIDs)
      .sort((a, b) => a[0].localeCompare(b[0], undefined, { numeric: true, sensitivity: 'base' })).map((d) => d[1])
  }


  get hasArchivedElements(): boolean[] {
    return this.elementsByGroups.map((G) =>
      G.displayGroups.some((dg) =>
        dg.elements.some((el) => ['archived', 'deleted'].includes(el.publishingState))
      )
    )
  }

  public showDeletedArchivedGroups = false

  get hasDeletedArchivedGroups() {
    return this.groups
      // filter by groupType
      .filter((g) => g.public.groupType === this.groupType)
      .some((g) => ['deleted', 'archived'].includes(g.publishingState))
  }

  get hasData() {
    return this.groups.length > 0
    // && Object.keys(this.categories).length > 0
  }

  @Watch('$route', { immediate: true })
  public onRouteChanged() {
    // check if the route matches any of the avaibale groups or elements

    // get the groupID from the route
    const groupID = this.$route.params.groupID

    // get the elementID from the route
    const elementID = this.$route.params.id

    let group = null

    if (groupID) {
      group = this.groups.find((g) => g.id === groupID)
    } else if (elementID) {
      const activeElement = this.moduleElements.find((e) => e.id === elementID)

      if (!activeElement) return

      // check if the group of the element is the right type
      const activeElementGroupID = activeElement.public.groupID

      group = this.groups.find((g) => g.id === activeElementGroupID)
    }

    if (!group) return

    this.$emit('route-active', group.public.groupType === this.groupType)
  }


  public displayGroupCollapsed(moduleGroupID: objectID, groupName: string) {
    return this.$localSettings.modules.collapsedDisplayGroups.includes(`${this.moduleType}_${moduleGroupID}_${groupName}`)
  }

  public onCollapseAllDisplayGroups() {
    this.elementsByGroups.forEach((eg) => {
      eg.displayGroups.forEach((dg) => {
        if (!this.displayGroupCollapsed(eg.id, dg.name))
          this.$localSettings.modules.collapsedDisplayGroups.push(`${this.moduleType}_${eg.id}_${dg.name}`)
      })
    })
  }

  public onExpandAllDisplayGroups() {
    this.elementsByGroups.forEach((eg) => {
      eg.displayGroups.forEach((dg) => {
        const index = this.$localSettings.modules.collapsedDisplayGroups.indexOf(`${this.moduleType}_${eg.id}_${dg.name}`)
        if (index >= 0)
          this.$localSettings.modules.collapsedDisplayGroups.splice(index, 1)
      })
    })
  }

  public onToggleCollapsedDisplayGroup(moduleGroupID: objectID, groupName: string) {
    const index = this.$localSettings.modules.collapsedDisplayGroups.indexOf(`${this.moduleType}_${moduleGroupID}_${groupName}`)

    if (index >= 0) {
      this.$localSettings.modules.collapsedDisplayGroups.splice(index, 1)
    } else {
      this.$localSettings.modules.collapsedDisplayGroups.push(`${this.moduleType}_${moduleGroupID}_${groupName}`)
    }
  }

  public groupCollapsed(groupID: objectID) {
    return this.$localSettings.modules.collapsedGroups.includes(`${this.moduleType}_${groupID}`)
  }

  public onCollapseAllWidgets() {
    if (this.hideGroups) return
    this.groups.forEach((g) => {
      if (!this.groupCollapsed(g.id))
        this.$localSettings.modules.collapsedGroups.push(`${this.moduleType}_${g.id}`)
    })
  }

  public onExpandAllWidgets() {
    this.groups.forEach((g) => {
      const index = this.$localSettings.modules.collapsedGroups.indexOf(`${this.moduleType}_${g.id}`)
      if (index >= 0)
        this.$localSettings.modules.collapsedGroups.splice(index, 1)
    })
  }

  public onToggleCollapsedGroup(groupID: objectID) {
    const index = this.$localSettings.modules.collapsedGroups.indexOf(`${this.moduleType}_${groupID}`)

    if (index >= 0) {
      this.$localSettings.modules.collapsedGroups.splice(index, 1)
    } else {
      this.$localSettings.modules.collapsedGroups.push(`${this.moduleType}_${groupID}`)
    }
  }

  public getCategoryName(catID: CategoryID) {
    return (this.categoriesDoc[catID] && this.categoriesDoc[catID].name) || 'category not found'
  }

  private referencesToQuery(elementReference: ElementReference) {
    const query: Dictionary<string | (string | null)[] | null | undefined> = {}

    if (this.newElementAsidPresets.length > 0)
      query['elRefPresetAsid'] = this.newElementAsidPresets

    if (Object.keys(this.newElementIdentifierPresets).length > 0)
      query['elRefPresetIdentifier'] = Object.keys(this.newElementIdentifierPresets).map((idKey) => `${idKey}__-__${this.newElementIdentifierPresets[idKey]}`)

    if (elementReference.categoryIDs) {
      query['elRefPresetCategories_c1'] = elementReference.categoryIDs.c1
      query['elRefPresetCategories_c2'] = elementReference.categoryIDs.c2
      query['elRefPresetCategories_c3'] = elementReference.categoryIDs.c3
    }
    if (elementReference.identifierValues) {
      query['elRefPresetIdentifier'] = Object.entries(elementReference.identifierValues)
        .filter(([idKey, idValue]) => idValue.length > 0)
        .map(([idKey, idValue]) => `${idKey}__-__${idValue}`)
    }

    return query
  }

  public async onAddElement(groupID: ElementID, pivotElementID?: ElementID, position: 'above' | 'below' = 'below') {
    // todo disable and emit instead
    // this.$emit('add-element', groupID)
    const query: Dictionary<string | (string | null)[] | null | undefined> = {}

    if (this.newElementAsidPresets.length > 0)
      query['elRefPresetAsid'] = this.newElementAsidPresets

    if (Object.keys(this.newElementIdentifierPresets).length > 0)
      query['elRefPresetIdentifier'] = Object.keys(this.newElementIdentifierPresets).map((idKey) => `${idKey}__-__${this.newElementIdentifierPresets[idKey]}`)

    // if a pivotElementID is given, calculate the order based on the pivotElementID and the position
    let order = undefined
    if (pivotElementID) {
      // if reordering is not possible due to filters, show a dialog and cancel the reordering
      if (this.isDisplayGroupsActive || this.$localSettings.modules.sortBy !== '') {
        this.$buefy.dialog.alert({
          title: 'Add Element at position not possible',
          message: 'Adding an element at a specific position is not possible while filters are active. Please clear the filters to add an element at a specific position.',
          type: 'is-warning',
          hasIcon: true,
          iconPack: 'fas',
          icon: 'exclamation-triangle',
          ariaRole: 'alertdialog',
          ariaModal: true,
          canCancel: false,
          confirmText: 'OK'
        })
        return
      }

      const sortedElementsInGroup = this.elementsByGroups
        .find((g) => g.id === groupID)?.displayGroups?.flatMap((dg) => dg.elements)
        .sort(sortElements) || []

      const pivotElementIndex = sortedElementsInGroup.findIndex((e) => e.id === pivotElementID)

      const targetIndex = (position === 'above') ? pivotElementIndex : pivotElementIndex + 1

      order = SortingOrderHelper.getOrderToInsertAtPosition(sortedElementsInGroup, targetIndex)
    }

    const toSingleElementLink = this.toSingleElementLinkWrapper('new', groupID, this.Module, this.groupType, order)

    await this.$router.push({
      ...toSingleElementLink,
      query: {
        ...query,
        ...toSingleElementLink.query
      }
    })
  }

  public async onAddElementToDisplayGroup(groupName: string, displayGroupName: string) {
    // if adding a new elemtn to a display group, get one element of this group and based on the current filter, set the respective references
    const groupedBy = this.$localSettings.modules.groupedBy
    const elementsByGroup = this.elementsByGroups.find((eg) => eg.name === groupName)

    if (!elementsByGroup) return

    const displayGroup = elementsByGroup?.displayGroups.find((dg) => dg.name === displayGroupName)
    const elementFromDisplayGroup = displayGroup?.elements[0]

    const elementReference: ElementReference = {
      asidIDs: [], categoryIDs: { c1: [], c2: [], c3: [] }, identifierValues: {
        i1: [], i2: [], i3: [], i4: [], i5: [], i6: []
      }
    }

    switch (groupedBy) {
      case 'categories':
        elementReference.categoryIDs = elementFromDisplayGroup?.reference.categoryIDs || { c1: [], c2: [], c3: [] }
        break
      case 'identifier':
        if (elementFromDisplayGroup?.reference.identifierValues)
          elementReference.identifierValues = elementFromDisplayGroup?.reference.identifierValues
        break
      case 'name':
        break
    }

    const query = this.referencesToQuery(elementReference)

    // if grouped by name, add the string before thr first '/' in the name as url query
    if (groupedBy === 'name' && elementFromDisplayGroup?.name.includes('/')) {
      query['elRefPresetName'] = elementFromDisplayGroup?.name.split('/')[0]
    }

    const toSingleElementLink = this.toSingleElementLinkWrapper('new', elementsByGroup.id, this.Module, this.groupType)

    await this.$router.push({
      ...toSingleElementLink,
      query
    })
  }

  public async onChangePublishElements(groupID: ElementID, targetState: PublishingState) {
    const verbWords = {
      published: 'publish',
      draft: 'draft',
      deleted: 'deleted',
      archived: 'archive'
    }

    const pastWords = {
      published: 'published',
      draft: 'drafted',
      deleted: 'deleted',
      archived: 'archived'
    }

    try {
      const elements = this.elementsByGroups
        .find((g) => g.id === groupID)?.displayGroups?.flatMap((dg) => dg.elements)
        .filter((e) => !['deleted', 'archived'].includes(e.publishingState))
        .filter((e) => e.publishingState !== targetState)
        || []
      if (elements?.length === 0) throw 'no matching elements to publish'

      await new Promise((res, rej) => this.$buefy.dialog.confirm({
        title: `${capitalize(verbWords[targetState])} Elements`,
        message: `Are you sure you want to <b>${verbWords[targetState]} all ${elements?.length}</b> elements?`,
        confirmText: `${capitalize(verbWords[targetState])} ${elements?.length} Elements`,
        type: 'is-success',
        hasIcon: false,
        onConfirm: res,
        onCancel: () => rej(`changing state to ${verbWords[targetState]} canceled`)
      }))

      this.isLoading = true
      for (const el of elements) {
        await this.Module.updateElement(this.$auth.tenant.id, this.$auth.userEmail, el.id, { publishingState: targetState })
      }
      this.$helpers.notification.Success(`${elements?.length} Elements have been ${pastWords[targetState]}`)
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    } finally {
      this.isLoading = false
    }
  }

  public async onAddGroup() {
    // todo disable and emit instead
    // this.$emit('add-group', groupID)
    if (this.hideGroups) {
      await this.onAddElement(this.Module.defaultGroupID)
    } else {
      const toSingleGroupLink = this.toSingleGroupLink('new', this.Module, this.groupType)
      await this.$router.push(toSingleGroupLink)
    }
  }

  public onCopyElement(moduleElementId: objectID) {
    this.Module.copyElement(this.$auth.tenant.id, this.$auth.userEmail, moduleElementId)
      .then(async (d) => {
        this.$helpers.notification.Success('Element copied')
        await this.$router.push({ name: this.Module.routeNameElement, params: { id: d.id } })
      })
      .catch((e: any) => this.$helpers.notification.Error('[20210702] Error copying element: ' + e))
  }

  public async onOrderChanged(
    { event, newIndex, oldIndex }: { event: any, newIndex: number, oldIndex: number },
    elements: Array<BaseElementDB & hasDBid>
  ) {
    if (newIndex === oldIndex) return

    // if reordering is not possible due to filters, show a dialog and cancel the reordering
    if (this.isDisplayGroupsActive || elements[oldIndex].publishingState === 'archived' || this.$localSettings.modules.sortBy !== '') {
      this.$buefy.dialog.alert({
        title: 'Reordering not possible',
        message: 'Reordering is not possible due to active filters or archived elements. Please clear the filters or hide archived elements to reorder.',
        type: 'is-warning',
        hasIcon: true,
        iconPack: 'fas',
        icon: 'exclamation-triangle',
        ariaRole: 'alertdialog',
        ariaModal: true,
        canCancel: false,
        confirmText: 'OK'
      })
      return
    }

    this.isLoading = true
    SortingOrderHelper.manageOrder(
      elements,
      oldIndex,
      newIndex,
      async (el, order) => {
        console.log('updating', el, order)

        return this.Module
          .updateElement(this.$auth.tenant.id, this.$auth.userEmail, el.id, { public: { order: order } })
      },
      (el) => el.public.order,
      (el, order) => el.public.order = order)
      .then(() => this.$helpers.notification.Success('Order updated'))
      .catch((e) => this.$helpers.notification.Error('Error updating Order ' + e.toString()))
      .finally(() => this.isLoading = false)

    console.log(this.moduleElements.map((el) => el.public.groupID))
    console.log(this.moduleElements.map((el) => el.id))

    // this.moduleElements = this.moduleElements.sort((a, b) => a.public.order - b.public.order)
  }

  public isDisplayGroupsActive = false

  /**
   * Elements are grouped by widget (group) and displayGroup
   * [
   *  {
   *   name : 'widget name',
   *   displayGroups: [
   *     {
   *       name: 'displa group Name'
   *       elements: []
   *     }
   *   ]
   *  }
   * ]
   */
  get elementsByGroups(): Array<hasDBid & BaseGroupDB & {
    displayGroups: { name: string, elements: (hasDBid & BaseElementDB)[] }[]
  }> {
    const elsByGroup = groupBy(this.moduleElements, (el) => el.public.groupID)

    const tmpElByGroup = []
    const lcFilter = this.$localSettings.modules.filter.toLowerCase()
    const isFilterActive = lcFilter.length > 0
    const isCategoryFilterActive = this.$localSettings.modules.filters.categories.length > 0

    const filterCategories = CategoryHelper.getFilteredCategories(
      this.$categories,
      this.$localSettings.modules.filters.categories,
      this.$auth.user?.visibleCategories || [],
      this.$localSettings.modules.filters.categoriesIncludeParentCats,
      this.$localSettings.modules.filters.categoriesIncludeChildCats
    )

    // todo filter by comma
    for (const group of this.groups) {
      if (group.public.groupType !== this.groupType) continue

      /**
       * Element A v2 versionBaseElementId A v0
       *  Element A v1 versionBaseElementId: A v0
       *  Element A v0 versionBaseElementId: null
       * Element B
       * Element C
       * ----
       * Archived Element
       */

      const elements = (elsByGroup[group.id] || [])
        .sort((a, b) => {
          if (this.$localSettings.modules.sortBy === 'element-name-asc') {
            return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
          } else if (this.$localSettings.modules.sortBy === 'element-name-desc') {
            return b.name.localeCompare(a.name, undefined, { numeric: true, sensitivity: 'base' })
          }

          // sort archived and deleted elements to bottom preserving their order
          let orderA = a.public.order
          let orderB = b.public.order

          if (a.publishingState == 'archived') orderA += 100000
          if (b.publishingState == 'archived') orderB += 100000
          if (a.publishingState == 'deleted') orderA += 10000000
          if (b.publishingState == 'deleted') orderB += 10000000

          return orderA - orderB || a.id.localeCompare(b.id, undefined, { numeric: true, sensitivity: 'base' }) // if order is the same, fallback to id based sorting
        })
        .filter((el) => (!this.filterElements) || this.filterByElements.includes(el.id))
        .filter((el) => (
          !isFilterActive
          // name matches
          || el.name.toLowerCase().includes(lcFilter)
          // category name matches
          || Object.values(el.reference.categoryIDs)
            .flat()
            .map((cid) => this.getCategoryName(cid))
            .some((cName) => cName.toLowerCase().includes(lcFilter))
          // identifier matches
            || Object.values(el.reference.identifierValues)
              .map((id) => (id + '').toLowerCase())
              .some((id) => id.includes(lcFilter))
          // id matches
              || el.id.toLowerCase().includes(lcFilter)
          // group name matches
              || group.name.toLowerCase().includes(lcFilter)
        ))
        .filter((el) => (
          filterCategories.length == 0
          || intersectSome(Object.values(el.reference.categoryIDs)
            .flat(), filterCategories)
        ))

      // group versions together {Av0:[Av2,Av0,Av1]}
      // const data = Object.values(groupBy(elements, (el) => el.versionBaseElementId || el.id))
      // // console.log(data)

      // elements = data.map(versions => versions.sort((a, b) => (b.version || 0) - (a.version || 0))) // sort versions by version number
      //   .flat()
      // versionBaseElementId

      let groupByProp = (el: hasDBid & BaseElementDB) => 'group'

      this.isDisplayGroupsActive = false
      if (this.$localSettings.modules.groupedBy !== '') this.isDisplayGroupsActive = true

      switch (this.$localSettings.modules.groupedBy) {
        case 'name':
          groupByProp = (el: hasDBid & BaseElementDB) => {
            const groupElements = el.name.split('/')
            return groupElements.length > 1 ? groupElements[0] : 'other'
          }
          break

        case 'categories':
          groupByProp = (el: hasDBid & BaseElementDB) =>
            Object.values(el.reference.categoryIDs)
              .flat()
              .map((cid) => this.$getCategoryName(cid)).sort().join(', ') || 'no categories'
          break

        case 'identifier':
          groupByProp = (el: hasDBid & BaseElementDB) => {
            const identifierValues = Object.values(el.reference.identifierValues).flatMap((iv) => iv).filter((iv) => !!iv).sort()
            return identifierValues.length > 0 ? identifierValues.join(', ') : 'no identifiers'
          }

          break

        default:
          break
      }

      const elsByDisplayGroup = groupBy(elements, groupByProp)

      if (
        // dont add the groups if there are no elements and a filter is active
        (isFilterActive || isCategoryFilterActive) && Object.keys(elsByDisplayGroup).length === 0
        // still show the group if the text filter matches the group name
        && (!isFilterActive || !group.name.toLowerCase().includes(lcFilter))
      ) continue

      tmpElByGroup.push({
        ...group,
        id: group.id,
        displayGroups: Object.entries(elsByDisplayGroup).map(([groupName, elements]) => ({ name: groupName, elements }))
      })
    }

    tmpElByGroup.sort(sortGroups)

    return tmpElByGroup
  }

  /**
   * Elements are grouped by pageID
   * [
   *  {
   *   name : 'page name',
   *   pageID: 'pageID',
   *   elementsByGroups: []
   *  }
   * ]
   */
  get elementsByPages() {
    const elementsByPages: {
      pageID: string
      name: string
      elementsByGroups: Array<hasDBid & BaseGroupDB & {
        displayGroups: { name: string, elements: (hasDBid & BaseElementDB)[] }[]
      }>
    }[] = []

    for (const elementsByGroup of this.elementsByGroups) {
      if (['archived', 'deleted'].includes(elementsByGroup.publishingState) && !this.showDeletedArchivedGroups) continue

      const pageID = elementsByGroup.public.pageID
      const page = this.pages.find((p) => p.id === pageID)

      const resultEntry = elementsByPages.find((e) => e.pageID === pageID)

      if (!resultEntry) {
        elementsByPages.push({
          pageID,
          name: page?.name || (pageID === '' ? 'Main Page' : 'Unknown Page'),
          elementsByGroups: [elementsByGroup]
        })
      } else {
        resultEntry.elementsByGroups.push(elementsByGroup)
      }
    }

    // sort alphabetically by page name but put main page first
    elementsByPages.sort((a, b) => {
      if (a.pageID === '') return -1
      if (b.pageID === '') return 1
      return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' })
    })

    return elementsByPages
  }
}
</script>


<style lang="scss">
@import '@/variables.scss';
@import '@/mixins.scss';

.module-menu-body {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: clip;
  min-height: 0; // to override the default behavior of not beeing smaller than the content

  .setting-dropdown .icon {
    vertical-align: middle;
  }

  .filter-modal {
    .modal-card {
      overflow: visible;

      .modal-card-body {
        overflow: visible;
      }
    }
  }

  .tags.has-addons .tag:not(:first-child) {
    border-left: 1px solid #c1c1c1;
  }

  // @include from($tablet) {
  //   width: 16rem;

  //   &.is-expanded {
  //     width: 26rem;
  //     // width: auto;
  //     // max-width: 26rem;
  //   }
  // }

  .sidebar-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: all 0.2s linear;
    transform: rotate(180deg);
  }

  &.is-expanded {
    .sidebar-toggle {
      transform: rotate(0deg);
      // left: 8px;
    }
  }

  .filter-message {
    margin-top: 1rem;
  }

  .module-element-container {
    height: 100%;
    overflow-y: auto;
    margin: 0.1em -0.3em 1em;
    margin-bottom: 1em;
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    padding: 0.3em;

    @include scrollbars(8px, $primary, #e0e0e0);

    .expand-collapse {
      margin-right: 0.5em;
      cursor: pointer;
    }

    .page-wrapper {
      .page-header {
        margin: 1.5rem 0.8rem 0.7rem;
        font-weight: bold;
      }

      span.page-name {
        // margin-left: 0.6rem;
      }
    }

    .group-container {
      margin-bottom: 1.3em;

      &:not(:hover) .hover-menu {
        display: none;
      }

      // border-left: 0.3em solid #b4b4b4;

      &.display-group {
        margin-left: 0.3em;
        padding-top: 0.5em;
      }

      .group-items {
        border-left: 0.3em solid #d6d6d6;
      }

      .group-header {
        background: #d6d6d6;
        padding: 0.2em 0.2em 0.2em 0.8em;
        padding-left: 1em;
        color: #222;
        border-radius: 4px 4px 4px 0;
        line-height: 1.72em;
        position: relative;
        margin-bottom: 0;

        &.level {
          display: flex;
        }

        .hover-menu {
          line-height: 0.5em;
          position: absolute;
          top: 0;
          right: 0;
          /* background: #f7f7f7; */
          padding: 0;
          box-shadow: 0 0 0.15rem 0.15rem #d6d6d6;
          margin: 0.3rem;

          .control {
            line-height: 0.5em;
          }

          button {
            height: 2em;
          }
        }

        .group-name {
          position: relative;
          width: fit-content;
          word-break: break-word;

          .is-protected {
            margin-right: 0.5em;
          }

          .element-count {
            margin-left: 0.2em;
          }
        }

        &.is-active {
          background: $primary;
          color: white;

          .hover-menu {
            box-shadow: 0 0 0.15rem 0.15rem $primary;
          }

          .group-order {
            color: white;
          }

          + .group-items {
            border-color: $primary;
          }
        }

        &.is-deleted {
          background: $danger;
          color: white;

          .group-order {
            color: white;
          }

          + .group-items {
            border-color: $danger;
          }
        }

        &.is-archived {
          // background: $danger;
          color: grey;

          &.is-active {
            color: rgb(210 210 210);
          }

          .group-order {
            color: white;
          }
        }

        .group-order {
          font-size: smaller;
          margin: 0 0.4em;
          color: #5d5d5d;
        }
      }

      .module-element-list-item {
        padding: 0.4rem 0.5rem;
        width: 100%;
        border-bottom: 2px solid #e0e0e0;
        display: inline-block;
        color: #333;
        background: #e9e9e9;
        position: relative;

        &:hover .hover-menu {
          display: block;
        }

        .hover-menu {
          position: absolute;
          top: 0;
          right: 0;
          background: #f7f7f7;
          padding: 0.4rem 0.5rem 0;
          display: none;

          button {
            height: 2em;
          }
        }

        &.archived.is-version {
          margin-left: 0.8em;
          width: calc(100% - 1em) !important;
          height: 42px;
        }

        &.archived,
        &.deleted {
          color: #8a8a8a;
        }

        .level {
          display: flex;
        }

        &.is-active {
          background: $primary;
          color: white;
          border-radius: 0 3px 3px 0;

          .order {
            color: white;
          }

          .hover-menu {
            background: $primary;
          }

          .handle {
            // display: flex;
            color: #adb1b8;
          }
        }

        &:hover:not(.is-active) {
          // background: #d6d6d6;
          background: #f7f7f7;
        }

        .dropdown-menu {
          min-width: 11rem;
        }

        .element-container {
          width: 100%;

          display: flex;
          flex-direction: row;
          align-items: baseline;

          span.element-name {
            flex-grow: 1;
            word-break: break-word;
          }
        }

        div.tags {
          margin-bottom: -0.5em;
          margin-top: 0.5rem;
        }

        div.level {
          margin-bottom: 0;
        }

        .order {
          color: #757575;
          font-size: smaller;
        }

        .handle {
          // display: none;
          color: #7f8593;
          cursor: row-resize;
        }

        // &:hover .handle {
        //   display: flex;
        // }
      }
    }
  }

  .level-left {
    height: 100%;
  }

  .level {
    align-items: flex-start;
  }

  .dragging {
    z-index: 9999;

    .handle {
      display: flex !important;
      color: #adb1b8;
    }
  }
}
</style>
