<template>
  <section>
    <div v-for="(formUser, index) in formUsers" :key="formUser.id" class="userbox box">
      <b-field grouped group-multiline>
        <b-button
          outlined
          title="Remove user"
          type="is-danger"
          icon-right="trash"
          style="margin-right: 10px;"
          @click="formRemoveUser(index)"
        />
        <b-tooltip label="Send invitation link to user" position="is-top">
          <a :href="getBackendUserRegistrationInviteEmailMailto(formUser.email, formUser.name)">
            <b-button outlined icon-right="envelope" style="margin-right: 10px;" />
          </a>
        </b-tooltip>
        <b-tooltip label="Copy invitation link" position="is-top">
          <b-button
            outlined
            icon-right="link"
            style="margin-right: 10px;"
            @click="copyTextToClipboard(getBackendUserRegistrationLink(formUser.email))"
          />
        </b-tooltip>

        <b-field>
          <b-tooltip
            label="The user Email can't be changed. Add a new user instead"
            position="is-top"
          >
            <b-input
              v-model="formUser.email"
              placeholder="Email"
              type="email"
              icon="envelope"
              disabled
            />
          </b-tooltip>
        </b-field>
        <b-field expanded>
          <b-input
            v-model="formUser.name"
            expanded
            placeholder="Name"
            @blur="valueChanged(formUser.id)"
          />
        </b-field>
        <!-- <b-field expanded>
            <b-input v-model="formUsers[index].notes" placeholder="Notes" />
        </b-field>-->
        <b-field expanded class="user-roles-container" label="User Privileges">
          <VInputUserRolesTags
            v-model="formUsers[index].privileges"
            @selected="valueChanged(formUser.id)"
          />
        </b-field>

        <!-- category input -->
        <b-field class="user-categories-container">
          <template #label>
            Categories
            <VTooltipIconHelp
              text="Limit the user Backend element visibility to the selected categories"
              position="is-top"
            />
          </template>
          <VInputMultiCategorySelection
            v-model="formUsers[index].visibleCategories"
            :categories-doc="categories"
            @selected="valueChanged(formUser.id)"
          />
        </b-field>
      </b-field>
    </div>

    <b-field grouped position="is-right">
      <b-button title="Add new user" @click="formAddUser()">add User</b-button>
    </b-field>

    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay, faPlus, faMinus, faEnvelope, faLink } from '@fortawesome/free-solid-svg-icons'


import VInputUserRolesTags from '@/components/VInputUserRolesTags.vue'
import { UserReferenceDB } from '@/types/typeUser'

import { copy } from '@/helpers/clipboardHelper'

import UserManager from '@/database/userManager'
import { DebounceInstance, debounce } from 'vue-debounce'
import { hasDBid, objectID } from '@/types/typeGeneral'
import VInputMultiCategorySelection from './VInputMultiCategorySelection.vue'
import { CategoryCollection } from '@/types/typeCategory'

library.add(faCalendarDay, faPlus, faMinus, faEnvelope, faLink)


@Component({
  components: {
    VInputUserRolesTags,
    VInputMultiCategorySelection
  }
})
export default class VInputMultiUserManagement extends Vue {
  @Prop({ type: Boolean, required: false, default: () => true })
  readonly autosave!: boolean

  @Prop({ type: String, required: true })
  readonly tenantId!: string

  @Prop({ type: String, required: true })
  readonly tenantName!: string

  @Prop({ type: Object, required: true })
  readonly categories!: CategoryCollection

  public isLoading = false

  public formUsers: Array<UserReferenceDB & hasDBid> = []

  private EMPTY_MAIL_STR = 'DEFAULT_MAIL' // used for a new emelent with not yet an email set

  private userIDsChanged: any = {}

  public getBackendUserRegistrationLink(email: string) {
    return this.$auth.getBackendUserRegistrationLink(email, this.tenantName)
  }

  public getBackendUserRegistrationInviteEmailMailto(email: string, name: string) {
    const getMailtoUrl = ({ to = '', subject = '', body = '' }) => {
      const args = []
      if (subject) {
        args.push('subject=' + encodeURIComponent(subject))
      }
      if (body) {
        args.push('body=' + encodeURIComponent(body))
      }

      let url = 'mailto:' + encodeURIComponent(to)
      if (args.length > 0) {
        url += '?' + args.join('&')
      }
      return url
    }

    return getMailtoUrl({
      to: email,
      subject: `Invitation to ECHO PRM from ${this.tenantName}`,
      body:
        `Hello ${name},

follow this link to register as a user for ${this.tenantName}.

${this.getBackendUserRegistrationLink(email)}

Email: ${email}
Registration Code: ${this.$auth.createRegistrationCode(email, this.tenantName)}
`

    })
  }

  public formAddUser() {
    if (this.formUsers.length >= 200) {
      this.$helpers.notification.Error('you may only add 200 users')
      return
    }

    this.$buefy.dialog.prompt({
      message: 'User Email Address',
      type: 'is-success',
      inputAttrs: {
        placeholder: 'user@example.com',
        maxlength: 300
      },
      trapFocus: true,
      onConfirm: async (value) => {
        this.isLoading = true
        try {
          await UserManager.add(this.tenantId, this.$auth.userEmail, { email: value })
        } catch (error: any) {
          this.$helpers.notification.Error('Error creating user ' + error)
        } finally {
          this.isLoading = false
        }
      }
    })
  }

  public copyTextToClipboard(text: string) {
    copy(text)
  }

  private debounceInstance?: DebounceInstance<any>
  public async valueChanged(userId: objectID) {
    this.userIDsChanged[userId] = true

    if (!this.debounceInstance)
      this.debounceInstance = debounce(async () => {
        if (this.autosave && !this.isLoading) await this.save()
      }, 5000)

    await this.debounceInstance()
  }

  public async save() {
    this.isLoading = true
    for (const userId in this.userIDsChanged) {
      await UserManager.update(this.tenantId, this.$auth.userEmail, userId, { ...this.formUsers.find((el) => el.id === userId) })
        .then(() => this.$helpers.notification.Success('user updated ' + userId))
        .catch((e) => this.$helpers.notification.Error('Error updating user' + e))
    }
    this.isLoading = false
    this.userIDsChanged = {}
  }

  public formRemoveUser(index: number) {
    if (this.formUsers.length === 1) {
      this.$helpers.notification.Error('there must be at least one user')
      return
    }
    const tmpUser = this.formUsers[index]

    this.$buefy.dialog.confirm({
      title: 'Deleting account',
      message: `Are you sure you want to <b>delete ${tmpUser.name} ${tmpUser.email
      }s</b> account? This action cannot be undone.`,
      confirmText: 'Delete Account',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        UserManager.remove(this.tenantId, tmpUser.id)
          .then(() => this.$helpers.notification.Success('user removed ' + tmpUser.id))
          .catch((e) => this.$helpers.notification.Error('Error removing user' + e))
      }
    })
  }

  public async initialise() {
    this.isLoading = true

    await this.$firestoreBind('formUsers', UserManager.getDbCollectionReference(this.tenantId), { wait: true })

    this.isLoading = false
  }

  public async created() {
    await this.initialise()
  }
}
</script>

<style scoped>
.user-roles-container {
  /* margin-bottom: 30px; */
  width: 100%;
}

.field.is-grouped .field.user-categories-container {
  margin-bottom: 0.6rem;
  /* width: 100%; */
}
</style>
