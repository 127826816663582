<template>
  <section class="backend-asid-single">
    <b-notification
      v-if="codeNotFound && !isLoading"
      type="is-danger"
      aria-close-label="Close notification"
      :closable="false"
      role="alert"
    >
      Code
      <b>{{ $props.asid }}</b> could not be found. Make sure the code is valid and not assigned to other User already.
      <router-link class="button" :to="{ name: 'activate-asid' }">Scan again</router-link>
    </b-notification>

    <b-tabs v-if="!isLoading" v-model="tabHashMixin_activeTab" destroy-on-hide>
      <b-tab-item class="asid-assignment-tab">
        <template slot="header">
          <span>Assignment</span>
        </template>

        <section class="right-items">
          <VRecordMeta
            class="is-pulled-right"
            position="is-bottom-left"
            :document-path="docPath"
            :record-meta="asidDB._meta"
            :required-privileges="documentPrivileges"
          />

          <VButtonSettingsModal :settings="settings" :config="settingsConfig" @save="saveSettings" />
        </section>

        <div class="columns asid-page-wrapper">
          <div class="column">
            <b-field label="Categories">
              <!-- <VInputMultiCategorySelection
                v-model="asidDB.categoryIDs"
                :categories-doc="categoryCollection"
                hide-root
              />-->

              <VInputMultiCategoryEntry
                ref="category-value"
                v-model="asidDB.categoryIDs"
                class="box"
                :category-entry-definitions="$backendConfig.asid.categoryDefinitions"
              />
            </b-field>
            <br />

            <!-- <b-field label="Identifier">
        <b-input
          v-model="asidDB.identifierValue"
          :autofocus="settings.persistCategory"
          placeholder="Identifier"
          required
        />
            </b-field>-->
            <b-field>
              <template slot="label">
                Identifiers
                <VTooltipIconHelp
                  text="Identifiers are variables specific to an asset, like serial number. Use categories and the Data Module when data applies for multiple ECHO Codes"
                />
              </template>
            </b-field>
            <VFormDataEntry
              ref="identifier-value"
              :data-value-object.sync="asidDB.identifierValue"
              :data-definition-object="$backendConfig.asid.identifierDefinition"
              :upload-path="uploadPath"
              :display-barcode-scanner="$localSettings.asidActivate.displayBarcodeScanner"
              :document-path="docPath"
              :categories="asidDB.categoryIDs"
              do-set-default-values
            />

            <template v-if="showAttributes">
              <b-field>
                <template slot="label">
                  Attributes
                  <VTooltipIconHelp
                    text="Attributes are variables specific to an asset, like serial number. Use categories and the Data Module when data applies for multiple ECHO Codes"
                  />
                </template>
              </b-field>
              <VFormDataEntry
                ref="asset-attribute-value"
                :data-value-object.sync="asidDB.assetAttributeValue"
                :data-definition-object="$backendConfig.asid.assetAttributeDefinitions"
                :upload-path="uploadPath"
                :display-barcode-scanner="$localSettings.asidActivate.displayBarcodeScanner"
                :document-path="docPath"
                :categories="asidDB.categoryIDs"
                do-set-default-values
              />
            </template>

            <section v-for="dataDefinition,i in dataModuleGroups" :key="i">
              <template v-if="dataElements.find(da=>da.public.groupID === dataDefinition.id)">
                <b-field>
                  <template slot="label">
                    {{ dataDefinition.name }}
                    <VTooltipIconHelp
                      text="These Data Module variables might apply to multiple ECHO Codes. You can change them in the Data Module."
                    />
                    <b-button
                      tag="router-link"
                      :to="{ name: 'module-data-single', params: { id: dataElements.find(da => da.public.groupID === dataDefinition.id)?.id || '' } }"
                      type="is-text"
                      size="is-small"
                      icon-right="external-link-alt"
                    >open Data Element</b-button>
                  </template>
                </b-field>

                <VFormDataEntry
                  disabled
                  :data-value-object="dataElements.find(da=>da.public.groupID === dataDefinition.id)?.data"
                  :data-definition-object="dataDefinition.dataDefinition"
                  :upload-path="uploadPath"
                  :document-path="docPath"
                  title="those values might apply to multiple ECHO Codes. Go to the Data module to change them."
                />
              </template>
            </section>
          </div>

          <div class="column is-narrow echo-sticker-preview-container">
            <VEchoCode
              class="echo-sticker-preview"
              :code-config="asidDB.codeConfig"
              :attribute="asidDB.assetAttributeValue"
              :asid="asidDB.id"
              :base-url="$backendConfig.asid.baseUrl"
              :identifier="asidDB.identifierValue"
              :data="dataByDefinitionKeys"
              :asid-categories="asidDB.categoryIDs"
              :category-collection="categoryCollection"
            />
            <b-tag type="is-dark" class="echo-id">
              <span style="font-family: monospace;">{{ asidDB.id }}</span>
            </b-tag>
          </div>
        </div>

        <hr />

        <section
          v-if="$auth.userHasAllPrivilege([$auth.dbPrivileges.CATEGORIES_READ, $auth.privileges.CODE_LIST_VIEW])"
          class="module-elements-container"
        >
          <h5 class="title is-5">
            Elements
            <b-button
              type="is-text"
              size="is-small"
              @click="$localSettings.asidShow.expandElements=!$localSettings.asidShow.expandElements"
            >{{ !$localSettings.asidShow.expandElements?'show':'hide' }} Module Elements</b-button>
          </h5>
          <div v-if="$localSettings.asidShow.expandElements" class="module-menus-container columns">
            <div v-for="moduleType in activeModuleTypes" :key="moduleType" class="column">
              <VModuleMenu
                :new-element-asid-presets="(Object.keys(asidDB.identifierValue).length>0)?[]:[$props.asid]"
                :new-element-identifier-presets="asidDB.identifierValue"
                :module-type="moduleType"
                :label="getModuleNameByType(moduleType)"
                description=" "
                add-widget-text
                filter-elements
                :filter-by-elements="moduleElements.filter(me => me.type === moduleType).filter(me => me.publishingState === 'published').map(me => me.id)"
              />
            </div>
          </div>
        </section>
        <VCrudControl
          hide-remove
          :save-button-text="asidDB.activated ? 'Update' : asid === 'any' ? 'Activate New ECHO Code' : 'Activate'"
          @cancel="onCancel"
          @save="onSave"
        >
          <b-button
            v-if="isActivateAndNext"
            type="is-success"
            @click="onSaveAndNext"
          >{{ asidDB.activated ? 'Update' : 'Activate' }} & Next</b-button>
        </VCrudControl>
      </b-tab-item>

      <b-tab-item
        v-if="$auth.userHasPrivilege($auth.privileges.CODE_LIST_VIEW) && asidDB.activated"
      >
        <template slot="header">
          <span>
            Responses
            <b-tag rounded>{{ totalResponseCount }}</b-tag>
          </span>
        </template>

        <h5 class="title is-5">Responses</h5>
        <b-field label="Filter">
          <b-field grouped>
            <p class="control">
              <VFilterModulesDropdownView
                v-model="$localSettings.asidShow.filters.responseModules"
              />
            </p>

            <b-field>
              <b-switch
                v-model="$localSettings.asidShow.filters.hideVisits"
                :rounded="false"
              >Hide Visits</b-switch>
            </b-field>
          </b-field>
        </b-field>
        <section class="responses">
          <VResponsesTimelineView
            :asid="asid"
            :responses-form="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('Form')) ? alignedResponsesForm: []"
            :responses-protection="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('Protection')) ? alignedResponsesProtection: []"
            :responses-file="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('File')) ? alignedResponsesFile: []"
            :responses-i18n=" $localSettings.asidShow.filters.hideVisits ? [] : ($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('I18n')) ? alignedResponsesI18n: []"
            :responses-custom="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('Custom')) ? alignedResponsesCustom: []"
            :responses-script="($localSettings.asidShow.filters.responseModules.length === 0 || $localSettings.asidShow.filters.responseModules.includes('Script')) ? alignedResponsesScript: []"
            :sessions="($localSettings.asidShow.filters.hideVisits) ? [] : alignedSessions"
            :module-elements-with-type="moduleElements"
          />

          <!-- show load more button if any response count is equal to the limit -->
          <div class="has-text-centered">
            <b-button
              v-if="alignedResponsesForm.length === responseLimit
                || alignedResponsesProtection.length === responseLimit
                || alignedResponsesFile.length === responseLimit
                || alignedResponsesCustom.length === responseLimit
                || alignedResponsesScript.length === responseLimit
                || alignedSessions.length === responseLimit
                || alignedResponsesI18n.length === responseLimit"
              class="load-more-button"
              @click="loadMoreResponses"
            >Load more</b-button>
          </div>

          <VCrudControl hide-remove hide-cancel is-autosave />
        </section>

        <b-loading :active="responsesLoading" />
      </b-tab-item>

      <b-tab-item
        v-if="$auth.userHasPrivilege($auth.privileges.CODE_LIST_VIEW) && asidDB.activated"
      >
        <template slot="header">
          <span>
            Visits
            <b-tag rounded>{{ asidDB._computed.pageviews }}</b-tag>
          </span>
        </template>

        <VueApexCharts
          v-if="!isLoading"
          height="300"
          :options="timeSeriesAppSessionsChartOptions"
          :series="timeSeriesAppSessions"
        />
      </b-tab-item>

      <!-- if the user ha the incidents view privilege, show a tab with open and active incidents -->
      <b-tab-item v-if="$auth.userHasAllPrivilege(servicePrivileges) && asidDB.activated">
        <template slot="header">
          <span @click="onClickGotoIncidents">
            Tickets
            <b-taglist attached style="display: inline-block;">
              <b-tag
                v-if="newIncidentCount > 0"
                title="new ticket count"
                rounded
                type="is-twitter"
              >new: {{ newIncidentCount }}</b-tag>
              <b-tag
                v-if="openIncidentsCount > 0"
                rounded
                type="is-success"
                title="open ticket count"
              >open: {{ openIncidentsCount }}</b-tag>
              <b-tag
                v-if="totalIncidentsCount > 0"
                title="total ticket count"
                rounded
              >total: {{ totalIncidentsCount }}</b-tag>
            </b-taglist>
          </span>
        </template>
      </b-tab-item>
    </b-tabs>

    <!-- </b-tab-item>
    <b-tab-item label="settings" icon="cog">-->
    <b-loading :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Watch, Prop } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faQuestionCircle, faCog, faQrcode, faSyncAlt, faEdit, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'

import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import VTooltipIconHelp from '@/components/global/VTooltipIconHelp.vue'


import { CategoryCollection } from '@/types/typeCategory'

import { assetAttributeKeyedObject, AsidDB, IdentifierKeyedObject, isIdentifierKey, isAssetAttributeKey } from '@/types/typeAsid'
import VueApexCharts from 'vue-apexcharts'
import AsidManager from '@/database/asidManager'
import { DataDefinition, isDataDefinitionKey } from '@/types/typeDataDefinition'
import databaseSchema from '@/database/databaseSchema'
import { merge, typedWhere, typedOrderBy, arrayGroupBy } from '@/database/dbHelper'
import VRecordMeta from '@/components/VRecordMeta.vue'
import VButtonSettingsModal from '@/components/VButtonSettingsModal.vue'
import VResponsesTimelineView from '@/components/VResponsesTimelineView.vue'
import VInputMultiModuleSelection from '@/components/VInputMultiModuleSelection.vue'
import { SettingsAsidActivate, SettingsConfig } from '@/types/typeLocalSettings'
import { cloneObject } from '@/helpers/dataShapeUtil'
import VEchoCode from '@/components/VEchoCode.vue'
import { FormResponseDB } from '@/modules/form/typeFormModule'
import FormModule from '@/modules/form/formModule'

import { CustomResponseDB } from '@/modules/custom/typeCustomModule'
import { FileResponseDB } from '@/modules/file/typeFileModule'
import FileModule from '@/modules/file/fileModule'
import CustomModule from '@/modules/custom/customModule'
import { ElementWithTypeAndID, ModuleType } from '@/modules/typeModules'
import { ModuleManager } from '@/modules/moduleManager'
import { ScriptResponseDB } from '@/modules/script/typeScriptModule'
import ScriptModule from '@/modules/script/scriptModule'

import VModuleMenu from './../../modules/VModuleMenu.vue'
import { ProtectionResponseDB } from '@/modules/protection/typeProtectionModule'
import ProtectionModule from '@/modules/protection/protectionModule'
import { hasDBid } from '@/types/typeGeneral'
import { DataElementDB, DataGroupDB, isDataKey } from '@/modules/data/typeDataModule'
import DataModule from '@/modules/data/dataModule'
import { SessionDB } from '@/types/typeAppSession'
import SessionManager from '@/database/sessionManager'
import VFilterModulesDropdownView from '@/components/VFilterModulesDropdownView.vue'
import { intersectSome } from '@/helpers/arrayHelper'
import { AppPreview } from '@/components/VBackendAppPreview.vue'
import moment from 'dayjs'
import { getStatisticsPerDay } from '@/helpers/statisticsHelper'
import VFormDataEntry from '@/components/VFormDataEntry.vue'
import { UPLOAD_PATHS } from '@/helpers/storageHelper'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import I18nModule from '@/modules/i18n/i18nModule'
import { I18nResponseDB } from '@/modules/i18n/typeI18nModule'
import { mixins } from 'vue-class-component'
import VTabHashMixin from '@/components/mixins/VTabHashMixin.vue'
import ServiceModule from '@/modules/service/serviceModule'
import VInputMultiCategoryEntry from '@/components/VInputMultiCategoryEntry.vue'
import CategoryHelper from '@/database/categoryHelper'

library.add(faInfoCircle, faQuestionCircle, faCog, faQrcode, faSyncAlt, faEdit, faExternalLinkAlt)


@Component({
  components: {
    VInputMultiCategorySelection,
    VTooltipIconHelp,
    VRecordMeta,
    VButtonSettingsModal,
    VResponsesTimelineView,
    VModuleMenu,
    VEchoCode,
    VFilterModulesDropdownView,
    VInputMultiModuleSelection,
    VueApexCharts,
    VFormDataEntry,
    VInputMultiCategoryEntry
  }
})
export default class BackendAsidSingle extends mixins(VTabHashMixin, VCustomVueFireBindMixin) {
  // used for form controls
  public asidDB: AsidDB & hasDBid = { ...AsidManager.defaultDocDB, id: '' }

  // #region tab handling
  public tabHashMixin_activeTab = 0

  protected tabHashMixin_HASH_TAB_MAP = [
    '',
    '#responses',
    '#visits'
  ]
  // #endregion tab handling

  // #region service Module incidents

  public servicePrivileges = [...ServiceModule.authPrivileges.view, ...ServiceModule.authPrivileges.r]
  public openIncidentsCount: number = 0
  public openIncidentsUnseenCount: number = 0
  public newIncidentCount: number = 0
  public totalIncidentsCount: number = 0

  public getModuleNameByType(moduleType: ModuleType) {
    return ModuleManager.getModuleClassByType(moduleType).displayName
  }

  public async onClickGotoIncidents() {
    await this.$router.push({
      name: ServiceModule.routeNameIncidentsFilter, query: {
        'public.asidID': this.asid
      }
    })
  }

  private async initIncidentCounts() {
    // if the user does not have the incidents view privilege, return
    if (!this.$auth.userHasAllPrivilege(this.servicePrivileges))
      return

    const openFilter = {
      'public.asidID': this.asid,
      'public.state': 'open'
    }
    const { totalCount, unseenCount } = await ServiceModule.getCountForFilter(openFilter, {}, this.$auth.userEmail, this.$auth.tenantID)
    this.openIncidentsCount = totalCount
    this.openIncidentsUnseenCount = unseenCount

    // total filter
    const totalFilter = {
      'public.asidID': this.asid
    }

    const { totalCount: allCount } = await ServiceModule.getCountForFilter(totalFilter, {}, this.$auth.userEmail, this.$auth.tenantID)
    this.totalIncidentsCount = allCount

    // new filter
    const newFilter = {
      'public.asidID': this.asid,
      'public.state': 'new'
    }

    const { totalCount: newCount } = await ServiceModule.getCountForFilter(newFilter, {}, this.$auth.userEmail, this.$auth.tenantID)
    this.newIncidentCount = newCount
  }

  // #endregion service Module incidents

  // prop asid
  @Prop({ type: String, required: false, default: () => '' }) readonly asid!: string

  @Prop({ type: String, required: false, default: () => 'false' }) readonly activateNext!: string

  get isActivateAndNext() {
    return this.activateNext === 'true'
  }

  @Watch('isActivateAndNext', { immediate: true })
  public onActivateNextChange() {
    this.firestore_isUnsavedChangesTrapActive = !this.isActivateAndNext
  }

  get uploadPath() {
    return UPLOAD_PATHS.ASID_ASH_ATTRIBUTES(this.$auth.tenantID)
  }

  private mounted() {
    console.debug('BackendAsidSingle mounted')
  }

  public isLoading = false
  // public asidDB.identifierValue: any = {}
  public identifierDefinitions: (DataDefinition & { __variableKey__: keyof IdentifierKeyedObject })[] = []
  public assetAttributeDefinitions: (DataDefinition & { __variableKey__: keyof assetAttributeKeyedObject })[] = []

  // #region visit timeseries
  public get timeSeriesAppSessionsChartOptions() {
    return {
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      colors: ['#444'],
      chart: {
        type: 'area',
        // type: 'bar',
        stacked: false,
        height: 350,
        zoom: {
          type: 'x',
          enabled: true
          // autoScaleYaxis: true
        },
        toolbar: {
          show: true,
          autoSelected: 'zoom',
          download: true,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false
        }
      },
      dataLabels: {
        enabled: false
      },
      // markers: {
      //   size: 0
      // },
      // title: {
      //   // text: 'Assigned Codes',
      //   align: 'left'
      // },
      // fill: {
      //   type: 'gradient',
      //   gradient: {
      //     shadeIntensity: 1,
      //     inverseColors: false,
      //     opacityFrom: 0.5,
      //     opacityTo: 0,
      //     stops: [0, 90, 100]
      //   }
      // },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            // console.debug(val)

            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
        // title: {
        //   // text: 'Price'
        // }
      },
      xaxis: {
        type: 'datetime',
        // get the first entry from the date sorted timeSeriesAppSessions
        min: moment(this.timeSeriesAppSessions?.[0]?.data?.[0]?.x || new Date()).subtract(1, 'month').toDate().getTime(),
        max: moment(new Date()).toDate().getTime()
        // max: moment(new Date()).add(1,'week').toDate().getTime()
        // min: moment(new Date()).startOf('month').toDate().getTime(),
        // max: moment(new Date()).endOf('month').toDate().getTime()
      },
      tooltip: {
        shared: false,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
      }
    }
  }

  get timeSeriesAppSessions() {
    return [{
      name: 'Sessions',
      data: this.asidDB?._computed?.statistics
        && getStatisticsPerDay(this.asidDB?._computed?.statistics)
          .map((d) => ({ x: d.date, y: d.events.pv || 0 }))
          .sort((a, b) => a.x.valueOf() - b.x.valueOf()) // sort by date to easily get the first and last entry
    }]
  }

  // #endregion visit timeseries

  // #region Data Module
  public dataModuleGroups: (DataGroupDB & hasDBid)[] = []

  // moduleElement[] only the top MEs per group
  public get dataElements() {
    return arrayGroupBy(
      (this.moduleElements
        .filter((me) => me.type === 'Data')
        .filter((me) => me.publishingState === 'published')) as Array<{
          id: string
          type: ModuleType
        } & DataElementDB>,
      (d) => d.public.groupID) // {group:groupID, data: moduleElements}
      .map(({ data }) => // moduleElement[] only the top MEs per group
        data.sort((a, b) => a.public.order - b.public.order)[0]
      )
  }

  get dataByDefinitionKeys() {
    // {varName1: 12, varNameFromOtherElement: '23'}
    let combinesDataElements: { [key: string]: any } = {}
    this.dataModuleGroups.forEach((dd) => {
      Object.entries(dd.dataDefinition).map(([key, def]) => {
        const element = this.dataElements.find((me) => me.public.groupID === dd.id)
        if (element && def.name)
          combinesDataElements[def.name] = element?.data?.[key as isDataKey]
      }
      )
    })
    return combinesDataElements
  }

  // #endregion Data Module


  public categoryCollection: CategoryCollection = this.$categories

  public codeNotFound = false


  get totalResponseCount() {
    // exclude count from service module
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { service, ...responseCountPerModule } = this.asidDB._computed.responseCountPerModule
    return Object.values(responseCountPerModule).reduce((prev, curr) => prev + curr, 0)
  }


  // #region RecordMeta
  get docPath() {
    return (this.$props.asid) ? databaseSchema.COLLECTIONS.ASID.__DOCUMENT_PATH__(this.$props.asid) : null
  }


  get documentPrivileges() {
    return merge(databaseSchema.COLLECTIONS.ASID.__PRIVILEGES__,
      { r: databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__PRIVILEGES__.r, w: [] })
  }

  // #endregion RecordMeta

  get showAttributes() {
    return Object.values(this.$backendConfig.asid.assetAttributeDefinitions).some((dd) => dd.name !== '')
  }

  public async onSaveAndNext() {
    if ((await this.$save()) === true)
      await this.$router.push({ name: 'activate-asid' })
  }

  private async $save() {
    let successful = false

    try {
      const identifierValid = (this.$refs['identifier-value'] as VFormDataEntry).validateInput()
      const assetAttributeValid = this.showAttributes ? (this.$refs['asset-attribute-value'] as VFormDataEntry).validateInput() : true
      const categoriesValid = (this.$refs['category-value'] as VInputMultiCategoryEntry).validateInput()

      if (!identifierValid) {
        throw 'identifiers not valid, cant save'
      } else if (!assetAttributeValid) {
        throw 'attributes not valid, cant save'
      } else if (!categoriesValid) {
        throw 'categories not valid, cant save'
      }

      const getvariableString = (dataDefinition: (DataDefinition & { __variableKey__: string })[], getDataValue: (key: isDataDefinitionKey) => any) => {
        return dataDefinition
          .filter((dd) => dd.categories.length === 0 || CategoryHelper.isElementActiveForAsidRef(dd.categories, this.asidDB.categoryIDs, this.$categories))
          .sort((a, b) => a.order - b.order || a.__variableKey__.localeCompare(b.__variableKey__))
          .reduce((str, dataDef) => {
            // if type is image, show image
            if (dataDef.datatype === 'image') {
              return `${str} <b>${dataDef.title || dataDef.name}</b>: <img src="${getDataValue(dataDef.__variableKey__)}" style="max-width: 50px; max-height: 50px;"><br>`
            } else if (dataDef.datatype === 'auto_generated' && !getDataValue(dataDef.__variableKey__)) { // if type is auto_generated and not value is present, show the pattern
              return `${str} <b>${dataDef.title || dataDef.name}</b>: ${dataDef.validator.pattern || ''}<br>`
            }
            return `${str} <b>${dataDef.title || dataDef.name}</b>: ${getDataValue(dataDef.__variableKey__) || ''}<br>`
          }, '')
      }


      const identifierString = 'Identifiers: <br>' + getvariableString(this.identifierDefinitions, (key) => this.asidDB.identifierValue[key as isIdentifierKey])
      const assetAttributeString = 'Attributes: <br>' + getvariableString(this.assetAttributeDefinitions, (key) => this.asidDB.assetAttributeValue[key as isAssetAttributeKey])

      if ((!this.asidDB.activated && this.$backendConfig.activation.confirmActivation)
        || (this.asidDB.activated && this.$backendConfig.activation.confirmUpdate))
        await new Promise<void>((res, rej) => this.$buefy.dialog.confirm({
          title: `Confirm ${(this.asidDB.activated ? 'Update' : 'Activation')}`,
          message: `Setting ${identifierString} 
<hr> 
Setting ${assetAttributeString} 
<hr>
Categories: <b>${this.asidDB.categoryIDs.map((catID) => this.categoryCollection[catID].name).join('</b>, <b>')}</b>`,
          confirmText: 'Set Data',
          type: 'is-success',
          onConfirm: async () => {
            res()
          },
          onCancel: () => rej('cancel by user')
        }))

      if (this.$localSettings.asidActivate.persistCategory)
        this.$localSettings.asidActivate.persistedCategories = this.asidDB.categoryIDs

      this.isLoading = true


      if (this.asidDB.tenantID && this.asidDB.tenantID != this.$auth.tenant.id)
        throw 'This CODE is already assigned by another tenant'


      if (this.asid === 'any') {
        const asidID = await AsidManager.activateAnyASID(this.$auth.userEmail, this.$auth.tenant.id, {
          identifierValue: this.asidDB.identifierValue,
          assetAttributeValue: this.asidDB.assetAttributeValue,
          categoryIDs: this.asidDB.categoryIDs
        })

        await this.$router.push({ name: 'asid-single', params: { asid: asidID } })

        return
      } else if (!this.asidDB.tenantID) {
        await AsidManager.assignAndActivateASID(this.$props.asid, this.$auth.userEmail, this.$auth.tenant.id, {
          identifierValue: this.asidDB.identifierValue,
          assetAttributeValue: this.asidDB.assetAttributeValue,
          categoryIDs: this.asidDB.categoryIDs
        })
          .then(() => this.$helpers.notification.Success('ECHO Code activated and assigned'))
      } else if (!this.asidDB.activated) {
        await AsidManager.activateASID(this.$props.asid, this.$auth.userEmail, {
          identifierValue: this.asidDB.identifierValue,
          assetAttributeValue: this.asidDB.assetAttributeValue,
          categoryIDs: this.asidDB.categoryIDs
        })
          .then(() => this.$helpers.notification.Success('ECHO Code activated'))
      } else {
        await AsidManager.updateAsid(
          this.$props.asid,
          this.$auth.userEmail,
          this.asidDB.identifierValue,
          this.asidDB.assetAttributeValue,
          this.asidDB.categoryIDs
        )
          .then(() => this.$helpers.notification.Success('ECHO Code saved'))
          .catch((e: any) => this.$helpers.notification.Error('ECHO Code not saved ' + e))
      }


      await this.init()

      successful = true
    } catch (e: any) {
      this.$helpers.notification.Error('ECHO Code not activated: ' + e)
      successful = false
    } finally {
      this.isLoading = false
    }

    return successful
  }

  public async onSave() {
    await this.$save()
  }

  public async onCancel() {
    await this.init()
  }

  @Watch('asidDB', { deep: true })
  public onAsidChanged() {
    AppPreview.setReferences({
      categoryIDs: this.asidDB.categoryIDs,
      asidID: this.asidDB.id,
      identifierValue: this.asidDB.identifierValue
    },
    this.asidDB.assetAttributeValue
    )

    // hide notifications when asid is not active
    AppPreview.hideNotifications(!this.asidDB.activated)

    // only activate isUnsavedChangesTrapActive when the asid is already active
    this.firestore_isUnsavedChangesTrapActive = this.asidDB.activated && !this.isActivateAndNext
  }

  // public categoryDataTree: CategoryTree = {} as CategoryTree

  // #region settings


  public settings: SettingsAsidActivate = {
    persistCategory: true,
    displayQRCodeScanner: true,
    displayBarcodeScanner: false,
    displayActivateAnyButton: false,
    persistedCategories: []
  }

  public settingsConfig: SettingsConfig[] = [
    {
      title: 'Persist Category',
      description: 'Keep the category selected for the next ECHO CODE when saving this one.',
      accessorKey: 'persistCategory',
      type: 'boolean'
    },
    // {
    //   title: 'ECHO Code Scanner',
    //   description: 'Activate camera based ECHO Code scanner.',
    //   accessorKey: 'displayQRCodeScanner',
    //   type: 'boolean'
    // },
    {
      title: 'Barcode Scanner',
      description: 'Activate barcode scanner to input identifiers',
      accessorKey: 'displayBarcodeScanner',
      type: 'boolean'
    }
  ]

  public initSettings() {
    console.debug('init settings')
    this.settings = cloneObject(this.$localSettings.asidActivate)
  }

  public saveSettings() {
    this.$localSettings.asidActivate = this.settings
  }

  // #endregion settings


  // #region responses
  public moduleElements: ElementWithTypeAndID[] = []

  @Watch('asidDB.categoryIDs')
  @Watch('asidDB.identifierValue')
  private async updateModuleElements() {
    try {
      // if cant read categories its impossible to view hierarchically assigned modules
      if (!this.$auth.userHasPrivilege(this.$auth.dbPrivileges.CATEGORIES_READ)) {
        // show error message
        this.$helpers.notification.Error('You dont have the privilege to read categories. Please contact your administrator.')
      } else {
        this.$unbindHandle(await ModuleManager.onSnapshotElementsForReference({
          asid: this.asid,
          categoryIDs: this.asidDB.categoryIDs,
          identifierValue: this.asidDB.identifierValue
        }, this.$auth.tenant.id, this.$auth.userPrivileges, {
          includeDeleted: false, debugName: 'asidSingle'
        }, (elements) => {
          this.moduleElements = elements
        }, true))
      }
    } catch (error) {
      this.$helpers.notification.Error(error)
    }
  }

  public responsesProtection: Array<hasDBid & ProtectionResponseDB> = []
  public responsesForm: Array<hasDBid & FormResponseDB> = []
  public responsesFile: Array<hasDBid & FileResponseDB> = []
  public responsesCustom: Array<hasDBid & CustomResponseDB> = []
  public responsesScript: Array<hasDBid & ScriptResponseDB> = []
  public responsesI18n: Array<hasDBid & I18nResponseDB> = []
  public sessions: Array<hasDBid & SessionDB> = []

  private allResponsesLoaded = false

  /**
   * Aligned means that for a certain date range all responses for all modules are available
   * if e.g. there are 10 form responses for one month and 5 file responses for one week
   * the smallest timespan to fill the 10 responses (file) will dictate the overall scale
   * otherwise we would show one month, but only one week of file responses, which would be confusing
   */
  public alignedResponsesProtection: Array<hasDBid & ProtectionResponseDB> = []
  public alignedResponsesForm: Array<hasDBid & FormResponseDB> = []
  public alignedResponsesFile: Array<hasDBid & FileResponseDB> = []
  public alignedResponsesCustom: Array<hasDBid & CustomResponseDB> = []
  public alignedResponsesScript: Array<hasDBid & ScriptResponseDB> = []
  public alignedResponsesI18n: Array<hasDBid & I18nResponseDB> = []
  public alignedSessions: Array<hasDBid & SessionDB> = []


  /**
   * ------------time-(older)------>
   * a:             |
   * b: #     #    #|    #     #     #
   * c: ### #  #   #|
   * d:       #     |     #                 #
   * e:             |   #     # ####
   *   |---aligned--|
   *
   * align to series c as it reaches 6 responses first
   * 0. filter out all response with less than 6 responses
   * 1. get the oldest date of all responses
   * 2. get the newset date of the oldest dates
   * 3. filter all responses that are older than the oldest date
   *
   */
  @Watch('responsesProtection')
  @Watch('responsesForm')
  @Watch('responsesFile')
  @Watch('responsesCustom')
  @Watch('responsesScript')
  @Watch('responsesI18n')
  @Watch('sessions')
  private alignResponses() {
    console.debug('try aligning responses. all loaded:', this.allResponsesLoaded)
    if (!this.allResponsesLoaded) return

    const allResponses = [
      this.responsesProtection,
      this.responsesForm,
      this.responsesFile,
      this.responsesCustom,
      this.responsesScript,
      this.responsesI18n,
      this.sessions
    ]

    // if there are more than LIMI responses get the newest date
    // if its less tnan 10 ignore the dates as it is not aligning relevant
    const oldestResponse = allResponses
      .filter((responses) => responses.length >= this.responseLimit)
      .map((responses) => responses[responses.length - 1])

    const oldestDates = oldestResponse.map((response) => response._meta.dateCreated.toDate())

    const newestDate = oldestDates
      .filter((date) => date)
      .sort((a, b) => b.valueOf() - a.valueOf())[0]

    // filter all responses that are older than the oldest date
    // if no oldest date is available due to less than responseLimit responses, dont filter
    if (!newestDate) {
      this.alignedResponsesProtection = this.responsesProtection
      this.alignedResponsesForm = this.responsesForm
      this.alignedResponsesFile = this.responsesFile
      this.alignedResponsesCustom = this.responsesCustom
      this.alignedResponsesScript = this.responsesScript
      this.alignedResponsesI18n = this.responsesI18n
      this.alignedSessions = this.sessions
    } else {
      this.alignedResponsesProtection = this.responsesProtection.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesForm = this.responsesForm.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesFile = this.responsesFile.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesCustom = this.responsesCustom.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesScript = this.responsesScript.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedResponsesI18n = this.responsesI18n.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
      this.alignedSessions = this.sessions.filter((response) => response._meta.dateCreated.toDate().valueOf() >= newestDate.valueOf())
    }
  }


  public async loadMoreResponses() {
    this.responseLimit += 20
    await this.loadResponses()
  }

  private initialResponsesLoadingRequested = false
  private responseLimit = 20

  @Watch('tabHashMixin_activeTab', { immediate: true })
  private async initLoadResponses() {
    if (this.tabHashMixin_activeTab !== 1) return
    if (this.initialResponsesLoadingRequested) return

    this.initialResponsesLoadingRequested = true

    await this.loadResponses()
  }

  public responsesLoading = false

  private async loadResponses() {
    try {
      this.responsesLoading = true

      await this.$firestoreBind('sessions',
        typedOrderBy<SessionDB>(
          typedWhere<SessionDB>(
            SessionManager
              .getDbCollectionReference(this.$auth.tenant.id)
              .limit(this.responseLimit),
            { asidID: '' }, '==', this.$props.asid
          ),
          { _meta: { dateCreated: '' as any } }, 'desc'
        ),
        { wait: true }
      )


      if (this.$auth.userHasAllPrivilege(FormModule.authPrivileges.r))
        await this.$firestoreBind('responsesForm',
          typedOrderBy<FormResponseDB>(
            typedWhere<FormResponseDB>(
              typedOrderBy<FormResponseDB>(
                typedWhere<FormResponseDB>(
                  FormModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(FileModule.authPrivileges.r))
        await this.$firestoreBind('responsesFile',
          typedOrderBy<FileResponseDB>(
            typedWhere<FileResponseDB>(
              typedOrderBy<FileResponseDB>(
                typedWhere<FileResponseDB>(
                  FileModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(CustomModule.authPrivileges.r))
        await this.$firestoreBind('responsesCustom',
          typedOrderBy<CustomResponseDB>(
            typedWhere<CustomResponseDB>(
              typedOrderBy<CustomResponseDB>(
                typedWhere<CustomResponseDB>(
                  CustomModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(ScriptModule.authPrivileges.r))
        await this.$firestoreBind('responsesScript',
          typedOrderBy<ScriptResponseDB>(
            typedWhere<ScriptResponseDB>(
              typedOrderBy<ScriptResponseDB>(
                typedWhere<ScriptResponseDB>(
                  ScriptModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(ProtectionModule.authPrivileges.r))
        await this.$firestoreBind('responsesProtection',
          typedOrderBy<ProtectionResponseDB>(
            typedWhere<ProtectionResponseDB>(
              typedOrderBy<ProtectionResponseDB>(
                typedWhere<ProtectionResponseDB>(
                  ProtectionModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      if (this.$auth.userHasAllPrivilege(I18nModule.authPrivileges.r))
        await this.$firestoreBind('responsesI18n',
          typedOrderBy<ProtectionResponseDB>(
            typedWhere<ProtectionResponseDB>(
              typedOrderBy<ProtectionResponseDB>(
                typedWhere<ProtectionResponseDB>(
                  I18nModule
                    .getResponsesDbReference(this.$auth.tenant.id)
                    .limit(this.responseLimit), { publishingState: 'archived' }, 'not-in', ['deleted', 'archived']
                ), { publishingState: 'archived' }, 'asc'),
              { public: { asidID: '' } }, '==', this.$props.asid)
            , { _meta: { dateCreated: '' as any } }, 'desc'),
          { wait: true })

      this.allResponsesLoaded = true

      console.debug('responses loaded')

      this.alignResponses()
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.responsesLoading = false
    }
  }
  // #endregion responses

  public async created() {
    this.initSettings()
    this.initActiveModules()
    await this.initIncidentCounts()
  }

  public activeModuleTypes: ModuleType[] = []

  private initActiveModules() {
    this.$unbindHandle(ModuleManager
      .onSnapshotActivatedModuleClasses(this.$auth.tenant.id, this.$auth.userPrivileges, (Ms) => {
        this.activeModuleTypes = Ms
          .filter((M) => intersectSome(M.authPrivileges.view, this.$auth.userPrivileges))
          .map((M) => M.type)
          .filter((type) => ['Html', 'Form', 'File', 'Custom', 'Script'].includes(type))
      }, (e) => { /** */ }, 'asid-single'))
  }

  // leaving this for reference, using listener on the relevant prop (asid) instead pf route, as route may also change due to hash
  // @Watch('$route', { immediate: true })
  // private async onRouteChange(val: Route, oldVal: Route) {
  //   console.debug('route changed', val, oldVal)

  //   // if only hash changed, dont reload
  //   // if (!!oldVal && val.path === oldVal.path) return

  //   this.init()
  // }

  @Watch('asid', { immediate: true })
  private async init() {
    this.isLoading = true
    this.codeNotFound = false
    this.initialResponsesLoadingRequested = false
    this.allResponsesLoaded = false

    this.$disposeSnapshots()

    await this.updateModuleElements()

    try {
      if (this.asid !== 'any') {
        await this.$bindSnapshot('asidDB', AsidManager.getDbDocReference(this.$props.asid)).catch((e) => {
          this.codeNotFound = true
          throw `ECHO Code not found. (may be assigned to other company) ${e}`
        })
        if (!this.asidDB) {
          throw 'ECHO Code not found or already activated by other tenant'
        }
      } else {
        this.asidDB = cloneObject({
          ...AsidManager.defaultDocDB,
          id: 'ECHO CODE NOT SELECTED',
          codeConfig: this.$backendConfig.codes[0]
        })
      }


      this.identifierDefinitions = Object.entries(this.$backendConfig.asid.identifierDefinition).map(([key, value]) => ({
        __variableKey__: key as keyof IdentifierKeyedObject,
        ...value
      }))
        .filter((e) => e.name || e.title)
        .sort((a, b) => a.order - b.order)

      this.assetAttributeDefinitions = Object.entries(this.$backendConfig.asid.assetAttributeDefinitions).map(([key, value]) => ({
        __variableKey__: key as keyof assetAttributeKeyedObject,
        ...value
      }))
        .filter((e) => e.name || e.title)
        .sort((a, b) => a.order - b.order)

      // if a data element is available for this asid, get its respective dataDefinition
      // => always get all data groups. Simpler and not much data
      // if (this.dataElements.length > 0) {
      // const query = typedWhere<DataGroupDB & hasDBid>(DataModule.getGroupsQuery(this.$auth.tenant.id, false, true), { id: '' }, 'in', this.dataElements.map(d => d.public.groupID))
      if (this.$auth.userHasAllPrivilege(DataModule.authPrivileges.r)) {
        await this.$firestoreBind('dataModuleGroups', DataModule.getGroupsDbReference(this.$auth.tenant.id), { wait: true })
          .catch((e) => {
            throw `dataModuleGroups not found. ${e}[20220226]`
          })
      } else {
        this.dataModuleGroups = []
      }


      if (this.$localSettings.asidActivate.persistCategory && !this.asidDB.dateActivated)
        this.asidDB.categoryIDs = this.$localSettings.asidActivate.persistedCategories
    } catch (error: any) {
      this.$helpers.notification.Error(`${error}`)
      // this.$router.push({ name: 'activate-asid' })
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
.backend-asid-single {
  .load-more-button {
    margin-bottom: 2rem;
  }

  .asid-page-wrapper {
    // width: 100%;
  }

  .module-elements-container {
    margin-bottom: 3em;
  }

  .b-tabs {
    .asid-assignment-tab {
      display: flex;
      flex-direction: column;
    }

    .tab-content {
      padding: 1rem 0;

      .tab-item {
        max-width: 85em;
        width: 100%;
        margin: auto;
      }
    }
  }

  form.box {
    margin-bottom: 2em;

    .field-label.is-normal {
      flex-grow: 2;
    }
  }

  .echo-sticker-preview-container {
    height: 22em;
    padding: 0 3em 3em;
    max-width: 32em;
    width: 100%;

    /* Media query for mobile phone screens */
    @media screen and (width <= 768px) {
      /* center the qr code */
      & {
        margin: auto;
      }
    }

    .echo-id {
      margin: auto;
      font-size: 0.9em;
      font-weight: 700;
      width: 100%;
    }
    // .echo-sticker-preview {}
  }

  .module-menus-container {
    overflow-x: auto;
    flex-wrap: wrap;
  }
}

.category-table tr.detail {
  display: none;
}

form.box {
  background: #f6f6f6;
}

.clickable {
  cursor: pointer;
}
</style>
