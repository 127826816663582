<template>
  <section>
    <template v-if="editor !== null">
      <div class="editor">
        <b-field class="menubar has-addons" group-multiline grouped>
          <VInputLocalizedText
            v-model="data"
            :is-translateable="isTranslateable"
            hide-input
            display-type="editor"
            @changedLocale="locale => formSelectedLocale = locale"
          />

          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('bold') }"
              icon-right="bold"
              title="bold"
              @click="editor.commands.toggleBold()"
            />
          </p>
          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('italic') }"
              icon-right="italic"
              title="italic"
              @click="editor.commands.toggleItalic()"
            />
          </p>
          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('strike') }"
              icon-right="strikethrough"
              title="strike"
              @click="editor.commands.toggleStrike()"
            />
          </p>
          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('underline') }"
              icon-right="underline"
              title="underline"
              @click="editor.commands.toggleUnderline()"
            />
          </p>
          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('setParagraph') }"
              icon-right="paragraph"
              title="paragraph"
              @click="editor.commands.setParagraph()"
            />
          </p>

          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
              title="heading H1"
              @click="editor.commands.toggleHeading({ level: 1 })"
            >H1</b-button>
          </p>

          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
              title="heading H2"
              @click="editor.commands.toggleHeading({ level: 2 })"
            >Title</b-button>
          </p>

          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
              title="heading H3"
              @click="editor.commands.toggleHeading({ level: 3 })"
            >Subtitle</b-button>
          </p>

          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
              @click="editor.commands.toggleHeading({ level: 4 })"
            >H4</b-button>
          </p>

          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
              icon-right="align-left"
              title="align left"
              @click="
                editor.isActive({ textAlign: 'left' })
                  ? editor.commands.unsetTextAlign()
                  : editor.commands.setTextAlign('left')
              "
            />
          </p>
          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
              icon-right="align-center"
              title="align center"
              @click="
                editor.isActive({ textAlign: 'center' })
                  ? editor.commands.unsetTextAlign()
                  : editor.commands.setTextAlign('center')
              "
            />
          </p>
          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
              icon-right="align-right"
              title="align right"
              @click="
                editor.isActive({ textAlign: 'right' })
                  ? editor.commands.unsetTextAlign()
                  : editor.commands.setTextAlign('right')
              "
            />
          </p>

          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('bulletList') }"
              icon-right="list-ul"
              title="bullet list"
              @click="editor.commands.toggleBulletList()"
            />
          </p>

          <p class="control">
            <b-button
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('orderedList') }"
              icon-right="list-ol"
              title="ordered list"
              @click="editor.commands.toggleOrderedList()"
            />
          </p>
          <p class="control">
            <b-button
              icon-right="ruler-horizontal"
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('horizontalRule') }"
              @click="editor.commands.setHorizontalRule()"
            />
          </p>
          <p class="control">
            <VColorPicker
              :color="editor.getAttributes('textStyle').color ? editor.getAttributes('textStyle').color : ''"
              allow-reset
              title="color picker"
              @changed="(color) => (color === '' ? editor.commands.unsetColor() : editor.commands.setColor(color))"
            />
          </p>
          <p class="control">
            <b-button
              icon-right="link"
              class="menubar__button"
              title="add link"
              :class="{ 'is-active': editor.isActive('setLink') }"
              @click="showLinkPrompt()"
            />
          </p>
          <p v-if="uploadPath !== ''" class="control">
            <b-button
              icon-right="image"
              class="menubar__button"
              title="add image"
              :class="{ 'is-active': editor.isActive('setImage') }"
              @click="showImagePrompt()"
            />
          </p>
          <p class="control">
            <b-button
              class="menubar__button"
              title="addTable"
              @click="editor.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: false })"
            >
              <span class="icon is-small table-icon">
                <svg id="icon--table" viewBox="0 0 24 24" width="100%" height="100%">
                  <path
                    fill-rule="evenodd"
                    d="M17 17v5h2a3 3 0 003-3v-2h-5zm-2 0H9v5h6v-5zm2-2h5V9h-5v6zm-2 0V9H9v6h6zm2-8h5V5a3 3 0 00-3-3h-2v5zm-2 0V2H9v5h6zm9 9.177V19a5 5 0 01-5 5H5a5 5 0 01-5-5V5a5 5 0 015-5h14a5 5 0 015 5v2.823a.843.843 0 010 .354v7.646a.843.843 0 010 .354zM7 2H5a3 3 0 00-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 003 3h2v-5H2z"
                  />
                </svg>
              </span>
            </b-button>
          </p>

          <p v-if="editor.isActive('table')" class="control">
            <b-button
              class="menubar__button"
              title="deleteTable"
              @click="editor.commands.deleteTable()"
            >
              <span class="icon is-small table-icon">
                <svg id="icon--delete_table" viewBox="0 0 24 24" width="100%" height="100%">
                  <path
                    d="M19 14a5 5 0 110 10 5 5 0 010-10zm-2.5 5.938h5a.937.937 0 100-1.875h-5a.937.937 0 100 1.875zM12.29 17H9v5h3.674c.356.75.841 1.426 1.427 2H5a5 5 0 01-5-5V5a5 5 0 015-5h14a5 5 0 015 5v2.823a.843.843 0 010 .354V14.1a7.018 7.018 0 00-2-1.427V9h-5v3.29a6.972 6.972 0 00-2 .965V9H9v6h4.255a6.972 6.972 0 00-.965 2zM17 7h5V5a3 3 0 00-3-3h-2v5zm-2 0V2H9v5h6zM7 2H5a3 3 0 00-3 3v2h5V2zM2 9v6h5V9H2zm0 8v2a3 3 0 003 3h2v-5H2z"
                  />
                </svg>
              </span>
            </b-button>
          </p>

          <p v-if="editor.isActive('table')" class="control">
            <b-button
              class="menubar__button"
              title="addColumnAfter"
              @click="editor.commands.addColumnAfter()"
            >
              <span class="icon is-small table-icon">
                <svg id="icon--add_col_before" viewBox="0 0 24 24" width="100%" height="100%">
                  <path
                    d="M19 14a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875h-1.25a.312.312 0 01-.313-.313V16.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 101.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM2 19a3 3 0 006 0V5a3 3 0 10-6 0v14zm-2 0V5a5 5 0 1110 0v14a5 5 0 01-10 0z"
                  />
                </svg>
              </span>
            </b-button>
          </p>

          <p v-if="editor.isActive('table')" class="control">
            <b-button
              class="menubar__button"
              title="addColumnBefore"
              @click="editor.commands.addColumnBefore()"
            >
              <span class="icon is-small table-icon">
                <svg id="icon--add_col_after" viewBox="0 0 24 24" width="100%" height="100%">
                  <path
                    d="M5 14a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875H6.25a.312.312 0 01-.313-.313V16.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H2.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 101.875 0v-1.25c0-.173.14-.313.312-.313H7.5zM16 19a3 3 0 006 0V5a3 3 0 00-6 0v14zm-2 0V5a5 5 0 0110 0v14a5 5 0 01-10 0z"
                  />
                </svg>
              </span>
            </b-button>
          </p>

          <p v-if="editor.isActive('table')" class="control">
            <b-button
              class="menubar__button"
              title="deleteColumn"
              @click="editor.commands.deleteColumn()"
            >
              <span class="icon is-small table-icon">
                <svg id="icon--delete_col" viewBox="0 0 24 24" width="100%" height="100%">
                  <path
                    d="M12.641 21.931a7.01 7.01 0 001.146 1.74A5 5 0 017 19V5a5 5 0 1110 0v7.29a6.972 6.972 0 00-2 .965V5a3 3 0 00-6 0v14a3 3 0 003.641 2.931zM19 14a5 5 0 110 10 5 5 0 010-10zm-2.5 5.938h5a.937.937 0 100-1.875h-5a.937.937 0 100 1.875z"
                  />
                </svg>
              </span>
            </b-button>
          </p>

          <p v-if="editor.isActive('table')" class="control">
            <b-button
              class="menubar__button"
              title="addRowBefore"
              @click="editor.commands.addRowBefore()"
            >
              <span class="icon is-small table-icon">
                <svg id="icon--add_row_after" viewBox="0 0 24 24" width="100%" height="100%">
                  <path
                    d="M19 0a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875h-1.25a.312.312 0 01-.313-.313V2.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312V7.5a.937.937 0 101.875 0V6.25c0-.173.14-.313.312-.313h1.25zM5 16a3 3 0 000 6h14a3 3 0 000-6H5zm0-2h14a5 5 0 010 10H5a5 5 0 010-10z"
                  />
                </svg>
              </span>
            </b-button>
          </p>

          <p v-if="editor.isActive('table')" class="control">
            <b-button
              class="menubar__button"
              title="addRowAfter"
              @click="editor.commands.addRowAfter()"
            >
              <span class="icon is-small table-icon">
                <svg id="icon--add_row_before" viewBox="0 0 24 24" width="100%" height="100%">
                  <path
                    d="M19 14a5 5 0 110 10 5 5 0 010-10zm2.5 5.938a.937.937 0 100-1.875h-1.25a.312.312 0 01-.313-.313V16.5a.937.937 0 10-1.875 0v1.25c0 .173-.14.313-.312.313H16.5a.937.937 0 100 1.875h1.25c.173 0 .313.14.313.312v1.25a.937.937 0 101.875 0v-1.25c0-.173.14-.313.312-.313h1.25zM5 2a3 3 0 100 6h14a3 3 0 000-6H5zm0-2h14a5 5 0 010 10H5A5 5 0 115 0z"
                  />
                </svg>
              </span>
            </b-button>
          </p>

          <p v-if="editor.isActive('table')" class="control">
            <b-button
              class="menubar__button"
              title="deleteRow"
              @click="editor.commands.deleteRow()"
            >
              <span class="icon is-small table-icon">
                <svg id="icon--delete_row" viewBox="0 0 24 24" width="100%" height="100%">
                  <path
                    d="M13.255 15a6.972 6.972 0 00-.965 2H5A5 5 0 015 7h14a5 5 0 014.671 6.787 7.01 7.01 0 00-1.74-1.146A3 3 0 0019 9H5a3 3 0 000 6h8.255zM19 14a5 5 0 110 10 5 5 0 010-10zm-2.5 5.938h5a.937.937 0 100-1.875h-5a.937.937 0 100 1.875z"
                  />
                </svg>
              </span>
            </b-button>
          </p>

          <p v-if="editor.isActive('table')" class="control">
            <b-button
              class="menubar__button"
              title="toggleCellMerge"
              @click="editor.commands.mergeCells()"
            >
              <span class="icon is-small table-icon">
                <svg id="icon--combine_cells" viewBox="0 0 24 24" width="100%" height="100%">
                  <path
                    d="M2 19a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3H5a3 3 0 00-3 3v14zm-2 0V5a5 5 0 015-5h14a5 5 0 015 5v14a5 5 0 01-5 5H5a5 5 0 01-5-5zm12-9a1 1 0 011 1v2a1 1 0 01-2 0v-2a1 1 0 011-1zm0 6a1 1 0 011 1v3a1 1 0 01-2 0v-3a1 1 0 011-1zm0-13a1 1 0 011 1v3a1 1 0 01-2 0V4a1 1 0 011-1z"
                  />
                </svg>
              </span>
            </b-button>
          </p>
          <p class="control">
            <b-button
              icon-right="film"
              class="menubar__button"
              :class="{ 'is-active': editor.isActive('setYoutubeVideo') }"
              @click="setVideoUrl"
            />
          </p>
          <p class="control">
            <b-button icon-right="undo" class="menubar__button" @click="editor.commands.undo()" />
          </p>
          <p class="control">
            <b-button icon-right="redo" class="menubar__button" @click="editor.commands.redo()" />
          </p>
          <p class="control">
            <b-button icon-left="trash" class="menubar__button" @click="onClearContent">Clear</b-button>
          </p>
        </b-field>
        <editor-content
          ref="editorRef"
          class="editor__content content"
          :style="editorStyle"
          :editor="editor"
        />
        <!-- @keyup.native.delete="updateHtmlOnBackspace" -->

        <b-field
          v-if="linksInText.length > 0"
          class="link-footnotes"
          label="Links in this document"
        >
          <div v-for="(href, i) in linksInText" :key="i">
            <a :href="href">{{ href }}</a>
            <br />
          </div>
        </b-field>
      </div>

      <b-modal
        :active.sync="videoModalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        scroll="keep"
      >
        <div class="modal-card" style="width: auto;">
          <header class="modal-card-head">
            <p class="modal-card-title">YouTube Url</p>
          </header>
          <section class="modal-card-body">
            <b-field :message="videoMessage" :type="videoType" label="Url" horizontal>
              <b-input v-model="videoLinkUrl" />
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <div class="is-right">
              <button
                class="button"
                type="button"
                @click=";(videoModalActive = false), (videoType = ''), (videoMessage = '')"
              >Cancel</button>
              <button class="button is-primary" @click="onSetYoutubeLink()">Add Video</button>
            </div>
          </footer>
        </div>
      </b-modal>
      <b-modal
        :active.sync="linkModalActive"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
        scroll="keep"
      >
        <section class="link-modal">
          <div class="modal-card" style="width: auto;">
            <header class="modal-card-head">
              <p class="modal-card-title">Modify {{ !formLinkIsButton ? 'Link' : 'Button' }}</p>
            </header>
            <section class="modal-card-body">
              <b-field :message="message" :type="type">
                <b-field :type="type" expanded>
                  <b-select v-model="formLinkType" placeholder="select a link type" expanded>
                    <option value="web">Website</option>
                    <option value="mail">E-Mail</option>
                    <option value="tel">Phone Number</option>
                    <option value="custom">Custom</option>
                  </b-select>
                  <b-input v-model="formLinkUrl" />
                  <p class="control">
                    <a :href="linkHref" class="button" target="_blank">Test</a>
                  </p>
                </b-field>
              </b-field>
              <b-field>
                Insert as:
                <b-radio
                  v-model="formLinkIsButton"
                  name="link"
                  style="margin-left: 0.6em;"
                  :native-value="false"
                >Link</b-radio>
                <b-radio v-model="formLinkIsButton" name="button" :native-value="true">Button</b-radio>
              </b-field>
              <b-field v-if="!['tel', 'mail'].includes(formLinkType)">
                Open in:
                <b-radio
                  v-model="formLinkIsSameWindow"
                  name="same"
                  style="margin-left: 0.6em;"
                  :native-value="true"
                >Same Tab</b-radio>
                <b-radio v-model="formLinkIsSameWindow" name="other" :native-value="false">New Tab</b-radio>
              </b-field>
              <b-field v-else>
                Open in:
                <b-radio
                  :value="true"
                  name="same"
                  style="margin-left: 0.6em;"
                  :native-value="true"
                >native {{ formLinkType === 'tel' ? 'phone' : 'email' }} app</b-radio>
              </b-field>
            </section>
            <footer class="modal-card-foot">
              <div class="is-right">
                <button class="button" type="button" @click="linkModalActive = false">Cancel</button>
                <button
                  :disabled="!editor.isActive('link')"
                  class="button"
                  type="button"
                  @click="onRemoveLink()"
                >Remove Link</button>
                <button
                  class="button is-primary"
                  @click="onSetLink()"
                >{{ linkUpdate ? 'Update' : 'Add' }} {{ !formLinkIsButton ? 'Link' : 'Button' }}</button>
              </div>
            </footer>
          </div>
        </section>
      </b-modal>

      <VImageUploadModal
        :active.sync="isImageUploadModalActive"
        name-prefix
        :max-filesize="maxImagesize"
        :max-image-width="maxImageWidth"
        :min-image-width="maxImageHeight"
        :upload-path="uploadPath"
        :url="currentImageUrl"
        @url="onImageUrl"
      />
    </template>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch, Model } from 'vue-property-decorator'
import { Cropper } from 'vue-advanced-cropper'
import VResizableImage from '@/components/editor/VEditorResizableImage.vue'
import VYoutubeExtension from '@/components/editor/VEditorYoutubeExtension.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faUndo,
  faRedo,
  faBold,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faStrikethrough,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteLeft,
  faRulerHorizontal,
  faImage,
  faUpload,
  faExpandArrowsAlt,
  faFilm,
  faLink,
  faSquare
} from '@fortawesome/free-solid-svg-icons'
import VModuleElementBaseConfig from '@/components/VModuleElementBaseConfig.vue'
import VImageUploadModal from '@/components/image/VImageUploadModal.vue'
import VInputLocalizedText from '@/components/VInputLocalizedText.vue'
import VColorPicker from '@/components/editor/VEditorColorPicker.vue'

library.add(
  faUndo,
  faLink,
  faSquare,
  faImage,
  faRedo,
  faBold,
  faItalic,
  faUnderline,
  faAlignLeft,
  faAlignRight,
  faAlignCenter,
  faStrikethrough,
  faStrikethrough,
  faCode,
  faParagraph,
  faListUl,
  faListOl,
  faQuoteLeft,
  faRulerHorizontal,
  faUpload,
  faExpandArrowsAlt,
  faFilm
)

import { locale, LocalizedField } from '@/types/typeI18n'
import { Color } from '@tiptap/extension-color'
import TextStyle from '@tiptap/extension-text-style'
import { Editor, EditorContent, VueNodeViewRenderer } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Image from '@tiptap/extension-image'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Youtube from '@tiptap/extension-youtube'
import Focus from '@tiptap/extension-focus'
import TextAlign from '@tiptap/extension-text-align'
import Link from '@tiptap/extension-link'
import Underline from '@tiptap/extension-underline'
import UserManager from '@/database/userManager'
import { arrayUnique } from '@/helpers/arrayHelper'
import { sanitizeEditorHtml } from '../global/VEditorHtml.vue'

const CustomYoutube = Youtube.extend({
  name: 'custom-youtube',
  addAttributes() {
    return {
      ...this.parent?.(),
      isEditable: {
        default: true
      }
    }
  },
  parseHTML() {
    return [
      {
        tag: 'iframe'
      }
    ]
  },
  draggable: true,
  addCommands() {
    return {
      ...this.parent?.()
      /* unsetYoutubeVideo: () => (chain()) => {
          return chain()
            .unsetMark(this.name, { extendEmptyMarkRange: true })
            .setMeta('preventAutolink', true)
            .run()
        } */
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(VYoutubeExtension)
  }
})

const ImageResizable: any = Image.extend({
  name: 'image-resizable',
  addAttributes() {
    return {
      ...this.parent?.(),
      width: {
        default: '100%',
        renderHTML: (attributes: any) => {
          return {
            width: attributes.width
          }
        }
      },
      height: {
        default: 'auto',
        renderHTML: (attributes: any) => {
          return {
            height: attributes.height
          }
        }
      },
      isDraggable: {
        default: false,
        renderHTML: (attributes: any) => {
          return {}
        }
      }
    }
  },
  addOptions() {
    return {
      ...this.parent?.(),
      uploadPath: ''
    }
  },
  addNodeView() {
    return VueNodeViewRenderer(VResizableImage)
  }
})

@Component({
  components: {
    Cropper,
    VInputLocalizedText,
    VModuleElementBaseConfig,
    EditorContent,
    VImageUploadModal,
    VColorPicker
  }
})
export default class VInputEditorLocalized extends Vue {
  @Model('input', { type: Object, required: true, default: (): LocalizedField => ({ _ltType: true, locales: {} }) })
  readonly data!: LocalizedField

  @Prop({ type: String, required: false, default: () => '' })
  readonly uploadPath!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly limitHeight!: string

  @Prop({ type: Number, required: false, default: () => 1024 * 1024 /* 1 MB */ })
  readonly maxImagesize!: number

  @Prop({ type: Number, required: false, default: () => 1000 })
  readonly maxImageHeight!: number

  @Prop({ type: Number, required: false, default: () => 1000 })
  readonly maxImageWidth!: number

  @Prop({ type: Boolean, required: false, default: false })
  readonly isTranslateable!: boolean


  get editorStyle() {
    return this.limitHeight !== '' ? { maxHeight: this.limitHeight } : {}
  }

  public textColor: string = ''
  // editor is not reactive when initialised in the created hook
  public editor: null | Editor = null
  initEditor() {
    if (!this.data.locales[this.formSelectedLocale]) this.$set(this.data.locales, this.formSelectedLocale, '')

    this.editor = new Editor({
      content: this.data.locales[this.formSelectedLocale],
      // parseOptions: { // issue was that within a nodes style tag the spaces were removed, causing the unsaved changes modal to be triggered
      // this did not work
      //   preserveWhitespace: 'full'
      // },
      extensions: [
        // Bold,
        // Italic,
        // Strike,
        Underline,
        // Paragraph,
        // Heading,
        StarterKit,
        // BulletList,
        // OrderedList,
        // ListItem,
        TextStyle,
        Color,
        // HorizontalRule,
        Link.configure({
          openOnClick: false,
          autolink: false,
          HTMLAttributes: {
            contenteditable: true
          }
        }),
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        ImageResizable.configure({
          inline: true,
          uploadPath: this.uploadPath
        }),
        Table.configure({
          resizable: false
        }),
        TableRow,
        TableHeader,
        TableCell,
        CustomYoutube,
        Focus.configure({
          className: 'has-focus',
          mode: 'all'
        })
      ],
      autofocus: true,
      enableInputRules: false,
      enablePasteRules: false,
      onUpdate: () => {
        if (!this.editor) return

        const updatedHtml = this.editor.getHTML()

        // sanitize the html as it might have been pasted from a different source
        const sanitizedHtml = sanitizeEditorHtml(updatedHtml)
        this.onEditorHtmUpdate(sanitizedHtml)
      }
      /* content: '',
      onUpdate:({ editor }) => ({ getHTML }: { getHTML: () => string }) => {
        let html = editor.getHTML()
        if (html === '<p></p>') html = '' // fix <p> when content empty
        this.onEditorHtmUpdate(html)
      } */
    })
  }

  private mounted() {
    this.initEditor()
  }

  /** for some reason the html is not updated on backspace, which is confusing UX when using the preview
   * => resolved: only happens when editor is initialized in created hook
   */
  public updateHtmlOnBackspace() {
    // workaround to force update
    // this.editor.blur()
    // this.editor.focus()
  }

  public formSelectedLocale = this.$i18n.activeLocales[0]

  get availableLocales() {
    // get all configured locales and combine with locales that are already in the v-model
    return arrayUnique([
      ...Object.entries(this.data.locales).filter(([key, value]) => value !== '').map(([key, value]) => key),
      ...this.$i18n.backendEnabledLocales
    ]) as locale[]
  }

  private onEditorHtmUpdate(html: string) {
    if (!this.data.locales[this.formSelectedLocale]) this.$set(this.data.locales, this.formSelectedLocale, '')
    this.data.locales[this.formSelectedLocale] = html || ''
    this.$emit('input', this.data)
    this.updateLinksFootNotes()
  }

  @Watch('formSelectedLocale', { immediate: true })
  @Watch('data', { deep: true }) // false to not update editor on typing (cursor jumps)
  private updateEditorHtm() {
    if (!this.data.locales[this.formSelectedLocale]) this.$set(this.data.locales, this.formSelectedLocale, '')

    const localeValue: string = this.data.locales[this.formSelectedLocale]

    if (this.editor) {
      const editorHtml = sanitizeEditorHtml(this.editor.getHTML())
      const isSame = editorHtml === localeValue

      if (!isSame) {
        this.editor.commands.setContent(localeValue)
      }
    }

    this.updateLinksFootNotes()
  }

  @Watch('formLinkUrl', { immediate: true })
  @Watch('linkModalActive')
  private resetValidationMessage() {
    this.type = ''
    this.message = ''
  }

  public linksInText: string[] = []
  private updateLinksFootNotes() {
    this.linksInText = []
    const as = ((this.$refs.editorRef as any)?.$el as HTMLElement)?.getElementsByTagName('a')
    if (as)
      for (const a of as) {
        this.linksInText.push(a.getAttribute('href') || '')
      }
  }

  public onClearContent() {
    this.data.locales[this.formSelectedLocale] = ''
    this.updateEditorHtm()
  }

  beforeDestroy() {
    // Always destroy your editor instance when it's no longer needed
    if (this.editor) this.editor.destroy()
  }

  private command: any = null

  // IMAGE Upload

  public isImageUploadModalActive = false
  public currentImageUrl = ''
  public imageUpdate = false

  public showImagePrompt() {
    if (!this.editor) return
    this.currentImageUrl = this.editor.getAttributes('image')?.src || ''
    this.imageUpdate = !!this.currentImageUrl

    this.isImageUploadModalActive = true
    // this.command = command
  }

  /* public onUploadVariable(variable : string) {
      this.editor?.commands.insertContent(variable)
        const path = 'src/assets/editor/placeholder-image.jpeg'
      const url = new URL(path, window.location.origin)
      console.log('url ' +  url.href)
      if (this.editor) {
        this.editor.commands.setImage({ src: url.href})
      }
    } */

  public onImageUrl(url: string) {
    if (!this.editor) return
    // if (this.imageUpdate) {
    // this.editor.activeNodes.image.updateAttrs({ src: url, width: '50%' })
    // } else {
    // if (this.command) this.command({ src: url })
    this.editor.commands.setImage({ src: url })
    // if (this.command) this.command({ src: url, width: '50%' })
    // }
  }

  // #region Link

  public formLinkUrl = ''
  public formLinkType = 'web'
  public linkUpdate = false
  public linkModalActive = false
  public formLinkIsButton = false
  public formLinkIsSameWindow = true

  public showLinkPrompt() {
    if (!this.editor) return

    this.linkModalActive = true
    this.formLinkIsButton = false
    this.formLinkIsSameWindow = true

    const url: string = this.editor.getAttributes('link')?.href || ''
    this.linkUpdate = !!url
    this.formLinkUrl = ''
    this.formLinkType = 'web'

    const cssClass: string = this.editor.getAttributes('link')?.class || ''
    this.formLinkIsButton = !!cssClass

    const target: string = this.editor.getAttributes('link')?.target || '_self'
    this.formLinkIsSameWindow = target === '_self'

    // parse form fields from url
    if (this.linkUpdate) {
      if (url.startsWith('mailto:')) {
        this.formLinkType = 'mail'
        this.formLinkUrl = url.substring(7)
      } else if (url.startsWith('tel:')) {
        this.formLinkType = 'tel'
        this.formLinkUrl = url.substring(4)
      } else if (url.startsWith('http')) {
        this.formLinkType = 'web'
        this.formLinkUrl = url
      } else {
        this.formLinkType = 'custom'
        this.formLinkUrl = url
      }
    }

    // this.command = command
    // this.$buefy.dialog.prompt({
    //   message: 'insert link',
    //   icon: 'link',
    //   title: 'Link',
    //   inputAttrs: {
    //     placeholder: 'https://example.com',
    //     value: url,
    //     name: 'Link'
    //   },
    //   confirmText: (url) ? 'Update Link' : 'Insert Link',
    //   onConfirm: (url: string) => command({ href: addUrlSchemaIfNeeded(url) })
    // })
  }

  get linkHref() {
    return this.getHref(false)
  }

  public getHref(validate = false) {
    function addUrlSchemaIfNeeded(url: string) {
      const regex = /^(http|https|mailto:|tel:)/
      if (url.length > 3 && !url.match(regex)) {
        url = 'http://' + url
      }
      return url
    }

    let href = ''
    switch (this.formLinkType) {
      case 'web':
        href = addUrlSchemaIfNeeded(this.formLinkUrl)
        break
      case 'custom':
        href = this.formLinkUrl
        break
      case 'tel':
        href = `tel:${this.formLinkUrl}`
        break
      case 'mail':
        if (validate && !UserManager.validateEmail(this.formLinkUrl.split('?')[0])) {
          throw `Email address ${this.formLinkUrl} not valid`
        }
        href = `mailto:${this.formLinkUrl}`

        break
      default:
        break
    }

    return href
  }

  public type: string = ''
  public message: string = ''
  public href = ''

  public onSetLink() {
    if (!this.editor) return

    try {
      if (this.formLinkUrl === '') {
        this.type = 'is-danger'
        this.message = 'please fill out this field'
      } else {
        this.href = this.getHref(true)
        /*  if (this.command) this.command(
            {
              href,
              class: (this.formLinkIsButton ? 'button' : ''),
              target: (this.formLinkIsSameWindow ? '_self' : '_blank')
            }
          ) */
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({
            href: this.href,
            class: this.formLinkIsButton ? 'button' : '',
            target: this.formLinkIsSameWindow ? '_self' : '_blank'
          } as any)
          .run()
        this.linkModalActive = false
      }
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    }
  }

  public onRemoveLink() {
    if (!this.editor) return

    this.formLinkUrl = ''
    /* if (this.command) this.command({ href: '' }) */
    this.editor.chain().focus().extendMarkRange('link').unsetLink().run()
    this.linkModalActive = false
  }
  // #endregion Link

  // #region video
  public videoModalActive = false
  public videoLinkUrl = ''
  // public videoLinkUpdate = false
  public removeYouTubeLink = false
  public videoType = ''
  public videoMessage = ''
  public youtubeRegex = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
  public setVideoUrl() {
    this.videoModalActive = true
    if (this.videoLinkUrl !== '') {
      // this.videoLinkUpdate = true
      this.removeYouTubeLink = true
    }
  }

  public onSetYoutubeLink() {
    if (this.videoLinkUrl.match(this.youtubeRegex)) {
      this.editor?.commands.setYoutubeVideo({
        src: this.videoLinkUrl
      })
      this.videoModalActive = false
      this.videoLinkUrl = ''
    } else {
      this.videoType = 'is-danger'
      this.videoMessage = 'please enter a valid youtube url'
    }
    if (this.videoLinkUrl == '') {
      this.videoType = 'is-danger'
      this.videoMessage = 'field is empty'
    }
  }

  @Watch('videoLinkUrl', { immediate: true })
  public resetVideoLinkUrl() {
    this.videoType = ''
    this.videoMessage = ''
  }
  // #endregion video
}
</script>

<style lang="scss">
.cropper {
  height: 50vh;
  background: #ddd;
}

.link-modal {
  .help.is-danger {
    margin-left: 13.8em;
  }
}

.editor {
  color: rgb(0 0 0);
  // max-width: 50em;
  margin: auto;

  .tiptap.ProseMirror {
    min-height: 30rem;
  }

  .field.link-footnotes.has-addons {
    display: block;
  }

  .content {
    .button:not(.is-control) {
      width: 100%;
      padding: 1.3em;
      text-decoration: none;
      user-select: auto !important;
    }

    .resize-cursor {
      cursor: col-resize;
    }

    .modal-card {
      p {
        margin: 0;
      }

      .button:not(.is-control) {
        width: initial;
        padding: 1rem;
        text-decoration: none;
        user-select: initial;
      }
    }

    table td,
    table th {
      border: 1px solid #dbdbdb;
      border-width: 1 1 1px;
      padding: 0.1em;
      vertical-align: top;
      border-bottom-width: 1px !important;

      &.selectedCell {
        background: #4099ff12;
      }

      p {
        margin: 0.5rem;
      }
    }
  }

  .icon.is-small.table-icon {
    svg {
      width: 1.2em;
      margin-top: 0.7em;
    }
  }

  .menubar {
    // add a border radius to the first element of type="button"
    p.control:first-of-type button.button {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }

    .localized-textarea-container {
      margin-bottom: 0 !important;
    }

    p.control,
    div.control {
      margin: 0.4em -1px !important;
    }

    // .modal {
    //   p.control:first-of-type button.button {
    //     border-top-left-radius: initial !important;
    //     border-bottom-left-radius: initial !important;
    //   }

    //   p.control,
    //   div.control {
    //     margin: initial !important;
    //   }
    // }
  }

  .editor__content {
    padding: 0.5em 1em;
    background: #fcfcfc;
    border-radius: 0.2em;
    border: 1px solid #dbdbdb;
    overflow-y: auto;
    margin-top: 1rem;

    a {
      cursor: text;
    }

    .iframe {
      input {
        width: 100%;
      }
      background: #4099ff;
      border-radius: 0.3em;
    }

    iframe[src*='youtube'] {
      max-height: 319.5px;
      /* height: 20rem; */
      width: 100%;
      // width: 100vw;
      height: 47.25vw; /* 16:9 */
    }

    .has-focus {
      border-radius: 3px;
      box-shadow: 0 0 0 2px #3ea4ffe6;
    }

    hr {
      background-color: #d8d8d8;
    }

    h1,
    h2,
    h3 {
      font-weight: bold;
    }

    h1 {
      font-size: 3em;
    }

    h2 {
      font-size: 2em;
    }

    h3 {
      font-size: 1.5em;
    }

    ul,
    ol {
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding-inline-start: 1.5em;
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    li > p {
      margin: 0;
    }

    p {
      margin: 1rem 0;
    }

    [contenteditable]:focus {
      outline: 0 solid transparent;
    }

    a {
      text-decoration: underline;
    }
  }
}
</style>

