<template>
  <div class="sticker-preview-container">
    <div class="actions">
      <b-button class="download" @click.stop="onDownload()">download</b-button>
      <b-button title="open CODE in App" icon-left="mobile-alt" tag="a" :href="asidAppLink" />
      <VPrintButton
        type="button"
        :print-container-ref="$refs.sticker"
        class="print-button"
        print-only
      />
    </div>
    <!-- eslint-disable-next-line vue/no-v-html -->
    <div ref="sticker" class="sticker-preview" v-html="svgText" />
    <VStyle>
      @media print {
      .sticker-preview > svg {
      width: {{ codeConfig.width }}mm;
      }
      }
    </VStyle>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import { saveAs } from 'file-saver'

import AsidManager from '@/database/asidManager'

import { BackendConfigDB, CodeConfig } from '@/types/typeBackendConfig'
import { isAssetAttributeKey, isIdentifierKey } from '@/types/typeAsid'
import { dbPrivileges } from '@/helpers/privileges'
import { CategoryCollection, CategoryID } from '@/types/typeCategory'


const VStyle = Vue.component('VStyle', {
  render: function (createElement) {
    return createElement('style', this.$slots.default)
  }
})


@Component({
  components: {
    VStyle
  }
})
export default class VEchoCode extends Vue {
  @Prop({ type: Object, required: false })
  readonly codeConfig!: CodeConfig

  @Prop({ type: Object, required: false, default: () => (null) })
  readonly backendConfig!: BackendConfigDB

  @Prop({ type: Array, required: false, default: () => [] })
  readonly asidCategories!: CategoryID[]

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly categoryCollection!: CategoryCollection

  @Prop({ type: String, required: false, default: () => '' })
  readonly baseUrl!: string

  @Prop({ type: String, required: true })
  readonly asid!: string

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly showPrint!: boolean

  @Prop({ type: String, required: false, default: () => '' })
  readonly svgTemplateText!: string

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly identifier!: any

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly attribute!: any

  @Prop({ type: Object, required: false, default: () => ({}) })
  readonly data!: any

  // @Prop({ type: String, required: false, default: () => '' })
  // private svgUrl!: string

  get asidAppLink() {
    return AsidManager.createLink(this.asid, this.baseUrl || undefined)
  }

  public svgText = ''

  // @Watch('svgUrl')
  @Watch('codeConfig', { deep: true })
  @Watch('showPrint', { deep: true })
  @Watch('data', { deep: true })
  @Watch('identifier', { deep: true })
  @Watch('svgTemplateText')
  @Watch('asid', { immediate: true })
  @Watch('attribute', { deep: true })
  public async insertQRCode() {
    // raise warning if user does not have read backend config permission
    if (!this.$auth.userHasPrivilege(dbPrivileges.CONFIG_READ)) {
      this.$helpers.notification.Warn(`You do not have the permission "${dbPrivileges.CONFIG_READ}", which might be required to display the ECHO CODE sticker template.`)
    }

    const backendConfig = this.backendConfig || this.$backendConfig

    // create object with identifier, attribute names
    const identifierNameeKeyedObject = Object.keys(this.identifier).reduce((acc, key) => {
      acc[backendConfig.asid.identifierDefinition[key as isIdentifierKey].name] = this.identifier[key]
      return acc
    }, {} as any)

    const attributeNameKeyedObject = Object.keys(this.attribute).reduce((acc, key) => {
      acc[backendConfig.asid.assetAttributeDefinitions[key as isAssetAttributeKey].name] = this.attribute[key]
      return acc
    }, {} as any)

    try {
      this.svgText = (await AsidManager.getCodeSVG(
        this.asid,
        this.baseUrl,
        this.svgTemplateText || await AsidManager.getCodeTemplateSVGTextFromUrl(this.codeConfig.svgTemplateUrl),
        this.codeConfig.errorCorrectionLevel,
        [this.codeConfig.customText, this.codeConfig.customText2],
        this.codeConfig.logoUrl,
        this.codeConfig.color,
        this.showPrint,
        {
          identifier: {
            ...identifierNameeKeyedObject,
            ...this.identifier
          },
          attribute: {
            ...attributeNameKeyedObject,
            ...this.attribute
          },
          data: this.data
        },
        this.asidCategories,
        this.categoryCollection,
        backendConfig,
        {
          error: (error) => this.$helpers.notification.Error('Sticker Template: ' + error),
          warn: (warn) => this.$helpers.notification.Warn('Sticker Template: ' + warn)
        }
      )).innerHTML
    } catch (error) {
      this.$helpers.notification.Error(error)
    }
    // this.qrCode = new QRCode({
    //   content: AsidManager.createLink(this.asid, this.baseUrl || undefined),
    //   padding: 0,
    //   width: 100,
    //   height: 100,
    //   color: '#000000',
    //   background: '#ffffff',
    //   ecl: this.codeConfig.errorCorrectionLevel,
    //   container: 'g'
    // }).svg()

    // this.$nextTick(function () {
    //   const qrCodeEl = (this.$refs.sticker as Element).querySelector('#echo-qr-code')
    //   if (!qrCodeEl) {
    //     this.$helpers.notification.Error('svg template does not include id:echo-qr-code to place the WR code into.')
    //   }
    //   const dataMapping = [
    //     {
    //       id: 'echo-asid',
    //       value: this.asid
    //     }, {
    //       id: 'echo-asid-first',
    //       value: this.asid.substr(0,11)
    //     }, {
    //       id: 'echo-asid-second',
    //       value: this.asid.substr(12)
    //     }, {
    //       id: 'echo-text',
    //       value: this.codeConfig.customText
    //     }, {
    //       id: 'echo-qr-code',
    //       value: this.qrCode
    //     }
    //   ]

    //   dataMapping.forEach(mapping => {
    //     const svgEl = (this.$refs.sticker as Element).querySelector('#' + mapping.id)
    //     if (svgEl) {
    //       svgEl.innerHTML = mapping.value
    //     }
    //   })


    // const printStyle = this.$refs.printStyle as HTMLElement
    // const style = `
    //   .sticker-preview > svg {
    //     width: ${this.codeConfig.width}cm;
    //   }`
    // printStyle.innerText = style
  }


  public onDownload() {
    const svgText = (this.$refs.sticker as Element).innerHTML
    const svgBlob = new Blob([svgText], { type: 'image/svg+xml;charset=utf-8' })
    console.log('sdsdsdsdsd')

    saveAs(svgBlob, `echoCODE-${this.asid}.svg`)
  }
}
</script>

<style lang="scss">
@media print {
  // .sticker-preview > svg {
  //   width: 3cm;
  // }
}

// moved to global style as only this node is udes for printing
.sticker-preview {
  width: 100%;
  height: 100%;

  svg {
    display: block;
    max-height: 100%;
    // max-height: stretch; doesnt work in firefox
  }
}

.sticker-preview-container {
  position: relative;
  height: 100%;
  width: 100%;

  &:hover .actions {
    display: block;
  }

  .actions {
    display: none;
    position: absolute;
    top: 0.5em;
    left: 0.5em;

    & > * {
      margin: 0.2em;
    }

    .print-button {
      background: white;
    }
  }
}
</style>
