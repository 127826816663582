<template>
  <div class="category-entry-container">
    <b-field
      v-for="(catEntryDef, index) in filteredCategoryEntryDefinitions"
      :key="index"
      :label="catEntryDef.title"
      :type="validationErrorMessages[index] ? 'is-danger' : ''"
      :message="validationErrorMessages[index]"
    >
      <VInputMultiCategorySelection
        v-model="formSelectedCategories[index]"
        :categories-doc="getCategoryBranchBasedOnPivotElement(catEntryDef.validator.pivotCategory)"
        :disable-branch-nodes="catEntryDef.validator.constraint === 'categoryEntryConstraint_leafNode'"
        :multiple="catEntryDef.validator.maxCount > 1"
      />
    </b-field>
  </div>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'


import CategoryHelper from '@/database/categoryHelper'
// import the component
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { CategoryID, CategoryCollection } from '@/types/typeCategory'
import { CategoryEntryDefinition, CategoryEntryDefinitionObject } from '@/types/typeBackendConfig'
import VInputMultiCategorySelection from './VInputMultiCategorySelection.vue'
import { arrayUnique, identicalArray } from '@/helpers/arrayHelper'

/**
 * Entry categories based on the CategoryEntryDefinitionObject
 * inout is one array of categories and the CategoryEntryDefinitionObject
 * multiple input forms are shown to the user to input categories based on the CategoryEntryDefinitionObject
 */

@Component({
  components: {
    VInputMultiCategorySelection
  },
  model: {
    prop: 'selectedCategoryIDs',
    event: 'selected'
  }
})
export default class VInputMultiCategoryEntry extends Vue {
  @Model('selected', { type: Array, required: true }) readonly selectedCategoryIDs!: CategoryID[]
  @Prop({ type: Object, required: true }) readonly categoryEntryDefinitions!: CategoryEntryDefinitionObject

  // categories array for each category entry
  public formSelectedCategories: {
    e1: CategoryID[]
    e2: CategoryID[]
    e3: CategoryID[]
    e4: CategoryID[]
    e5: CategoryID[]
    e6: CategoryID[]
  } = {
      e1: ['featureExt2Id'],
      e2: [],
      e3: [],
      e4: [],
      e5: [],
      e6: []
    }


  public formCategoryBranches: {
    e1: CategoryID[]
    e2: CategoryID[]
    e3: CategoryID[]
    e4: CategoryID[]
    e5: CategoryID[]
    e6: CategoryID[]
  } = {
      e1: [],
      e2: [],
      e3: [],
      e4: [],
      e5: [],
      e6: []
    }


  // filter out the category entry definitions which do not contain a pivotCategory
  public get filteredCategoryEntryDefinitions(): Partial<CategoryEntryDefinitionObject> {
    return Object.fromEntries(Object.entries(this.categoryEntryDefinitions)
      .filter(([key, value]: [string, CategoryEntryDefinition]) => value.validator.pivotCategory)
      .filter(([key, value]: [string, CategoryEntryDefinition]) => value.title)
      // sort by key
      .sort(([key1, value1]: [string, CategoryEntryDefinition], [key2, value2]: [string, CategoryEntryDefinition]) => key1.localeCompare(key2)))
  }


  public getCategoryBranchBasedOnPivotElement(pivotCategoryID: CategoryID): CategoryCollection {
    return CategoryHelper.getCategoryBranchBasedOnPivotElement(pivotCategoryID, this.$categories)
  }


  // todo see AppAdress on how its done
  @Watch('categoryEntryDefinitions', { immediate: true, deep: true })
  @Watch('selectedCategoryIDs', { immediate: true })
  private onselectedCategoryIDChanged(val: string[]) {
    // assign the selected categories to the formSelectedCategories based on the category entry definition
    // if a selected category intersects with the set of categories of the category entry definition, then assign it to the formSelectedCategories

    for (const key in this.categoryEntryDefinitions) {
      // const branchCategories = CategoryHelper.getAllChildCategoriesArray([value.validator.pivotCategory], this.$categories)
      //   ; (this.formSelectedCategories as any)[key] = this.selectedCategoryIDs.filter((catID: CategoryID) => branchCategories.includes(catID))
      (this.formSelectedCategories as any)[key] = CategoryHelper.filterCategoryIDsByEntryDefinition(
        this.$categories,
        this.categoryEntryDefinitions,
        key as keyof CategoryEntryDefinitionObject,
        this.selectedCategoryIDs
      )
    }
  }

  @Watch('formSelectedCategories', { immediate: true, deep: true })
  private onFormSelectedCategoriesChanged(val: string[]) {
    // aggregate all selections and propagate up
    let selectedCategories = Object.values(this.formSelectedCategories).reduce((acc: CategoryID[], val: CategoryID[]) => acc.concat(val), [])

    selectedCategories = arrayUnique(selectedCategories)

    // only emit if the selected categories are different from the current selected categories to avoid infinite loops
    if (!identicalArray(selectedCategories, this.selectedCategoryIDs)) {
      this.$emit('selected', selectedCategories)
      this.validateInput()
    }
  }

  public validationErrorMessages: { [key: string]: string } = {}

  public validateInput() {
    this.validationErrorMessages = {}
    for (const [key, value] of Object.entries(this.categoryEntryDefinitions)) {
      const selectedCategories = (this.formSelectedCategories as any)[key]
      const branchCategories = CategoryHelper.getAllChildCategoriesArray([value.validator.pivotCategory], this.$categories)
      if (selectedCategories.length < value.validator.minCount) {
        this.validationErrorMessages[key] = `Please select at least ${value.validator.minCount} categories`
      } else if (selectedCategories.length > value.validator.maxCount) {
        this.validationErrorMessages[key] = `Please select at most ${value.validator.maxCount} categories`
      } else if (selectedCategories.some((catID: CategoryID) => !branchCategories.includes(catID))) {
        this.validationErrorMessages[key] = `Please select a category from the branch of ${value.validator.pivotCategory}`
      }
    }

    return Object.keys(this.validationErrorMessages).length === 0
  }
}
</script>

<style lang="scss">
@import '@/variables.scss';
</style>
