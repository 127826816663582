<template>
  <div class="modal-card" style="width: auto;">
    <header class="modal-card-head">
      <p class="modal-card-title">Select Imported Changes</p>
    </header>
    <section class="modal-card-body">
      <b-table
        :data="data"
        :columns="columns"
        checkable
        :checked-rows.sync="checkedRows"
        @check="onRowChecked"
      >
        <b-input
          v-if="!props.column.numeric"
          slot="searchable"
          v-model="props.filters[props.column.field]"
          slot-scope="props"
          placeholder="Search..."
          icon="search"
          size="is-small"
        />
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No changes in imported data.</p>
            </div>
          </section>
        </template>
      </b-table>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$emit('close');$parent.close()">Close</button>
      <button class="button is-success" @click="onConfirmChanges">Apply Changes</button>
    </footer>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'

import ComparisonResult from '@/types/typeComparisonResult'
import { hasDBid } from '@/types/typeGeneral'

@Component({
  components: {},
  props: {
    datasets: {
      type: Object,
      required: true
    }
  }
})
export default class VModuleCompareData extends Vue {
  public checkedRows: Array<any> = []

  public data: Array<ComparisonResult> = []
  public columns = [
    {
      field: 'id',
      label: 'ID',
      numeric: false,
      searchable: true,
      sortable: true
    },
    {
      field: 'name', // a user readable name to identify the dataset
      label: 'Name',
      numeric: false,
      searchable: true,
      sortable: true
    },
    {
      field: 'attribute',
      label: 'Attribute',
      searchable: true,
      sortable: true
    },
    {
      field: 'oldValue',
      label: 'Old Value',
      searchable: true
    },
    {
      field: 'newValue',
      label: 'New Value',
      searchable: true
    }
  ]

  // compare based on attributes in old dataset (adding new attribs is NOT! possible, adding new elements not)
  private compareDatasets(a: Array<any & hasDBid>, b: Array<any & hasDBid>) {
    const differences: Array<ComparisonResult> = []
    for (const objInOldDataset of [...a, ...b.filter((b) => b.id.startsWith('new_'))]) {
      const objInNewDataset = b.find((o) => o.id === objInOldDataset.id)
      if (objInNewDataset)
        for (const attribute of [...new Set([...Object.keys(objInOldDataset), ...Object.keys(objInNewDataset)])]) {
          const newValue = objInNewDataset[attribute]
          const oldValue = (objInNewDataset.id.startsWith('new_')) ? null : objInOldDataset[attribute]

          if (newValue instanceof Array) newValue.sort()
          if (oldValue instanceof Array) oldValue.sort()

          const bothFalsy = !newValue && !oldValue

          if (
            attribute in objInOldDataset
            && attribute in objInNewDataset
            && JSON.stringify(oldValue) !== JSON.stringify(newValue)
            && !bothFalsy
            && attribute !== 'id'
          )
            differences.push({ name: objInOldDataset.name || objInNewDataset.name || '', id: objInOldDataset.id, oldValue, newValue, attribute })
        }
    }

    this.data = differences
  }

  public onConfirmChanges() {
    this.$emit('confirmed', this.checkedRows) // emit ids of changed rows
    this.$emit('close')
  }

  public onRowChecked(checkedList: Array<any>) {
    // do stuff
  }

  @Watch('datasets', { immediate: true })
  public datasetsChanged(val: { oldData: Array<any>, newData: Array<any> }, oldVal: { oldData: Array<any>, newData: Array<any> }) {
    this.compareDatasets(this.$props.datasets.oldData, this.$props.datasets.newData)
    this.checkedRows = this.data
  }
}
</script>
