<template>
  <b-select
    v-model="formSelectedLocale"
    placeholder="Language"
    :icon="doHideGlobe ? '' : 'globe'"
    :class="{'default-selected': formSelectedLocale === 'default'}"
    v-bind="$attrs"
  >
    <option
      v-for="(locale) in supportedLocales"
      :key="locale"
      :value="locale"
      :disabled="disabledLocales.includes(locale)"
    >{{ getLocaleName(locale) }}</option>
  </b-select>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop, Watch } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'
import { locale, LOCALES_NAME_MAP } from '../types/typeI18n'
import { getLocaleName } from '@/helpers/i18nUtil'


library.add(faGlobe)

@Component({
  components: {}
})
export default class VInputLocalizedFieldLocaleselectorView extends Vue {
  @Model('selection-changed', { type: String, default: () => 'default' })
  public selection!: string

  @Prop({ type: Array, required: false, default: () => [] })
  readonly disabledLocales!: locale[]

  @Prop({ type: Array, required: false, default: () => [] })
  readonly availableLocales!: locale[]

  @Prop({ type: Boolean, required: false, default: false })
  readonly doHideGlobe!: boolean

  public get supportedLocales() {
    return this.availableLocales.length > 0 ? this.availableLocales : this.$i18n.backendEnabledLocales
  }

  // disable changes to active locals when using a fallback
  private doChangeActiveLocals = true

  @Watch('$i18n.activeLocales', { deep: true, immediate: true })
  public onActiveLocalChanged() {
    let activeLocale = this.$i18n.activeLocales[0]
    if (this.formSelectedLocale !== activeLocale) {
      // active locale might be 'de-de' but only 'de' is supported
      if (!this.supportedLocales.includes(activeLocale)) {
        // fallback to default if other than 'default' was requested. Fallback to first locale if 'default' was requested
        activeLocale = this.supportedLocales.find((sl) => sl === activeLocale.split('-')[0]) || (activeLocale === 'default' ? this.supportedLocales[0] : 'default')

        // dont change global locale setting if using a fallback to prevent implicitely changing it by just visiting a page with this component
        this.doChangeActiveLocals = false
      }

      this.formSelectedLocale = activeLocale

      // reset this so locale gets changed in every other scenario
      this.doChangeActiveLocals = true
    }
  }

  public set formSelectedLocale(data: string) {
    if (this.doChangeActiveLocals)
      this.$i18n.activeLocales = [data as locale]

    this.$emit('selection-changed', data)
  }

  public get formSelectedLocale() {
    return this.selection
  }

  public getLocaleName(localeId: (keyof typeof LOCALES_NAME_MAP)) {
    return getLocaleName(localeId)
  }
}
</script>

<style lang="scss">
@import '@/variables.scss';

.default-selected.control {
  &.has-icons-left .icon {
    color: $yellow;
  }

  .select select {
    border-color: $yellow;
  }
}
</style>
