import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'

import { CustomModuleDB, CustomElementDB, RequiredCustomPrivileges, CustomGroupDB } from './typeCustomModule'

import { defaultCustomModuleDB, defaultCustomGroupDB, defaultCustomElementDB } from '@/database/databaseSchema'
import { UserPrivilegeIdDB } from '@/types/typeUser'

export default class CustomModule extends BaseModule {
  public static type: ModuleType = 'Custom'
  public static displayName = 'Custom'
  public static color = '#1bd7c9'
  public static hasWidget = true
  public static description = 'Create a custom widget'
  public static descriptionLong
    = 'Create custom widgets to provide tailored functionality for your users. Use javascript, html and css to create your own widgets, seamlessly integrated into the platform.'

  public static authPrivileges: RequiredCustomPrivileges = {
    r: ['custom:read'],
    w: ['custom:write'],
    view: ['custom:view']
  }

  public static moduleDB: CustomModuleDB = defaultCustomModuleDB
  public static elementDB: CustomElementDB = defaultCustomElementDB
  public static groupDB: CustomGroupDB = defaultCustomGroupDB

  constructor() {
    super()
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: this.routeNameList },
        displayName: this.displayName
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/custom',
        name: this.routeNameList,
        component: () => import('./Backend_Module_Custom_List.vue'),
        meta: {
          label: this.displayName,
          description: 'Manage your Custom Widgets',
          breadcrumbs: false,
          isFullsize: true
        },
        children: [
          {
            path: 'groups/:groupID',
            name: this.routeNameGroup,
            component: () => import('./Backend_Module_Custom_Group_Single.vue'),
            meta: {
              label: `${this.displayName} Widget`,
              description: `Manage your ${this.displayName} Widget`,
              isFullsize: true
            },
            props: true
          },
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Module_Custom_Single.vue'),
            meta: {
              label: this.displayName,
              description: 'Manage your Custom Widgets',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          }
        ]
      }
    ]
  }
}
