<template>
  <section>
    <VRecordMeta
      class="is-pulled-right"
      position="is-bottom-left"
      :document-path="docPath"
      :record-meta="asidDb._meta"
      :required-privileges="documentPrivileges"
    />
    <VButtonSettingsModal :settings="settings" :config="settingsConfig" @save="saveSettings" />

    <h5 class="title is-5">{{ settings.displayQRCodeScanner?'Scan':'Input' }} ECHO Code</h5>

    <section v-if="settings.displayQRCodeScanner" class="qr-code-scanner">
      <VAsidScanner @asidDetected="onAsidDetect" />
    </section>

    <section v-else>
      <b-field label="ECHO Code">
        <b-field>
          <b-input expanded @blur="onManualAsidInput" @keyup.native.enter="onManualAsidInput" />
          <p class="control">
            <b-button>load ECHO Code</b-button>
          </p>
        </b-field>
      </b-field>
    </section>

    <b-field v-if="settings.displayActivateAnyButton" class="activate-any-button">
      <b-button expanded @click="onActivateAnyClick">Activate any not activated ECHO Code</b-button>
    </b-field>

    <hr />

    <!-- </b-tab-item>
    <b-tab-item label="settings" icon="cog">-->
    <b-loading :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>


<script lang="ts">
import { Component } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfoCircle, faQuestionCircle, faCog, faQrcode, faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import VTooltipIconHelp from '@/components/global/VTooltipIconHelp.vue'
import { CategoryCollection } from '@/types/typeCategory'

import { AsidDB, IdentifierKeyedObject } from '@/types/typeAsid'
import VButtonSettingsModal from '@/components/VButtonSettingsModal.vue'

import AsidManager from '@/database/asidManager'
import VRecordMeta from '@/components/VRecordMeta.vue'

import databaseSchema from '@/database/databaseSchema'
import { merge } from '@/database/dbHelper'

import { SettingsAsidActivate, SettingsConfig } from '@/types/typeLocalSettings'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { hasDBid } from '@/types/typeGeneral'
import { DataDefinition } from '@/types/typeDataDefinition'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import VAsidScanner from '@/components/VAsidScanner.vue'

library.add(faInfoCircle, faQuestionCircle, faCog, faQrcode, faSyncAlt)

@Component({
  components: {
    VTooltipIconHelp,
    VRecordMeta,
    VButtonSettingsModal,
    VAsidScanner
  },
  props: {
    asid: {
      type: String,
      required: false,
      default: () => ''
    }
  }
})
export default class BackendScanAsid extends VCustomVueFireBindMixin {
  protected firestore_isUnsavedChangesTrapActive = false

  public isLoading = false

  // used for form controls
  public asidDb: AsidDB & hasDBid = { ...AsidManager.defaultDocDB, id: '' }


  public identifierDefinitions: (DataDefinition & { __identifierKey__: keyof IdentifierKeyedObject })[] = []

  public categoryCollection: CategoryCollection = this.$categories

  public codeNotFound = false

  public async onActivateAnyClick() {
    await this.$router.push({ name: 'asid-single', params: { asid: 'any' } })
  }

  public async onAsidDetect(asid: string) {
    this.isLoading = true

    this.$helpers.notification.InfoToast(`ECHO Code ${asid} detected. Loading data...`)

    await this.$router.push({
      name: 'asid-single',
      params: { asid },
      query: { activateNext: 'true' }
    })

    this.isLoading = false
  }

  public async onManualAsidInput(e: any) {
    const asid = e.target.value
    const asidValidated = AsidManager.extractAsidFromString(asid)

    if (asidValidated) {
      await this.onAsidDetect(asidValidated)
    } else {
      this.$helpers.notification.Error('The provided ECHO Code is not in the correct format. Errorid: [20240216]')
    }
  }

  // #region RecordMeta
  get docPath() {
    return (this.$props.asid) ? databaseSchema.COLLECTIONS.ASID.__DOCUMENT_PATH__(this.$props.asid) : null
  }


  get documentPrivileges() {
    return merge(databaseSchema.COLLECTIONS.ASID.__PRIVILEGES__,
      { r: databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__PRIVILEGES__.r, w: [] })
  }

  // #endregion RecordMeta

  // #region barcodeModal
  public isBarcodeScannerModalActive = false
  private barcodeIdentifierIndex = 0
  public onOpenBarcodeModal(index: number) {
    this.barcodeIdentifierIndex = index
    this.isBarcodeScannerModalActive = true
  }

  public onBarcodeDecode(value: string, b: any, c: any) {
    this.isBarcodeScannerModalActive = false
    console.log(value, b, c)
    this.asidDb.identifierValue[this.identifierDefinitions[this.barcodeIdentifierIndex].__identifierKey__] = value
  }

  public onBarcodeLoaded() {
    // this.isBarcodeScannerModalActive = false
  }

  // #endregion barcodeModal

  public async onCancel() {
    await this.$router.push({ name: 'activate-asid' })
  }

  // public categoryDataTree: CategoryTree = {} as CategoryTree

  // #region settings


  public settings: SettingsAsidActivate = {
    persistCategory: true,
    displayQRCodeScanner: true,
    displayBarcodeScanner: false,
    displayActivateAnyButton: false,
    persistedCategories: []
  }

  public settingsConfig: SettingsConfig[] = [
    {
      title: 'ECHO Code Scanner',
      description: 'Activate camera based ECHO Code scanner.',
      accessorKey: 'displayQRCodeScanner',
      type: 'boolean'
    }, {
      title: 'Activate any ECHO Code',
      description: 'Show a button to activate any not yet activated ECHO Code.',
      accessorKey: 'displayActivateAnyButton',
      type: 'boolean'
    }
  ]

  public initSettings() {
    console.log('init settings')
    this.settings = cloneObject(this.$localSettings.asidActivate)
  }

  public saveSettings() {
    this.$localSettings.asidActivate = this.settings
  }

  // #endregion settings

  public created() {
    this.initSettings()
  }
}
</script>

<style lang="scss">
.activate-any-button {
  margin-top: 1em;
}

.category-table tr.detail {
  display: none;
}

.clickable {
  cursor: pointer;
}

form.box {
  background: #f6f6f6;
}

.drop-area span {
  margin: 0.2em;
  margin-top: -0.4em;
}

.image-upload {
  margin-top: 1em;

  &.highlight-dragover {
    background: gray;
  }
}
</style>
