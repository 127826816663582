import BackendConfigMasterData from '@/pages/backend/Backend_Config_MasterData.vue'

import BackendConfigAsid from '@/pages/backend/Backend_Config_Asid.vue'
import BackendConfigLegal from '@/pages/backend/Backend_Config_Legal.vue'
import BackendConfigAuditLog from '@/pages/backend/Backend_Config_AuditLog.vue'
import BackendConfigAlarmLog from '@/pages/backend/Backend_Config_AlarmLog.vue'

import BackendConfigUser from '@/pages/backend/Backend_Config_User.vue'
import BackendConfigBilling from '@/pages/backend/Backend_Config_Billing.vue'

import { dbPrivileges, viewPrivileges } from '@/helpers/privileges'

// use a dedictaed file to be able to programatically acess all config routes

export const configChildRoutes = [
  {
    path: 'backend',
    name: 'config-backend',
    component: BackendConfigAsid,
    meta: {
      label: 'ECHO Code Config',
      description: 'Configure your ECHO ID and other Backend settings.',
      privileges: [viewPrivileges.BACKEND_CONFIG_VIEW, dbPrivileges.CONFIG_READ],
      icon: 'table'
    },
    props: true
  },
  {
    path: 'legal',
    name: 'config-legal',
    component: BackendConfigLegal,
    meta: {
      label: 'App Legal',
      description: 'Customize your Apps legal pages.',
      privileges: [viewPrivileges.APP_CONFIG_VIEW, dbPrivileges.CONFIG_READ],
      icon: 'balance-scale'
    },
    props: true
  },
  {
    path: 'masterdata',
    name: 'config-masterdata',
    component: BackendConfigMasterData,
    meta: {
      label: 'Master Data',
      description: 'Set Invoice and Shipping Adress',
      privileges: [viewPrivileges.MASTERDATA_MANAGE_VIEW],
      icon: 'building'
    }
  },
  {
    path: 'audit-log',
    name: 'audit-log',
    component: BackendConfigAuditLog,
    meta: {
      label: 'Audit Log',
      description: 'View Audit Log',
      privileges: [viewPrivileges.MASTERDATA_MANAGE_VIEW, dbPrivileges.MASTERDATA_WRITE],
      icon: 'clipboard-list'
    }
  },
  {
    path: 'alarm-log',
    name: 'alarm-log',
    component: BackendConfigAlarmLog,
    meta: {
      label: 'Alarm Log',
      description: 'View Alarm Log',
      privileges: [viewPrivileges.MASTERDATA_MANAGE_VIEW, dbPrivileges.MASTERDATA_WRITE],
      icon: 'exclamation-triangle'
    }
  },
  {
    path: 'users',
    name: 'config-user',
    component: BackendConfigUser,
    props: true,
    meta: {
      label: 'User Admin',
      description: 'Manage user permissions',
      privileges: [viewPrivileges.USER_MANAGE_VIEW, dbPrivileges.USER_READ, dbPrivileges.CONFIG_READ],
      icon: 'users'
    }
  },
  {
    path: 'billing',
    name: 'config-billing',
    component: BackendConfigBilling,
    props: true,
    meta: {
      label: 'Purchase & Billing',
      description: 'Manage PRM Modules and pruchase ECHO Codes.',
      privileges: [viewPrivileges.BILLING_VIEW],
      staffOnly: true,
      icon: 'wallet'
    }
  }
]
