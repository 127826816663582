import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'
import JSZip from 'jszip'

// import Backend_Module_File_List from './Backend_Module_File_List.vue'
import { FileModuleDB, FileElementDB, FileGroupDB, FileRessourceType, RequiredFilePrivileges } from './typeFileModule'

import { TenantID } from '@/types/typeTenant'

import { locale, LOCALES_NAME_MAP, LocalizedField } from '@/types/typeI18n'
import StorageManager from '@/helpers/StorageManager'
import { uniqueID } from '@/database/dbHelper'
import { defaultFileModuleDB, defaultFileElementDB, defaultFileGroupDB } from '@/database/databaseSchema'
import { DeepPartial, objectID } from '@/types/typeGeneral'
import { UserPrivilegeIdDB } from '@/types/typeUser'
import { getLocalString } from '@/helpers/i18nUtil'
import { getRessourceTypeFromFileName } from '@/helpers/fileHelper'
import { I18nGlobalsInst } from '../_globals/i18nGlobals'

export default class FileModule extends BaseModule {
  public static type: ModuleType = 'File'
  public static displayName = 'File'
  public static color = '#38bc7d'
  public static description = 'Provide user viewable and downloadable files.'
  public static descriptionLong
    = 'Upload files, such as PDF Manuals, technical documentation, HTML manuals, Videos, Images, youtube URLS, which you want to display on the App.'

  public static MAX_FILESIZE = 1024 * 1024 * 500 // 500MB

  public static authPrivileges: RequiredFilePrivileges = {
    r: ['file:read'],
    w: ['file:write'],
    view: ['file:view']
  }

  public static moduleDB: FileModuleDB = defaultFileModuleDB
  public static elementDB: FileElementDB = defaultFileElementDB
  public static groupDB: FileGroupDB = defaultFileGroupDB

  constructor() {
    super()
  }

  public static get defaultElementName() {
    return `New ${this.type} Container`
  }

  public static automaticElementNaming(element: DeepPartial<FileElementDB>): string {
    // if not all required properties to set the name are available, return the default name
    if (!element.public || !element.public.title || typeof element.name !== 'string' || typeof element.public.ressourceType !== 'string') {
      console.log('not all required properties to set the name are available')
      return this.defaultElementName
    }

    const fileName = getLocalString(element.public.title as LocalizedField, ['en'])

    const getElementName = (fileName: string) => {
      if (fileName === '') fileName = 'without Title'
      return element.public?.ressourceType === 'link' ? `Link ${fileName}` : `File ${fileName}`
    }

    const elementName = element.name.trim()

    if (!fileName && !elementName) {
      // if the element has no name and there is no file name either, return the default name
      return this.defaultElementName
    } else if (!elementName || elementName === this.defaultElementName || elementName === getElementName('')) {
      // if the element has no name or the name is the default name or the name is the same as another the file name, return the file name
      return getElementName(fileName)
    }
    return elementName
  }

  private static defaultLocale: locale = 'default' // needed for eslint to not remove locale type import
  public static async uploadAndAddFile(
    uploaderEmail: string,
    file: File,
    locale: locale = this.defaultLocale,
    tenantId: TenantID,
    authEmail: string,
    elementId: objectID,
    progress: (progress: number) => void,
    maxFileSize = 1024 * 1024,
    allowedFileType: string[] = []
  ) {
    const moduleElementDoc = await this.getElementDocDbReference(tenantId, elementId).get()
    if (!moduleElementDoc.exists) {
      throw `[8013] moduleElement does not exist (${moduleElementDoc.id})`
    }

    const moduleElement: FileElementDB & { id?: string } = {
      ...(moduleElementDoc.data() as FileElementDB),
      id: moduleElementDoc.id
    }

    // log all function parameters
    console.log('uploadAndAddFile:file', file)
    console.log('uploadAndAddFile:locale', locale)
    console.log('uploadAndAddFile:tenantId', tenantId)
    console.log('uploadAndAddFile:authEmail', authEmail)
    console.log('uploadAndAddFile:elementId', elementId)
    console.log('uploadAndAddFile:maxFileSize', maxFileSize)
    console.log('uploadAndAddFile:allowedFileType', allowedFileType)

    let fileType: FileRessourceType = getRessourceTypeFromFileName(file.name)

    // if the filetyps is zip, check if there is an index.html file in the zip to detect if it is a ziphtml file
    if (fileType === 'archive') {
      const zipFile = (await JSZip.loadAsync(file))
      // regex to check if the zip contains an index.html file at any depth
      const fileRegex = new RegExp('index.html', 'i')
      const matchingResult = zipFile.file(fileRegex)
      matchingResult.length > 0 ? (fileType = 'ziphtml') : (fileType = 'archive')
    }

    const fileName = `${uniqueID()}-${file.name}`

    if (moduleElement.public.ressourceType !== '' && moduleElement.public.ressourceType !== fileType) {
      throw `[8012] ressource type does not match (${moduleElement.public.ressourceType}, ${fileType})`
    }

    const downloadUrl = await StorageManager.uploadFile(
      uploaderEmail,
      file,
      fileName,
      this.getUploadPath(tenantId),
      progress,
      maxFileSize,
      allowedFileType,
      {},
      moduleElementDoc.ref.path
    )

    moduleElement.public.url.locales[locale] = downloadUrl

    if (!moduleElement.public.title.locales[locale]) moduleElement.public.title.locales[locale] = file.name
    moduleElement.public.ressourceType = fileType

    delete moduleElement.id

    await this.handleZipHtml(file, moduleElement)

    await this.updateElement(tenantId, authEmail, moduleElementDoc.id, moduleElement)

    return fileType
  }

  /**
 * detects if the zip is multilingual, by checking for /de-DE/index.html or /de/index.html or /en-US/index.html etc. within the zip
 * if it is found, set the startPath of each locale to the respective locale path
 */
  private static async handleZipHtml(zipFile: File, formModuleElement: FileElementDB) {
    if (formModuleElement.public.ressourceType === 'ziphtml') {
      const localesToCheck: locale[] = []
      const allLocales = Object.keys(LOCALES_NAME_MAP) as locale[]
      // also add specific locales like /de-DE if only de is available
      I18nGlobalsInst.backendEnabledLocales.forEach((locale, index) => {
        localesToCheck.push(locale)

        if (locale.length === 2) {
          // check if specific locale is available
          const specificLocales = allLocales.filter((l) => l.startsWith(locale + '-'))
          if (specificLocales.length > 0) {
            localesToCheck.push(...specificLocales)
          }
        }
      })

      const zip = await JSZip().loadAsync(zipFile)

      localesToCheck.forEach((locale) => {
        const localePath = `${locale}/index.html`
        const caseInsensitiveLocalePathRegex = new RegExp(localePath, 'i')
        const file = zip.file(caseInsensitiveLocalePathRegex)
        if (file.length > 0) {
          let targetLocale = locale
          // check if the specific locale is available
          if (!I18nGlobalsInst.backendEnabledLocales.includes(locale))
            targetLocale = locale.split('-')[0].toLocaleLowerCase() as locale

          // only override if not already set
          if (!formModuleElement.public.options.startPath.locales[targetLocale])
            formModuleElement.public.options.startPath.locales[targetLocale] = file[0].name
        }
      })
    }
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: 'module-file-list' },
        displayName: this.displayName
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/files',
        name: this.routeNameList,
        component: () => import('./Backend_Module_File_List.vue'),
        meta: {
          label: this.displayName,
          description: 'Manage your Files',
          breadcrumbs: false,
          isFullsize: true
        },
        children: [
          {
            path: 'groups/:groupID',
            name: this.routeNameGroup,
            component: () => import('./Backend_Module_File_Group_Single.vue'),
            meta: {
              label: 'File Widget',
              description: 'Manage your Widget',
              isFullsize: true
            },
            props: true
          },
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Module_File_Single.vue'),
            meta: {
              label: this.displayName,
              description: 'Manage your File',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          }
        ]
      }
    ]
  }
}
