<template>
  <section>
    <nav class="navbar" role="navigation" aria-label="main navigation" :class="'environment-'+env">
      <div class="navbar-brand">
        <router-link exact-active-class class="navbar-item logo" :to="{ name: 'backend' }">
          <img src="@/assets/echoprm-logo-line.svg" width="180" height="28" />
        </router-link>

        <a
          role="button"
          class="navbar-burger burger"
          :class="{ 'is-active': isMenuOpen }"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          @click="toggleMenu"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div id="navbarBasicExample" class="navbar-menu" :class="{ 'is-active': isMenuOpen }">
        <div class="navbar-start">
          <a
            v-if="$auth.isEchoPrmStaff"
            class="navbar-item"
            :class="{'is-active':$router.currentRoute.name =='landing'}"
            :href="link.home"
          >
            <b-tag type="is-info">Staff Only</b-tag>
          </a>
          <a
            v-if="$auth.isEchoPrmStaff"
            class="navbar-item"
            :class="{'is-active':$router.currentRoute.name =='landing'}"
            :href="link.home"
          >Home</a>
          <a
            v-if="$auth.isEchoPrmStaff"
            class="navbar-item"
            :class="{'is-active':$router.currentRoute.name =='backend'}"
            :href="link.backend"
          >Backend</a>
          <a
            v-if="$auth.isEchoPrmStaff"
            class="navbar-item"
            :class="{'is-active':$router.currentRoute.name =='admin'}"
            :href="link.admin"
          >Admin</a>
          <a
            v-if="$auth.isEchoPrmStaff"
            class="navbar-item"
            :class="{'is-active':$router.currentRoute.name =='app'}"
            :href="link.app+'/'"
          >App</a>

          <div v-if="isBackend" class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">
              Filters&nbsp;
              <span v-if="$localSettings.modules.filters.categories.length > 0">
                <b-tag
                  rounded
                  size="is-small"
                >{{ $localSettings.modules.filters.categories.length }}</b-tag>
              </span>
              <span v-else-if="($auth?.user?.visibleCategories || []).length > 0">
                <b-tag rounded size="is-small">({{ ($auth?.user?.visibleCategories || []).length }})</b-tag>
              </span>
            </a>

            <div class="navbar-dropdown">
              <!-- <a class="navbar-item">About</a>
              <a class="navbar-item">Jobs</a>-->
              <a class="navbar-item" @click.prevent="isFilterModalActive = true">Category Filter</a>
              <!-- <hr class="navbar-divider" /> -->
            </div>
          </div>

          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">More</a>

            <div class="navbar-dropdown">
              <!-- <a class="navbar-item">About</a>
              <a class="navbar-item">Jobs</a>-->
              <a
                class="navbar-item"
                :href="`mailto:info@echoprm.com?subject=Contact via Backend`"
              >Contact</a>
              <hr class="navbar-divider" />
              <a
                class="navbar-item"
                :href="`mailto:info@echoprm.com?subject=Issue found on page: ${$router.currentRoute.fullPath}`"
              >Report an issue</a>

              <hr class="navbar-divider" />
              <router-link
                class="navbar-item"
                exact-active-class
                :to="{ name: 'tos' }"
              >Terms of Service</router-link>
              <router-link
                class="navbar-item"
                exact-active-class
                :to="{ name: 'odp' }"
              >Order Data Processing</router-link>

              <a class="navbar-item" href="//echoprm.com/impressum">Imprint</a>
              <!-- <hr class="navbar-divider" /> -->
              <!-- <a
                class="navbar-item"
                :href="`mailto:info@echoprm.com?subject=Issue found on page: ${$router.currentRoute.fullPath}`"
              >Report an issue</a>-->
              <hr class="navbar-divider" />
              <small class="navbar-item">Version: {{ appVersion }}</small>
              <small class="navbar-item" :title="appBuildTime">Build: {{ appBuildHash }}</small>

              <hr class="navbar-divider" />
              <div
                class="navbar-item debug-info"
                @click="onCopyDebugInfoClicked"
              >Copy Debug Information</div>
            </div>
          </div>
        </div>

        <div class="navbar-end">
          <div class="navbar-item name-and-tenant">
            <div v-if="$auth.user" class="user-account media">
              <!-- <Avatar class="profile-picture media-left" :username="$auth.user.name" /> -->
              <router-link :to="{ name: 'user-single' }">
                <div class="settings">{{ $auth.user.email }}</div>
              </router-link>
              <router-link
                :to="{
                  name: ($auth.userPrivileges.includes($auth.privileges.MASTERDATA_MANAGE_VIEW))?
                    'config-masterdata':
                    'user-single'
                }"
              >
                <div class="tenant-name">({{ $auth.tenantName }})</div>
              </router-link>
            </div>
          </div>
          <div class="navbar-item">
            <div class="buttons">
              <!-- <b-button
                v-if="!$auth || !$auth.user"
                type="is-primary"
                @click="isRegistrationComponentModalActive = true"
              >Sign Up</b-button>-->
              <b-button
                v-if="$localSettingsGlobal.login.selectedTenantID"
                type="is-light"
                @click="onClickChangeTenant"
              >Change Tenant</b-button>
              <b-button
                v-if="!$auth || !$auth.user"
                type="is-light"
                @click="isLoginComponentModalActive = true"
              >Log In</b-button>
              <b-button v-else type="is-light" @click="$auth.logout()">Log Out</b-button>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <b-modal :active.sync="isLoginComponentModalActive" has-modal-card scroll="keep">
      <VFormLoginModalView />
    </b-modal>

    <VModalCategoryFilter v-if="isBackend" :active.sync="isFilterModalActive" />

    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import VFormLoginModalView from '@/components/VFormLoginModalView.vue'
import VFormRegisterView from '@/components/VFormRegisterView.vue'
import Avatar from 'vue-avatar'
import { copyDebugInformationToClipboard } from '@/helpers/debugInfoHelper'
import VModalCategoryFilter from '@/components/VModalCategoryFilter.vue'
import moment from 'dayjs'

@Component({
  components: {
    VFormLoginModalView,
    VFormRegisterView,
    Avatar,
    VModalCategoryFilter
  }
})
export default class TheMainMenu extends Vue {
  public isFilterModalActive = false
  public isLoading = false


  public isLoginComponentModalActive: boolean = false
  public isRegistrationComponentModalActive: boolean = false

  public link = {
    app: `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_APP_BASE_URL}`,
    backend: `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_BACKEND_BASE_URL}`,
    admin: `${process.env.VUE_APP_URL_PROTOCOL}${process.env.VUE_APP_ADMIN_BASE_URL}`
  }

  public env = process.env.VUE_APP_FIREBASE_ENV

  public appVersion = process.env.VUE_APP_VERSION || '0'
  public appBuildHash = (+(process.env.VUE_APP_BUILD_TIME || 0)).toString(36)
  public appBuildTime = moment(+(process.env.VUE_APP_BUILD_TIME || 0)).format('YYYY-MM-DD HH:mm:ss')

  get isBackend() {
    return process.env.VUE_APP_PAGE === 'backend'
  }

  public isMenuOpen = false

  public async onClickChangeTenant() {
    this.$localSettingsGlobal.login.selectedTenantID = ''
    // if not on the landing page, redirect to landing page
    if (this.$router.currentRoute.name !== 'start') {
      this.isLoading = true
      // toast the tenant change and redirect to landing page
      this.$buefy.toast.open({ message: 'Chaning Tenant...' })
      await this.$router.push({ name: 'start' })
    }
    this.$router.go(0)
  }

  public toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen
  }

  public onCopyDebugInfoClicked() {
    // add tenantid query parameter to the url
    const urlWithTenantID = new URL(window.location.href)
    urlWithTenantID.searchParams.set('tenant-id', this.$auth.tenantID)

    const successfull = copyDebugInformationToClipboard({
      tenant: this.$auth.tenantName + ' - ' + this.$auth.tenantID,
      tenantUrl: urlWithTenantID.href,
      user: this.$auth.userName + ' - ' + this.$auth.user?.email,
      appBuildTime: this.appVersion + ' - ' + this.appBuildTime,
      appBuildHash: this.appVersion + ' - ' + this.appBuildHash
    })

    if (successfull) {
      this.$helpers.notification.Info('Debug information copied to clipboard')
    } else {
      this.$helpers.notification.Error('Error occured while trying to copy debug information to clipboard')
    }
  }
}
</script>

<style lang="scss">
.navbar {
  border-bottom: 1px solid #f5f5f5;

  &.environment-stage {
    background: #ffdf42;
  }

  &.environment-emulator {
    background: #75bee9;
  }

  &.environment-dev {
    background: rgb(202 147 226);
  }

  .is-active {
    font-weight: bold;
  }

  .debug-info {
    cursor: pointer;
  }

  .navbar-item.logo img {
    max-height: 1.65rem;
    margin-left: 1em;
    margin-top: 0.2em;
  }

  .name-and-tenant {
    padding: 0.1em;

    .tenant-name {
      margin-left: 0.7em;
    }
  }
}
</style>
