/**
 * An asid follows this schema: [codeVersion][a-z0-9]{4}(-[a-z0-9]{5}){3}
 *
 * Subtypes exist per code version:
 * - e: echo PRM generated
 * - c: custom generated by tenant
 *      [c][<tenantNumberRange>]-[a-z0-9]{5}-[a-z0-9]{5}-[a-z0-9]{5}
 *
 * @param codeVersion dentotes the possible first char of the asid
 * @returns
 */
export const getRegex = (codeVersion = 'eac') => {
  // https://regexr.com/4jk1n
  return new RegExp(`(([${codeVersion}][a-z0-9]{4})(-[a-z0-9]{5}){3})`, 'gm')
}

export const DEFAULT_CODE_VERSION = 'e'
export const CUSTOM_CODE_VERSION = 'c'

export function createID(codeVersion = DEFAULT_CODE_VERSION) {
  function formatToID(formatString: string) {
    // const charList = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    // removed uppercase and chars that might be confused (l1i, o0). Removed aeiou for curse words
    // 28^19 = 3E27 possibilities
    const charList = 'bcdfghjkmnpqrstvwxyz23456789'
    const dt = Date.now()
    return formatString.replace(/[x]/g, (c) =>
      charList.charAt((dt + Math.random() * charList.length) % charList.length | 0)
    )
  }

  const uuid = codeVersion + formatToID('xxxx-xxxxx-xxxxx-xxxxx')
  return uuid
}

/**
 * if the first char is CUSTOM_CODE_VERSION, the next 4 chars denote the tenant number range
 * within this range the tenant is free to create unique asids
 *
 * @param asid
 * @returns
 */
export function getcustomNumberRange(asid: string) {
  return asid.substring(1, 5)
}
