<template>
  <section class="help-center">
    <section v-if="helpCategoriesFilterOptions.length > 1 || adminMode">
      <h2 class="title is-4">Help Resources</h2>

      <div class="columns">
        <div class="column">
          <section v-if="adminMode" class="admin-mode">
            <b-tag type="is-info">Staff Only</b-tag>

            <b-select v-model="sectionFilter" placeholder="Filter Section">
              <option v-for="rtype in sectionFilterOptions" :key="rtype" :value="rtype">{{ rtype }}</option>
            </b-select>
            <p class="control">
              <b-button type label="add element" @click="onAddElement()" />
            </p>
          </section>
          <div
            v-for="(ressource,i) in filteredHelpRessources"
            :id="$i18n.getLocalString(ressource.name, [selectedLocale])"
            :key="i"
            class="help-ressource-container"
            :class="ressource.ressourceType"
          >
            <h3 class="title is-5">{{ $i18n.getLocalString(ressource.name, [selectedLocale]) }}</h3>

            <section v-if="adminMode" class="admin-mode">
              <b-tag type="is-info">Staff Only</b-tag>

              <b-field>
                <b-input
                  v-model="filteredHelpRessources[i].order"
                  type="number"
                  max-value="100"
                  placeholder="order"
                />
                <b-input
                  v-model="filteredHelpRessources[i].name.locales[selectedLocale]"
                  type="text"
                  placeholder="name"
                />
                <b-select v-model="filteredHelpRessources[i].category" placeholder="Category">
                  <option v-for="hcat in helpCategories" :key="hcat" :value="hcat">{{ hcat }}</option>
                </b-select>
                <b-select
                  v-model="filteredHelpRessources[i].ressourceType"
                  placeholder="Ressource Type"
                >
                  <option v-for="rtype in ressourceTypes" :key="rtype" :value="rtype">{{ rtype }}</option>
                </b-select>
                <b-select v-model="filteredHelpRessources[i].section" placeholder="Section">
                  <option
                    v-for="rtype in sectionAvailableOptions"
                    :key="rtype"
                    :value="rtype"
                  >{{ rtype }}</option>
                </b-select>
                <b-input
                  v-model="filteredHelpRessources[i].url.locales[selectedLocale]"
                  type="text"
                  placeholder="url"
                />
                <p class="control">
                  <b-button type="is-success" label="Update" @click="onUpdateElement(i)" />
                </p>
                <p class="control">
                  <b-button type="is-danger" label="Delete" @click="onDeleteElement(i)" />
                </p>
              </b-field>
            </section>

            <section v-if="ressource.ressourceType === 'video'">
              <div class="videoWrapper">
                <iframe
                  :src="ressource.url.locales[selectedLocale]"
                  :title="ressource.name.locales[selectedLocale]"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </div>
            </section>

            <section v-if="ressource.ressourceType === 'pdf'">
              <VPdfViewer style="height: 90vh;" :url="ressource.url.locales[selectedLocale]" />
            </section>

            <section v-if="ressource.ressourceType === 'faq'">
              <div v-for="(qa, j) in ressource.faqData" :key="j">
                <h5 class="title is-6">{{ qa.question }}</h5>
                <!-- eslint-disable-next-line vue/no-v-html -->
                <p v-html="qa.answer" />
              </div>
            </section>
            <hr />
          </div>
        </div>
        <div class="column help-menu">
          <nav class="panel">
            <p class="panel-heading">Learning Ressources</p>
            <div class="panel-block">
              <b-field label="Language" horizontal>
                <VInputLocalizedFieldLocaleselectorView
                  v-model="selectedLocale"
                  :available-locales="['de','en']"
                  class="pull-right"
                />
              </b-field>
            </div>
            <div class="panel-block">
              <b-field style="width: 100%;">
                <b-input
                  v-model="filterText"
                  expanded
                  placeholder="search"
                  type="text"
                  icon="search"
                  icon-right="times"
                  icon-right-clickable
                  @icon-right-click="filterText = ''"
                />
              </b-field>
            </div>
            <p class="panel-tabs">
              <a
                v-for="(hcat,i) in helpCategoriesFilterOptions"
                :key="i"
                :class="{'is-active': helpCategoryFilter === hcat}"
                @click="helpCategoryFilter = hcat"
              >{{ hcat }}</a>
            </p>

            <div class="item-container">
              <a
                v-for="(d,i) in filteredHelpRessources"
                :key="i"
                :href="`#${ $i18n.getLocalString(d.name, [selectedLocale]) }`"
                class="panel-block"
              >
                <span class="level">
                  <span class="level-left level-item">
                    <span class="panel-icon">
                      <ModuleFileFileIconComponent
                        :ressource-type="d.ressourceType"
                        class="file-icon"
                      />
                    </span>
                    {{ $i18n.getLocalString(d.name, [selectedLocale]) }}
                  </span>
                  <span class="level-right level-item">
                    <a
                      v-if="d.ressourceType === 'pdf'"
                      :href="d.url.locales[selectedLocale]"
                    >download</a>
                  </span>
                </span>
              </a>
            </div>

            <!-- <label class="panel-block">
            <input type="checkbox" />
            remember me
            </label>-->
            <div v-if="!$route.name.includes('helpcenter')" class="panel-block">
              <b-button
                class="button is-link is-outlined is-fullwidth"
                @click="$parent && $parent.close && $parent.close(); $router.push({path:'/helpcenter'})"
              >Open Help Center</b-button>
            </div>
          </nav>
        </div>
      </div>
      <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
    </section>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import { faSearch, faBook, faTimes } from '@fortawesome/free-solid-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import VInputLocalizedFieldLocaleselectorView from '@/components/VInputLocalizedFieldLocaleselectorView.vue'
import ModuleFileFileIconComponent from '@/modules/file/Module_File_FileIcon_Component.vue'
import VPdfViewer from '../VPdfViewer.vue'
import { HelpRessouceDB } from '@/types/typeHelpRessource'
import { defaultRecordMeta } from '@/database/databaseSchema'
import { distinctObjects } from '@/database/dbHelper'
import HelpRessourceManager from '@/database/helpRessourceManager'
import { locale } from '@/types/typeI18n'
import { HELP_RESSOURCE_SECTIONS } from '@/businessLogic/constants'
import { hasDBid } from '@/types/typeGeneral'

library.add(faSearch, faBook, faTimes)

@Component({
  components: {
    ModuleFileFileIconComponent,
    VPdfViewer,
    VInputLocalizedFieldLocaleselectorView
  }
})
export default class VHelpCenterView extends Vue {
  @Prop({ type: String, required: false, default: () => 'All' })
  readonly section!: string

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly adminMode!: boolean

  public isLoading = false

  public selectedLocale: locale = 'en'

  public ressourceTypes = ['video', 'pdf', 'faq']
  public sectionAvailableOptions = HELP_RESSOURCE_SECTIONS
  public helpCategories = ['Tutorials', 'Manuals', 'FAQs']
  // public helpCategoriesFilterOptions = ['All', ...this.helpCategories]
  public helpCategoryFilter = 'All'
  public sectionFilter = 'All'
  public filterText = ''

  get helpCategoriesFilterOptions() {
    return ['All', ...distinctObjects(this.helpRessources.filter((r) =>
      this.section === 'All'
      || r.section === this.section
    ).filter((r) =>
      this.sectionFilter === 'All'
      || r.section === this.sectionFilter
    ).map((r) => r.category), (s) => s)]
  }

  get sectionFilterOptions() {
    return ['All', ...distinctObjects(this.helpRessources.map((r) => r.section), (s) => s)]
  }

  private helpRessources: (HelpRessouceDB & hasDBid)[] = [
    {
      id: '',
      category: 'Tutorials',
      order: 3,
      section: 'html',
      ressourceType: 'video',
      name: { locales: { en: 'help video' }, _ltType: true },
      url: { locales: { en: 'https://www.youtube-nocookie.com/embed/YjZ0uou_5-Y' }, _ltType: true },
      faqData: [

      ],
      _meta: defaultRecordMeta
    },
    // {
    //   category: 'FAQs',
    //   order: 2,
    //   section: 'html',
    //   ressourceType: 'faq',
    //   name: { locales: { en: 'FAQ' }, _ltType: true },
    //   { locales: { en:  url: '' }, _ltType: true },
    //   faqData: [
    //     {
    //       question: 'How to add a category',
    //       answer: 'do xyz <b>bold</b>'
    //     }, {
    //       question: 'How to add a category',
    //       answer: 'Excepturi omnis molestias tempore commodi ex id illum, animi repellat iste, repellendus recusandae inventore voluptatem porro libero iusto provident? Soluta autem in minima adipisci praesentium sapiente tenetur, cumque aspernatur iusto debitis commodi, hic perspiciatis voluptate laboriosam rerum quas. Quos iure doloribus sit nam iusto culpa minima officiis? Optio harum fugit incidunt ipsa architecto nobis ex blanditiis necessitatibus quod facere tenetur perspiciatis debitis sint neque sed, quos asperiores hic quidem nemo repellat numquam animi molestias deserunt nihil? Voluptas eligendi quis rem quibusdam ratione numquam, porro animi voluptate doloremque consectetur, doloribus, cumque in ab maxime ullam labore molestiae? Iure vitae sequi error.'
    //     }
    //   ],
    //   _meta: defaultRecordMeta
    // },
    {
      id: '',
      category: 'Manuals',
      order: 1,
      section: 'html',
      ressourceType: 'pdf',
      name: { locales: { en: 'manual.pdf' }, _ltType: true },
      url: { locales: { en: '/testFiles/compressed.tracemonkey-pldi-09.pdf' }, _ltType: true },
      faqData: [

      ],
      _meta: defaultRecordMeta
    }
  ]

  public async onUpdateElement(id: number) {
    // this.filteredHelpRessources[id];
    // await this.$firestoreBind('groups', FileModule.getGroupsDbReference(this.$auth.tenant.id))
    await HelpRessourceManager.update(this.filteredHelpRessources[id].id, this.$auth.userEmail, this.filteredHelpRessources[id])
      .catch((e) => this.$helpers.notification.Error(e.toString()))
      .then(() => this.$helpers.notification.Success('element updated'))
  }

  public async onDeleteElement(id: number) {
    // this.filteredHelpRessources[id];
    // await this.$firestoreBind('groups', FileModule.getGroupsDbReference(this.$auth.tenant.id))
    await HelpRessourceManager.delete(this.filteredHelpRessources[id].id)
      .catch((e) => this.$helpers.notification.Error(e.toString()))
      .then(() => this.$helpers.notification.Success('element updated'))
  }

  public async onAddElement() {
    await HelpRessourceManager.add(this.$auth.userEmail)
      .catch((e) => this.$helpers.notification.Error(e.toString()))
      .then(() => this.$helpers.notification.Success('element added'))
  }


  public async mounted() {
    this.isLoading = true
    await this.$firestoreBind('helpRessources', HelpRessourceManager.getDbCollectionReference())
    this.isLoading = false
  }

  public get filteredHelpRessources() {
    return this.helpRessources.filter((r) =>
      this.filterText === ''
      || this.$i18n.getLocalString(r.name, [this.selectedLocale]).toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase())
      || r.category.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase())
      || r.ressourceType.toLocaleLowerCase().includes(this.filterText.toLocaleLowerCase())
    ).filter((r) =>
      this.helpCategoryFilter === 'All'
      || r.category === this.helpCategoryFilter
    ).filter((r) =>
      this.section === 'All'
      || r.section === this.section
    ).filter((r) =>
      this.sectionFilter === 'All'
      || r.section === this.sectionFilter
    ).filter((r) =>
      this.adminMode
      || this.$i18n.getLocalString(r.name, [this.selectedLocale], 'none') !== 'not localized'
    ).sort((a, b) => a.order - b.order)
  }
}
</script>

<style lang="scss">
.help-center {
  .column {
    // background: green;
    // border: 1px solid red;
    .admin-mode {
      margin-bottom: 1em;
    }

    .videoWrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }

    .videoWrapper iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &.help-menu {
      max-width: 20em;

      span.level-item {
        flex: auto;
      }
    }

    .faq h5.title.is-6 {
      margin-top: 1.5em;
      margin-bottom: 0.5em;
    }

    .panel {
      position: sticky;
      top: 1em;

      .item-container {
        max-height: 60vh;
        overflow-y: auto;
      }

      .level {
        width: 100%;
      }
    }
  }
}
</style>
