import Vue from 'vue'

import BaseGlobals from './baseGlobals'

import { VueConstructor } from 'vue/types/umd'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { BackendConfigDB } from '@/types/typeBackendConfig'

import BackendConfigManager from '@/database/backendConfigManager'
import { UserPrivilegeIdDB } from '@/types/typeUser'
import { intersectSome } from '@/helpers/arrayHelper'
import { objectID } from '@/types/typeGeneral'
import notificationHelper from '@/helpers/notificationHelper'

const ENABLE_LOG = false

class BackendConfigGlobals extends BaseGlobals {
  public obersvableData: BackendConfigDB = Vue.observable(BackendConfigManager.defaultDocDB)

  constructor() {
    super()
  }

  // init is only called in backend
  init(tenantID: objectID, userPrivileges: UserPrivilegeIdDB[]): Promise<SnapshotUnbindHandle> {
    if (!intersectSome([...BackendConfigManager.requiredPrivileges.r], userPrivileges)) {
      return new Promise((resolve) =>
        resolve(() => {
          /** */
        })
      )
    } else {
      return new Promise((resolve, reject) => {
        const unbindSnapshot = BackendConfigManager.onSnapshot(
          tenantID,
          (d) => {
            // this.obersvableData = d
            Object.keys(d).forEach((key) => {
              if (ENABLE_LOG) console.log(d)

              Vue.set(this.obersvableData, key, d[key as keyof BackendConfigDB])
            })
            Vue.set(this.obersvableData.asid, 'identifierDefinition', d.asid.identifierDefinition)

            resolve(unbindSnapshot)
          },
          (e) => {
            reject(e)
            notificationHelper.Error(`Error while loading backend config: ${e}`)
          }
        )
      })
    }
  }

  public get identifierDefinitions() {
    return this.obersvableData.asid.identifierDefinition
  }

  // public set locales(locales: locale[]) {
  //   Vue.set(this.obersvableData, 'locales', locales)
  // }
}

export const BackendConfigGlobalsInst = new BackendConfigGlobals()

declare module 'vue/types/vue' {
  interface Vue {
    $backendConfig: BackendConfigDB
  }
}

export default {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$backendConfig = BackendConfigGlobalsInst.obersvableData
    // Vue.prototype.$identifierDefinitions = BackendConfigGlobalsInst.identifierDefinitions
  }
}
