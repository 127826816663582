<template>
  <b-field>
    <b-upload
      v-model="formFile"
      :accept="supportedFiletypes.join(', ')"
      :native="true"
      @input="onFileChange"
    >
      <slot :loading="isLoading">
        <a class="button" :class="{'is-loading':isLoading}">
          <b-icon icon="upload" />
          <span>{{ (fileUrl !== '')? 'Change File' : 'Add File' }}</span>
        </a>
      </slot>
    </b-upload>
    <p v-if="fileUrl !== ''" class="control">
      <b-button outlined icon-right="trash" @click="$emit('url', '')" />
    </p>
  </b-field>
</template>

<script lang="ts">
import { Component, Vue, Model, Prop, PropSync } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUpload } from '@fortawesome/free-solid-svg-icons'


import VEchoCode from '@/components/VEchoCode.vue'


import StorageManager from '@/helpers/StorageManager'

library.add(faUpload)


@Component({
  components: {
    VEchoCode
  }
})
export default class VFormUploadFileView extends Vue {
  public isImageUploadModalActive = false
  public formFile: any = null

  @Model('url', { type: String, required: true, default: () => '' })
  public fileUrl!: string

  @Prop({ type: String, required: true, default: () => '' })
  readonly uploadPath!: string

  @Prop({ type: String, required: true, default: () => '' })
  readonly uploaderDocumentPath!: string

  @Prop({ type: Array, required: false, default: () => [] })
  readonly supportedFiletypes!: string[]

  @Prop({ type: Number, required: false, default: () => 1024 * 1024 })
  readonly maxFileSize!: number

  @PropSync('loading', { type: Boolean, required: false, default: () => false })
  public isLoading!: boolean

  @Prop({ type: Function, required: false, default: () => () => true })
  readonly fileValidator!: (file: File) => boolean

  public async onFileChange(file: File) {
    this.isLoading = true
    try {
      if (!this.fileValidator(file))
        throw 'file not valid'

      const downloadUrl = await StorageManager.uploadFile(
        this.$auth.userEmail,
        this.formFile,
        this.formFile.name,
        this.uploadPath,
        (progress) => {
          //
        },
        this.maxFileSize,
        this.supportedFiletypes,
        {},
        this.uploaderDocumentPath
      )

      this.$emit('url', downloadUrl)
    } catch (error: any) {
      this.$helpers.notification.Error(`upload error: ${error}`)
    } finally {
      this.isLoading = false
      this.formFile = null // reset file to be able to select new one
    }
  }
}
</script>

<style lang="scss">
</style>
