export const ALL_FILETYPES = ['image', 'pdf', 'link', 'video', 'other', 'archive', 'dir', 'ziphtml'] as const

export type fileType = typeof ALL_FILETYPES[number]

/**
 * Get the file type from a file name
 * @param fileName
 * @returns
 */
export const getRessourceTypeFromFileName = (fileName: string) => {
  const extension = fileName.split('.').pop()?.toLowerCase() || ''
  return getRessourceTypeFromFileExtension(extension)
}

/**
 * Get the file type from a file extension
 * @param fileName
 * @returns
 */
export const getRessourceTypeFromFileExtension = (extension: string): fileType => {
  switch (extension) {
    case 'gif':
    case 'png':
    case 'jpeg':
    case 'jpg':
      return 'image'

    case 'pdf':
      return 'pdf'

    case 'mp4':
    case 'wav':
    case 'mov':
      return 'video'

    case 'zip':
    case '7z':
    case 'tar':
      return 'archive'

    case 'dir':
      return 'dir'

    case 'ziphtml':
      return 'ziphtml'

    default:
      return 'other'
  }
}

/**
 * Get the icon for a file type
 * @param ressourceType
 * @returns
 */
export const getIconForFileType = (ressourceType: fileType) => {
  return ressourceType === 'pdf'
    ? 'file-pdf'
    : ressourceType === 'image'
      ? 'file-image'
      : ressourceType === 'link'
        ? 'link'
        : ressourceType === 'video'
          ? 'film'
          : ressourceType === 'other'
            ? 'file'
            : ressourceType === 'archive'
              ? 'file-archive'
              : ressourceType === 'dir'
                ? 'folder'
                : 'border-style'
}
