<template functional>
  <span class="notification-container">
    <!-- display tag with exclamation-triangle icon warnings if count > 0 -->
    <b-tag
      v-if="props.menuNotifications.warning.count > 0"
      type="is-warning"
      size="is-small"
      class="notification-tag"
      icon="exclamation-triangle"
      :title="props.menuNotifications.warning.text"
    >{{ props.menuNotifications.warning.count }}</b-tag>

    <!-- display tag with exclamation-triangle icon errors if count > 0 -->
    <b-tag
      v-if="props.menuNotifications.error.count > 0"
      type="is-danger"
      size="is-small"
      class="notification-tag"
      icon="exclamation-triangle"
      :title="props.menuNotifications.error.text"
    >{{ props.menuNotifications.error.count }}</b-tag>

    <!-- display tag with exclamation-triangle icon info if count > 0 -->
    <b-tag
      v-if="props.menuNotifications.info.count > 0"
      type="is-info"
      size="is-small"
      class="notification-tag"
      icon="exclamation-triangle"
      :title="props.menuNotifications.info.text"
    >{{ props.menuNotifications.info.count }}</b-tag>
  </span>
</template>

<script lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

library.add(faExclamationTriangle)

interface MenuNotification {
  count: number
  text: string
}

export interface MenuNotifications {
  info: MenuNotification
  warning: MenuNotification
  error: MenuNotification
}


@Component
export default class VNotificationCounts extends Vue {
  @Prop({ type: Object, required: true })
  public menuNotifications!: MenuNotifications
}

</script>

<style lang="scss">
.notification-container > span {
  margin: 0 0.15rem;
}
</style>
