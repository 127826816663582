<template>
  <div class="login-modal" @submit.prevent="onLogin()">
    <div class="modal-card" style="width: auto;">
      <header class="modal-card-head">
        <p class="modal-card-title">Login</p>
      </header>
      <section class="modal-card-body">
        <VFormLoginView ref="formLogin" :is-hide-header="true" :is-hide-login-button="true" />
      </section>

      <footer class="modal-card-foot">
        <b-button class="button is-pulled-right" type="is-text" @click="onClose">Close</b-button>
        <b-button
          class="button is-primary is-pulled-right"
          :loading="isLoading"
          native-type="submit"
          @click="onLogin"
        >Login</b-button>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import VFormLoginView from './VFormLoginView.vue'

@Component({
  components: {
    VFormLoginView
  }
})
export default class VFormLogin extends Vue {
  public formEmail = ''
  public formPassword = ''
  public formRememberMe = false

  public isLoading = false

  public onClose() {
    (this.$parent as any).close()
  }

  public async onLogin() {
    const formRef = this.$refs.formLogin as VFormLoginView

    try {
      this.isLoading = true
      await formRef.onLogin()
      this.onClose()
    } finally {
      this.isLoading = false
    }
  }
}
</script>
