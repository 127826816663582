<template>
  <section class="manage-widgets-wrapper">
    <div class="level">
      <b-field class="level-left" />
      <b-field grouped>
        <b-field>
          <VRecordMeta position="is-bottom-left" :required-privileges="documentPrivileges" />
        </b-field>
      </b-field>
    </div>

    <b-message
      v-if="$localSettings.modules.filters.categories.length > 0"
      type="is-warning"
      size="is-small"
    >A global category filter is set. Ordering with an active filter may lead to unexpected ordering.</b-message>

    <!-- b-tabs for switching pages -->
    <b-tabs v-model="tabIndex" type="is-boxed" position="is-centered" :destroy-on-hide="false">
      <b-tab-item
        v-for="(page, index) in pagesSortedByName"
        :key="page.name"
        header-class="tab-label"
      >
        <template #header>
          <span
            :class="{ 'is-dragging': isDragging && tabIndex !== index}"
            @dragover.prevent="tabIndex = index"
          >{{ page.name }}</span>
          <b-button
            v-if="index > 0"
            class="edit-page-button"
            size="is-small"
            icon-left="pen"
            expanded
            @click="()=>onEditPage(page.id)"
          />
        </template>

        <div class="center-main-container">
          <b-button icon-left="plus" expanded @click="()=>onAddWidget(-1)">Add Widget</b-button>

          <Draggable
            :list="getWidgetsForPage(page.id)"
            ghost-class="dragging-ghost"
            handle=".handle"
            class="app-layout-container"
            group="widgets"
            :animation="300"
            @start="onSortStart"
            @end="onSortEnd"
          >
            <div
              v-for="(group, groupIndex) in getWidgetsForPage(page.id)"
              :key="groupIndex"
              :index="groupIndex"
              class="sort-element widget"
              :class="`group-display-type__${group.public.display.displayType}`"
              :style="getBackgroundStyle(group)"
            >
              <div class="sort-element-inner widget">
                <router-link
                  :to="{name: getModuleRoute(group.type), params: { groupID: group.id } }"
                  :style="getTileColor(group.type)"
                >
                  <div class="level">
                    <div class="level-left">
                      <b-icon v-handle icon="grip-vertical" class="handle" />
                      <div class="info">
                        <span class="internal-name">{{ group.name }}</span>
                        <!-- "{{ group.public.order }}" -->
                        <br />
                        <router-link
                          :to="{name: getModuleRoute(group.type), params: { groupID: group.id }, hash: '#design'}"
                          :style="getTileColor(group.type)"
                        >
                          <span class="display-type">display type: {{ getDisplayTypeName(group) }}</span>
                        </router-link>
                      </div>
                    </div>
                    <div class="level-right">{{ getDisplayName(group.type) }}</div>
                  </div>
                </router-link>
              </div>
            </div>
          </Draggable>

          <b-button
            icon-left="plus"
            expanded
            @click="()=>onAddWidget(getWidgetsForPage(page.id).length - 1)"
          >Add Widget</b-button>
        </div>
      </b-tab-item>

      <!-- item to add aditional page -->
      <b-tab-item :disabled="pages.length > 20">
        <template #header>
          <b-button
            class="add-page-button"
            type="is-text"
            icon-left="plus"
            expanded
            @click.stop="()=>onAddPage()"
          >Add page</b-button>
        </template>
      </b-tab-item>
    </b-tabs>
    <!-- <SlickList
      v-model="groups"
      :use-drag-handle="true"
      class="widget-sort-container"
      axis="xy"
      helper-class="dragging"
      @sort-end="onSortEnd"
    >
      <div v-if="groups.length > 0" class="add-element">
        <b-button size="is-small" @click="()=>onAddWidget(-1)">Add Widget</b-button>
        <span class="line" />
      </div>
      <div v-else class="buttons">
        <b-button expanded class="is-fullwidth" @click="()=>onAddWidget(-1)">Add Widget</b-button>
      </div>

      <SlickItem
        v-for="(group, index) in groups"
        :key="index"
        :index="index"
        class="sort-element widget"
        :style="getBackgroundStyle(group)"
      >
        <div>{{ index }}</div>
        <div class="sort-element-inner widget">
          <router-link
            :to="{name: getModuleRoute(group.type), params: { groupID: group.id } }"
            :style="getTileColor(group.type)"
          >
            <div class="level">
              <div class="level-left">
                <b-icon v-handle icon="grip-vertical" class="handle" />
                {{ group.name }}
              </div>
              <div class="level-right">{{ getDisplayName(group.type) }}</div>
            </div>
          </router-link>
        </div>
        <div class="add-element">
          <b-button size="is-small" @click="()=>onAddWidget(index)">Add Widget</b-button>
          <span class="line" />
        </div>
        <router-link :to="{name: mod.getRoutes()[0].name}">
        <h1 class="title is-5">#{{ index }} {{ mod.type }} : {{ mod.name }} {{ mod.public.order }}</h1>
        </router-link>
      </SlickItem>
    </SlickList>-->
    <br />
    <br />

    <VCrudControl hide-remove hide-cancel is-autosave />

    <b-modal
      :active.sync="newWidgetModal"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      scroll="keep"
    >
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">New Widget</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Type">
            <b-select v-model="selectedWidgetType" placeholder="Select a name" expanded>
              <option
                v-for="option in modules"
                :key="option.type"
                :value="option.type"
              >{{ option.displayName }}</option>
            </b-select>
          </b-field>
          <b-field label="Name">
            <b-input v-model="newWidgetName" />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <div class="is-right">
            <button class="button" type="button" @click="newWidgetModal = false">Cancel</button>
            <button class="button is-success" @click="onCreateWidget()">Create</button>
          </div>
        </footer>
      </div>
    </b-modal>

    <b-modal
      :active="pageModal === 'new' || pageModal === 'edit'"
      has-modal-card
      trap-focus
      aria-role="dialog"
      aria-modal
      scroll="keep"
      @abort="pageModal = 'none'"
    >
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{
              pageModal === 'new' ? 'New Page' : 'Edit Page Name'
            }}
          </p>
        </header>
        <section class="modal-card-body">
          <b-field
            :message="pageNameErrorMessages"
            :type="pageNameErrorMessages ? 'is-danger' : ''"
          >
            <template slot="label">
              Page
              <VTooltipIconHelp
                text="Internal name of the page. This name is only visible in the backend."
              />
            </template>
            <b-input v-model="pageName" />
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <div class="is-right">
            <button class="button" type="button" @click="pageModal = 'none'">Cancel</button>
            <!-- delete button -->
            <button
              v-if="pageModal === 'edit'"
              class="button is-danger"
              @click="()=>onDeletePage()"
            >Delete</button>
            <button class="button is-success" @click="onUpdateCreatePage()">
              {{
                pageModal === 'new' ? 'Create' : 'Save'
              }}
            </button>
          </div>
        </footer>
      </div>
    </b-modal>

    <b-loading :is-full-page="false" :active="isLoading > 0" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGripVertical, faPen, faPlus } from '@fortawesome/free-solid-svg-icons'

library.add(faGripVertical, faPlus, faPen)


import { ModuleManager } from '@/modules/moduleManager'
import color from 'color'
import BaseModule from '@/modules/baseModule'
import Draggable from 'vuedraggable'
import { BaseGroupDB, ElementWithTypeAndID, hasModuleType, ModuleType } from '../../modules/typeModules'
import { SortingOrderHelper } from '@/helpers/sortingOrderHelper'
import { RequiredDocPrivileges } from '@/types/typeRequiredPrivileges'
import VRecordMeta from '@/components/VRecordMeta.vue'
import { hasDBid } from '@/types/typeGeneral'
import { AppPreview } from '@/components/VBackendAppPreview.vue'
import { moduleOrder } from '@/shared/general'
import { PageConfig } from '@/types/typeAppConfig'
import TenantManager from '@/database/tenantManager'
import { cloneObject } from '@/helpers/dataShapeUtil'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { uniqueID } from '@/database/dbHelper'


@Component({
  components: {
    SlickList,
    SlickItem,
    VRecordMeta,
    Draggable
  },
  directives: {
    handle: HandleDirective
  }
})
export default class BackendWidgetsList extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public groups: Array<BaseGroupDB & hasModuleType & hasDBid> = []

  public modules: typeof ModuleManager.moduleClasses = []

  public isLoading = 0

  private newWidgetIndex = 0
  public newWidgetModal = false
  public newWidgetName = ''
  public selectedWidgetType = '' as ModuleType
  private moduleElementsMatchingFilter: ElementWithTypeAndID[] = []
  public filteredGroups: (BaseGroupDB & hasModuleType & hasDBid)[] = []

  private appConfig = { ...TenantManager.defaultAppConfigDB }

  public isDragging = false

  // #region pages

  public pageModal: 'new' | 'edit' | 'none' = 'none'
  public pageName = ''
  public pageId = ''
  public tabIndex = 0
  public pageNameErrorMessages: string = ''

  private MAIN_PAGE_CONFIG: PageConfig = {
    id: '',
    name: 'Main Page'
  }

  public get pages(): PageConfig[] {
    return this.appConfig.public.pages || []
  }

  get pagesSortedByName() {
    const userPages = [...this.pages].sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' }))
    return [this.MAIN_PAGE_CONFIG, ...userPages]
  }

  public onAddPage() {
    // if the link module is not active, show a dialog that the link module is required
    if (!this.modules.some((m) => m.type === 'Link')) {
      this.$buefy.dialog.confirm({
        title: 'Page Functionality not Enabled',
        message: 'The "Page" functionality allows the creation of multiple pages besides the "Main Page". To activate it, please contact your ECHO PRM sales representative for more information.',
        type: 'is-info',
        hasIcon: true,
        canCancel: true,
        confirmText: 'Contact ECHO PRM',
        onConfirm: () => {
          window.location.href = 'mailto:info@echoprm.com?subject=Activate Page Functionality'
        }
      })
      return
    }
    this.pageModal = 'new'
    this.pageName = `Page #${this.pages.length + 1}`
    this.pageNameErrorMessages = ''
  }

  public onEditPage(pageId: string) {
    this.pageModal = 'edit'
    this.pageId = pageId
    this.pageName = this.pagesSortedByName.find((p) => p.id === pageId)?.name || ''
    this.pageNameErrorMessages = ''
  }

  public async onUpdateCreatePage() {
    this.isLoading += 1

    // make sure the page name is not empty and unique
    if (this.pageName.trim() === '') {
      this.pageNameErrorMessages = 'Page name cannot be empty'
      this.isLoading -= 1
      return
    }

    if (this.pagesSortedByName.some((p) => p.name === this.pageName)) {
      this.pageNameErrorMessages = 'Page name must be unique'
      this.isLoading -= 1
      return
    }

    const pagesClone = cloneObject(this.pages)

    if (this.pageModal === 'new') {
      pagesClone.push({ name: this.pageName, id: uniqueID() })
    } else {
      const unsortedPagesIndex = pagesClone.findIndex((p) => p.id === this.pageId)
      pagesClone[unsortedPagesIndex].name = this.pageName
    }

    try {
      await TenantManager.updateAppConfig(this.$auth.tenant.id, this.$auth.userEmail, { public: { pages: pagesClone } })

      // hide modal
      this.pageModal = 'none'

      // wait 5 secs to be sure the preview also updates its appDataDB
      await new Promise((resolve) => setTimeout(resolve, 2500))

      // toast for sucess
      this.$helpers.notification.SuccessToast('Page updated')

      // as a workaround set the index to 0 and then to the actual index, as if the datat changes, but the index keeps the same, the component has some update issues
      this.tabIndex = 0

      // wait for next tick, so the new tab can be addded
      await this.$nextTick()

      // set the tabindex to the new or edited page based on the index obtained from pagesSortedByName
      this.tabIndex = this.pagesSortedByName.findIndex((p) => p.name === this.pageName)
      this.pageName = ''

      console.log('tabIndex', this.tabIndex)
      console.log('pagesSortedByName', this.pagesSortedByName.length)
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading -= 1
    }
  }

  public async onDeletePage() {
    this.isLoading += 1

    // a page can only be deleted if there are no widgets on it
    if (this.filteredGroupsOnCurrentPage.length > 0) {
      this.$helpers.notification.Error(`Page cannot be deleted, as it still contains widgets. Remove all widgets from page ${this.pageName} before deleting it.`)
      this.isLoading -= 1
      return
    }

    const pagesClone = cloneObject(this.pages)
    pagesClone.splice(pagesClone.findIndex((p) => p.id === this.pageId), 1)

    try {
      await TenantManager.updateAppConfig(this.$auth.tenant.id, this.$auth.userEmail, { public: { pages: pagesClone } })

      // toast for sucess
      this.$helpers.notification.SuccessToast('Page deleted')

      this.pageModal = 'none'
      this.pageName = ''
      this.tabIndex = 0
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading -= 1
    }
  }

  @Watch('tabIndex')
  public onTabChange() {
    // if the tabindex is higher than the number of pages, the add page button was clicked
    if (this.tabIndex >= this.pages.length + 1) {
      this.onAddPage()
    }
  }

  // #endregion pages

  // #region RecordMeta
  get documentPrivileges(): RequiredDocPrivileges {
    return { r: this.modules.flatMap((m) => m.authPrivileges.r), w: this.modules.flatMap((m) => m.authPrivileges.w) }
  }
  // #endregion RecordMeta

  public async onCreateWidget() {
    this.newWidgetModal = false
    this.isLoading += 1

    const orderIndex
      = (this.newWidgetIndex < 0)
        ? (this.filteredGroupsOnCurrentPage.length === 0)
          ? 0 // first widget
          : this.filteredGroupsOnCurrentPage[0].public.order - 100 // add in front
        : (this.newWidgetIndex === this.filteredGroupsOnCurrentPage.length - 1)
          ? this.filteredGroupsOnCurrentPage[this.filteredGroupsOnCurrentPage.length - 1].public.order + 100 // add last
          : (this.filteredGroupsOnCurrentPage[this.newWidgetIndex].public.order + this.filteredGroupsOnCurrentPage[this.newWidgetIndex + 1].public.order) / 2 // add inbetween


    await ModuleManager.getModuleClassByType(this.selectedWidgetType)
      .addGroup(this.$auth.tenant.id,
        this.$auth.userEmail,
        {
          name: this.newWidgetName,
          public: { order: orderIndex, pageID: this.currentPageID }
        }
      )

    this.newWidgetName = ''
    this.isLoading -= 1
  }

  public onAddWidget(index: number) {
    this.newWidgetModal = true
    this.newWidgetIndex = index
    this.newWidgetName = `Widget #${index}`
  }

  private sortStartPageID = ''

  public async onSortStart() {
    this.sortStartPageID = this.currentPageID
    this.isDragging = true
  }

  public getWidgetsForPage(pageID: string) {
    return this.filteredGroups.filter((g) => g.public.pageID === pageID)
  }

  public async onSortEnd({ event, newIndex, oldIndex }: { event: any, newIndex: number, oldIndex: number }) {
    this.isDragging = false

    let tempOldIndex = oldIndex
    const addedToOtherPage = this.sortStartPageID !== this.currentPageID

    // widget was moved to other page
    if (addedToOtherPage) {
      // update the pageID of the moved widget
      const startPageWidget = this.getWidgetsForPage(this.sortStartPageID)[oldIndex]
      const widget = this.groups.find((g) => g.id === startPageWidget.id && g.type === startPageWidget.type)
      if (!widget) {
        this.$helpers.notification.Error(`Widget with ID ${startPageWidget.id} and name ${startPageWidget.name} not found`)
        return
      } else {
        widget.public.pageID = this.currentPageID
        // temporary add to the index 0 of the new page, it will then later be sorted to the correct position
        widget.public.order = this.filteredGroupsOnCurrentPage[0].public.order - 100
        tempOldIndex = 0
      }
    } else if (newIndex === oldIndex) return

    // update filteredGroups
    this.onGroupsChange()

    this.isLoading += 1
    SortingOrderHelper.manageOrder(
      this.filteredGroupsOnCurrentPage,
      tempOldIndex,
      newIndex,
      async (el, order) => {
        try {
          await ModuleManager.getModuleClassByType(el.type)
            .updateGroup(this.$auth.tenant.id, this.$auth.userEmail, el.id, { public: { order: order, pageID: el.public.pageID } })
          return this.$helpers.notification.Success('Order updated ' + el.type)
        } catch (e) {
          return this.$helpers.notification.Error('Error updating Order ' + el.type + ' ' + String(e))
        }
      },
      (el) => el.public.order,
      (el, order) => el.public.order = order,
      false)
      .then(() => this.$helpers.notification.Success('Order updated'))
      .catch((e) => this.$helpers.notification.Error('Error updating Order ' + e.toString()))
      .finally(() => this.isLoading -= 1)
  }

  public getModuleRoute(modType: string) {
    const module = this.modules.find((m) => m.type === modType)
    return module?.routeNameGroup || ''
  }

  public getDisplayTypeName(group: BaseGroupDB) {
    return BaseModule.viewOptions.find((el) => el[0] === group.public.display.displayType)?.[1] || ''
  }

  public getDisplayName(modType: string) {
    const module = this.modules.find((m) => m.type === modType) || BaseModule
    return module.displayName
  }

  public getBackgroundStyle(group: BaseGroupDB & hasModuleType) {
    const module = this.modules.find((m) => m.type === group.type) || BaseModule
    return {
      backgroundImage: `linear-gradient( 155deg, ${module.color} 10%, ${color(module.color).rotate(-15).lighten(0.1)} 100%)`,
      color: (color(module.color).isDark()) ? '#fff' : '#000',
      width: group.public.display.displayType === 'tile-half' ? 'calc(50% - 0.8em)' : '100%'
    }
  }

  public getTileColor(modType: string) {
    const module = this.modules.find((m) => m.type === modType) || BaseModule
    return {
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  private getActivatedModulesSnapshotHandle: (() => void) | undefined
  private getModulesGroupsSnapshotHandle: (() => void) | undefined

  public async created() {
    try {
      this.isLoading += 1

      // dont call with privileges, since user must have the manage privieleg anyway to do this operation
      this.getActivatedModulesSnapshotHandle = ModuleManager.onSnapshotActivatedModuleClasses(this.$auth.tenant.id, undefined, (modules) => {
        this.modules = modules.filter((g) => g.hasWidget)
      }, (e) => { /** */ }, 'orderWidgets')

      this.getModulesGroupsSnapshotHandle = ModuleManager.onSnapshotModuleGroups(
        this.$auth.tenant.id,
        undefined,
        { includeDeleted: false },
        (groups) => {
          console.log('onSnapshotModuleGroups')

          this.groups = groups
            .filter((g) => ModuleManager.getModuleClassByType(g.type).hasWidget)
        },
        (err) => this.$helpers.notification.Error(err)
      )

      await this.$bindSnapshot('appConfig', TenantManager.getDbAppConfigDocReference(this.$auth.tenant.id))
      // make sure newly added widgets have a proper order no
      // disable automatic order change for now, since sideffects of a user with not all required privileges have still to be identified
      // let updateRequired = false
      // SortingOrderHelper.checkAndFixOrder(
      //   this.groups,
      //   (el, order) => {
      //     updateRequired = true
      //     return ModuleManager.getModuleClassByType(el.type)
      //       .updateGroup(this.$auth.tenant.id, this.$auth.userEmail, el.id, { public: { order: order } })
      //       .catch((e) => this.$helpers.notification.Error('Error updating Order ' + el.type + ' ' + e.toString()))
      //   },
      //   (el) => el.public.order,
      //   (el, order) => el.public.order = order)

      // if (updateRequired)
      //   this.$helpers.notification.Success('Order updated for new Widgets')
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading -= 1
    }
  }

  public mounted() {
    AppPreview.setMode('references')
  }

  @Watch('currentPageID')
  private onCurrentPageChange() {
    const newAppPath = this.currentPageID ? '/page/' + this.currentPageID : ''
    AppPreview.setAppPath(newAppPath)
  }

  get currentPageID() {
    return this.pagesSortedByName[this.tabIndex]?.id || ''
  }

  get filteredGroupsOnCurrentPage() {
    return this.filteredGroups.filter((g) => g.public.pageID === this.currentPageID)
  }

  @Watch('groups', { immediate: true, deep: true })
  @Watch('$localSettings.modules.filters.categories', { deep: true })
  @Watch('moduleElementsMatchingFilter', { deep: true })
  private onGroupsChange() {
    // filter groups by elements which match the global category filter
    this.filteredGroups = (this.$localSettings.modules.filters.categories.length > 0
      ? this.groups.filter((g) => this.moduleElementsMatchingFilter.some((el) => el.public.groupID === g.id && el.type === g.type))
      : this.groups)
      // filter out goups with public.groupType === 'group-type_group'
      .filter((g) => g.public.groupType !== 'group-type_group')
      .sort((a, b) =>
        // if the order is qual, sort by the initial module order, if equal, sort by group id
        a.public.order - b.public.order
        || (moduleOrder[a.type] || 0) - (moduleOrder[b.type] || 0)
        || a.id.localeCompare(b.id, undefined, { numeric: true, sensitivity: 'base' }))
  }

  // public get filteredGroups() {
  //   // filter groups by elements which match the global category filter
  //   return this.$localSettings.modules.filters.categories.length > 0
  //     ? this.groups.filter(g => this.moduleElementsMatchingFilter.some(el => el.public.groupID === g.id && el.type === g.type))
  //     : this.groups
  // }


  @Watch('$localSettings.modules.filters.categories', { immediate: true, deep: true })
  @Watch('$localSettings.modules.filters.categoriesIncludeChildCats')
  @Watch('$categories')
  private async onChangeGlobalCategoryFilter() {
    // get a list of all elements matching the global category filter

    const filterInputCagegoryIDs = this.$localSettings.modules.filters.categories

    if (filterInputCagegoryIDs.length === 0) {
      this.moduleElementsMatchingFilter = []
      return
    }

    this.isLoading += 1

    try {
      const elements = await ModuleManager.getModuleElementsForCategories({
        allCategories: this.$categories,
        filterCategoryIDs: filterInputCagegoryIDs,
        includeChildCategories: this.$localSettings.modules.filters.categoriesIncludeChildCats,
        includeParentCategories: this.$localSettings.modules.filters.categoriesIncludeParentCats,
        tenantID: this.$auth.tenant.id,
        userPrivileges: this.$auth.userPrivileges
      })

      this.moduleElementsMatchingFilter = elements
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading -= 1
    }
  }

  beforeDestroy() {
    console.info('beforeDestroy')
    this.getModulesGroupsSnapshotHandle && this.getModulesGroupsSnapshotHandle()
    this.getActivatedModulesSnapshotHandle && this.getActivatedModulesSnapshotHandle()
  }
}
</script>

<style lang="scss">
// .widget-sort-container {

.manage-widgets-wrapper {
  .is-dragging {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: -0.3rem;
      left: -0.7rem;
      right: -0.7rem;
      bottom: -0.3rem;
      border: 2px dashed rgb(1 125 197 / 90%); /* Adjust color and opacity */
      background: rgb(0 204 255 / 10%);
      border-radius: 0.5rem;
      pointer-events: none; /* Ensure it doesn't interfere with other content */
    }
  }

  .add-page-button {
    padding: 0;
    margin: -0.1rem;
  }

  .edit-page-button {
    // position: absolute;
    right: 0.5rem;
    margin: -0.1rem -1rem -0.1rem 1rem;
    display: none !important;
  }

  .tab-label:hover .edit-page-button {
    display: inline-block !important;
  }

  .center-main-container {
    margin: auto;
    max-width: 45em;

    .app-layout-container {
      display: flex;
      flex-wrap: wrap;
      background: #f2f2f2;
      width: 100%;
      padding: 1em;
      border-radius: 2em;
    }

    .button {
      margin: 2em 0;
    }
  }

  //the dragged element is not in the container
  .sort-element.widget {
    margin: 0.4em;
    padding: 1em;
    border-radius: 1em;
    transition: background 0.2s, transform 0.2s;

    span.display-type {
      font-style: italic;
      font-size: small;
    }

    span.internal-name {
      margin-right: 1rem;
    }

    &.dragging-ghost {
      background: #ddd !important;
    }
    // &.dragging .add-element {
    //   visibility: hidden;
    //   opacity: 0;
    // }

    .handle {
      opacity: 0.75;
      margin-right: 1em;
      cursor: grab;
      // cursor: row-resize;
    }

    &.group-display-type__button {
      // height: 3em;

      .info {
        display: flex;
      }
    }

    &.group-display-type__inline {
      height: 6em;
    }
  }

  // .add-element {
  //   height: 1em;

  //   button.button {
  //     position: relative;
  //     left: -9em;
  //     bottom: 0.6em;
  //   }

  //   span.line {
  //     border-bottom: 2px solid #e6e6e6;
  //     display: block;
  //     margin: 0em 0em 0em -1.1em;
  //     bottom: 1.3em;
  //     position: relative;
  //   }
  // }
}
</style>
