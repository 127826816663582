<template>
  <b-notification aria-close-label="Close notification" v-bind="$attrs">
    {{ !showTruncatedString ? message : truncatedString }}
    <b-button
      v-if="showTruncatedString && mayBeTruncated"
      type="is-text"
      size="is-small"
      @click="showTruncatedString = !showTruncatedString"
    >Show more</b-button>
    <b-button
      v-else-if="mayBeTruncated"
      type="is-text"
      size="is-small"
      @click="showTruncatedString = !showTruncatedString"
    >Show less</b-button>
  </b-notification>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'


@Component({
  components: {
  }
})
export default class VNotificationReadMore extends Vue {
  @Prop({ type: String, required: true, default: () => '' })
  readonly message!: string

  @Prop({ type: Number, required: false, default: () => 70 })
  readonly truncateAfter!: number

  showTruncatedString = true

  get mayBeTruncated() {
    return this.message.length !== this.truncatedString.length
  }

  get truncatedString() {
    // try to truncate after the first sentence, by looking for the '. ' pattern
    // if not found use truncateAfter
    const index = this.message.indexOf('. ')
    if (index > 0) {
      return this.message.substring(0, index) + ' …'
    }
    return this.message.substring(0, this.truncateAfter)
  }

  get messageLength() {
    return this.message.length
  }
}
</script>

<style lang="scss">
</style>
