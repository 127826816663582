<template>
  <section>
    <VFormUserData />

    <!-- <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" /> -->
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'


import VFormUserData from '@/components/VFormUserData.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserTag } from '@fortawesome/free-solid-svg-icons'


library.add(faUserTag)

@Component({
  components: { VFormUserData }

})
export default class BackendUserSingle extends Vue {
  // used for form controls

  // public formUser: FormUserReferenceDB = defaultFormUserReference

  // public isLoading = false


  // public created() {
  //   this.loadData()
  // }

  // public onUpdateUsers() {
  //   const { email, ...tmpUserRef } = this.formUser;

  //   (this.$firestoreRefs.tenant as firebase.firestore.DocumentReference).update({
  //     [`Users.${encode(email)}`]: tmpUserRef
  //   }).then(() => this.$helpers.notification.Success('User updated'))
  //     .catch((e) => this.$helpers.notification.Error('Error updating Users' + e))

  //   this.$router.push({ name: 'user-single', params: { id: email } })
  // }

  // public async loadData() {
  //   this.isLoading = true

  //   let tmpUser = defaultFormUserReference

  //   if (this.$props.id !== 'new') {
  //     if (!this.$auth.tenant.Users[encode(this.$props.id)]) {
  //       this.$helpers.notification.Error(`Could not find user with id ${this.$props.id}`)
  //       return
  //     }
  //     tmpUser = this.$helpers.merge(tmpUser, {
  //       ...this.$auth.tenant.Users[encode(this.$props.id)],
  //       email: this.$props.id
  //     }, { arrayMerge: (destinationArray, sourceArray, options) => sourceArray })
  //   }

  //   this.formUser = tmpUser

  //   this.isLoading = false
  // }
}
</script>

<style></style>
