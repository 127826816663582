<template>
  <section class="modify-category references">
    <p class="notification is-grey references-container">
      <b-field>
        <template #label>
          References
          <VTooltipIconHelp :text="assignmentExplanationText" position="is-right" />
        </template>
        <b-tabs v-model="activeTab" type="is-boxed" size="is-small" class="tabs-menu">
          <b-tab-item
            v-if="$auth.userHasAllPrivilege([$auth.dbPrivileges.CATEGORIES_READ])"
            label="Categories"
            class="category-reference tab-item"
          >
            <template v-if="categories">
              <template v-for="(categoryRefKey,i) in sortedCategoryRefKeys">
                <b-field
                  v-if="displayedCategoryLines > i"
                  :key="categoryRefKey"
                  horizontal
                  :message="fieldMessages[categoryRefKey]"
                  type="is-danger"
                >
                  <template v-if="displayedCategoryLines > 1" #label>
                    <span class="category-line-label">{{ i === 0 ? '' : 'AND' }} any of</span>
                  </template>
                  <VInputMultiCategorySelection
                    v-model="formBaseData.reference.categoryIDs[categoryRefKey]"
                    :disabled="disabled"
                    :categories-doc="categories"
                    :enabled-category-i-ds="enabledCategoryIDs"
                  />
                </b-field>
              </template>

              <!-- only allow removing lines if last line has no elements -->
              <b-button
                v-if="displayedCategoryLines > 1 && formBaseData.reference.categoryIDs[sortedCategoryRefKeys[displayedCategoryLines-1]].length === 0"
                expanded
                icon-left="times"
                size="is-small"
                @click="displayedCategoryLines--"
              >Remove last Category Reference</b-button>
              <!-- only allow adding new lines if last line has elements -->
              <b-button
                v-else-if="displayedCategoryLines < 3 && formBaseData.reference.categoryIDs[sortedCategoryRefKeys[displayedCategoryLines-1]].length !== 0"
                expanded
                icon-left="plus"
                size="is-small"
                @click="displayedCategoryLines++"
              >Add AND Category Reference</b-button>
            </template>
          </b-tab-item>

          <b-tab-item v-if="identifierDefinitions.length > 0" label="Identifiers">
            <b-field
              v-for="identifierDefinition in identifierDefinitions"
              :key="identifierDefinition.__identifierKey__"
              :label="getIdentifierDefinitionTitle(identifierDefinition.__identifierKey__)"
              :message="validationIdentifierMessage[identifierDefinition.__identifierKey__] || ''"
              :type="validationIdentifierMessage[identifierDefinition.__identifierKey__] !== '' ? 'is-warning' : ''"
            >
              <b-taginput
                v-model="formBaseData.reference.identifierValues[identifierDefinition.__identifierKey__]"
                v-debounce:500ms="(text)=>onGetIdentifierAutocomplData(identifierDefinition.__identifierKey__, text)"
                expanded
                maxtags="10"
                :data="identifierAutocompleteData[identifierDefinition.__identifierKey__]"
                autocomplete
                :allow-new="true"
                :open-on-focus="true"
                icon="list-alt"
                :placeholder="`${getIdentifierDefinitionName(identifierDefinition.__identifierKey__)} value`"
                :disabled="disabled"
                :loading="isFetchingIdentifierAutocompleteData"
                @focus="() => onGetIdentifierAutocomplData(identifierDefinition.__identifierKey__, '')"
                @typing="() => {/**/}"
              />
              <!-- @input="newVal => setValueOnIdentifier(identifierDefinition.__identifierKey__, newVal)" -->

              <!-- <b-select
                  v-if="identifierDefinition.validator.choices"
                  v-model="formBaseData.reference.identifierValues[identifierDefinition.__identifierKey__][formBaseData.reference.identifierValues[identifierDefinition.__identifierKey__].length]"
                  :placeholder="identifierDefinition.name"
                  expanded
                >
                  <optgroup :label="identifierDefinition.name">
                    <option
                      v-for="option in identifierDefinition.validator.choices"
                      :key="option"
                      :value="option"
                    >{{ option }}</option>
                  </optgroup>
                </b-select>

                <b-autocomplete
                  v-else
                  v-debounce:500ms="(text)=>onGetIdentifierAutocomplData(identifierDefinition.__identifierKey__, text)"
                  :value="formBaseData.reference.identifierValues[identifierDefinition.__identifierKey__][formBaseData.reference.identifierValues[identifierDefinition.__identifierKey__].length]"
                  :data="identifierAutocompleteData[identifierDefinition.__identifierKey__]"
                  :placeholder="`${getIdentifierDefinitionName(identifierDefinition.__identifierKey__)} value`"
                  field="title"
                  :disabled="disabled"
                  :loading="isFetchingIdentifierAutocompleteData"
                  @input="newVal => setValueOnIdentifier(identifierDefinition.__identifierKey__, newVal)"
                >
                  <template #footer>
                    <span
                      v-if="!hasAsidReadPrivilege"
                    >Input validation not possible due to missing ECHO Code read privilege</span>
                  </template>
              </b-autocomplete>-->
            </b-field>
          </b-tab-item>

          <b-tab-item label="ECHO ID">
            <!-- <b-input v-model="formBaseData.reference.asidIDs[0]" placeholder="ECHO ID" /> -->
            <b-field :message="validationAsidMessage">
              <b-autocomplete
                v-model="formBaseData.reference.asidIDs[0]"
                v-debounce:500ms="(text)=>onGetAsidAutocomplData(text)"
                :data="asidAutocompleteData"
                placeholder="ECHO ID"
                field="title"
                :disabled="disabled"
                :loading="isFetchingAsidAutocompleteData"
              >
                <template #footer>
                  <span
                    v-if="!hasAsidReadPrivilege"
                  >Input validation not possible due to missing ECHO Code read privilege</span>
                </template>
              </b-autocomplete>
            </b-field>
          </b-tab-item>
        </b-tabs>
      </b-field>
    </p>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop, ModelSync, Watch } from 'vue-property-decorator'
import { BaseElementDB } from '../modules/typeModules'
import { CategoryCollection } from '../types/typeCategory'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'


import { dbPrivileges } from '@/helpers/privileges'

import AsidManager from '@/database/asidManager'
import { AsidDB, IdentifierValue, IdentifierValues, isIdentifierKey } from '@/types/typeAsid'
import { typedWhere, typedWhereSartsWith } from '@/database/dbHelper'
import { arrayUnique, getDuplicates, intersect, removeAfromB } from '@/helpers/arrayHelper'
import { DebounceInstance, debounce } from 'vue-debounce'
import { hasDBid } from '@/types/typeGeneral'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faListAlt } from '@fortawesome/free-solid-svg-icons'
import { DataDefinition } from '@/types/typeDataDefinition'
import CategoryHelper from '@/database/categoryHelper'

library.add(faListAlt)

@Component({
  components: {
    VInputMultiCategorySelection
  }
})
export default class VModuleElementReferences extends Vue {
  @ModelSync('elementDoc')
  public formBaseData!: BaseElementDB & hasDBid

  @Prop({ type: Boolean, required: false, default: () => false })
  public disabled!: boolean


  public get categories(): CategoryCollection {
    return this.$categories
  }

  public get enabledCategoryIDs() {
    return Object.keys(CategoryHelper.getAvailableUserCategoriesCollection(this.$categories, this.$auth?.user?.visibleCategories))
  }

  public mounted() {
    // set all autocompleteDataKeys based on the identifierDefinitions
    for (const identifierKey in this.$backendConfig.asid.identifierDefinition) {
      this.$set(this.identifierAutocompleteData, identifierKey, [])

      // if its type is choices, statically set the options
      if (this.$backendConfig.asid.identifierDefinition[identifierKey as isIdentifierKey].validatorType === 'validatorType_choices') {
        this.identifierAutocompleteData[identifierKey] = this.$backendConfig.asid.identifierDefinition[identifierKey as isIdentifierKey].validator.choices || []
      }
    }
  }

  public activeTab = 0
  public displayedCategoryLines = 1

  @Watch('sortedCategoryReferenceValues', { deep: true, immediate: true })
  private onChangeFormBaseData() {
    this.sortedCategoryReferenceValues
      .map((c) => c.length).forEach((numberOfCategories, i) => {
        if (numberOfCategories > 0) this.displayedCategoryLines = i + 1
      })
  }

  public fieldMessages = {
    c1: '',
    c2: '',
    c3: ''
  }

  // public setValueOnIdentifier(key: keyof IdentifierValues, value: string) {
  //   if (value === undefined) {
  //     this.formBaseData.reference.identifierValues[key] = []
  //   } else {
  //     this.formBaseData.reference.identifierValues[key][0] !== undefined
  //       ? this.formBaseData.reference.identifierValues[key][0] = value
  //       : this.formBaseData.reference.identifierValues[key].push(value)
  //   }
  // }

  @Watch('formBaseData.reference.categoryIDs', { deep: true, immediate: true })
  private onChangeCategoryReferences() {
    const duplicates = getDuplicates(Object.values(this.formBaseData.reference.categoryIDs).flat())

    Object.entries(this.formBaseData.reference.categoryIDs)
      .forEach(([key, value]) => {
        const dups = intersect(value, duplicates)
        this.fieldMessages[key as 'c1' | 'c2' | 'c3'] = (dups.length > 0)
          ? `Using the same Category '${dups.map((d) => this.$getCategoryName(d)).join(',')}' in multiple references on the same element is not possible.`
          : ''
      })
  }

  get sortedCategoryRefKeys() {
    return Object.keys(this.formBaseData.reference.categoryIDs).sort()
  }

  get sortedCategoryReferenceValues() {
    return Object.entries(this.formBaseData.reference.categoryIDs)
      .sort((a, b) => a[0].localeCompare(b[0], undefined, { numeric: true, sensitivity: 'base' })).map((d) => d[1])
  }

  public get assignmentExplanationText() {
    let text = ''

    let first = true
    this.sortedCategoryReferenceValues.forEach((catIDs) => {
      if (catIDs.length > 0) {
        if (!first) text += 'AND '
        text += catIDs.length > 1 ? 'any of the categories or subcategories of ' : 'the category or subcategory of '
        text += `"${catIDs.map((cId) => this.$getCategoryName(cId)).join(', ')}"`
        text += ' '

        first = false
      }
    })

    this.identifierDefinitions.forEach((identDef) => {
      const title = this.getIdentifierDefinitionTitle(identDef.__identifierKey__)
      const identifierValue = this.formBaseData.reference.identifierValues[identDef.__identifierKey__]
      if (identifierValue.length > 0) {
        if (!first) text += 'AND '
        text += identifierValue.length > 1 ? 'any of the identifiers ' : 'the identifier '
        text += `"${title}:'${identifierValue.join(', ')}'" `
      }
    })

    this.formBaseData.reference.asidIDs.forEach((asidID) => {
      if (asidID) {
        if (!first) text += 'AND '
        text += `has the ECHO ID "${asidID}"`
      }
    })

    if (text.length === 0) {
      text = 'Assign references to define when this element is shown on a ECHO CODE.'
    } else {
      text = 'This element is shown when the ECHO CODE is assigned to ' + text
    }

    return text
  }

  // #region identifiers

  public get identifierDefinitions(): (DataDefinition & { __identifierKey__: keyof IdentifierValues })[] {
    return Object.entries(this.$backendConfig.asid.identifierDefinition).map(([key, value]) => ({
      __identifierKey__: key as keyof IdentifierValues,
      ...value
    }))
      .filter((e) => e.name || e.title)
      // sort first by order and if order is the same, sort by __identifierKey__
      .sort((a, b) => a.order - b.order || a.__identifierKey__.localeCompare(b.__identifierKey__, 'en', { numeric: true }))
  }

  public isFetchingIdentifierAutocompleteData = false
  public identifierAutocompleteData: { [key: string]: (string | number | null | object | boolean)[] } = {}

  get hasAsidReadPrivilege() {
    return this.$auth.userHasAllPrivilege([dbPrivileges.ASID_READ])
  }

  public async onGetIdentifierAutocomplData(identifierKey: keyof IdentifierValue, text: string) {
    if (this.$backendConfig.asid.identifierDefinition[identifierKey].validatorType === 'validatorType_choices') {
      // if choices are the validator, the choices filter the available options
      this.identifierAutocompleteData[identifierKey]
        = (this.$backendConfig.asid.identifierDefinition[identifierKey].validator.choices as string[])
          .filter((c) => String(c).toLowerCase().includes(text.toLowerCase()))
          // filter out the selected values
          .filter((c) => !this.formBaseData.reference.identifierValues[identifierKey].includes(c))
    } else if (this.hasAsidReadPrivilege) {
      this.isFetchingIdentifierAutocompleteData = true

      const query = typedWhere<AsidDB>(AsidManager.getDbCollectionReference(), { tenantID: '' }, '==', this.$auth.tenant.id)
      const queryOC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text)
      const queryUC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text.toUpperCase())
      const queryLC = typedWhereSartsWith<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, text.toLowerCase())

      const queries = [queryOC, queryUC, queryLC]
      const queryRequests = queries.map((q) => q.limit(50).get())

      const queryResults = (await Promise.all(queryRequests))

      const responseDocuments = queryResults.flatMap((r) => r.docs.map((d) => d.data() as AsidDB).map((asid) => asid.identifierValue[identifierKey]))
      this.identifierAutocompleteData[identifierKey]
        = arrayUnique(responseDocuments)
          // filter out the selected values
          .filter((c) => !this.formBaseData.reference.identifierValues[identifierKey].includes(c || ''))
      this.isFetchingIdentifierAutocompleteData = false
    } else {
      this.identifierAutocompleteData[identifierKey] = [text]
    }
  }

  public validationIdentifierMessage: { [indentifierName: string]: string } = {}

  private debounceInstanceValidateIdentifierReferences?: DebounceInstance<any>
  @Watch('formBaseData.reference.identifierValues', { deep: true, immediate: true })
  private async onIdentifierReferenceChange() {
    if (!this.debounceInstanceValidateIdentifierReferences)
      this.debounceInstanceValidateIdentifierReferences = debounce(async () => {
        await this.validateIdentifierReferences()
      }, 500)

    await this.debounceInstanceValidateIdentifierReferences()
  }

  public getIdentifierDefinitionName(identifierKey: keyof IdentifierValues) {
    return this.$backendConfig.asid.identifierDefinition[identifierKey].name
  }

  public getIdentifierDefinitionTitle(identifierKey: keyof IdentifierValues) {
    // title is optional, fallback to name
    return this.$backendConfig.asid.identifierDefinition[identifierKey].title || this.$backendConfig.asid.identifierDefinition[identifierKey].name
  }

  private async validateIdentifierReferences() {
    let identifierKey: keyof IdentifierValues
    for (identifierKey in this.formBaseData.reference.identifierValues) {
      if (Object.prototype.hasOwnProperty.call(this.formBaseData.reference.identifierValues, identifierKey)) {
        // reset message
        this.$set(this.validationIdentifierMessage, identifierKey, '')
        if (!this.hasAsidReadPrivilege) {
          this.$set(this.validationIdentifierMessage, identifierKey, 'Input validation not possible due to missing ECHO Code read privilege')
        } else {
          try {
            // check if an asid with the given identifiers exists

            // query each selected identifier and check if it exists for an asid
            const asyncPromises = this.formBaseData.reference.identifierValues[identifierKey].map((identifier) => {
              const query = typedWhere<AsidDB>(AsidManager.getDbCollectionReference(), { tenantID: '' }, '==', this.$auth.tenant.id)
              return typedWhere<AsidDB>(query, { identifierValue: { [identifierKey]: '' } }, '==', identifier)
                .limit(1)
                .get()
            })

            // check that no query was empty
            const asidResponses = (await Promise.all(asyncPromises))
              .flatMap((reponse) => reponse.docs)
              .map((doc) => doc.data() as AsidDB)
              .map((asidDB) => asidDB.identifierValue[identifierKey])

            // get list of identifiers that were not found on an asid
            const notFoundIdentifiers = removeAfromB(asidResponses, this.formBaseData.reference.identifierValues[identifierKey])

            if (notFoundIdentifiers.length > 0)
              this.$set(this.validationIdentifierMessage, identifierKey,
                `No matching ECHO Code was found for the identifier "${this.getIdentifierDefinitionTitle(identifierKey)}" with the values "${notFoundIdentifiers.join(',')}".`)
          } catch (error: any) {
            this.$helpers.notification.Error(error.toString())
          }
        }
      }
    }
  }
  // #endregion identifiers

  // #regiopn url presets

  @Watch('$route.query', { deep: false, immediate: true }) // not deep to prevent loop
  public async onRouteChanged() {
    if (this.formBaseData.id !== 'new' && this.formBaseData.id !== '') return

    this.activeTab = 0

    let elRefPresetAsid = this.$route.query.elRefPresetAsid
    if (elRefPresetAsid && this.formBaseData.reference.asidIDs.length === 0) {
      if (!Array.isArray(elRefPresetAsid)) elRefPresetAsid = [elRefPresetAsid]
      this.formBaseData.reference.asidIDs = elRefPresetAsid as string[]
      this.activeTab = 2
    }

    let elRefPresetIdentifier = this.$route.query.elRefPresetIdentifier as (string | string[])
    if (elRefPresetIdentifier && (!Array.isArray(elRefPresetIdentifier) || elRefPresetIdentifier.length > 0)) {
      if (!Array.isArray(elRefPresetIdentifier)) elRefPresetIdentifier = [elRefPresetIdentifier]
      elRefPresetIdentifier.forEach((identifierString) => {
        const [identifier, value] = identifierString.split('__-__')
        if (value && value !== 'null')
          this.formBaseData.reference.identifierValues[identifier as isIdentifierKey] = [value]
      })
      this.activeTab = 1
    }

    // categories elRefPresetCategories_c1
    let elRefPresetCategories_c1 = this.$route.query.elRefPresetCategories_c1 as (string | string[])
    if (elRefPresetCategories_c1) {
      if (!Array.isArray(elRefPresetCategories_c1)) elRefPresetCategories_c1 = [elRefPresetCategories_c1]
      this.formBaseData.reference.categoryIDs.c1 = elRefPresetCategories_c1 as string[]
    }

    let elRefPresetCategories_c2 = this.$route.query.elRefPresetCategories_c2 as (string | string[])
    if (elRefPresetCategories_c2) {
      if (!Array.isArray(elRefPresetCategories_c2)) elRefPresetCategories_c2 = [elRefPresetCategories_c2]
      this.formBaseData.reference.categoryIDs.c2 = elRefPresetCategories_c2 as string[]
    }

    let elRefPresetCategories_c3 = this.$route.query.elRefPresetCategories_c3 as (string | string[])
    if (elRefPresetCategories_c3) {
      if (!Array.isArray(elRefPresetCategories_c3)) elRefPresetCategories_c3 = [elRefPresetCategories_c3]
      this.formBaseData.reference.categoryIDs.c3 = elRefPresetCategories_c3 as string[]
    }

    // if elRefPresetName is set, use it as a prefix for the element name
    let elRefPresetName = this.$route.query.elRefPresetName
    if (elRefPresetName && !this.formBaseData.name.includes('/')) {
      this.formBaseData.name = elRefPresetName + '/' + this.formBaseData.name
    }
  }
  // #endregiopn url presets

  // #region asid autocomplete

  public isFetchingAsidAutocompleteData = false
  public validationAsidMessage: string = ''
  public asidAutocompleteData: string[] = []

  public async onGetAsidAutocomplData(text: string) {
    this.isFetchingAsidAutocompleteData = true

    if (this.hasAsidReadPrivilege && text.length > 0) {
      const query = typedWhere<AsidDB & hasDBid>(AsidManager.getDbCollectionReference(), { tenantID: '' }, '==', this.$auth.tenant.id)
      const queryOC = typedWhereSartsWith<AsidDB & hasDBid>(query, { id: '' }, text)
      const queryUC = typedWhereSartsWith<AsidDB & hasDBid>(query, { id: '' }, text.toUpperCase())
      const queryLC = typedWhereSartsWith<AsidDB & hasDBid>(query, { id: '' }, text.toLowerCase())

      const queries = [queryOC, queryUC, queryLC]
      const queryRequests = queries.map((q) => q.limit(10).get())

      const queryResults = (await Promise.all(queryRequests))

      const responseDocuments = queryResults.flatMap((r) => r.docs.map((d) => d.id))


      this.asidAutocompleteData = arrayUnique(responseDocuments)
      this.isFetchingAsidAutocompleteData = false
    } else {
      this.asidAutocompleteData = [text]
      this.isFetchingAsidAutocompleteData = false
    }
  }

  private debounceInstanceValidateAsidReferences?: DebounceInstance<any>
  @Watch('formBaseData.reference.asidIDs', { deep: true, immediate: true })
  private async onAsidReferenceChange() {
    if (!this.debounceInstanceValidateAsidReferences)
      this.debounceInstanceValidateAsidReferences = debounce(async () => {
        await this.validateReference()
      }, 500)

    await this.debounceInstanceValidateAsidReferences()
  }


  private async validateReference() {
    this.validationAsidMessage = ''

    if (this.hasAsidReadPrivilege && this.formBaseData.reference.asidIDs[0]) {
      try {
        await AsidManager.get(this.formBaseData.reference.asidIDs[0])
      } catch (error: any) {
        this.validationAsidMessage = `No matching ECHO Code with ID "${this.formBaseData.reference.asidIDs[0]}" was found`
      }
    }
  }
  // #endregion asid autocomplete
}
</script>

<style lang="scss">
.references {
  .category-reference {
    .field {
      align-items: center;

      .field-label {
        flex-grow: initial;
        margin-right: 0;

        .category-line-label {
          margin-right: 1rem;
        }

        .label {
          width: max-content;
          font-weight: initial;
        }
      }
    }
  }

  p.references-container {
    padding: 1.25rem;
  }

  .tabs-menu {
    a {
      text-decoration: none !important;
    }

    section.tab-content {
      padding: 0 !important;

      .tab-item {
        padding: 1em 0 !important;
      }
    }
  }
}
</style>
