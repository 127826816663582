<template>
  <section class="is-limited-width-content">
    <VRecordMeta
      position="is-bottom-left"
      class="level-right"
      :required-privileges="documentPrivileges"
    />
    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">User Management</h3>
        <span class="setting-description">Manage your backend users and their permissions.</span>
      </div>

      <div class="column is-three-quarters setting-column">
        <div class="setting-box card">
          <VInputMultiUserManagementView
            ref="userRef"
            :tenant-id="$auth.tenant.id"
            :tenant-name="$auth.tenant.name"
            :categories="$categories"
            :autosave="false"
          />
        </div>
      </div>
    </div>
    <!-- <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">Api User Management</h3>
        <span class="setting-description">Manage your api users and their permissions.</span>
      </div>

      <div class="column is-three-quarters setting-column">
        <div class="setting-box card">
          <VInputMultiApiUserManagementView
            ref="apiUserRef"
            :tenant-id="$auth.tenant.id"
            :tenant-name="$auth.tenant.name"
            :autosave="false"
          />
        </div>
      </div>
    </div>-->
    <hr />

    <VCrudControl
      hide-remove
      save-button-text="Update"
      :is-saving="isLoading"
      @cancel="initialise"
      @save="$save"
    />

    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus
} from '@fortawesome/free-solid-svg-icons'


import VInputMultiUserManagementView from '@/components/VInputMultiUserManagementView.vue'
import VInputMultiApiUserManagementView from '@/components/VInputMultiApiUserManagementView.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'
import databaseSchema from '@/database/databaseSchema'


library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus)

@Component({
  components: {
    VInputMultiUserManagementView,
    VInputMultiApiUserManagementView,
    VRecordMeta
  }
})
export default class BackendUserList extends Vue {
  public isLoading = false

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.USERS.__PRIVILEGES__
  }

  //  get documentPath() { // todo users are mutliple docs
  //     return UserManager.ge(this.$auth.tenant.id).path
  //   }

  public async initialise() {
    (this.$refs.userRef as any).initialise()
    // (this.$refs.apiUserRef as any).initialise()
  }

  public async $save() {
    (this.$refs.userRef as any).save()
    // (this.$refs.apiUserRef as any).save()
  }
}
</script>
