import Vue from 'vue'

import BaseGlobals from './baseGlobals'

import { VueConstructor } from 'vue/types/umd'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { PlanDB } from '@/types/typePlan'

import { UserPrivilegeIdDB } from '@/types/typeUser'
import { intersectSome } from '@/helpers/arrayHelper'
import { objectID } from '@/types/typeGeneral'
import notificationHelper from '@/helpers/notificationHelper'
import TenantManager from '@/database/tenantManager'
import { defaultPlanDB } from '@/database/databaseSchema'

const ENABLE_LOG = false

class PlanGlobals extends BaseGlobals {
  public obersvableData: PlanDB = Vue.observable(defaultPlanDB)

  constructor() {
    super()
  }

  // init is only called in backend
  init(tenantID: objectID, userPrivileges: UserPrivilegeIdDB[]): Promise<SnapshotUnbindHandle> {
    if (!intersectSome([...TenantManager.requiredPlanPrivileges.r], userPrivileges)) {
      return new Promise((resolve) =>
        resolve(() => {
          /** */
        })
      )
    } else {
      return new Promise((resolve, reject) => {
        const unbindSnapshot = TenantManager.getDbPlanDocReference(tenantID).onSnapshot(
          (d) => {
            const planDB = { ...defaultPlanDB, ...d.data() }

            Object.keys(planDB).forEach((key) => {
              if (ENABLE_LOG) console.log(planDB)

              Vue.set(this.obersvableData, key, planDB[key as keyof PlanDB])
            })

            resolve(unbindSnapshot)
          },
          (e) => {
            reject(e)
            notificationHelper.Error(`Error while loading plan: ${e}`)
          }
        )
      })
    }
  }
}

export const PlanGlobalsInst = new PlanGlobals()

declare module 'vue/types/vue' {
  interface Vue {
    $plan: PlanDB
  }
}

export default {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$plan = PlanGlobalsInst.obersvableData
    // Vue.prototype.$identifierDefinitions = PlanGlobalsInst.identifierDefinitions
  }
}
