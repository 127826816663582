import db from '@/firebase'

import { TenantID } from '@/types/typeTenant'
import BaseManager from './baseManager'
import CategoryHelper from '@/database/categoryHelper'

import { BackendConfigDB } from '@/types/typeBackendConfig'
import { IdentifierKeyedObject } from '@/types/typeAsid'
import databaseSchema from './databaseSchema'
import { DeepPartial, hasDBid } from '@/types/typeGeneral'
import { DataDefinition, DataValueObject, isDataDefinitionKey } from '@/types/typeDataDefinition'
import { CategoryCollection } from '@/types/typeCategory'

export default class BackendConfigManager extends BaseManager {
  public static defaultDocDB: BackendConfigDB = databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__EMPTY_DOC__ // todo move to respective managers
  public static requiredPrivileges = databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__PRIVILEGES__

  public static validateIdentifierName(identifierName: string) {
    return !!identifierName.match(/^[a-zA-Z0-9_&+-]+$/g)
  }

  public static showDataEntryBasedOnCategories(dataDefinition: DataDefinition, categoriesRef: string[], allCategories: CategoryCollection) {
    if (dataDefinition.categories.length === 0) return true // if no categories are set, show the data entry
    if (categoriesRef.includes('ALL_CATEGORIES')) return true // if the data entry is in the ALL_CATEGORIES category, show it

    return CategoryHelper.isElementActiveForAsidRef(dataDefinition.categories, categoriesRef, allCategories)
  }

  /**
   * get data definition array from object
   */
  public static getDataDefinitionsFromObject(dataValueObject: DataValueObject): (DataDefinition & { __identifierKey__: isDataDefinitionKey })[] {
    return Object.entries(dataValueObject).map(([key, value]) => ({
      __identifierKey__: key as keyof IdentifierKeyedObject,
      ...value
    }))
  }

  /**
   *
   * @param dataDefinitions
   * @param allCategories // all categories in the system
   * @param categories // optional, if provided, only dataDefinitions where its category is in the categories array or is a subcategory of a category in the array will be validated
   * @returns
   */
  public static filterDataDefinitionsByCategories(
    dataDefinitions: (DataDefinition & { __identifierKey__: isDataDefinitionKey })[],
    allCategories: CategoryCollection,
    categories: string[] = ['ALL_CATEGORIES']
  ) {
    return dataDefinitions.filter((dataDefinition) =>
      this.showDataEntryBasedOnCategories(dataDefinition, categories, allCategories)
    )
  }

  /**
   * all provided dataDefinitions will be validated. Make sure to filter them by categories first if needed
   *
   * @param dataValueObjectSync
   * @param dataDefinitions
   * @returns [boolean, { [key: string]: string }] // [isValid, validationErrorMessages]
   */
  public static validateDataDefinitionInput(
    dataValueObjectSync: DataValueObject,
    dataDefinitions: (DataDefinition & { __identifierKey__: isDataDefinitionKey })[],
    ignoreAllowEmpty = false // overrides datadefinitions allowEmpty
  ): [boolean, { [key: string]: string }] {
    const validationErrorMessages: { [key: string]: string } = {}

    // check for all dataValueObjectSync keys if they are valid according to the dataDefinitionObject
    // if not, add a warning to the the validationErrorMessages

    Object.entries(dataValueObjectSync).forEach(([key, value]) => {
      const dataDefinition = dataDefinitions.find((d) => d.__identifierKey__ === key)
      if (!dataDefinition) return

      if (!ignoreAllowEmpty) {
        if (value === null && dataDefinition.allowEmpty === true) return

        // check allow empty
        if (
          dataDefinition.allowEmpty === false
          && (value === '' || value === null)
          && dataDefinition.datatype !== 'auto_generated'
        ) {
          validationErrorMessages[key] = `value for "${dataDefinition.title}" must not be empty`
        }
      }

      switch (dataDefinition.validatorType) {
        case 'validatorType_none':
          break
        case 'validatorType_regex': {
          const regex = new RegExp(dataDefinition.validator.regex)
          if (!regex.test(value) || value === null) {
            validationErrorMessages[
              key
            ] = `value for "${dataDefinition.title}" does not match the regex ${dataDefinition.validator.regex}`
          }
          break
        }
        case 'validatorType_minMax': {
          // cast everyting to number for comparison
          if (
            Number(value) < Number(dataDefinition.validator.minMax[0])
            || Number(value) > Number(dataDefinition.validator.minMax[1])
          ) {
            validationErrorMessages[
              key
            ] = `value for "${dataDefinition.title}" must be between ${dataDefinition.validator.minMax[0]} and ${dataDefinition.validator.minMax[1]}`
          }
          break
        }
        case 'validatorType_value': {
          if (
            (dataDefinition.datatype === 'string' && dataDefinition.validator.value !== value)
            || (dataDefinition.datatype === 'number' && dataDefinition.validator.value !== +value)
            || (dataDefinition.datatype === 'boolean' && dataDefinition.validator.value !== value)
          ) {
            validationErrorMessages[
              key
            ] = `value for "${dataDefinition.title}" must equal ${dataDefinition.validator.value}`
          }
          break
        }
        case 'validatorType_choices': {
          if (
            !(dataDefinition.validator.choices as string[]).includes(value)
            || (dataDefinition.allowEmpty === true && value === '')
          ) {
            validationErrorMessages[key] = `value for ${dataDefinition.title
            } must be one of ${dataDefinition.validator.choices.join(', ')}`
          }
          break
        }
        case 'validatorType_pattern':
          break
        default: {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const type: never = dataDefinition.validatorType
          break
        }
      }

      if (dataDefinition.datatype === 'email') {
        const regex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$')
        if (value !== '' && value !== null && !regex.test(value)) {
          validationErrorMessages[key] = `value for "${dataDefinition.title}" is not a valid email address`
        }
      }
    })

    return [Object.keys(validationErrorMessages).length === 0, validationErrorMessages]
  }

  // todo this will be done using a cloud function in the future
  public static async add(tenantId: TenantID, authEmail: string, fields: DeepPartial<BackendConfigDB> = {}) {
    return await this.addDoc(this.getDbDocReference(tenantId), authEmail, fields, this.defaultDocDB)
  }

  public static async update(tenantId: TenantID, authEmail: string, fields: DeepPartial<BackendConfigDB> = {}) {
    return this.updateDoc(this.getDbDocReference(tenantId), authEmail, fields)
  }

  public static async get(tenantId: TenantID) {
    return this.getDocHelper<BackendConfigDB>(this.getDbDocReference(tenantId))
  }

  public static getDbDocReference(tenantId: TenantID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__DOCUMENT_PATH__(tenantId))
  }

  public static getCollectionGroupReference() {
    // just return the data collection here, as backend config itself is a document
    // be carefult if the queried key also exists in other child docs of data, as they might be also returned
    return db.collectionGroup(databaseSchema.COLLECTIONS.TENANTS.DATA.__NAME__)
  }

  public static onSnapshot(
    tenantId: string,
    onNext: (data: BackendConfigDB & hasDBid) => void,
    onError: (e: any) => void
  ) {
    return this.onSnapshotHelper<BackendConfigDB>(this.getDbDocReference(tenantId), onNext, (d) => d, onError)
  }
}
