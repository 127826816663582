<template>
  <section class="user-roles">
    <!-- <treeselect
      :value="formSelectedIds"
      :multiple="true"
      :options="privilegesTree"
      :normalizer="normalizer"
      search-nested
      value-consists-of="LEAF_PRIORITY"
      :always-open="false"
      :append-to-body="false"
      @input="onInputChange"
      @close="onBlur"
    >
      <label
        slot="option-label"
        slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }"
        :class="labelClassName"
      >
        {{ node.isBranch ? 'Group' : 'Privilege' }}:
        <b>{{ node.label }}</b>
        <span v-if="shouldShowCount" :class="countClassName">({{ count }})</span>
      </label>
    </treeselect>-->
    <LiquorTree
      v-show="privilegesLiquorTree.length > 0"
      ref="tree"
      class="treeview user-treeview"
      :data="privilegesLiquorTree"
      :options="treeOptions"
      :filter="filterText"
      @tree:mounted="updateCheckedNodes"
    >
      <div slot-scope="{ node }" class="tree-scope">
        <template>
          <b-field>
            <b-checkbox
              :value="isNodeChecked(node)"
              :indeterminate="isNodeIntermediateChecked(node)"
              @input="value=>onCheckedChange(node,value)"
              @click.native.stop
            >{{ node.text }}</b-checkbox>
            <!-- <b-checkbox :value="node.data.checked" :indeterminate="node.data.intermediate" @click="onChecked(node)">{{ node.text }}</b-checkbox> -->
          </b-field>
        </template>
      </div>
    </LiquorTree>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'


// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { CategoryID, CategoryTree } from '@/types/typeCategory'
import PrivilegeHelper, { UserPrivilegeTreeData } from '../helpers/privilegeHelper'
import { UserPrivilegeIdDB } from '../types/typeUser'
import LiquorTree from 'liquor-tree'
import { arrayUnique } from '@/helpers/arrayHelper'
import { TreeData } from '@/types/typeGeneral'

const state = {
  selected: false,
  selectable: true,
  checked: false,
  expanded: true,
  disabled: false,
  visible: true,
  indeterminate: false,
  matched: false,
  editable: true,
  dragging: false,
  draggable: true,
  dropable: true
}


interface LiquorTreeElement extends CategoryTree {
  data: {
    intermediate: boolean
    checked: boolean
    [key: string]: any
  }
  text: string
  state: typeof state
  parent: LiquorTreeElement
  children: LiquorTreeElement[]
  remove: () => void
}

@Component({
  components: {
    Treeselect,
    LiquorTree
  },
  model: {
    prop: 'selectedUserRoles',
    event: 'selected'
  },
  props: {
    selectedUserRoles: {
      type: Array,
      required: true
    },
    hideViews: {
      type: Boolean,
      required: false
    }
  }
})
export default class VInputUserRolesTags extends Vue {
  public privilegesTree = PrivilegeHelper.getAvailableRolesAndPrivilegesTree(this.$props.hideViews)
  public formSelectedIds: UserPrivilegeIdDB[] = []
  public filterText = ''
  public treeOptions = {
    propertyNames: {
      text: 'title',
      children: 'children',
      state: 'state'
    },
    filter: {
      emptyText: ''
    },
    multiple: true,
    dnd: false,
    checkbox: false
  }

  private programmaticChecking = false

  get privilegesLiquorTree() {
    // add the name to data prop
    // name is the id stored in the db
    const addNameToData = (node: UserPrivilegeTreeData): UserPrivilegeTreeData => {
      const newNode = {
        ...node,
        data: {
          name: node.name
        },
        children: node.children?.map((child) => addNameToData(child))
      }
      return newNode
    }

    return this.privilegesTree.map((t) => addNameToData(t))
  }


  // public normalizer(node: TreeData) {
  //   const tmpNode = {
  //     id: node.name,
  //     label: node.name,
  //     children: (node.children && node.children.length > 0) ? node.children : undefined // to remove the child prop when empty
  //   }
  //   return {
  //     ...tmpNode // to remove the child prop when empty
  //   }
  // }


  // Does not work cause unique index is not in db
  // private index = 0

  // public normalizer(node: TreeData) {
  //   const tmpNode = {
  //     id: `${this.index++}___${node.name}`, // treeselect need unique data index
  //     label: node.name,
  //     children: (node.children && node.children.length > 0) ? node.children : undefined // to remove the child prop when empty
  //   }
  //   return {
  //     ...tmpNode // to remove the child prop when empty
  //   }
  // }

  // public onInputChange(selectedIDs: CategoryID[]) {
  //   this.$emit('selected', selectedIDs.map(id=>id.split('___')[1])) // remove unique id
  // }

  //
  private selectAllChildrenByNodeID(selectedIDs: string[]) {
    const allSelectedIdLeafs: string[] = []

    function iterateTreeDown(subtree: TreeData, addToResult = false) {
      addToResult = selectedIDs.includes(subtree.id) || addToResult

      if (addToResult && !(subtree.children)) allSelectedIdLeafs.push(subtree.name)

      subtree.children?.forEach((child) => iterateTreeDown(child, addToResult))
    }

    this.privilegesTree.forEach((t) => iterateTreeDown(t))

    return allSelectedIdLeafs
  }

  private aggregateTopMostSelection(selectedIDs: string[]) {
    const allSelectedIdLeafs: string[] = []

    function iterateTreeUp(subtree: TreeData): boolean {
      if (!subtree.children) {
        return selectedIDs.includes(subtree.name)
      } else {
        const allChildsMatch = subtree.children?.map((child) => iterateTreeUp(child)).every((e) => e)
        if (allChildsMatch) allSelectedIdLeafs.push(subtree.name)
        return allChildsMatch
      }
    }
    console.log('aggregating')


    this.privilegesTree.forEach((t) => iterateTreeUp(t))

    return allSelectedIdLeafs
  }

  public onCheckedChange(node: LiquorTreeElement, checked: boolean) {
    if (checked) {
      const checkedPrivileges = arrayUnique([...this.formSelectedIds, ...this.selectAllChildrenByNodeID([node.id])])

      this.$emit('selected', checkedPrivileges)
    } else {
      const checkedPrivileges = this.formSelectedIds
      const toBeUnchecked = this.selectAllChildrenByNodeID([node.id])
      // the unchecked node may appear multiple times in the tree and ths still be in the result set
      const newCheckedPrivileges = checkedPrivileges.filter((cp) => !toBeUnchecked.includes(cp))
      this.$emit('selected', newCheckedPrivileges)
    }
  }

  // public onChecked(node: LiquorTreeElement) {
  //   if (this.programmaticChecking) return

  //   const checkedItems = (this.$refs.tree as any).checked() as LiquorTreeElement[]
  //   console.log('checked', node.text)
  //   const checkedPrivileges = arrayUnique([node.text, ...this.selectAllChildrenByNodeID(checkedItems.map(i => i.text))])

  //   this.$emit('selected', checkedPrivileges)
  // }

  // public onUnchecked(node: LiquorTreeElement) {
  //   if (this.programmaticChecking) return

  //   const checkedItems = (this.$refs.tree as any).checked() as LiquorTreeElement[]
  //   console.log('unchecked', node.text)

  //   const checkedPrivileges = arrayUnique(this.selectAllChildrenByNodeID(checkedItems.map(i => i.text)))

  //   // the unchecked node may appear multiple times in the tree and ths still be in the result set
  //   const newCheckedPrivileges = checkedPrivileges.filter(cp => cp !== node.text)
  //   this.$emit('selected', newCheckedPrivileges)
  // }

  public isNodeChecked(node: LiquorTreeElement) {
    if (this.formSelectedIds.includes(node.text as UserPrivilegeIdDB)) return true

    const iterateTreeNodeHasEveryCheckedChildren = (node: LiquorTreeElement): boolean => {
      if (this.formSelectedIds.includes(node.text as UserPrivilegeIdDB)) {
        return true
      } else if (node.children.length > 0) {
        return node.children.every((node) => iterateTreeNodeHasEveryCheckedChildren(node))
      } else {
        return false
      }
    }

    return iterateTreeNodeHasEveryCheckedChildren(node)
  }

  /**
   * some but not all child nodes are checked
   */
  public isNodeIntermediateChecked(node: LiquorTreeElement) {
    if (this.formSelectedIds.includes(node.text as UserPrivilegeIdDB)) return false

    const iterateTreeNodeHasCheckedChildren = (node: LiquorTreeElement): boolean => {
      if (this.formSelectedIds.includes(node.text as UserPrivilegeIdDB)) {
        return true
      } else if (node.children.length > 0) {
        return node.children.some((node) => iterateTreeNodeHasCheckedChildren(node))
      } else {
        return false
      }
    }

    return iterateTreeNodeHasCheckedChildren(node) && !this.isNodeChecked(node)
  }

  public onInputChange(selectedIDs: CategoryID[]) {
    this.$emit('selected', (selectedIDs))
    // this.$emit('selected', this.selectAllChildrenByNodeID(selectedIDs))
  }

  public onBlur(selectedIDs: CategoryID[]) {
    this.$emit('blur', (selectedIDs))
    // this.$emit('blur', this.selectAllChildrenByNodeID(selectedIDs))
  }

  // todo see AppAdress on how its done
  @Watch('selectedUserRoles', { immediate: true })
  onselectedCategoryIDChanged(val: UserPrivilegeIdDB[], oldVal: string[]) {
    this.formSelectedIds = val
    this.updateCheckedNodes()
    // this.formSelectedIds = this.aggregateTopMostSelection(val) as UserPrivilegeIdDB[]
  }

  mounted() {
    this.updateCheckedNodes()
  }


  private updateCheckedNodes() {
    if (this.$refs.tree) {
      this.programmaticChecking = true
      // const findNodesRegex = new RegExp(`${this.formSelectedIds.join('|')}`)

      //   ; (this.$refs.tree as any).checked().uncheck()
      // const selection = (this.$refs.tree as any).findAll({ text: findNodesRegex, state: { disabled: false } })
      // console.log(selection)
      // selection.check(true)
      this.programmaticChecking = false
    }
  }
}

</script>


<style lang="scss">
@import '@/css/treeview.scss';
@import '@/variables.scss';

.user-treeview {
  &.treeview.tree .tree-node.selected > .tree-content {
    background-color: #fff;

    .tree-arrow.has-child::after {
      border-color: #494646;
    }
  }

  &.treeview.tree span.tree-anchor {
    line-height: 1em;
    vertical-align: super;
  }
}

.user-roles {
  // .vue-treeselect__control {
  //     height: 0px;
  // }

  span.vue-treeselect__checkbox.vue-treeselect__checkbox--checked,
  span.vue-treeselect__checkbox.vue-treeselect__checkbox--indeterminate {
    border-color: $primary;
    background: $primary;
  }

  // div.vue-treeselect span.vue-treeselect__minus-mark,
  // div.vue-treeselect span.vue-treeselect__check-mark {
  //   background-size: 0.65em 0.7em;
  // }

  span.vue-treeselect__checkbox {
    width: 1.1em;
    height: 1.1em;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-radius: 4px;
    border: 2px solid #7a7a7a;
    transition: background 150ms ease-out;
  }

  span.vue-treeselect__minus-mark,
  span.vue-treeselect__check-mark {
    width: 1.1em;
    height: 1.1em;
    background-size: 11px 12px;
  }

  input.vue-treeselect__input {
    font-size: 1rem;
    padding: 0;
    padding: 5px 5px 0 !important;
  }

  .vue-treeselect__input-container {
    padding: 0 !important;
  }

  div.vue-treeselect__multi-value-item,
  span.vue-treeselect__value-remove {
    background: $light;
    color: $text;
  }
}
</style>
