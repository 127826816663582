<template>
  <section class="config-asid">
    <div class="level record-meta">
      <div class="level-left" />
      <VRecordMeta
        position="is-bottom-left"
        class="level-right"
        :record-meta="backendConfig._meta"
        :required-privileges="documentPrivileges"
        :document-path="documentPath"
      />
    </div>
    <!-- configure identifier, custom domain, -->
    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">ECHO CODE</h3>
        <span class="setting-description">Define parameters for your printed ECHO CODE</span>
      </div>

      <div class="column is-three-quarters setting-column">
        <div class="setting-box card">
          <h3 class="title is-5">Sticker Design Presets</h3>

          <b-field grouped>
            <b-field>
              <b-select v-model="formSelectedCodeConfigIndex" placeholder="Default">
                <option
                  v-for="(code,index) in backendConfig.codes"
                  :key="code.id"
                  :value="index"
                >Design {{ code.id }}</option>
              </b-select>

              <p v-if="backendConfig.codes.length > 1" class="control">
                <b-button
                  :disabled="!$auth.isEchoPrmStaff"
                  icon-left="trash"
                  type="is-danger"
                  @click="onRemoveCodeConfig"
                >remove</b-button>
              </p>
              <!-- <p class="control">
                <b-button type="is-success" @click="$saveCodeConfig">update</b-button>
              </p>-->
            </b-field>
            <b-field>
              <p class="control">
                <b-button :disabled="!$auth.isEchoPrmStaff" @click="onAddCodeConfig">add design</b-button>
              </p>
            </b-field>
          </b-field>

          <VFormConfigureSticker
            v-model="backendConfig.codes[formSelectedCodeConfigIndex]"
            :disabled="!$auth.isEchoPrmStaff"
            :base-url="backendConfig.asid.baseUrl"
            :disable-modify-error-correction-level="true"
            :disable-modify-width="true"
            :disable-custom-logo="true"
          />

          <hr />

          <b-field>
            <template slot="label">
              App domain name
              <VTooltipIconHelp
                :text="`When active, newly generated echo Codes point to ${AsidManager.createLink('<code>',backendConfig.asid.baseUrl||'<yourDomain>')}. Ask your ECHO PRM contact for more information. ` + ((backendConfig.asid.baseUrl)?`Point your ${backendConfig.asid.baseUrl} DNS record CNAME to app.echoprm.com `:'')"
              />
            </template>
            <b-input
              v-model="backendConfig.asid.baseUrl"
              disabled
              placeholder="app.your-custom-domain.de"
            />
          </b-field>

          <b-field v-if="backendConfig.asid.customNumberRange">
            <template slot="label">
              Custom ECHO CODE Number Range
              <VTooltipIconHelp
                :text="`You can create ECHO CODES that match the format c${backendConfig.asid.customNumberRange}-xxxxx-xxxxx-xxxxx, where x may be [a-z,0-9]`"
              />
            </template>
            <b-input
              v-model="backendConfig.asid.customNumberRange"
              disabled
            />
          </b-field>

          <b-field v-if="backendConfig.asid.baseUrl">
            <template slot="label">
              App landing page redirect url
              <VTooltipIconHelp
                :text="`When ${backendConfig.asid.baseUrl} is called without scanning an ECHO CODE (without ECHO ID parameters) the user may be redirected to a default page`"
              />
            </template>
            <b-input
              v-model="backendConfig.asid.redirectUrl"
              type="url"
              placeholder="https://redirect-url"
            />
          </b-field>
        </div>

        <!-- <div class="setting-box card">
          <h3 class="title is-5">QR Code</h3>
           <b-field>
            <template slot="label">
              qr error correction level
              <VTooltipIconHelp
                text="Changes the QR error correction level according to QR code specification"
              />
            </template>
            <b-select placeholder="Error Correction">
              <option
                v-for="option in ERROR_CORRECTION_LEVELS"
                :key="option.key"
                :value="option.key"
              >{{ option.value }}</option>
            </b-select>
          </b-field>
        -->

        <!--
          <b-field>
            <template slot="label">
              use custom domain
              <VTooltipIconHelp text="Hides all echo PRM related branding from the app by using your own domain. Contact us for more information" />
            </template>
            <b-switch disabled :rounded="false" />
          </b-field>
        </div>-->
      </div>
    </div>

    <hr />

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">ECHO ID Identifier</h3>
        <span class="setting-description">
          One or multiple Identifiers can be added to an ECHO ID to uniquely identify the assigned physical object
          <br />
        </span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <h3 class="title is-5">Identifiers</h3>

        <VFormDataDefinition
          ref="identifier-definition"
          v-model="backendConfig.asid.identifierDefinition"
          show-category-visibility
          show-identifier-warning
          :hide-types="['gps', 'image']"
        />
      </div>
    </div>

    <hr />

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">ECHO ID Attributes</h3>
        <span class="setting-description">
          One or multiple Attributes can be added to an ECHO ID to store ECHO ID specific values.
          <br />
        </span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <h3 class="title is-5">Attributes</h3>

        <VFormDataDefinition
          ref="asset-attribute-definition"
          v-model="backendConfig.asid.assetAttributeDefinitions"
          show-category-visibility
          :hide-types="['gps']"
        />
      </div>
    </div>

    <hr />
    <!-- {{ testCatSelection.join(', ') }}
    <VInputMultiCategoryEntry
      v-model="testCatSelection"
      :category-entry-definitions="backendConfig.asid.categoryDefinitions"
    />-->

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">Category Entry Definition</h3>
        <span class="setting-description">
          Define how categories shall be entered when assigning an ECHO CODE.
          <br />
        </span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <h3 class="title is-5">Categories</h3>

        <VFormCategoryEntryDefinition
          ref="category-definition"
          v-model="backendConfig.asid.categoryDefinitions"
          :hide-types="['gps']"
        />
      </div>
    </div>

    <!-- <hr /> -->
    <!--  -->
    <!-- <div class="columns setting"> -->
    <!-- <div class="column setting-description-box"> -->
    <!-- <h3 class="title is-5">Assignement Workflows</h3> -->
    <!-- <span class="setting-description"> -->
    <!-- Define workflows to guide the activation and assignemt -->
    <!-- <br /> -->
    <!-- </span> -->
    <!-- </div> -->
    <!--  -->
    <!-- <div class="column is-three-quarters setting-box card"> -->
    <!-- <h3 class="title is-5">Workflows</h3> -->
    <!--  -->
    <!-- show ui to create and modify workflows and steps -->
    <!-- iterate formWorkflows -->
    <!-- <div -->
    <!-- v-for="(workflow, workflowIndex) in formWorkflows" -->
    <!-- :key="workflowIndex" -->
    <!-- class="workflow-wrapper box" -->
    <!-- > -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- workflow name -->
    <!-- <VTooltipIconHelp text="The name of the workflow" /> -->
    <!-- </template> -->
    <!-- <b-input v-model="workflow.title" /> -->
    <!-- </b-field> -->
    <!--  -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- workflow description -->
    <!-- <VTooltipIconHelp text="The description of the workflow" /> -->
    <!-- </template> -->
    <!-- <b-input v-model="workflow.description" /> -->
    <!-- </b-field> -->
    <!--  -->
    <!-- !-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- workflow categories -->
    <!-- <VTooltipIconHelp text="The categories to which the workflow is assigned" /> -->
    <!-- </template> -->
    <!-- <b-select v-model="workflow.categories" multiple placeholder="Select categories" /> -->
    <!-- :options="AsidManager.categories" todo -->
    <!-- </b-field>-->
    <!--  -->
    <!-- iterate steps -->
    <!-- <div class="steps-container"> -->
    <!-- <span class="label">Steps</span> -->
    <!--  -->
    <!-- <b-collapse -->
    <!-- v-for="(step, stepIndex) in workflow.steps" -->
    <!-- :key="stepIndex" -->
    <!-- animation="slide" -->
    <!-- class="workflow-step-wrapper box" -->
    <!-- :class="{ -->
    <!-- 'show-with-previous-step': (step.showWithPreviousStep && stepIndex > 0), -->
    <!-- 'show-with-next-step': (workflow.steps[stepIndex + 1] && workflow.steps[stepIndex + 1].showWithPreviousStep) -->
    <!-- }" -->
    <!-- :open="isOpen == stepIndex + workflowIndex*100" -->
    <!-- :aria-id="'contentIdForA11y5-' + stepIndex" -->
    <!-- @open="isOpen = stepIndex + workflowIndex*100" -->
    <!-- > -->
    <!-- <template #trigger="props"> -->
    <!-- <div class="level"> -->
    <!-- <div class="level-left"> -->
    <!-- <p -->
    <!-- class="card-header-title" -->
    <!-- >{{ step.title }} {{ step.action.mandatory ? '*' : '' }}</p> -->
    <!-- <p> -->
    <!-- <b-tag> -->
    <!-- {{ ACTION_NAME_MAP[step.action.type] }} -->
    <!-- <span -->
    <!-- v-if="step.action.value" -->
    <!-- >: {{ getHumanReadableActionValue(step.action.type, step.action.value) }}</span> -->
    <!-- </b-tag> -->
    <!-- </p> -->
    <!-- <!-<p class="is-small"> -->
    <!-- <small>{{ step.description }}</small> -->
    <!-- </p>-->
    <!-- </div> -->
    <!--  -->
    <!-- <a class="levle-right"> -->
    <!-- <span class="card-header-title"> -->
    <!-- <b-icon :icon="!props.open ? 'chevron-down' : 'chevron-up'" size="is-small" /> -->
    <!-- </span> -->
    <!-- </a> -->
    <!-- </div> -->
    <!-- </template> -->
    <!--  -->
    <!-- <div class="card-content"> -->
    <!-- <b-field grouped> -->
    <!-- <b-field expanded> -->
    <!-- <template slot="label"> -->
    <!-- step title -->
    <!-- <VTooltipIconHelp text="The title of the step" /> -->
    <!-- </template> -->
    <!-- <b-input v-model="step.title" expanded /> -->
    <!-- </b-field> -->
    <!--  -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- show with previous step -->
    <!-- <VTooltipIconHelp -->
    <!-- text="If true, the step is shown together with the previous step" -->
    <!-- /> -->
    <!-- </template> -->
    <!-- <b-switch v-model="step.showWithPreviousStep" :rounded="false" /> -->
    <!-- </b-field> -->
    <!-- </b-field> -->
    <!--  -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step description -->
    <!-- <VTooltipIconHelp text="The description of the step" /> -->
    <!-- </template> -->
    <!-- <b-input v-model="step.description" /> -->
    <!-- </b-field> -->
    <!--  -->
    <!-- <!- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step order -->
    <!-- <VTooltipIconHelp text="The order of the step" /> -->
    <!-- </template> -->
    <!-- <b-input v-model="step.order" type="number" /> -->
    <!-- </b-field>-->
    <!--  -->
    <!-- <b-field grouped> -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step action -->
    <!-- <VTooltipIconHelp text="The action of the step" /> -->
    <!-- </template> -->
    <!-- <b-select -->
    <!-- v-model="step.action.type" -->
    <!-- placeholder="Select action" -->
    <!-- @input="step.action.value = ''" -->
    <!-- > -->
    <!-- <option value="workflowStepType_setCategory">set category</option> -->
    <!-- <option value="workflowStepType_setAttribute">set attribute</option> -->
    <!-- <option value="workflowStepType_setIdentifier">set identifier</option> -->
    <!-- </b-select> -->
    <!-- </b-field> -->
    <!--  -->
    <!-- <template v-if="step.action.type === 'workflowStepType_setCategory'"> -->
    <!-- dropdown to select the correct categoryDefinitions -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step action value -->
    <!-- <VTooltipIconHelp text="The value of the action" /> -->
    <!-- </template> -->
    <!-- <b-select v-model="step.action.value" placeholder="Select category"> -->
    <!-- <option -->
    <!-- v-for="(categoryDefinition, catDefKey) in backendConfig.asid.categoryDefinitions" -->
    <!-- :key="catDefKey" -->
    <!-- :value="catDefKey" -->
    <!-- >{{ `${categoryDefinition.title} (${catDefKey})` }}</option> -->
    <!-- </b-select> -->
    <!-- </b-field> -->
    <!-- </template> -->
    <!--  -->
    <!-- <template v-else-if="step.action.type === 'workflowStepType_setAttribute'"> -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step action value -->
    <!-- <VTooltipIconHelp text="The value of the action" /> -->
    <!-- </template> -->
    <!-- <b-select v-model="step.action.value" placeholder="Select Attribute"> -->
    <!-- <option -->
    <!-- v-for="(attributeDefinition, attrDefKey) in backendConfig.asid.assetAttributeDefinitions" -->
    <!-- :key="attrDefKey" -->
    <!-- :value="attrDefKey" -->
    <!-- >{{ `${attributeDefinition.title} (${attrDefKey})` }}</option> -->
    <!-- </b-select> -->
    <!-- </b-field> -->
    <!-- </template> -->
    <!--  -->
    <!-- <template v-else-if="step.action.type === 'workflowStepType_setIdentifier'"> -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step action value -->
    <!-- <VTooltipIconHelp text="The value of the action" /> -->
    <!-- </template> -->
    <!-- <b-select v-model="step.action.value" placeholder="Select Identifier"> -->
    <!-- <option -->
    <!-- v-for="(attributeDefinition, attrDefKey) in backendConfig.asid.identifierDefinition" -->
    <!-- :key="attrDefKey" -->
    <!-- :value="attrDefKey" -->
    <!-- >{{ `${attributeDefinition.title} (${attrDefKey})` }}</option> -->
    <!-- </b-select> -->
    <!-- </b-field> -->
    <!-- </template> -->
    <!--  -->
    <!-- <template v-else> -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step action value -->
    <!-- <VTooltipIconHelp text="The value of the action" /> -->
    <!-- </template> -->
    <!-- <b-input v-model="step.action.value" /> -->
    <!-- </b-field> -->
    <!-- </template> -->
    <!-- </b-field> -->
    <!--  -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step action mandatory -->
    <!-- <VTooltipIconHelp text="If true, the action is mandatory" /> -->
    <!-- </template> -->
    <!-- <b-switch v-model="step.action.mandatory" :rounded="false" /> -->
    <!--  -->
    <!-- <template v-if="step.action.mandatory" slot="message"> -->
    <!-- <span class="has-text-danger">This action is mandatory</span> -->
    <!-- </template> -->
    <!-- </b-field> -->
    <!-- </div> -->
    <!-- </b-collapse> -->
    <!--  -->
    <!-- add step button -->
    <!-- <b-field> -->
    <!-- <b-button expanded icon-left="plus" @click="onAddWorkflowStep(workflowIndex)">add step</b-button> -->
    <!-- </b-field> -->
    <!-- </div> -->
    <!--  -->
    <!-- <div class="steps-container"> -->
    <!-- <span class="label">Workflow complete step</span> -->
    <!-- <b-collapse -->
    <!-- animation="slide" -->
    <!-- class="workflow-step-wrapper box" -->
    <!-- :open="isOpen == 99 + workflowIndex*100" -->
    <!-- :aria-id="'contentIdForA11y5-' + 99" -->
    <!-- @open="isOpen = 99 + workflowIndex*100" -->
    <!-- > -->
    <!-- <template #trigger="props"> -->
    <!-- <div class="level"> -->
    <!-- <div class="level-left"> -->
    <!-- <p class="card-header-title"> -->
    <!-- {{ workflow.completeStep.title }} -->
    <!-- {{ step.action.mandatory ? '*' : '' }} -->
    <!-- </p> -->
    <!-- <p> -->
    <!-- <b-tag> -->
    <!-- {{ ACTION_NAME_MAP['workflowStepType_setCategory'] }} -->
    <!-- <span -->
    <!-- v-if="workflow.completeStep.setCategory" -->
    <!-- >: {{ getHumanReadableActionValue('workflowStepType_setCategory', workflow.completeStep.setCategory) }}</span> -->
    <!-- </b-tag> -->
    <!-- </p> -->
    <!-- <!- <p class="is-small"> -->
    <!-- <small>{{ step.description }}</small> -->
    <!-- </p>-->
    <!-- </div> -->
    <!--  -->
    <!-- <a class="levle-right"> -->
    <!-- <span class="card-header-title"> -->
    <!-- <b-icon :icon="!props.open ? 'chevron-down' : 'chevron-up'" size="is-small" /> -->
    <!-- </span> -->
    <!-- </a> -->
    <!-- </div> -->
    <!-- </template> -->
    <!--  -->
    <!-- <div class="card-content"> -->
    <!-- <b-field grouped> -->
    <!-- <b-field expanded> -->
    <!-- <template slot="label"> -->
    <!-- step title -->
    <!-- <VTooltipIconHelp text="The title of the step" /> -->
    <!-- </template> -->
    <!-- <b-input v-model="workflow.completeStep.title" expanded /> -->
    <!-- </b-field> -->
    <!-- </b-field> -->
    <!--  -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step description -->
    <!-- <VTooltipIconHelp text="The description of the step" /> -->
    <!-- </template> -->
    <!-- <b-input v-model="workflow.completeStep.description" /> -->
    <!-- </b-field> -->
    <!--  -->
    <!-- <!- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step order -->
    <!-- <VTooltipIconHelp text="The order of the step" /> -->
    <!-- </template> -->
    <!-- <b-input v-model="step.order" type="number" /> -->
    <!-- </b-field>-->
    <!--  -->
    <!-- <b-field grouped> -->
    <!-- <b-field> -->
    <!-- <template slot="label"> -->
    <!-- step action value -->
    <!-- <VTooltipIconHelp text="The value of the action" /> -->
    <!-- </template> -->
    <!-- <b-select -->
    <!-- v-model="workflow.completeStep.setCategory" -->
    <!-- placeholder="Select category" -->
    <!-- > -->
    <!-- <option -->
    <!-- v-for="(categoryDefinition, catDefKey) in backendConfig.asid.categoryDefinitions" -->
    <!-- :key="catDefKey" -->
    <!-- :value="catDefKey" -->
    <!-- >{{ `${categoryDefinition.title} (${catDefKey})` }}</option> -->
    <!-- </b-select> -->
    <!-- </b-field> -->
    <!-- </b-field> -->
    <!-- </div> -->
    <!-- </b-collapse> -->
    <!-- </div> -->
    <!-- </div> -->
    <!--  -->
    <!-- add workflow button -->
    <!-- <b-field> -->
    <!-- <b-button expanded icon-left="plus" @click="onClickAddWorkflow">add workflow</b-button> -->
    <!-- </b-field> -->
    <!-- </div> -->
    <!-- </div> -->

    <hr />

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">Activation</h3>
        <span class="setting-description">Configure the ECHO ID activation screen</span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <h3 class="title is-5">Activation</h3>

        <b-field>
          <template slot="label">
            activation confirmation
            <VTooltipIconHelp
              text="shows an additional promt to confirm the activation of an ECHO Code to confirm the changes."
            />
          </template>
          <b-switch v-model="backendConfig.activation.confirmActivation" :rounded="false" />
        </b-field>

        <b-field>
          <template slot="label">
            update confirmation
            <VTooltipIconHelp
              text="shows an additional promt to confirm the update of an ECHO Code to confirm the changes."
            />
          </template>
          <b-switch v-model="backendConfig.activation.confirmUpdate" :rounded="false" />
        </b-field>
      </div>
    </div>

    <hr />

    <VCrudControl
      hide-remove
      save-button-text="Update"
      :is-saving="isLoading"
      @cancel="loadData"
      @save="$save"
    />

    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp,
  faChevronRight,
  faAngleRight,
  faAngleLeft,
  faMars,
  faVenus,
  faChevronDown,
  faChevronUp
} from '@fortawesome/free-solid-svg-icons'


import AsidManager from '@/database/asidManager'

import VRecordMeta from '@/components/VRecordMeta.vue'
import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'

import BackendConfigManager from '@/database/backendConfigManager'
import { BackendConfigDB } from '@/types/typeBackendConfig'
import databaseSchema, { defaultCodeConfig } from '@/database/databaseSchema'
import VFormDataDefinition from '@/components/VFormDataDefinition.vue'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { CategoryID } from '@/types/typeCategory'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import VFormCategoryEntryDefinition from '@/components/VFormCategoryEntryDefinition.vue'
import VInputMultiCategoryEntry from '@/components/VInputMultiCategoryEntry.vue'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'

library.add(faArrowUp, faChevronRight, faAngleRight, faAngleLeft, faMars, faVenus, faChevronUp, faChevronDown)

interface Workflow {
  title: string
  description: string
  categories: CategoryID[]
  steps: WorkflowStep[]
  completeStep: {
    title: string
    description: string

    setCategory: CategoryID
  }
}

interface WorkflowStep {
  title: string
  description: string
  showWithPreviousStep: boolean
  order: number
  action: {
    type: 'workflowStepType_setCategory' | 'workflowStepType_setAttribute' | 'workflowStepType_setIdentifier'
    mandatory: boolean
    value: string // references the id of the dataDefiniton. e.g. i1 for identifier 1, a5 for attribute 5
  }
}

@Component({
  components: {
    SlickList,
    SlickItem,
    VRecordMeta,
    VFormConfigureSticker,
    VFormDataDefinition,
    VInputMultiCategorySelection,
    VFormCategoryEntryDefinition,
    VInputMultiCategoryEntry
  },
  directives: {
    handle: HandleDirective
  }
})
export default class BackendConfigAsid extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public testCatSelection: CategoryID[] = ['featureExt2Id', 'featureInt3Id']

  public isOpen = -1

  public AsidManager = AsidManager
  // public formSampleAsid = AsidManager.createID('a')


  // #region workflows
  /**
   * Workflows can be created to guide the user through the activation and assignement process
   * each workflow has a name and a description
   * a workflow consists of on or multiple steps
   * a workflow can be assigned to a category
   *  each step has a name and a description
   *  each step can have different actions:
   *  - set a category
   *  - set an attribute
   *  - set an identifier
   *
   * at the end of the workflow it is possible to change the categories to advance the process step
   */

  public formWorkflows: Workflow[] = [
    {
      title: 'Default',
      description: 'Default workflow',
      categories: [],
      steps: [
        {
          title: 'Set category',
          description: 'Set category',
          showWithPreviousStep: false,
          order: 0,
          action: {
            type: 'workflowStepType_setCategory',
            mandatory: true,
            value: ''
          }
        }
      ],
      completeStep: {
        title: 'Complete',
        description: 'Complete',
        setCategory: ''
      }
    },
    {
      title: 'Sample Workflow 2 Title',
      description: 'Sample Workflow 2 Description',
      categories: [],
      steps: [
        {
          title: 'Step 1',
          description: 'Step 1',
          showWithPreviousStep: false,
          order: 0,
          action: {
            type: 'workflowStepType_setCategory',
            mandatory: true,
            value: ''
          }
        },
        {
          title: 'Step 2',
          description: 'Step 2',
          showWithPreviousStep: false,
          order: 1,
          action: {
            type: 'workflowStepType_setCategory',
            mandatory: true,
            value: ''
          }
        }
      ],
      completeStep: {
        title: 'Complete',
        description: 'Complete',
        setCategory: ''
      }
    }
  ]


  public onClickAddWorkflow() {
    this.formWorkflows.push({
      title: 'New workflow',
      description: 'New workflow',
      categories: [],
      steps: [
        {
          title: 'New step',
          description: 'New step',
          showWithPreviousStep: false,
          order: 0,
          action: {
            type: 'workflowStepType_setCategory',
            mandatory: true,
            value: ''
          }
        }
      ],
      completeStep: {
        title: 'Complete',
        description: 'Complete',
        setCategory: ''
      }
    })
  }

  public onAddWorkflowStep(workflowIndex: number) {
    const workflow = this.formWorkflows[workflowIndex]
    workflow.steps.push({
      title: 'New step',
      description: 'New step',
      showWithPreviousStep: false,
      order: workflow.steps.length,
      action: {
        type: 'workflowStepType_setCategory',
        mandatory: true,
        value: ''
      }
    })
  }

  public ACTION_NAME_MAP = {
    workflowStepType_setCategory: 'Set category',
    workflowStepType_setAttribute: 'Set attribute',
    workflowStepType_setIdentifier: 'Set identifier'
  }

  public getHumanReadableActionValue(actionType: string, actionValue: string) {
    if (actionType === 'workflowStepType_setCategory') {
      return this.backendConfig.asid.categoryDefinitions[actionValue as keyof typeof this.backendConfig.asid.categoryDefinitions].title
    } else if (actionType === 'workflowStepType_setAttribute') {
      return this.backendConfig.asid.assetAttributeDefinitions[actionValue as keyof typeof this.backendConfig.asid.assetAttributeDefinitions].title
    } else if (actionType === 'workflowStepType_setIdentifier') {
      return this.backendConfig.asid.identifierDefinition[actionValue as keyof typeof this.backendConfig.asid.identifierDefinition].title
    }
  }

  // #endregion workflows

  // public formPreviewWidth = false
  public formSelectedCodeConfigIndex = 0
  // public formSvgText = ''

  public backendConfig: BackendConfigDB = { ...BackendConfigManager.defaultDocDB }

  public get documentPath() {
    return BackendConfigManager.getDbDocReference(this.$auth.tenant.id).path
  }

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__PRIVILEGES__
  }

  // #region code config
  public onAddCodeConfig() {
    const hightestID = this.backendConfig.codes.reduce((acc, code) => {
      return Math.max(acc, parseInt(code.id))
    }, 0)
    this.backendConfig.codes.push({ ...defaultCodeConfig, id: String(hightestID + 1) })
  }

  public onRemoveCodeConfig() {
    if (this.backendConfig.codes.length === 1) return

    const removeIndex = this.formSelectedCodeConfigIndex
    this.backendConfig.codes.splice(removeIndex, 1)
  }

  @Watch('backendConfig.codes', { immediate: true })
  private onBackendConfigUpdate() {
    this.formSelectedCodeConfigIndex = this.backendConfig.codes.length - 1
  }

  // #endregion code config

  // @Watch('formSelectedCodeConfigIndex')
  // private async onSelectedTemplateChanged() {
  //   if (this.formSelectedCodeConfigID === 'Default') {
  //     this.formSvgText = ''
  //     return
  //   }

  //   // eslint-disable-next-line @typescript-eslint/no-var-requires
  //   const template = await require('!!raw-loader!@/assets/echoCodeTemplates/' + this.formSelectedCodeConfigID + '.svg')
  //   if (!template) {
  //     this.$helpers.notification.Error(`template ${this.formSelectedCodeConfigID} not found`)
  //   } else {
  //     this.formSvgText = template.default
  //   }
  // }


  // #region identifier

  public isLoading = false

  public async $save() {
    this.isLoading = true

    try {
      // try to save the identifier definition
      const identifierDefSaveError = (this.$refs['identifier-definition'] as VFormDataDefinition).$externalSave()
      if (identifierDefSaveError) {
        throw 'Error in identifier definition: ' + identifierDefSaveError
      }
      // try to save the asset attribute definition
      const assetAttributeDefSaveError = (this.$refs['asset-attribute-definition'] as VFormDataDefinition).$externalSave()
      if (assetAttributeDefSaveError) {
        throw 'Error in attribute definition: ' + assetAttributeDefSaveError
      }
      // try to save category definition
      const categoryDefSaveError = (this.$refs['category-definition'] as VFormCategoryEntryDefinition).$externalSave()
      if (categoryDefSaveError) {
        throw 'Error in category definition: ' + categoryDefSaveError
      }

      const backendConfigClone = cloneObject(this.backendConfig)

      await BackendConfigManager.update(this.$auth.tenant.id, this.$auth.userEmail, backendConfigClone)
      this.$helpers.notification.Success('identifierDefinitions updated & asset attibutes updated')
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
      return false
    } finally {
      this.isLoading = false
    }
  }


  // #endregion identifier


  public async loadData() {
    this.isLoading = true

    await this.$bindSnapshot('backendConfig', BackendConfigManager.getDbDocReference(this.$auth.tenant.id))

    this.isLoading = false
  }


  public async created() {
    await this.loadData()
  }
}
</script>

<style lang="scss">
.steps-container {
  background: #eaeaea;
  padding: 1rem;
  margin-top: 1rem;
  border-radius: 0.5rem;

  .workflow-step-wrapper.box {
    padding: 0;

    &.show-with-previous-step {
      margin-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    &.show-with-next-step {
      margin-bottom: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.config-asid {
  .table-wrapper {
    overflow: visible;
  }
}

.identifier-field {
  margin-bottom: 1em;

  .handle {
    cursor: row-resize;
  }
}

.level.record-meta {
  margin-top: -1em;
}

.echo-code-container {
  max-width: 100%;
  margin: auto;
  width: 14em;
  padding: 1em 0;
}
</style>
