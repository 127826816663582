import { RouteConfig, RawLocation } from 'vue-router'
import { ModuleType, ElementID } from '@/modules/typeModules'
import BaseModule from '@/modules/baseModule'

import { FormModuleDB, FormElementDB, FormGroupDB, SurveyJs, RequiredFormPrivileges, FormModuleAppData } from './typeFormModule'

import { defaultFormModuleDB, defaultFormGroupDB, defaultFormElementDB } from '@/database/databaseSchema'
import { TenantID } from '@/types/typeTenant'
import { DeepPartial } from '@/types/typeGeneral'
import { UserPrivilegeIdDB } from '@/types/typeUser'
import { functions } from '@/firebase'
import { RPCRequestgBackendGetAppDataForReference, RPCResponseBackendGetAppDataForReference } from '@/types/typeRPC'
import { asidID } from '@/types/typeAsid'

export default class FormModule extends BaseModule {
  public static type: ModuleType = 'Form'
  public static displayName = 'Form'
  public static color = '#9f60ec'
  public static description = 'Gather user feedback.'
  public static descriptionLong
    = 'Create and manage forms such as surveys, contact or service request forms. Two types of forms are available: Widget and Service Forms. Widgets are forms that can be added to the App directly. Service Forms are forms that can be used in the context of the service module.'

  public static defaultGroupTitle = 'Take Form'

  public static authPrivileges: RequiredFormPrivileges = {
    r: ['form:read'],
    w: ['form:write'],
    view: ['form:view']
  }

  public static moduleDB: FormModuleDB = defaultFormModuleDB
  public static groupDB: FormGroupDB = defaultFormGroupDB
  public static elementDB: FormElementDB = defaultFormElementDB

  constructor() {
    super()
  }

  /**
   * updates the questions as an complete object and not converting the keys to strings, to allow for field removal >>
   * >> flattening automatically stops at arrays, so we can still flatten, as we replace the complete array
   * returns element id
   *
   * @param tenantId
   * @param elementId
   * @param questionData
   */
  public static async updateFormQuestionsOnElement(
    tenantId: TenantID,
    authEmail: string,
    elementId: ElementID,
    questionData: SurveyJs.Page[],
    triggers: SurveyJs.Trigger[] = [],
    formDB: DeepPartial<FormElementDB> = {}
  ) {
    await this.updateElement<FormElementDB>(tenantId, authEmail, elementId, {
      public: { ...formDB.public, questions: questionData, triggers: triggers }
    }, true)
    return elementId
  }

  public static async getAppDataForReference(tenantID: TenantID, asidID: asidID) {
    const RPCRequestTranslationLocales: RPCRequestgBackendGetAppDataForReference = { tenantID, moduleTypes: ['Form'], asidID }

    const backendGetAppDataForReferenceRPC = functions.httpsCallable('backendGetAppDataForReferenceRPC')
    const rpcResult = await backendGetAppDataForReferenceRPC(RPCRequestTranslationLocales)

    const response = rpcResult.data as unknown as RPCResponseBackendGetAppDataForReference

    return response.modulesAppData as FormModuleAppData[]
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      // {
      //   to: { name: 'module-form-single', params: { id: 'new' } },
      //   displayName: 'new Form'
      // },
      {
        to: { name: this.routeNameList },
        displayName: this.displayName
      },
      {
        to: { name: 'module-form-response-list-explore' },
        displayName: 'Responses'
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/forms',
        name: this.routeNameList,
        component: () => import('./Backend_Forms_List.vue'),
        meta: {
          label: this.displayName,
          description: 'Manage your Forms',
          breadcrumbs: false,
          isFullsize: true
        },
        children: [
          {
            path: 'groups/:groupID',
            name: this.routeNameGroup,
            component: () => import('./Backend_Forms_Group_Single.vue'),
            meta: {
              label: 'Form Group',
              description: 'Manage your Form',
              isFullsize: true
            },
            props: (route) => ({ ...route.query, ...route.params }) // also applies query params as props
          },
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Forms_Single.vue'),
            meta: {
              label: 'Form',
              description: 'Manage your Form',
              isFullsize: true
            },
            props: true
          }
        ]
      },
      {
        path: 'module/form-responses/:elementID',
        name: 'module-form-response-single',
        component: () => import('./Backend_Forms_Response_List.vue'),
        meta: {
          label: 'Form Responses',
          description: 'Manage your Responses'
          // isFullsize: true
        },
        props: true
      },
      {
        path: 'module/form-responses',
        component: () => import('./Backend_Forms_Response_List.vue'),
        meta: {
          label: 'Form Responses',
          description: 'Manage your Responses'
          // isFullsize: true
        },
        children: [
          {
            path: '/',
            name: 'module-form-response-list-explore',
            component: () => import('./Backend_Forms_Response_List_Explore.vue'),
            meta: {
              label: 'Form Response Explore',
              description: 'Filter and Explore Form Response Data',
              helpSection: 'FormResponse'
              // isFullsize: true
            }
            // props: true
          },
          {
            path: 'analyze',
            name: 'module-form-response-list-analyze',
            component: () => import('./Backend_Forms_Response_List_Analyze.vue'),
            meta: {
              label: 'Form Response Analyze',
              description: 'Filter and Explore Form Response Data'
              // isFullsize: true
            }
            // props: true
          },
          {
            path: 'analyze-advanced',
            name: 'module-form-response-list-analyze-advanced',
            component: () => import('./Backend_Forms_Response_List_AnalyzeAdvanced.vue'),
            meta: {
              label: 'Form Response Analyze',
              description: 'Filter and Explore Form Response Data'
              // isFullsize: true
            }
            // props: true
          }
        ]
      }
    ]
  }
}
