<template>
  <b-button
    title="Data is being updated automatically"
    :icon-left="liveUpdate ? '' : 'pause'"
    @click="liveUpdate = !liveUpdate"
  >
    <span :class="liveUpdate ? 'blob blue' : ''" />
    {{ liveUpdate ? 'live update' : 'continue live update' }}
  </b-button>
</template>

<script lang="ts">
import { Component, ModelSync, Vue } from 'vue-property-decorator'


@Component({})
export default class VButtonToggleLiveUpdate extends Vue {
  @ModelSync('value', 'input', { type: Boolean })
  public liveUpdate!: boolean
}
</script>
<style lang="scss">
.blob {
  background: black;
  display: inline-block;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(0 0 0 / 100%);
  margin-right: 0.3em;
  height: 0.7em;
  width: 0.7em;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 70%);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgb(0 0 0 / 0%);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(0 0 0 / 0%);
  }
}

.blob.white {
  background: white;
  box-shadow: 0 0 0 0 rgb(255 255 255 / 100%);
  animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(255 255 255 / 70%);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgb(255 255 255 / 0%);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(255 255 255 / 0%);
  }
}

.blob.red {
  background: rgb(255 82 82 / 100%);
  box-shadow: 0 0 0 0 rgb(255 82 82 / 100%);
  animation: pulse-red 2s infinite;
}

.blob.blue {
  background: rgb(52 172 224 / 100%);
  box-shadow: 0 0 0 0 rgb(52 172 224 / 100%);
  animation: pulse-blue 2s infinite;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(52 172 224 / 70%);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgb(52 172 224 / 0%);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(52 172 224 / 0%);
  }
}

.blob.green {
  background: rgb(51 217 178 / 100%);
  box-shadow: 0 0 0 0 rgb(51 217 178 / 100%);
  animation: pulse-green 2s infinite;
}
</style>
