import BaseManager from './baseManager'
import databaseSchema from './databaseSchema'
import { AppSessionDB } from '@/types/typeAppSession'
import db from '@/firebase'
import { TenantID } from '@/types/typeTenant'

export default class SessionManager extends BaseManager {
  public static defaultDocDB: AppSessionDB = databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.__EMPTY_DOC__

  public static getStatisticsDbDocReference(tenantID: TenantID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.__DOCUMENT_PATH__(tenantID))
  }

  public static getDbCollectionReference(tenantID: TenantID) {
    return db.collection(databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.SESSIONS.__COLLECTION_PATH__(tenantID))
  }
}
