<template>
  <!-- <b-tooltip
    type="is-dark"
    multilined
    :label="Object.keys($props.element.public).map((k)=>`${k}: ${$props.element.public[k]}`).join('\n')"
    animated
  >-->
  <router-link :to="$props.getLinkToElement($props.element)">
    <b-tag
      :style="{background: $props.getColorForType($props.element.type), color:'white'}"
      :title="`${$props.getTitleForType($props.element.type)} Module: ${$props.element.name}`"
    >{{ $props.element.name }}</b-tag>
  </router-link>

  <!-- </b-tooltip> -->
</template>

<script lang="ts">
import { hasDBid } from '@/types/typeGeneral'
import { ModuleManager } from '../modules/moduleManager'
import { ModuleType, BaseElementDB } from '../modules/typeModules'

export default {
  props: {
    element: {
      type: Object, // Module Element with id and type
      required: true
    },
    getColorForType: {
      type: Function,
      default(type: ModuleType) {
        return ModuleManager.getModuleClassByType(type).color
      }
    },
    getLinkToElement: {
      type: Function,
      default(mod: (BaseElementDB & hasDBid & { type: ModuleType })) {
        const name = ModuleManager.getModuleClassByType(mod.type).routeNameElement
        return { name, params: { id: mod.id } }
        // return 4
      }
    },
    getTitleForType: {
      type: Function,
      default(type: ModuleType) {
        return ModuleManager.getModuleClassByType(type).displayName
      }
    }
  }
}
</script>

<style lang="scss">
</style>
