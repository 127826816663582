<template>
  <section class>
    <div class="level">
      <b-field class="level-left" />
      <b-field grouped>
        <b-field>
          <VRecordMeta position="is-bottom-left" :required-privileges="documentPrivileges" />
        </b-field>
      </b-field>
    </div>
    <h2 class="title is-4">Widget Modules</h2>
    <div
      class="subtitle"
    >Using Widget Modules you create the content of your App. Widgets appear in the App when a user scans an ECHO Code.</div>
    <div class="module-list-container">
      <div
        v-for="mod in widgetModules"
        :key="mod.type"
        :style="getTileStyle(mod)"
        class="box module-box"
        :class="{ 'module-inactive': !isModuleActive(mod) }"
      >
        <div class="content-wrapper">
          <div v-if="isModuleActive(mod)">
            <router-link :to="{name: mod.getRoutes()[0].name}">
              <h1 class="title is-5" :style="getTileColor(mod)">{{ mod.displayName }}</h1>
              <div class="description" :style="getTileColor(mod)">{{ mod.descriptionLong }}</div>
            </router-link>
          </div>
          <div v-else @click="showModuleDisabledDialog(mod)">
            <h1 class="title is-5" :style="getTileColor(mod)">{{ mod.displayName }}
              <b-tag>Not Active</b-tag>
            </h1>
            <div class="description" :style="getTileColor(mod)">{{ mod.descriptionLong }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="box module">add new</div> -->
    </div>
    <div class="spacer" />
    <h2 class="title is-4">Global Modules</h2>
    <div class="subtitle">Provide global functionality across the App. Those Modules are not bound to Widgets.</div>
    <div class="module-list-container">
      <div
        v-for="mod in nonWidgetModules"
        :key="mod.type"
        :style="getTileStyle(mod)"
        class="box module-box"
      >
        <router-link :to="{name: mod.getRoutes()[0].name}">
          <h1 class="title is-5" :style="getTileColor(mod)">{{ mod.displayName }}</h1>
          <div class="description" :style="getTileColor(mod)">{{ mod.descriptionLong }}</div>
        </router-link>
      </div>
      <!-- <div class="box module">add new</div> -->
    </div>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import { ModuleClass, ModuleManager } from '@/modules/moduleManager'
import color from 'color'
import BaseModule from '@/modules/baseModule'
import VRecordMeta from '@/components/VRecordMeta.vue'


import { RequiredDocPrivileges } from '@/types/typeRequiredPrivileges'

@Component({
  components: {
    VRecordMeta
  }
})
export default class BackendModulesList extends Vue {
  public activeModules: typeof ModuleManager.moduleClasses = []


  public isLoading = false
  public allModules: ModuleClass[] = []

  // #region RecordMeta
  get documentPrivileges(): RequiredDocPrivileges {
    return { r: this.activeModules.flatMap((m) => m.authPrivileges.r), w: [] }
  }
  // #endregion RecordMeta

  public getTileStyle(module: typeof BaseModule) {
    return {
      backgroundImage: `linear-gradient( 155deg, ${module.color} 10%, ${color(module.color).rotate(-20).lighten(0.1)} 100%)`,
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  public getTileColor(module: typeof BaseModule) {
    return {
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  get widgetModules() {
    return this.allModules.filter((M) => M.hasWidget)
  }

  get nonWidgetModules() {
    return this.allModules.filter((M) => !M.hasWidget)
  }

  public isModuleActive(module: typeof BaseModule) {
    return this.activeModules.some((m) => m.type === module.type)
  }

  public showModuleDisabledDialog(mod: typeof BaseModule) {
    this.$buefy.dialog.confirm({
      title: `Module "${mod.displayName}" is not Enabled`,
      message: `Please contact ECHO PRM to get more information regarding the "${mod.displayName}" Module.`,
      type: 'is-info',
      hasIcon: true,
      canCancel: true,
      confirmText: 'Contact ECHO PRM',
      onConfirm: () => {
        window.location.href = `mailto:info@echoprm.com?subject=Activate Module ${mod.displayName}`
      }
    })
  }

  private getActivatedModulesSnapshotHandle: (() => void) | undefined
  public async created() {
    this.isLoading = true

    this.allModules = ModuleManager.moduleClasses

    this.getActivatedModulesSnapshotHandle = ModuleManager.onSnapshotActivatedModuleClasses(this.$auth.tenant.id, undefined, (modules) => {
      console.log('ouou')
      this.activeModules = modules
      this.isLoading = false
    }, (e) => { /** */ }, 'sideMenu')
  }

  beforeDestroy() {
    console.info('beforeDestroy')
    this.getActivatedModulesSnapshotHandle && this.getActivatedModulesSnapshotHandle()
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.spacer {
  margin: 4em;
}

.module-list-container {
  display: flex;
  flex-wrap: wrap;

  .box.module-box {
    display: flex;
    margin: 0.5em;
    // height: 8em;
    width: calc(100% - 1em);

    .content-wrapper {
      cursor: pointer;
    }

    &.module-inactive {
      filter: grayscale(0.7);
    }

    .title {
      margin-top: 0;
      font-weight: 600;
    }

    @include tablet {
      width: calc(50% - 1em);
    }

    a {
      width: 100%;
    }
  }
}
</style>
