<template>
  <b-modal :active.sync="isModalRequestAsidCodesActive" :width="640" scroll="keep">
    <div class="modal-card changelog-diff" style="width: auto;">
      <header class="modal-card-head">
        <p class="modal-card-title">Request Codes</p>
        <button type="button" class="delete" @click="isModalRequestAsidCodesActive = false" />
      </header>
      <section class="modal-card-body">
        <b-steps v-model="activeStep" :animated="true" :rounded="true" :has-navigation="true">
          <b-step-item step="1" label="Quantity">
            <h1 class="title has-text-centered">Quantity</h1>

            <b-field label="How many ECHO Codes do you want to order">
              <b-numberinput v-model="formQuantity" placeholder="100" :min="0" step="1" />
            </b-field>
          </b-step-item>

          <b-step-item step="2" label="Sticker">
            <h1 class="title has-text-centered">Configure Sticker Template</h1>
            <b-field>
              <template slot="label">
                Select Template Preset
                <VTooltipIconHelp text="Select the template to use for your stickers" />
              </template>
              <b-select v-model="formSelectedCodeConfig" placeholder="Default">
                <option
                  v-for="code in formBackendConfig.codes"
                  :key="code.id"
                  :value="code"
                >Template {{ code.id }}</option>

                <!-- <option v-for="codeConfig in backendConfig.codes">SVGTemplate5</option> -->
              </b-select>
            </b-field>
            <VFormConfigureSticker v-model="formSelectedCodeConfig" />
          </b-step-item>

          <b-step-item step="3" label="Summary">
            <h1 class="title has-text-centered">Confirm</h1>
            <div>
              <div class="label">Numer of ECHO codes & Stickers: {{ formQuantity }}</div>
              <div class="label">Sticker Template: {{ formSelectedCodeConfig.svgTemplateUrl }}</div>
              <div
                class="label"
              >ECHO Code Error correction Level: {{ formSelectedCodeConfig.errorCorrectionLevel }}</div>
              <div class="label">ECHO Code Base Url: {{ formBackendConfig.asid.baseUrl }}</div>
              <div class="label">ECHO customText: {{ formSelectedCodeConfig.customText }}</div>
              <div class="label">ECHO width: {{ formSelectedCodeConfig.width }}mm</div>
              <div class="label">ECHO height: {{ formSelectedCodeConfig.height }}mm</div>
            </div>
          </b-step-item>

          <b-step-item step="4" label="Finish" :type="{'is-success': true}">
            <h1 class="title has-text-centered">Finish</h1>Yur request has been sent.
          </b-step-item>
        </b-steps>
      </section>
      <!-- <footer class="modal-card-foot">
          <button class="button" type="button" @click="isModalRequestAsidCodesActive = false">Close</button>
      </footer>-->
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Component, Vue, Watch, PropSync, Prop } from 'vue-property-decorator'

import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'


import BackendConfigManager from '@/database/backendConfigManager'

import { BackendConfigDB } from '@/types/typeBackendConfig'

library.add(faCalendarDay)

@Component({
  components: { VFormConfigureSticker, VRecordMeta },
  props: {}
})
export default class VModalCreateAsidCodes extends Vue {
  public activeStep = 0


  @PropSync('active', { type: Boolean, required: true, default: () => false })
  public isModalRequestAsidCodesActive!: boolean

  @Watch('isModalRequestAsidCodesActive')
  private onTenantDocUpdate() {
    if (!this.isModalRequestAsidCodesActive) this.activeStep = 0
  }

  // @Prop({ type: Object, required: true })
  // readonly tenant!: TenantDB & hasDBid

  @Prop({ type: Object, required: true })
  readonly backendConfig!: BackendConfigDB

  public formBackendConfig!: BackendConfigDB

  @Watch('backendConfig', { immediate: true })
  private onBackendConfigUpdate() {
    this.formBackendConfig = this.backendConfig
    this.formSelectedCodeConfig = this.backendConfig.codes[0]
  }


  public formQuantity = 0

  public formSelectedCodeConfig = { ...BackendConfigManager.defaultDocDB.codes[0] }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

</style>
