<template>
  <section>
    <section v-if="!isLoading" class="asid-selected">
      <section class="responses">
        <section v-for="(rspsPerDate, date) in allResponsesGrouped" :key="date">
          <div class="date">
            <b-tag>{{ date }}</b-tag>
          </div>
          <section
            v-for="(rspsPerSession, session) in rspsPerDate"
            :key="session"
            class="box session"
          >
            <div class="level">
              <span class="label level-left">
                <span
                  v-if="showPublishingState"
                  class="publishing-state-indicator"
                  :class="rspsPerSession[0].publishingState"
                  :title="`Publishing state: ${rspsPerSession[0].publishingState}`"
                />
                Session
                <small>&nbsp;({{ session }})</small>
                <!-- langiages of the session -->

                <template v-for="locale in getLocalesPerSession(rspsPerSession)">
                  <b-tag
                    v-if="locale"
                    :key="locale"
                    class="is-small language-tag"
                    :title="`Responses in this sessoion are in languages ${getLocaleName(locale)}`"
                  >{{ getLocaleName(locale) }}</b-tag>
                </template>
              </span>

              <b-field class="level-left">
                <b-field class="quick-menu">
                  <b-button
                    outlined
                    type="is-danger"
                    title="Remove all responses for this session"
                    icon-right="trash"
                    class="delete-session"
                    @click="onRemoveSession(session)"
                  />
                </b-field>

                <b-dropdown class="setting-dropdown" position="is-bottom-left">
                  <template #trigger>
                    <b-button icon-left="ellipsis-h" title="More options" />
                  </template>

                  <b-dropdown-item @click="onRemoveSession(session)">Remove Responses</b-dropdown-item>

                  <b-dropdown-item
                    v-if="showPublishingState && rspsPerSession[0].publishingState !== 'published'"
                    @click="onPublishSession(session)"
                  >Publish Responses</b-dropdown-item>

                  <b-dropdown-item
                    v-if="showPublishingState && rspsPerSession[0].publishingState !== 'archived'"
                    @click="onArchiveSession(session)"
                  >Archive Responses</b-dropdown-item>
                </b-dropdown>
              </b-field>
            </div>
            <span v-if="showAsidInfo">
              ECHO Code:
              <router-link
                :to="{ name: 'asid-single', params: { asid: getAsidForSession(session), tab: 1 } }"
              >
                <b-tag class="is-small" title="got to ECHO Code" icon-right="edit">
                  <span style="font-family: monospace;">{{ getAsidForSession(session) }}</span>
                </b-tag>
              </router-link>
            </span>
            <!--
            <b-table :data="rspsPerSession" :narrowed="true">
              <template slot-scope="props">
                <b-table-column field="type" label="Type">
                  <span
                    :style="{borderLeftColor: `${getModuleColor(props.row.type)}`, padding:'0.3em'}"
                  >{{ props.row.type }}</span>
                </b-table-column>
                <b-table-column
                  field="action"
                  label="Action"
                >{{ (props.row.type === 'file') ? 'View/Download': (props.row.type === 'form')?'respond':'' }}</b-table-column>
                <b-table-column field="value" label="Value">
                  <span
                    v-if="props.row.type === 'file'"
                  >{{ (elementsFile[props.row.elementID])?elementsFile[props.row.elementID].name:'' }}</span>
                </b-table-column>
              </template>
            </b-table>-->

            <section class="b-table">
              <table class="table is-narrow is-fullwidth">
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Prop/Action</th>

                    <th>Value</th>
                    <th>
                      Ack
                      <VTooltipIconHelp
                        :append-to-body="false"
                        text="Acknowledge is a property that does not have a predefined meaning. It is supposed to be used as part of your internal processes"
                      />
                    </th>
                    <th>
                      Note
                      <VTooltipIconHelp
                        :append-to-body="false"
                        position="is-left"
                        text="Note is a property that does not have a predefined meaning. It is supposed to be used as part of your internal processes"
                      />
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <template v-for="(rsp, i) in rspsPerSession">
                    <template v-if="rsp.type === 'File'">
                      <tr :key="i">
                        <td>
                          <span
                            class="is-capitalized module-name"
                            :style="{borderLeftColor: `${getModuleColor(rsp.type)}`}"
                          >
                            <router-link
                              v-if="elementsByID[rsp.public.elementID]"
                              :to="getLinkToElement(rsp.public.elementID)"
                            >{{ (elementsByID[rsp.public.elementID]) ? elementsByID[rsp.public.elementID].name : '' }} -</router-link>
                            <span v-else>
                              Element not found
                              <br />
                              <small>(may have been deleted)</small>
                            </span>
                            <small>&nbsp;{{ rsp.type }}</small>
                          </span>
                        </td>
                        <td>download/view</td>
                        <td>
                          <span>{{ ('title' in elementsByID[rsp.public.elementID].public) ? $i18n.getLocalString(elementsByID[rsp.public.elementID].public.title):'' }}</span>
                        </td>
                        <td>
                          <b-checkbox
                            v-model="rsp.backendData.acknowledge"
                            @input="(v) => onChangeAcknowledge(rsp.id, v)"
                          />
                        </td>
                        <td>
                          <span>
                            {{ rsp.backendData.note }}
                            <b-button
                              class="is-small"
                              title="Edit Note"
                              icon-right="edit"
                              @click="onClickEditNote(rsp.id)"
                            />
                          </span>
                        </td>
                      </tr>
                    </template>

                    <template v-if="rsp.type === 'Protection'">
                      <tr :key="i">
                        <td>
                          <span
                            class="is-capitalized module-name"
                            :style="{borderLeftColor: `${getModuleColor(rsp.type)}`}"
                          >
                            <router-link
                              v-if="elementsByID[rsp.public.elementID]"
                              :to="getLinkToElement(rsp.public.elementID)"
                            >{{ (elementsByID[rsp.public.elementID]) ? elementsByID[rsp.public.elementID].name : '' }} -</router-link>
                            <span v-else>
                              Element not found
                              <br />
                              <small>(may have been deleted)</small>
                            </span>
                            <small>&nbsp;{{ rsp.type }}</small>
                          </span>
                        </td>
                        <td>unlock {{ rsp.public.data.unlockedGroup.state === 'failed' ? 'failed' : 'successful' }}</td>
                        <td>{{ rsp.public.data.unlockedGroup.type }} - {{ rsp.public.data.unlockedGroup.name }}</td>
                        <td>
                          <b-checkbox
                            v-model="rsp.backendData.acknowledge"
                            @input="(v) => onChangeAcknowledge(rsp.id, v)"
                          />
                        </td>
                        <td>
                          <span>
                            {{ rsp.backendData.note }}
                            <b-button
                              class="is-small"
                              title="Edit Note"
                              icon-right="edit"
                              @click="onClickEditNote(rsp.id)"
                            />
                          </span>
                        </td>
                      </tr>
                    </template>

                    <template v-if="rsp.type === 'Session'">
                      <tr :key="i">
                        <td>
                          <span class="is-capitalized module-name">
                            <span>
                              Visit
                              <br />
                              <!-- <small>(may have been deleted)</small> -->
                            </span>
                          </span>
                        </td>
                        <td>visit</td>
                        <td>--</td>
                        <td>--</td>
                        <td>
                          <span />
                        </td>
                      </tr>
                    </template>

                    <template v-else-if="rsp.type === 'Script'">
                      <template
                        v-for="(scriptValue,scriptKey,scriptPropIndex) in (filterEmpty(rsp.public.data) || {})"
                      >
                        <tr v-if="scriptValue" :key="i+'_'+scriptKey">
                          <td
                            v-if="scriptPropIndex===0"
                            :rowspan="Object.values(rsp.public.data).filter(d=>d).length"
                          >
                            <span
                              class="is-capitalized module-name"
                              :style="{borderLeftColor: `${getModuleColor(rsp.type)}`}"
                            >
                              <router-link
                                v-if="elementsByID[rsp.public.elementID]"
                                :to="getLinkToElement(rsp.public.elementID)"
                              >{{ (elementsByID[rsp.public.elementID]) ? elementsByID[rsp.public.elementID].name : '' }} -</router-link>
                              <span v-else>
                                Element not found
                                <br />
                                <small>(may have been deleted)</small>
                              </span>
                              <small>&nbsp;{{ rsp.type }}</small>
                            </span>
                          </td>
                          <td>{{ elementsByID[rsp.public.elementID] && elementsByID[rsp.public.elementID].dataDefinition[scriptKey].name }}</td>
                          <td>{{ scriptValue }}</td>
                          <td
                            v-if="scriptPropIndex === 0"
                            :rowspan="Object.values(rsp.public.data).filter(d=>d).length"
                          >
                            <b-checkbox
                              v-model="rsp.backendData.acknowledge"
                              @input="(v) => onChangeAcknowledge(rsp.id, v)"
                            />
                          </td>
                          <td
                            v-if="scriptPropIndex === 0"
                            :rowspan="Object.values(rsp.public.data).filter(d=>d).length"
                          >
                            <span>
                              {{ rsp.backendData.note }}
                              <b-button
                                title="Edit Note"
                                class="is-small"
                                icon-right="edit"
                                @click="onClickEditNote(rsp.id)"
                              />
                            </span>
                          </td>
                        </tr>
                      </template>
                    </template>

                    <template v-else-if="rsp.type === 'Custom'">
                      <template
                        v-for="(customValue,customKey,customPropIndex) in (filterEmpty(rsp.public.data) || {})"
                      >
                        <tr v-if="customValue" :key="i+'_'+customKey">
                          <td
                            v-if="customPropIndex===0"
                            :rowspan="Object.values(rsp.public.data).filter(d=>d).length"
                          >
                            <span
                              class="is-capitalized module-name"
                              :style="{borderLeftColor: `${getModuleColor(rsp.type)}`}"
                            >
                              <router-link
                                v-if="elementsByID[rsp.public.elementID]"
                                :to="getLinkToElement(rsp.public.elementID)"
                              >{{ (elementsByID[rsp.public.elementID]) ? elementsByID[rsp.public.elementID].name : '' }} -</router-link>
                              <span v-else>
                                Element not found
                                <br />
                                <small>(may have been deleted)</small>
                              </span>
                              <small>&nbsp;{{ rsp.type }}</small>
                            </span>
                          </td>
                          <td>{{ elementsByID[rsp.public.elementID] && elementsByID[rsp.public.elementID].dataDefinition[customKey].name }}</td>
                          <td>{{ customValue }}</td>
                          <td
                            v-if="customPropIndex === 0"
                            :rowspan="Object.values(rsp.public.data).filter(d=>d).length"
                          >
                            <b-checkbox
                              v-model="rsp.backendData.acknowledge"
                              @input="(v) => onChangeAcknowledge(rsp.id, v)"
                            />
                          </td>
                          <td
                            v-if="customPropIndex === 0"
                            :rowspan="Object.values(rsp.public.data).filter(d=>d).length"
                          >
                            <span>
                              {{ rsp.backendData.note }}
                              <b-button
                                title="Edit Note"
                                class="is-small"
                                icon-right="edit"
                                @click="onClickEditNote(rsp.id)"
                              />
                            </span>
                          </td>
                        </tr>
                      </template>
                    </template>

                    <template v-if="rsp.type === 'Form'">
                      <VResponseTimelineForm
                        v-if="getPublicDataForElement(rsp.public.elementID)"
                        :key="rsp.id"
                        :rsp="rsp.public"
                        :element-public-data="getPublicDataForElement(rsp.public.elementID)"
                      >
                        <template #columns_before="{questionIndex}">
                          <td
                            v-if="questionIndex === 0"
                            :rowspan="Object.keys(rsp.public.data).length"
                          >
                            <span
                              class="is-capitalized module-name"
                              :style="{borderLeftColor: `${getModuleColor(rsp.type)}`}"
                            >
                              <router-link
                                v-if="elementsByID[rsp.public.elementID]"
                                :to="getLinkToElement(rsp.public.elementID)"
                              >{{ (elementsByID[rsp.public.elementID]) ? elementsByID[rsp.public.elementID].name : '' }} -</router-link>
                              <span v-else>
                                Element not found
                                <br />
                                <small>(may have been deleted)</small>
                              </span>
                              <small>&nbsp;{{ rsp.type }}</small>
                            </span>
                          </td>
                        </template>

                        <template #columns_after="{questionIndex}">
                          <td
                            v-if="questionIndex === 0"
                            :rowspan="Object.keys(rsp.public.data).length"
                          >
                            <b-checkbox
                              v-model="rsp.backendData.acknowledge"
                              @input="(v) => onChangeAcknowledge(rsp.id, v)"
                            />
                          </td>
                          <td
                            v-if="questionIndex === 0"
                            :rowspan="Object.keys(rsp.public.data).length"
                          >
                            <span>
                              {{ rsp.backendData.note }}
                              <b-button
                                title="Edit Note"
                                class="is-small"
                                icon-right="edit"
                                @click="onClickEditNote(rsp.id)"
                              />
                            </span>
                          </td>
                        </template>
                      </VResponseTimelineForm>
                      <!-- <template
                        v-for="(questionAndResponses, questionIndex) in getQuestionsAndResponses(rsp)"
                      >
                        <tr :key="questionIndex+'_'+i">
                          <td v-if="questionIndex === 0" :rowspan="Object.keys(rsp.public.data).length">
                            <span
                              class="is-capitalized module-name"
                              :style="{borderLeftColor: `${getModuleColor(rsp.type)}`}"
                            >
                              <router-link
                                v-if="elementsByID[rsp.public.elementID]"
                                :to="getLinkToElement(rsp.public.elementID)"
                              >{{ (elementsByID[rsp.public.elementID]) ? elementsByID[rsp.public.elementID].name : '' }} -</router-link>
                              <span v-else>
                                Element not found
                                <br />
                                <small>(may have been deleted)</small>
                              </span>
                              <small>&nbsp;{{ rsp.type }}</small>
                            </span>
                          </td>
                          <td>
                            <span>{{ $i18n.getLocalString(questionAndResponses.question.title) }}</span>
                          </td>

                          <td>
                            <template v-if="questionAndResponses.question.type === 'multipletext'">
                              <b-taglist>
                                <b-tag
                                  v-for="(value, key) in FormHelper.getQuestionAndResponsesObj_MultipleText(questionAndResponses.question, questionAndResponses.responses).items"
                                  :key="'k1_'+questionIndex+'_'+i+'_'+key"
                                >{{ $i18n.getLocalString(value.title) }}: {{ (value.responses || []).join('') }}</b-tag>

                                <template
                                  v-for="(comment, key) in FormHelper.getQuestionAndResponsesObj_MultipleText(questionAndResponses.question, questionAndResponses.responses).comments"
                                >
                                  <b-tag
                                    v-if="comment"
                                    :key="'k5_'+questionIndex+'_'+i+'_'+key"
                                    type="is-selected is-light"
                                  >Comment: {{ comment }}</b-tag>
                                </template>
                              </b-taglist>
                            </template>

                            <template v-else-if="questionAndResponses.question.type === 'boolean'">
                              <b-taglist>
                                <template
                                  v-for="(value, key) in FormHelper.getQuestionAndResponsesObj_Boolean(questionAndResponses.question, questionAndResponses.responses).items"
                                >
                                  <b-tag
                                    :key="'k2_'+questionIndex+'_'+i+'_'+key"
                                    :class="(value.responses.length > 0)?'is-selected is-light':'is-option'"
                                  >{{ $i18n.getLocalString(value.title) }}</b-tag>
                                </template>

                                <template
                                  v-for="(comment, key) in FormHelper.getQuestionAndResponsesObj_Boolean(questionAndResponses.question, questionAndResponses.responses).comments"
                                >
                                  <b-tag
                                    v-if="comment"
                                    :key="'k5_'+questionIndex+'_'+i+'_'+key"
                                    type="is-selected is-light"
                                  >Comment: {{ comment }}</b-tag>
                                </template>
                              </b-taglist>
                            </template>

                            <template v-else-if="questionAndResponses.question.type === 'rating'">
                              <b-taglist>
                                <template
                                  v-for="(value, key) in FormHelper.getQuestionAndResponsesObj_Rating(questionAndResponses.question, questionAndResponses.responses).items"
                                >
                                  <b-tag
                                    :key="'k3_'+questionIndex+'_'+i+'_'+key"
                                    :class="(value.responses.length > 0)?'is-selected is-light':'is-option'"
                                  >{{ $i18n.getLocalString(value.title) }}</b-tag>
                                </template>

                                <template
                                  v-for="(comment, key) in FormHelper.getQuestionAndResponsesObj_Rating(questionAndResponses.question, questionAndResponses.responses).comments"
                                >
                                  <b-tag
                                    v-if="comment"
                                    :key="'k5_'+questionIndex+'_'+i+'_'+key"
                                    type="is-selected is-light"
                                  >Comment: {{ comment }}</b-tag>
                                </template>
                              </b-taglist>
                            </template>

                            <template
                              v-else-if="[ 'radiogroup', 'dropdown', 'checkbox'].includes(questionAndResponses.question.type)"
                            >
                              <b-taglist>
                                <template
                                  v-for="(value, key) in FormHelper.getQuestionAndResponsesObj_CheckboxDropdownRadiogroup(questionAndResponses.question, questionAndResponses.responses).items"
                                >
                                  <b-tag
                                    :key="'k4_'+questionIndex+'_'+i+'_'+key"
                                    :class="(value.responses.length > 0)?'is-selected is-light':'is-option'"
                                  >{{ $i18n.getLocalString(value.title) }}</b-tag>
                                </template>

                                <template
                                  v-for="(comment, key) in FormHelper.getQuestionAndResponsesObj_CheckboxDropdownRadiogroup(questionAndResponses.question, questionAndResponses.responses).comments"
                                >
                                  <b-tag
                                    v-if="comment"
                                    :key="'k5_'+questionIndex+'_'+i+'_'+key"
                                    type="is-selected is-light"
                                  >Comment: {{ comment }}</b-tag>
                                </template>
                              </b-taglist>
                            </template>

                            <template v-else-if="questionAndResponses.question.type === 'file'">
                              <p
                                v-for="(file, key) in questionAndResponses.responses[0].data.filter(isNonImageFile)"
                                :key="'k6_'+questionIndex+'_'+i+'_'+key"
                              >
                                <a
                                  :href="file.content"
                                  @click.prevent="onDownloadFile(file)"
                                >{{ file.name }}</a>
                              </p>
                              <hr
                                v-if="questionAndResponses.responses[0].data.some(isNonImageFile) && questionAndResponses.responses[0].data.some(isImageFile)"
                              />
                              <VueGallery
                                :id="'vue_gallery' + rsp.id + '_' + questionIndex + '_' + i"
                                :key="'vue_gallery' + rsp.id + '_' + questionIndex + '_' + i"
                                :images="questionAndResponses.responses[0].data.filter(isImageFile).map(d=>d.content)"
                                :index="activeImageIndices[`${questionIndex}_${rsp.id}`]"
                                @close="activeImageIndices[`${questionIndex}_${rsp.id}`] = null"
                              />
                              <div
                                v-for="(file, key) in questionAndResponses.responses[0].data.filter(isImageFile)"
                                :key="key"
                                class="image"
                                :style="{backgroundImage: 'url(' + file.content + ')', width: '6em', height: '6em'}"
                                @click="!activeImageIndices[`${questionIndex}_${rsp.id}`] ? $set(activeImageIndices, `${questionIndex}_${rsp.id}`, key) : activeImageIndices[`${questionIndex}_${rsp.id}`] = key"
                              />
                            </template>

                            <template v-else-if="questionAndResponses.question.type === 'matrix'">
                              <b-taglist>
                                <b-tag
                                  v-for="([row,column], key) in FormHelper.getQuestionAndResponsesObj_Matrix(questionAndResponses.question, questionAndResponses.responses).items"
                                  :key="'k7_'+questionIndex+'_'+i+'_'+key"
                                >{{ $i18n.getLocalString(row.title) }}:{{ $i18n.getLocalString(column.title) }}</b-tag>
                              </b-taglist>
                            </template>

                            <template
                              v-else-if="questionAndResponses.question.type === 'signaturepad'"
                            >
                              <img
                                style="background: #f9f9f9; max-width: 70%;"
                                :src="questionAndResponses.responses[0].data"
                                alt="signature"
                              />
                            </template>

                            <template v-else>
                              {{ questionAndResponses.responses[0].data }}
                               {{ questionAndResponses.question.type }}
                            </template>
                          </td>

                          <td v-if="questionIndex === 0" :rowspan="Object.keys(rsp.public.data).length">
                            <b-checkbox
                              v-model="rsp.backendData.acknowledge"
                              @input="(v) => onChangeAcknowledge(rsp.id, v)"
                            />
                          </td>
                          <td v-if="questionIndex === 0" :rowspan="Object.keys(rsp.public.data).length">
                            <span>
                              {{ rsp.backendData.note }}
                              <b-button
                                class="is-small"
                                icon-right="edit"
                                @click="onClickEditNote(rsp.id)"
                              />
                            </span>
                          </td>
                        </tr>
                      </template>-->
                    </template>
                  </template>
                </tbody>
              </table>
            </section>
            <!-- <section
              v-for="(rsp, i) in rspsPerSession"
              :key="i"
              class="box"
              :style="{borderLeftColor: `${getModuleColor(rsp.type)}`}"
            >
              <span class="label" style="display:inline">
                <span class="is-capitalized">{{ rsp.type }}</span> Module Response
              </span>
              {{ (elementsByID[rsp.public.elementID])?elementsByID[rsp.public.elementID].name:'' }}

            </section>-->
          </section>
        </section>
      </section>
    </section>

    <b-loading :active="isLoading || isLoadingOverlay" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Watch, Vue, Prop } from 'vue-property-decorator'

import VTooltipIconHelp from '@/components/global/VTooltipIconHelp.vue'
import VueGallery from 'vue-gallery'


import { CategoryCollection } from '@/types/typeCategory'


import { capitalize } from '@/helpers/stringHelper'
import ResponseViewHelper from '@/helpers/responseViewHelper'

import { FormResponseDB } from '@/modules/form/typeFormModule'

import { CustomResponseData, CustomResponseDB } from '@/modules/custom/typeCustomModule'
import { FileResponseDB } from '@/modules/file/typeFileModule'
import { ModuleType, ElementWithTypeAndID, BaseResponseDB, PublishingState } from '@/modules/typeModules'
import { ModuleManager } from '@/modules/moduleManager'
import { ScriptResponseDB } from '@/modules/script/typeScriptModule'

import { ProtectionResponseDB } from '@/modules/protection/typeProtectionModule'
import { hasDBid, objectID } from '@/types/typeGeneral'
import { SessionDB } from '@/types/typeAppSession'
import { downloadBlob } from '@/helpers/downloadHelper'
import VResponseTimelineForm from '@/components/VResponsesTimelineForm.vue'
import { getLocaleName } from '@/helpers/i18nUtil'
import { locale } from '@/types/typeI18n'
import { I18nResponseDB } from '@/modules/i18n/typeI18nModule'

@Component({
  components: {
    VTooltipIconHelp,
    VueGallery,
    VResponseTimelineForm
  },
  props: {
    asid: {
      type: String,
      required: false,
      default: () => ''
    }
  }
})
export default class VResponseTimelineView extends Vue {
  // used for form controls


  public isLoading = false
  public isLoadingOverlay = false
  public categoryCollection: CategoryCollection = this.$categories
  public elementsByID: { [key: string]: ElementWithTypeAndID } = {}

  private MERGE_TIME_DELTA = 10 * 60 // 10 minutes

  @Prop({ type: Array, required: false, default: () => ([]) })
  public sessions!: Array<hasDBid & SessionDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesProtection!: Array<hasDBid & ProtectionResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesForm!: Array<hasDBid & FormResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesI18n!: Array<hasDBid & I18nResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesFile!: Array<hasDBid & FileResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesCustom!: Array<hasDBid & CustomResponseDB>

  @Prop({ type: Array, required: false, default: () => ([]) })
  public responsesScript!: Array<hasDBid & ScriptResponseDB>

  @Prop({ type: Array, required: true })
  public moduleElementsWithType!: ElementWithTypeAndID[]

  @Prop({ type: Boolean, required: false, default: () => false })
  public showAsidInfo!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  public showPublishingState!: boolean

  public filterEmpty(rspValues: CustomResponseData) {
    return Object.fromEntries(Object.entries(rspValues).filter(([key, value]) => value !== null))
  }

  public getLocalesPerSession(responsesPerSession: BaseResponseDB[]) {
    const locales: locale[] = []
    responsesPerSession.forEach((rsp) => {
      if (rsp.public && rsp.public.locale && !locales.includes(rsp.public.locale)) {
        locales.push(rsp.public.locale)
      }
    })
    return locales
  }

  public getLocaleName(locale: locale) {
    return getLocaleName(locale)
  }

  public async onDownloadFile(file: { content: string, name: string }) {
    this.isLoading = true

    try {
      const response = await fetch(file.content)
      const blob = await response.blob()

      // create a new file from the blob object
      const fileData = new File([blob], `${file.name}`)

      // save the file
      downloadBlob(fileData, fileData.name)

      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      console.error(error)
    }
  }

  public getPublicDataForElement(elementID: objectID) {
    const el = this.elementsByID[elementID]
    if (!el) return
    return el.public
  }

  public async onChangeAcknowledge(id: objectID, v: boolean) {
    const rsp = this.allResponses.find((me) => me.id === id)
    if (!rsp) throw '[20211104] Response not found'

    rsp.backendData.acknowledge = v

    await this.onChangeBackendData(id)
  }

  public async onChangeBackendData(id: objectID) {
    this.isLoadingOverlay = true

    try {
      const rsp = this.allResponses.find((me) => me.id === id)
      if (!rsp) throw '[20211104] Response not found'

      await ModuleManager.getModuleClassByType(rsp.type).updateResponse(this.$auth.tenant.id, this.$auth.userEmail, id, {
        backendData: rsp.backendData
      })
      this.$helpers.notification.Success(`Data for session ${rsp.responseSession} has been updated`)
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    } finally {
      this.isLoadingOverlay = false
    }
  }

  public onClickEditNote(id: objectID) {
    const rsp = this.allResponses.find((me) => me.id === id)
    if (!rsp) {
      this.$helpers.notification.Error('[20211105] Response not found')
      return
    }

    this.$buefy.dialog.prompt({
      message: 'Add Note for Response',
      inputAttrs: {
        placeholder: 'your note',
        maxlength: 10000,
        value: rsp.backendData.note
      },
      trapFocus: true,
      onConfirm: async (note: string) => {
        rsp.backendData.note = note
        await this.onChangeBackendData(id)
      }
    })
  }

  @Watch('moduleElementsWithType', { immediate: true })
  public onChangeModuleElementsWithType() {
    this.moduleElementsWithType.forEach((el) => {
      if (el.id in this.elementsByID) return
      this.$set(this.elementsByID, el.id, el)
    })
  }

  public getModuleColor(type: ModuleType) {
    return ModuleManager.getModuleClassByType(capitalize(type) as ModuleType).color
  }

  public getAsidForSession(session: string) {
    return this.allResponses.find((e) => e.responseSession === session)?.public.asidID || ''
  }

  public onRemoveSession(session: string) {
    const responsesToBeDeleted = this.allResponses
      .filter((e) => e.responseSession === session)
      .filter((e) => (e.type as any) !== 'Session')

    if (responsesToBeDeleted.length === 0) {
      this.$buefy.dialog.alert({
        message: 'View events can not be removed',
        type: 'is-warning',
        ariaModal: true
      })
    } else {
      this.$buefy.dialog.confirm({
        title: 'Deleting Responses',
        message: `Are you sure you want to <b>delete all ${responsesToBeDeleted.length} responses for the session ${session}</b>? Visit events are not removed. This action cannot be made undone.`,
        confirmText: 'Delete Responses',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: async () => {
          this.isLoading = true
          const promises: Promise<any>[] = []
          responsesToBeDeleted.forEach((rsp) => {
            promises.push(
              ModuleManager
                .getModuleClassByType(rsp.type)
                .deleteResponse(this.$auth.tenant.id, this.$auth.userEmail, rsp.id)
            )
          })

          try {
            await Promise.all(promises)
          } catch (error: any) {
            this.$helpers.notification.Error(error.toString())
          } finally {
            this.isLoading = false
          }
        }
      })
    }
  }

  public onArchiveSession(session: string) {
    this.changePublishingStateOnAllReponsesForSession(session, 'archived')
      .then(() => {
        this.$helpers.notification.Success(`All responses for session ${session} have been archived`)
      })
      .catch((error) => {
        this.$helpers.notification.Error(error.toString())
      })
  }

  public onPublishSession(session: string) {
    this.changePublishingStateOnAllReponsesForSession(session, 'published')
      .then(() => {
        this.$helpers.notification.Success(`All responses for session ${session} have been published`)
      })
      .catch((error) => {
        this.$helpers.notification.Error(error.toString())
      })
  }

  private async changePublishingStateOnAllReponsesForSession(session: string, state: PublishingState) {
    try {
      this.isLoading = true

      const responsesToBePublished = this.allResponses
        .filter((e) => e.responseSession === session)
        .filter((e) => (e.type as any) !== 'Session')

      const promises: Promise<any>[] = []
      responsesToBePublished.forEach((rsp) => {
        promises.push(
          ModuleManager
            .getModuleClassByType(rsp.type)
            .updateResponse(this.$auth.tenant.id, this.$auth.userEmail, rsp.id, {
              publishingState: state
            }) as Promise<void>
        )
      })

      await Promise.all(promises)
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    } finally {
      this.isLoading = false
    }
  }


  public getLinkToElement(elID: objectID) {
    const el = this.elementsByID[elID]
    if (!el) return { name: 'not-found' }
    const name = ModuleManager.getModuleClassByType(el.type).routeNameElement
    return { name, params: { id: el.id } }
  }

  public created() {
    //
  }


  get allResponses() {
    return [
      ...ResponseViewHelper.prepareResponses(this.responsesProtection, 'Protection'),
      ...ResponseViewHelper.prepareResponses(this.responsesFile, 'File'),
      ...ResponseViewHelper.prepareResponses(this.responsesCustom, 'Custom'),
      ...ResponseViewHelper.prepareResponses(this.responsesScript, 'Script'),
      ...ResponseViewHelper.prepareResponses(this.responsesForm, 'Form'),
      ...ResponseViewHelper.prepareResponses(this.responsesI18n, 'I18n'),
      ...ResponseViewHelper.prepareResponses(this.sessions.map((s) => ({ ...s, responseSession: s.sessionID })) as unknown as (BaseResponseDB & hasDBid)[], 'Session' as any)
    ]
  }

  get allResponsesGrouped() {
    return ResponseViewHelper.groupAndSortResponses(this.allResponses, this.MERGE_TIME_DELTA)
  }
}
</script>

<style lang="scss">
.category-table tr.detail {
  display: none;
}

form.box {
  background: #f6f6f6;
}

.clickable {
  cursor: pointer;
}

.responses {
  position: relative;
  padding: 1em 0;

  hr {
    margin: 0.7em 0;
  }

  span.tag.is-option {
    background: #fefefe;
    border: 1px solid #e2e2e2;
    color: #aaa;
  }

  .session {
    .language-tag {
      margin-left: 0.5em;
    }

    .label {
      line-height: 1.8em;
    }

    .field.quick-menu {
      margin-right: 0.6rem;
      margin-bottom: 0 !important;
    }

    .delete-session {
      display: none;
    }

    &:hover {
      .delete-session {
        display: inline-flex;
      }
    }
  }

  .table tbody tr:last-child td,
  .table tbody tr:last-child th {
    border-bottom-width: 1px;
  }

  .module-name {
    border-left: 4px solid;
    padding: 0 0.3em;
    display: block;
    height: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 2px solid #e1e1e1;
    transform: translate(-50%);
  }

  .date {
    margin: 1.9em auto 1em;
    width: fit-content;
  }

  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 5px;
    cursor: pointer;
  }
}
</style>
