import db, { functions } from '@/firebase'
import firebase from 'firebase/compat/app'
import { TenantID, TenantDB } from '@/types/typeTenant'
import BaseManager from './baseManager'

import UserManager from './userManager'
import CategoryHelper from './categoryHelper'
import { CategoryCollectionDocDB } from '@/types/typeCategory'
import BackendConfigManager from './backendConfigManager'
import { AdminDataPerTenantDB } from '@/types/typeAdminData'
import { PlanDB } from '@/types/typePlan'
import { AppConfigDB } from '@/types/typeAppConfig'
import { getBackendUploadPath } from '@/helpers/storageHelper'
import databaseSchema from './databaseSchema'
import { DeepPartial } from '@/types/typeGeneral'
import { getChunkedArray } from '@/helpers/arrayHelper'
import { AppSessionDB, SessionDB } from '@/types/typeAppSession'

export default class TenantManager extends BaseManager {
  public static defaultPlanDB: PlanDB = databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__EMPTY_DOC__

  public static defaultAppSessionDB: AppSessionDB = databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.__EMPTY_DOC__
  public static defaultSessionDB: SessionDB = databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.SESSIONS.__EMPTY_DOC__

  public static defaultDocDB: TenantDB = databaseSchema.COLLECTIONS.TENANTS.__EMPTY_DOC__

  public static defaultAdminDataDB: AdminDataPerTenantDB
    = databaseSchema.COLLECTIONS.TENANTS.DATA.ADMIN_DATA.__EMPTY_DOC__

  public static defaultAppConfigDB: AppConfigDB = databaseSchema.COLLECTIONS.TENANTS.DATA.APP_CONFIG.__EMPTY_DOC__

  public static requiredPlanPrivileges = {
    r: ['config:read'],
    w: ['config:write']
  }

  public static formatPaddedNumber(number: number) {
    const TENANT_ID_NUMBER_DIGITS = 6 // number padding
    return ('0'.repeat(TENANT_ID_NUMBER_DIGITS) + '' + number).slice(-TENANT_ID_NUMBER_DIGITS)
  }

  // todo this will be done using a cloud function in the future
  public static async add(authEmail: string, fields: DeepPartial<TenantDB> = {}, tenantID?: string) {
    const reference = tenantID ? this.getDbDocReference(tenantID) : this.getDbCollectionReference()
    const newTenant = await this.addDoc(reference, authEmail, fields, this.defaultDocDB)

    // add plan
    await this.addDoc(this.getDbPlanDocReference(newTenant.id), authEmail, this.defaultPlanDB, this.defaultPlanDB)

    // add amin data
    await this.addDoc(
      this.getDbAdminDataDocReference(newTenant.id),
      authEmail,
      this.defaultAdminDataDB,
      this.defaultAdminDataDB
    )

    // add app config
    await this.addDoc(
      this.getDbAppConfigDocReference(newTenant.id),
      authEmail,
      this.defaultAppConfigDB,
      this.defaultAppConfigDB
    )

    // add app sessions
    await this.addDoc(
      this.getDbAppSessionDocReference(newTenant.id),
      authEmail,
      this.defaultAppSessionDB,
      this.defaultAppSessionDB
    )

    // add backend config
    await BackendConfigManager.add(newTenant.id, authEmail)

    // add categories
    await CategoryHelper.addDoc<CategoryCollectionDocDB, firebase.firestore.DocumentReference<any>>(
      CategoryHelper.getCategoriesDocRef(newTenant.id),
      authEmail,
      undefined,
      CategoryHelper.defaultDocDB
    )

    return newTenant
  }

  public static async get(tenantId: TenantID) {
    return this.getDocHelper<TenantDB>(this.getDbDocReference(tenantId))
  }

  public static async getTenantsByUserEmail(email: string) {
    if (!email) throw 'ERR: get tenants to login no email given'
    console.log('getTenantsByUserEmail')

    const userDocs = await UserManager.getAllUserReferenceDocsByEmail(email)
    const tenantIDs = userDocs.docs.map((doc) => doc.ref.parent.parent?.id)
    if (tenantIDs.length > 1000) throw 'ERR: the user is active in more than 1000 tenants'
    if (tenantIDs.length === 0) return []

    const queries = getChunkedArray(tenantIDs, 10).map((tenantIDChunk) => {
      return this.getDbCollectionReference().where(firebase.firestore.FieldPath.documentId(), 'in', tenantIDChunk).get()
    })

    return (await Promise.all(queries)).flatMap((d) => d.docs)
  }

  public static async updatePlan(tenantId: TenantID, authEmail: string, fields: DeepPartial<PlanDB> = {}) {
    return this.updateDoc(this.getDbPlanDocReference(tenantId), authEmail, fields)
  }

  public static async updateAppConfig(tenantId: TenantID, authEmail: string, fields: DeepPartial<AppConfigDB> = {}) {
    return this.updateDoc(this.getDbAppConfigDocReference(tenantId), authEmail, fields)
  }

  public static async updateAdminData(
    tenantId: TenantID,
    authEmail: string,
    fields: DeepPartial<AdminDataPerTenantDB> = {}
  ) {
    return this.updateDoc(this.getDbAdminDataDocReference(tenantId), authEmail, fields)
  }

  public static update(tenantId: TenantID, authEmail: string, fields: DeepPartial<TenantDB>) {
    // if ('_number' in fields) delete fields._number

    return this.updateDoc(this.getDbDocReference(tenantId), authEmail, fields)
  }

  public static getDbDocReference(tenantId: TenantID) {
    return this.getDbCollectionReference().doc(tenantId)
  }

  public static async deleteTenantAndLinkedAsids(tenantId: TenantID) {
    /**
     * 0. delete the tenant
     * 1. delete all asids in a transaction
     * 2. dont delete linked asids here, as they also have subcollections. So just delete the tenant, a coud function will mark the asids as deleted
     */

    const deleteAtPath = (path: string) => {
      const deleteFn = functions.httpsCallable('recursiveDeletePath')
      return deleteFn({
        payload: { path: path },
        dryrun: false,
        verbose: true
      })
    }

    await deleteAtPath(this.getDbDocReference(tenantId).path)

    // wait 5 seconds for all triggers to finish and delete again
    await new Promise((resolve) => setTimeout(resolve, 5000))

    await deleteAtPath(this.getDbDocReference(tenantId).path)
  }

  public static getDbCollectionReference() {
    return db.collection(databaseSchema.COLLECTIONS.TENANTS.__COLLECTION_PATH__())
  }

  public static getDbPlanDocReference(tenantId: TenantID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__DOCUMENT_PATH__(tenantId))
  }

  public static getDbAppConfigDocReference(tenantId: TenantID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.APP_CONFIG.__DOCUMENT_PATH__(tenantId))
  }

  public static getDbAppSessionDocReference(tenantId: TenantID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.APP_SESSION.__DOCUMENT_PATH__(tenantId))
  }

  public static getAppConfig(tenantId: TenantID) {
    return this.getDocHelper<AppConfigDB>(this.getDbAppConfigDocReference(tenantId))
  }

  public static getDbAdminDataDocReference(tenantId: TenantID) {
    return db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.ADMIN_DATA.__DOCUMENT_PATH__(tenantId))
  }

  public static getConfigUploadPath(tenantId: TenantID) {
    return getBackendUploadPath(tenantId, '/config/code')
  }
}
