<template>
  <b-dropdown :position="position" append-to-body aria-role="menu" trap-focus>
    <a slot="trigger" class="navbar-item" role="button">
      <slot>
        <b-icon icon="info" size="is-small" />
      </slot>
    </a>

    <b-dropdown-item aria-role="menu-item" :focusable="false" custom paddingless>
      <div class="record-meta-container">
        <section v-if="requiredPrivileges.r.length > 0">
          <div class="label">Required Privileges to Read data</div>

          <b-taglist>
            <b-tag v-for="priv in requiredPrivileges.r" :key="priv">{{ priv }}</b-tag>
          </b-taglist>
          <hr />
        </section>
        <section v-if="requiredPrivileges.w.length > 0">
          <div class="label">Required Privileges to Write data</div>

          <b-taglist>
            <b-tag v-for="priv in requiredPrivileges.w" :key="priv">{{ priv }}</b-tag>
          </b-taglist>
          <hr />
        </section>

        <section v-if="viewPrivileges && viewPrivileges.length > 0">
          <div class="label">Required Privileges to View Page</div>

          <b-taglist>
            <b-tag v-for="priv in viewPrivileges" :key="priv">{{ priv }}</b-tag>
          </b-taglist>
          <hr />
        </section>
        <section v-if="recordMeta && recordMeta.revisions > 0">
          <div class="label">Document saved {{ recordMeta.revisions }} times</div>
          <b-button v-if="documentPath" @click="onOpenChangelogModal">Show Revisions</b-button>
          <!-- <ul class="changelog">
          <li v-for="(doc, i) in changelog" :key="i" class="item">
            Updated by
            <b>{{ doc._meta.modifiedByUserId }}</b>
            at
            {{ doc._meta.dateUpdated.toDate() }}
          </li>
          </ul>-->
          <hr />

          <div class="label">Created by</div>
          <div>{{ recordMeta.createdByUserId }}</div>

          <div class="label">Created at</div>
          <time datetime="2016-1-1">{{ recordMeta.dateCreated || '' }}</time>

          <hr />

          <div class="label">Updated by</div>
          <div>{{ recordMeta.modifiedByUserId }}</div>

          <div class="label">Updated at</div>
          <time datetime="2016-1-1">{{ recordMeta.dateUpdated || '' }}</time>
        </section>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import RecordMeta from '@/types/typeRecordMeta'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faInfo } from '@fortawesome/free-solid-svg-icons'


library.add(faInfo)

import VModuleCompareChangelogModal from '@/components/VModuleCompareChangelogModal.vue'

import { RequiredDocPrivileges } from '@/types/typeRequiredPrivileges'

@Component({
  components: {
    VModuleCompareChangelogModal
  }
})
export default class VRecordMeta extends Vue {
  @Prop({ type: Object, required: false, default: () => null })
  public recordMeta!: RecordMeta

  @Prop({ type: String, required: false, default: () => 'is-bottom-right' })
  public position!: string

  @Prop({ type: Object, required: false, default: () => ({ r: [], w: [] }) })
  public requiredPrivileges!: RequiredDocPrivileges

  @Prop({ type: String, required: false, default: () => '' })
  public documentPath!: string


  @Prop({ type: Boolean, required: false, default: () => false })
  private disallowResetToRevision!: boolean

  get viewPrivileges() {
    return this.$route.meta?.privileges || []
  }

  onOpenChangelogModal() {
    this.$buefy.modal.open({
      props: {
        documentPath: this.documentPath,
        disallowResetToRevision: this.disallowResetToRevision
      },
      events: {

      },
      parent: this,
      component: VModuleCompareChangelogModal,
      hasModalCard: true
    })
  }

  public changelog: { _meta: RecordMeta }[] = []
  // @Watch('documentPath', { immediate: true })
  // async onDocPathChanged() {
  //   const changelogDocs = (await db.doc(this.documentPath).collection('Changelog').orderBy('_meta.dateUpdated', 'desc').limit(10).get()).docs
  //   const changeLogData = changelogDocs.map(doc => doc.data() as { _meta: RecordMeta })
  //   console.log(changeLogData)

  //   this.changelog = changeLogData
  // }
}
</script>

<style lang="scss">
.record-meta-container {
  padding: 1em;
  width: 30em;

  hr {
    margin: 1em 0;
  }

  .tags:not(:last-child) {
    margin-bottom: 0;
  }
}

.dropdown-menu {
  z-index: 30 !important;
}
</style>
