
<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'


@Component({
  components: {

  }
})
export default class VAuth extends Vue {


  // /**
  //  * hasPrivilege
  //  */
  // public hasPrivilege(action: string, ressource: string) {

  //   const allowed = AuthHelper.hasPrivilege(ressource, action, this.userPrivileges)
  //   console.log('-----', allowed)
  //   return allowed
  // }

  // private userPrivileges: string[] = []

  // public user: User|null = null

  // public async created() {
  //   await AuthHelper.onUser(async (U) => {
  //     this.user = U
  //     this.userPrivileges = await AuthHelper.getUserPrivileges(U)
  //   })
  // }

}
</script>

