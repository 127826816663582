import { render, staticRenderFns } from "./Backend_Modules_List.vue?vue&type=template&id=423476e6&scoped=true"
import script from "./Backend_Modules_List.vue?vue&type=script&lang=ts"
export * from "./Backend_Modules_List.vue?vue&type=script&lang=ts"
import style0 from "./Backend_Modules_List.vue?vue&type=style&index=0&id=423476e6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "423476e6",
  null
  
)

export default component.exports