<template>
  <Fragment>
    <template v-for="(questionAndResponses, questionIndex) in getQuestionsAndResponses(rsp)">
      <tr :key="questionIndex+'_'+i">
        <slot name="columns_before" :rsp="rsp" :question-index="questionIndex" />

        <!-- <td v-if="questionIndex === 0" :rowspan="Object.keys(rsp.data).length">
          <span
            class="is-capitalized module-name"
            :style="{borderLeftColor: `${getModuleColor(rsp.type)}`}"
          >
            <router-link
              v-if="elementsByID[rsp.elementID]"
              :to="getLinkToElement(rsp.elementID)"
            >{{ (elementsByID[rsp.elementID]) ? elementsByID[rsp.elementID].name : '' }} -</router-link>
            <span v-else>
              Element not found
              <br />
              <small>(may have been deleted)</small>
            </span>
            <small>&nbsp;{{ rsp.type }}</small>
          </span>
        </td>-->
        <td>
          <span>{{ $i18n.getLocalString(questionAndResponses.question.title) }}</span>
        </td>

        <td>
          <template v-if="questionAndResponses.question.type === 'multipletext'">
            <b-taglist>
              <b-tag
                v-for="(item, key) in FormHelper.getQuestionAndResponsesObj_MultipleText(questionAndResponses.question, questionAndResponses.responses).items"
                :key="'k1_'+questionIndex+'_'+i+'_'+key"
              >{{ $i18n.getLocalString(item.title) }}: {{ (item.responses || []).join('') }}</b-tag>

              <template
                v-for="(comment, key) in FormHelper.getQuestionAndResponsesObj_MultipleText(questionAndResponses.question, questionAndResponses.responses).comments"
              >
                <b-tag
                  v-if="comment"
                  :key="'k5_'+questionIndex+'_'+i+'_'+key"
                  type="is-selected is-light"
                  class="comment-tag"
                >Comment: {{ comment }}</b-tag>
              </template>
            </b-taglist>
          </template>

          <template v-else-if="questionAndResponses.question.type === 'boolean'">
            <b-taglist>
              <template
                v-for="(item, key) in FormHelper.getQuestionAndResponsesObj_Boolean(questionAndResponses.question, questionAndResponses.responses).items"
              >
                <b-tag
                  :key="'k2_'+questionIndex+'_'+i+'_'+key"
                  :class="(item.responses.length > 0)?'is-selected is-light':'is-option'"
                >{{ $i18n.getLocalString(item.title) }}</b-tag>
              </template>

              <template
                v-for="(comment, key) in FormHelper.getQuestionAndResponsesObj_Boolean(questionAndResponses.question, questionAndResponses.responses).comments"
              >
                <b-tag
                  v-if="comment"
                  :key="'k5_'+questionIndex+'_'+i+'_'+key"
                  type="is-selected is-light"
                  class="comment-tag"
                >Comment: {{ comment }}</b-tag>
              </template>
            </b-taglist>
          </template>

          <template v-else-if="questionAndResponses.question.type === 'rating'">
            <b-taglist>
              <template
                v-for="(item, key) in FormHelper.getQuestionAndResponsesObj_Rating(questionAndResponses.question, questionAndResponses.responses).items"
              >
                <b-tag
                  :key="'k3_'+questionIndex+'_'+i+'_'+key"
                  :class="(item.responses.length > 0)?'is-selected is-light':'is-option'"
                >{{ $i18n.getLocalString(item.title) }}</b-tag>
              </template>

              <template
                v-for="(comment, key) in FormHelper.getQuestionAndResponsesObj_Rating(questionAndResponses.question, questionAndResponses.responses).comments"
              >
                <b-tag
                  v-if="comment"
                  :key="'k5_'+questionIndex+'_'+i+'_'+key"
                  type="is-selected is-light"
                  class="comment-tag"
                >Comment: {{ comment }}</b-tag>
              </template>
            </b-taglist>
          </template>

          <template
            v-else-if="[ 'radiogroup', 'dropdown', 'checkbox', 'imagepicker', 'ranking'].includes(questionAndResponses.question.type)"
          >
            <b-taglist>
              <template
                v-for="(item, key) in FormHelper.getQuestionAndResponsesObj_CheckboxDropdownRadiogroup(questionAndResponses.question, questionAndResponses.responses).items"
              >
                <b-tag
                  :key="'k4_'+questionIndex+'_'+i+'_'+key"
                  :class="(item.responses.length > 0)?'is-selected is-light':'is-option'"
                >
                  <!-- if type is ranking, show the index -->
                  <span v-if="questionAndResponses.question.type === 'ranking'">{{ key+1 }}):</span>
                  <!-- include image if present -->
                  <span v-if="item.imageLink && $i18n.getLocalString(item.imageLink)">
                    <img :src="$i18n.getLocalString(item.imageLink)" class="image-picker-response" />
                    &nbsp;
                  </span>
                  {{ $i18n.getLocalString(item.title) }}
                </b-tag>
              </template>

              <template
                v-for="(comment, key) in FormHelper.getQuestionAndResponsesObj_CheckboxDropdownRadiogroup(questionAndResponses.question, questionAndResponses.responses).comments"
              >
                <b-tag
                  v-if="comment"
                  :key="'k5_'+questionIndex+'_'+i+'_'+key"
                  type="is-selected is-light"
                  class="comment-tag"
                >Comment: {{ comment }}</b-tag>
              </template>
            </b-taglist>
          </template>

          <template v-else-if="questionAndResponses.question.type === 'file'">
            <p
              v-for="(file, key) in (questionAndResponses.responses[0].data || []).filter(isNonImageFile)"
              :key="'k6_'+questionIndex+'_'+i+'_'+key"
            >
              <a :href="file.content" @click.prevent="onDownloadFile(file)">{{ file.name }}</a>
            </p>
            <hr
              v-if="(questionAndResponses.responses[0].data || []).some(isNonImageFile) && (questionAndResponses.responses[0].data || []).some(isImageFile)"
            />
            <VueGallery
              :id="'vue_gallery_' + rsp.id + '_' + questionIndex + '_' + i"
              :key="'vue_gallery_' + rsp.id + '_' + questionIndex + '_' + i"
              :images="(questionAndResponses.responses[0].data || []).filter(isImageFile).map(d=>d.content)"
              :index="activeImageIndices[`${questionIndex}_${rsp.id}`]"
              @close="activeImageIndices[`${questionIndex}_${rsp.id}`] = null"
            />
            <div
              v-for="(file, key) in (questionAndResponses.responses[0].data || []).filter(isImageFile)"
              :key="key"
              class="image form-response-preview-image"
              :style="{backgroundImage: `url('${file.content}')`}"
              @click="!activeImageIndices[`${questionIndex}_${rsp.id}`] ? $set(activeImageIndices, `${questionIndex}_${rsp.id}`, key) : activeImageIndices[`${questionIndex}_${rsp.id}`] = key"
            />

            <!-- show comment -->
            <template v-if="questionAndResponses.responses[0].comment">
              <b-tag
                type="is-selected is-light"
                class="comment-tag"
              >Comment: {{ questionAndResponses.responses[0].comment }}</b-tag>
            </template>
          </template>

          <template v-else-if="questionAndResponses.question.type === 'matrix'">
            <b-taglist>
              <b-tag
                v-for="([row,column], key) in FormHelper.getQuestionAndResponsesObj_Matrix(questionAndResponses.question, questionAndResponses.responses).items"
                :key="'k7_'+questionIndex+'_'+i+'_'+key"
              >{{ $i18n.getLocalString(row.title) }}:{{ $i18n.getLocalString(column.title) }}</b-tag>
            </b-taglist>
          </template>

          <template v-else-if="questionAndResponses.question.type === 'signaturepad'">
            <img
              style="background: #f9f9f9; max-width: 70%;"
              :src="questionAndResponses.responses[0].data"
              alt="signature"
            />
          </template>

          <template v-else>
            {{ questionAndResponses.responses[0].data }}
            <!-- {{ questionAndResponses.question.type }} -->
          </template>
        </td>

        <slot name="columns_after" :rsp="rsp" :question-index="questionIndex" />

        <!-- <td v-if="questionIndex === 0" :rowspan="Object.keys(rsp.data).length">
          <b-checkbox v-model="rsp.backendData.acknowledge" @input="onChangeBackendData(rsp.id)" />
        </td>
        <td v-if="questionIndex === 0" :rowspan="Object.keys(rsp.data).length">
          <span>
            {{ rsp.backendData.note }}
            <b-button class="is-small" icon-right="edit" @click="onClickEditNote(rsp.id)" />
          </span>
        </td>-->
      </tr>
    </template>
  </Fragment>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

import VTooltipIconHelp from '@/components/global/VTooltipIconHelp.vue'
import VueGallery from 'vue-gallery'
import { FormElementPublicData, FormResponsePublicData } from '@/modules/form/typeFormModule'
import { BaseResposePublicData } from '@/modules/typeModules'
import { hasDBid } from '@/types/typeGeneral'
import FormHelper from '@/modules/form/formHelper'
import { downloadBlob } from '@/helpers/downloadHelper'
import { Fragment } from 'vue-frag'

@Component({
  components: {
    VTooltipIconHelp,
    VueGallery,
    Fragment
  },
  props: {
    asid: {
      type: String,
      required: false,
      default: () => ''
    }
  }
})
export default class VResponseTimelineForm extends Vue {
  public i = Math.floor(Math.random() * 1000000)
  public isLoading = false

  @Prop({ type: Object, required: true })
  public elementPublicData!: FormElementPublicData

  @Prop({ type: Object, required: true })
  public rsp!: BaseResposePublicData & hasDBid


  public async onDownloadFile(file: { content: string, name: string }) {
    this.isLoading = true

    try {
      const response = await fetch(file.content)
      const blob = await response.blob()

      // create a new file from the blob object
      const fileData = new File([blob], `${file.name}`)

      // save the file
      downloadBlob(fileData, fileData.name)

      this.isLoading = false
    } catch (error) {
      this.isLoading = false
      console.error(error)
    }
  }


  // #region Form

  public isNonImageFile = (file: any) => !file.type.startsWith('image')
  public isImageFile = (file: any) => file.type.startsWith('image')

  public activeImageIndices: any = {} // {1:0,4:3}

  /**
   * get array of single question and response tuple for the provided responses
   */
  public getQuestionsAndResponses(rsp: FormResponsePublicData) {
    return FormHelper.getQuestionsAndResponsesAndHeaderFromQuestionContainer(rsp, this.elementPublicData.questions)
  }

  get FormHelper() {
    return FormHelper
  }

  // #endregion Form

  public created() {
    //
  }
}
</script>

<style lang="scss">
.form-response-preview-image {
  background-size: cover;
  cursor: pointer;
  background-repeat: no-repeat;

  height: 6rem;
  width: 6rem;
}

span.tag.comment-tag {
  span {
    word-wrap: break-word;
    /* width: 50px; */
    white-space: normal;
  }
}

.print {
  .form-response-preview-image {
    background-size: contain;

    height: 15rem;
    width: 15rem;
  }
}

.is-option {
  .image-picker-response {
    // grey out the image
    filter: grayscale(100%);
  }
}

.image-picker-response {
  max-height: 1.3rem;
  display: inline;
  vertical-align: bottom;
  cursor: pointer;

  &:hover {
    max-height: 30rem;
  }
}
</style>
