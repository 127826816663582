<template functional>
  <b-tooltip
    class="help-icon-wrapper"
    :type="props.type"
    multilined
    :position="props.position"
    :animated="false"
    :append-to-body="props.appendToBody"
  >
    <template #content>
      {{ props.text }}
      <!-- eslint-disable-next-line vue/no-v-html -->
      <span v-html="props.textHtml" />
    </template>

    <b-icon class="help-icon" size="is-small" icon="question-circle" />
  </b-tooltip>
</template>

<script lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

library.add(faQuestionCircle)


@Component
export default class VTooltipHelp extends Vue {
  @Prop({ type: String, required: false, default: 'is-dark' })
  public type!: string

  @Prop({ type: String, required: false })
  public text!: string

  @Prop({ type: String, required: false })
  public textHtml!: string

  @Prop({ type: String, required: false, default: 'is-top' })
  public position!: string

  @Prop({ type: Boolean, required: false, default: true })
  public appendToBody!: boolean
}

// export default {
//   props: {
//     type: {
//       type: String,
//       required: false,
//       default: 'is-dark'
//     },
//     text: {
//       type: String,
//       required: true
//     },
//     position: {
//       type: String,
//       required: false,
//       default: 'is-top'
//     }
//   }
// }
</script>

<style lang="scss">
@import '@/variables.scss';

.help-icon-wrapper .tooltip-trigger {
  &:hover {
    .help-icon svg.svg-inline--fa.fa-question-circle {
      color: $primary;
    }
  }

  .help-icon {
    display: inline-block;

    &.icon svg {
      height: inherit;
    }

    svg.svg-inline--fa.fa-question-circle {
      color: #d5d5d5;
    }
  }
}
</style>
