<template>
  <section>
    <!-- draggable -->
    <b-table
      v-show="!isLoading"
      :key="tableColumnsHash"
      :data="pagination_paginatedData"
      :backend-pagination="!pagination_allLoaded"
      :checkable="false"
      pagination-simple
      paginated
      :backend-sorting="!pagination_allLoaded"
      :total="pagination_totalItemsEstimation"
      :per-page="pagination_perPage"
      :default-sort="[pagination_sortField, pagination_sortDirection]"
      :checked-rows.sync="pagination_checkedRows"
      @sort="pagination_onSort"
      @page-change="(page)=>pagination_currentPage = page"
    >
      <!-- <b-field
        v-if="pagination_getFilterConfig(props.column.field)"
        slot="searchable"
        slot-scope="props"
      >
        <VFilterDateDropdownView
          v-if="pagination_getFilterConfig(props.column.field).type === 'date'"
          :config="pagination_getFilterConfig(props.column.field)"
        />
        <VFilterCategoriesDropdownView
          v-else-if="pagination_getFilterConfig(props.column.field).type === 'categories'"
          :config="pagination_getFilterConfig(props.column.field)"
        />
        <VFilterDropdownView
          v-else
          :config="pagination_getFilterConfig(props.column.field)"
          :position="pagination_getFilterConfig(props.column.field).displayLeft ? 'is-bottom-left' : 'is-bottom-right'"
        />
      </b-field>-->

      <b-table-column
        v-for="(column,i) in tableColumns"
        :key="i"
        :visible="!column.isElementReference || showReferences"
        :field="column.field"
        :label="column.label"
        :sortable="column.sortable"
        :numberic="column.numberic"
        :centered="column.centered"
        :searchable="column.searchable"
        :header-class="column.headerClass"
        :cell-class="column.cellClass"
      >
        <template #default="props">
          <b-taglist v-if="column.display === 'taglist'">
            <b-tag
              v-for="(value,key) in ((column.formatter) ? column.formatter(accessorStringToValue(props.row, column.field)) : accessorStringToValue(props.row, column.field)).filter(v=>v) "
              :key="key"
            >{{ value }}</b-tag>
          </b-taglist>
          <span
            v-else
          >{{ (column.formatter) ? column.formatter(accessorStringToValue(props.row, column.field)) : accessorStringToValue(props.row, column.field) }}</span>
        </template>
      </b-table-column>

      <b-table-column field="changes" label="Changes">
        <template #default="props">
          <b-taglist>
            <template v-for="(change, index) in props.row.changes">
              <b-tag
                v-if="!change.path.includes('_meta')"
                :key="index"
              >{{ change.op }} field '{{ change.path.join('/') }}' from '{{ change.oldVal || '-' }}' to '{{ JSON.stringify(change.val) | truncate(50) }}'</b-tag>
            </template>
          </b-taglist>
        </template>
      </b-table-column>
    </b-table>
  </section>
</template>

<script lang="ts">

import VPaginationMixin from '@/components/mixins/VPaginateMixin.vue'
import VFilterCategoriesDropdownView from '@/components/VFilterCategoriesDropdownView.vue'
import VFilterDropdownView from '@/components/VFilterDropdownView.vue'
import VFilterDateDropdownView from '@/components/VFilterDateDropdownView.vue'
import { accessorStringToValue, assignValueBasedOnAccessorString } from '@/database/dbHelper'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowUp, faSave, faSearch, faTimes, faUpload
} from '@fortawesome/free-solid-svg-icons'
import moment from 'dayjs'
import { mixins } from 'vue-class-component'
import { Component } from 'vue-property-decorator'
import { AuditLogDB } from '@/types/typeAuditTrail'
import databaseSchema from '@/database/databaseSchema'
import { FilterConfigNew, SnapshotDatas } from '@/database/filterUtil'


library.add(faArrowUp, faSave, faTimes, faUpload, faSearch)

@Component({
  components: {
    VFilterCategoriesDropdownView,
    VFilterDateDropdownView,
    VFilterDropdownView
  }
})
export default class BackendConfigAuditLog extends mixins<VPaginationMixin<AuditLogDB>>(VPaginationMixin) {
  public isLoading = false

  get isAnyLoading() {
    return this.isLoading || this.pagination_isPaginationLoading
  }

  // #region Pagination

  public pagination_sortDirection: 'asc' | 'desc' = 'desc'
  public pagination_sortField: string = '_meta.dateCreated'
  public pagination_perPage = 50
  public pagination_liveUpdateOnFirstPage = true

  protected pagination_collectionGroupName = databaseSchema.COLLECTIONS.TENANTS.AUDIT_LOGS.__NAME__


  public pagination_filter(): FilterConfigNew<AuditLogDB>[] {
    // return typedWhere<AuditLogDB>(query, { tenantID: '' }, '==', this.$auth.tenant.id)
    return [
      {
        fieldAccessor: { tenantID: '' },
        opStr: '==',
        values: [this.$auth.tenant.id],
        indexGroups: [],
        isMandatory: true
      }
    ]
  }

  protected pagination_localDocsFilter(docs: SnapshotDatas<AuditLogDB>) {
    return docs
  }


  private intializeTable() {
    this.pagination_filterConfig = [
      // {
      //   fieldAccesor: { asidID: '' } as BaseResponseDB,
      //   collectionPath: this.Module.getResponsesDbReference(this.$auth.tenant.id).path,
      //   objAcessor: { asidID: '' } as BaseResponseDB,
      //   type: 'exact' as const,
      //   in: [],
      //   range: [],
      //   notBackendSortable: false
      // },
      // {
      //   fieldAccesor: { _meta: { dateCreated: '' } } as AuditLogDB,
      //   collectionPath: FileModule.getElementsDbReference(this.$auth.tenant.id).path,
      //   objAcessor: { _meta: { dateCreated: '' } } as AuditLogDB,
      //   type: 'date' as const,
      //   in: [],
      //   range: [],
      //   notBackendSortable: false
      // },
      // {
      //   fieldAccesor: { categoryIDs: [] },
      //   collectionPath: '',
      //   objAcessor: { categoryIDs: [] },
      //   type: 'categories' as const,
      //   in: [],
      //   range: [],
      //   notBackendSortable: false
      // }

    ]

    this.pagination_getData(true)
  }
  // #region table

  public accessorStringToValue(obj: any, acessor: string) {
    return accessorStringToValue(obj, acessor)
  }

  public assignValueBasedOnAccessorString(obj: any, acessor: string, value: any) {
    assignValueBasedOnAccessorString(obj, acessor, value)
  }

  // used as key to recreate table on column change
  public get tableColumnsHash() {
    return this.tableColumns.map((c) => c.field).join('')
  }


  public get tableColumns() {
    return [
      // {
      //   field: 'asidID',
      //   label: 'ECHO CODE',
      //   numeric: false,
      //   searchable: true,
      //   sortable: true
      // },
      {
        field: '_meta.dateCreated',
        label: 'Timestamp',
        formatter: (d: any) => moment(d?.toDate() || Date.now()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: false,
        sortable: false
      },
      {
        field: '_meta.modifiedByUserId',
        label: 'User',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: false,
        sortable: false,
        editable: true
      }, {
        field: 'operation',
        label: 'Operation',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: false,
        sortable: false,
        editable: true
      }, {
        field: 'relatedDoc.type',
        label: 'Type',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: false,
        sortable: false,
        editable: false
      }, {
        field: 'relatedDoc.subtype',
        formatter: (d: any) => d === 'Group' ? 'Widget' : d,
        label: 'Subtype',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: false,
        sortable: false,
        editable: false
      }, {
        field: 'relatedDoc.docId',
        label: 'ID',
        // formatter: (d: any) => moment(d.toDate()).format('YYYY.MM.DD - HH:mm'),
        numeric: false,
        searchable: false,
        sortable: false,
        editable: false
      }

    ]
  }

  // #endregion table

  // #endregion Pagination


  public async created() {
    this.isLoading = true

    this.intializeTable()

    this.isLoading = false
  }
}
</script>

<style lang="scss">
</style>
