<template>
  <b-icon
    :icon="
      (ressourceType === 'pdf') ? 'file-pdf':
      (ressourceType === 'image') ? 'file-image':
      (ressourceType === 'link') ? 'link':
      (ressourceType === 'video') ? 'film':
      (ressourceType === 'other') ? 'file':
      (ressourceType === 'archive') ? 'file-archive':
      (ressourceType === 'dir') ? 'folder':
      'border-style'
    "
    size="is-small"
  />
</template>

<script lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileImage, faFilePdf, faLink, faFile, faBorderStyle, faFilm, faFileArchive, faFolder } from '@fortawesome/free-solid-svg-icons'

library.add(faFileImage, faFilePdf, faFileArchive, faLink, faFile, faBorderStyle, faFilm, faFolder)

export default {
  props: {
    ressourceType: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
</style>
