<template>
  <section>
    <b-dropdown
      ref="dropdown"
      class="module-filter"
      v-bind="$attrs"
      aria-role="dialog"
      scrollable
      :append-to-body="true"
      trap-focus
      :close-on-click="false"
      @active-change="(a) => dropdownIsOpen = a"
    >
      <template #trigger>
        <b-field>
          <b-field class="has-addons">
            <p class="control">
              <b-button class="filter-button" icon-left="filter" :size="size" @click="onOpenFilter">
                Modules
                <span>
                  <b-tag
                    v-if="selectedModuleTypes.length > 0"
                    rounded
                    :size="size"
                  >{{ selectedModuleTypes.length }}</b-tag>
                </span>
              </b-button>
            </p>
            <p v-if="selectedModuleTypes.length > 0" class="control">
              <b-button
                class="filter-button"
                icon-left="times"
                :size="size"
                @click.stop="onResetFilter"
              />
            </p>
          </b-field>
        </b-field>
      </template>
      <section class="filter-dropdown-body">
        <b-dropdown-item custom aria-role="listitem">
          <VInputMultiModuleSelection v-model="localSelectedModuleTypes" @click.stop />
        </b-dropdown-item>

        <b-dropdown-item custom aria-role="listitem">
          <b-field grouped class="buttons">
            <b-button type="is-primary" @click="onApplyFilter">Apply Filter</b-button>
            <b-button @click="onResetFilter">Reset Filter</b-button>
          </b-field>
        </b-dropdown-item>
      </section>
      <!-- <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" /> -->
    </b-dropdown>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Model, Emit, Watch, Prop } from 'vue-property-decorator'


import VInputMultiModuleSelection from '@/components/VInputMultiModuleSelection.vue'

import { ModuleType } from '@/modules/typeModules'


@Component({
  components: {
    VInputMultiModuleSelection
  },
  inheritAttrs: false,
  props: {
    default: {
      type: Boolean,
      required: false,
      default: () => true
    }
  }
})
export default class VFilterModulesDropdownView extends Vue {
  @Model('input', { type: Array, required: true, default: () => [] })
  readonly selectedModuleTypes!: ModuleType[]

  public localSelectedModuleTypes: ModuleType[] = []

  @Prop({ type: String, required: false, default: '' })
  readonly size!: 'is-small' | ''


  @Watch('selectedModuleTypes', { immediate: true })
  public onSelectedModuleTypesChange() {
    this.localSelectedModuleTypes = [...this.selectedModuleTypes] // spread to not pass reference
  }

  @Emit('input')
  public onApplyFilter() {
    this.onCloseFilter()
    return this.localSelectedModuleTypes
  }

  @Emit('input')
  public onResetFilter() {
    this.onCloseFilter()
    return []
  }

  public dropdownIsOpen = false

  public onOpenFilter() {
    if (!this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }

  public onCloseFilter() {
    if (this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }
}
</script>

<style lang="scss">
.module-filter {
  .dropdown-menu {
    div.dropdown-content {
      overflow: visible !important;
    }
  }

  .filter-dropdown-body {
    min-width: 25em;

    .filter-dropdown-tabs {
      span.tag.is-small.is-rounded {
        height: 1.4em;
      }
    }
  }
}
</style>
