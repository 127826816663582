<template>
  <div class="modal-card animation-content">
    <header class="modal-card-head">
      <p class="modal-card-title">Unsaved changes</p>
    </header>
    <section class="modal-card-body is-flex">
      <div class="media">
        <div class="media-left">
          <span class="icon has-text-danger is-large">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="exclamation-circle"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="svg-inline--fa fa-exclamation-circle fa-w-16 fa-2x"
            >
              <path
                fill="currentColor"
                d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                class
              />
            </svg>
          </span>
        </div>
        <div class="media-content">
          <p>Unsaved changes were detected on this page. How would you like to proceed?</p>
        </div>
      </div>
    </section>
    <footer class="modal-card-foot">
      <b-button type @click="$emit('close')">Stay on page</b-button>

      <b-button type="is-danger" @click="$emit('discard-and-leave');$emit('close')">Discard Changes and Leave Page</b-button>
      <b-button v-if="displaySaveOption" type="is-success" @click="$emit('save-and-leave');$emit('close')">Save Changes and Leave</b-button>
    </footer>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'

import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'


library.add(faCalendarDay)

@Component({
  components: { VFormConfigureSticker, VRecordMeta },
  props: {}
})
export default class VModalSaveChanges extends Vue {
  @Prop({ default: false }) readonly displaySaveOption!: boolean
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';
</style>
