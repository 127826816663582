<template>
  <section>
    <div class="level">
      <b-field class="level-left">
        <b-select v-model="pagination_perPage">
          <!-- <option value="2">2 per page</option> -->
          <option value="20">20 per page</option>
          <option value="50">50 per page</option>
          <option value="100">100 per page</option>
          <option value="1000">1000 per page</option>
        </b-select>
        <p class="control">
          <b-button
            v-if="!pagination_liveUpdateActive"
            icon-left="sync"
            @click="()=>pagination_getData(false,true)"
          >Refresh</b-button>
        </p>
        <p class="control">
          <VButtonToggleLiveUpdate
            :value="pagination_liveUpdateActive"
            :disabled="pagination_currentPage > 1"
            :title="(pagination_currentPage > 1) ? 'Live data update is only available on the first page' : 'Data is being updated automatically'"
            @input="(v)=> pagination_liveUpdateOnFirstPage = v"
          />
        </p>
        <p class="control">
          <VTableColumnsDropdown
            v-model="hiddenColumns"
            :columns="localColumnDefinition.filter(c=>!c._derived || !c._derived.isColumnGroupColumn).map(c=>c.label)"
          />
        </p>
        <!-- button to clear all filters if any are set -->
        <p v-if="pagination_filterConfig.filter(f=>f.in.length > 0).length > 0" class="control">
          <b-button
            icon-left="times"
            @click="pagination_filterConfig = pagination_filterConfig.map(f=>({ ...f, in: [] }))"
          >Clear Filters</b-button>
        </p>
      </b-field>
      <b-field grouped>
        <VImportExport
          v-if="importExport_importExportDefinitions.length > 0"
          :create-doc-batch="importExport_createDocBatch"
          :update-doc-batch="importExport_updateDocBatch"
          :get-default-doc="importExport_getDefaultDoc"
          :before-get-current-data="importExport_beforeGetCurrentData"
          :import-formatter="(d)=>d"
          :get-doc="importExport_getDoc"
          :is-loading.sync="importExport_isLoading"
          :export-docs="(pagination_checkedRows.length > 0) ? pagination_checkedRows : pagination_paginatedData"
          :import-export-definitions="importExport_importExportDefinitions"
          :validate-imported-data="importExport_validateImportedData"
          @imported="refreshData(); $emit('imported')"
        >
          <template
            slot="exportText"
          >Export {{ (pagination_checkedRows.length > 0) ? pagination_checkedRows.length : pagination_paginatedData.length }} IDs</template>
        </VImportExport>
        <p class="control">
          <VPrintButton :print-container-ref="$refs.table" />
        </p>
      </b-field>
    </div>

    <b-table
      ref="table"
      :key="columnsHash"
      class="v-table"
      checkable
      :checked-rows.sync="pagination_checkedRows"
      :data="pagination_paginatedData"
      :backend-pagination="!pagination_allLoaded"
      pagination-simple
      paginated
      :detailed="!!$scopedSlots.detail"
      :backend-sorting="!pagination_allLoaded"
      :total="pagination_totalItemsEstimation"
      :per-page="pagination_perPage"
      :default-sort="[pagination_sortField, pagination_sortDirection]"
      aria-next-label="Next page"
      pagination-position="both"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      narrowed
      :selected.sync="localSelectedRow"
      @page-change="pagination_onPageChange"
      @sort="pagination_onSort"
      @details-open="e=>$emit('details-open',e)"
      v-on="$listeners"
    >
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <!-- if theres a warning , show it -->
            <b-notification
              v-if="pagination_error"
              type="is-warning"
              aria-close-label="Close notification"
              role="alert"
              :closable="false"
            >{{ pagination_error }}</b-notification>
            <p v-else>No data available.</p>
          </div>
        </section>
      </template>

      <template #detail="props">
        <slot name="detail" :row="props.row" />
      </template>

      <b-table-column
        v-for="(column,i) in localColumnDefinition"
        :key="i"
        :field="column.field"
        :visible="(column._derived && column._derived.isColumnGroupColumn) || ((!column.columnGroup || expandedColumnGroups.includes(column.columnGroup)) && !hiddenColumns.includes(column.label))"
        :label="column.label"
        :sortable="column.sortable"
        :numeric="column.numeric"
        :centered="column.centered"
        :searchable="column.searchable"
        :header-class="column.headerClass"
        :cell-class="column.cellClass"
        :custom-sort="(a,b,isAsc)=>customSort(a,b,isAsc,column.field || '')"
      >
        <template v-if="(column._derived && column._derived.isColumnGroupColumn)" #header="{}">
          <b-button
            class="column-group-header"
            type="is-text"
            :icon-left="(!expandedColumnGroups.includes(column.columnGroup)) ? 'chevron-right' : 'chevron-down'"
            @click="toggleShowColumnGroup(column.columnGroup)"
          >{{ expandedColumnGroups.includes(column.columnGroup) ? 'hide' : 'show' }} {{ preventSplittingByWordLength(column.label) }}</b-button>
          <!-- <span class="references-table" @click="toggleShowReferences">
            <b-icon :icon="(!showReferences)?'chevron-right':'chevron-down'" size="is-small" />
            {{ showReferences?'hide':'show' }} References
          </span>-->
        </template>
        <template v-else-if="(column.tooltip)" #header="{}">
          {{ preventSplittingByWordLength(column.label) }}
          <VTooltipIconHelp :text="column.tooltip" position="is-bottom" />
        </template>
        <template v-else #header="{}">
          <span
            v-if="pagination_sortField === column.field"
            class="icon sort-icon is-small is-desc"
          >
            <b-icon
              :icon="`arrow-${pagination_sortDirection === 'desc' ? 'up' : 'down'}`"
              size="is-small"
            />
          </span>
          {{ preventSplittingByWordLength(column.label) }}
        </template>

        <template #searchable="props">
          <template v-if="pagination_getFilterConfig(column.field)">
            <b-field>
              <VFilterDateDropdownView
                v-if="pagination_getFilterConfig(column.field).type === 'date'"
                :config="pagination_getFilterConfig(column.field)"
                :position="dropdownPosition(props.column)"
              />
              <VFilterCategoriesDropdownView
                v-else-if="pagination_getFilterConfig(column.field).type === 'categories'"
                :config="pagination_getFilterConfig(column.field)"
                :position="dropdownPosition(props.column)"
              />
              <VFilterDropdownView
                v-else
                :config="pagination_getFilterConfig(column.field)"
                :position="dropdownPosition(props.column)"
              />
            </b-field>
          </template>
          <!-- if no filterconfig is given, display the local search field -->
          <b-field v-else>
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Local Search"
              icon="search"
              size="is-small"
            />
          </b-field>
        </template>

        <template #default="props">
          <slot
            v-if="$scopedSlots[`column_${column.field.replaceAll('.','_')}`]"
            :name="`column_${column.field.replaceAll('.','_')}`"
            :row="props.row"
          />

          <b-field v-else-if="(localData[props.row.id]|| {}).editing && column.editable">
            <VInputMultiCategorySelection
              v-if="column.field.includes('categoryIDs')"
              :selected-category-i-ds="accessorStringToValue(props.row, column.field)"
              class="is-small"
              style="max-width: 500px; min-width: 200px;"
              :categories-doc="$categories"
              @selected="v=>assignValueBasedOnAccessorString(props.row,column.field,v)"
            />

            <b-taginput
              v-else-if="column.field.startsWith('reference.identifierValues')"
              :value="accessorStringToValue(props.row, column.field)"
              ellipsis
              size="is-small"
              placeholder
              @input="v=>assignValueBasedOnAccessorString(props.row,column.field,v)"
            />

            <b-numberinput
              v-else-if="column.numeric"
              controls-alignment="right"
              min-step="0.001"
              :use-html5-validation="false"
              controls-position="compact"
              size="is-small"
              :value="Number(accessorStringToValue(props.row, column.field))"
              :placeholder="column.label"
              @input="v=>assignValueBasedOnAccessorString(props.row,column.field,v)"
            />

            <!-- handle editing for display image -->
            <b-field v-else-if="column.display === 'image'">
              <!-- if upload path is missing, show an error -->
              <b-message
                v-if="!uploadPath || !accessorStringToValue(props.row, '_local.docPath')"
                type="is-danger"
                :has-icon="true"
                icon="alert-circle"
              >UploadPath prop is missing</b-message>

              <p
                v-if="accessorStringToValue(props.row, column.field)"
                class="control image-preview"
              >
                <button
                  :style="{backgroundImage: `url(${accessorStringToValue(props.row, column.field)}), repeating-linear-gradient(45deg, #aaa, #aaa 5px, #ddd 5px, #ddd 10px)`}"
                  style="width: 40px;"
                  class="button"
                />
                <img
                  class="tile-image-preview"
                  style="background-image: repeating-linear-gradient(45deg, #aaa, #aaa 10px, #ddd 10px, #ddd 20px);"
                  :src="accessorStringToValue(props.row, column.field)"
                  alt
                />
              </p>
              <p class="control tile-image-upload-button">
                <b-button icon-left="upload" @click="isImageUploadModalActive = true">
                  {{
                    accessorStringToValue(props.row, column.field)
                      ? 'Change Image'
                      : 'Add Image'
                  }}
                </b-button>
              </p>
              <p v-if="accessorStringToValue(props.row, column.field)" class="control">
                <b-button
                  @click="assignValueBasedOnAccessorString(props.row,column.field,'')"
                >clear image</b-button>
              </p>

              <VImageUploadModal
                :active.sync="isImageUploadModalActive"
                name-prefix="table-upload-"
                :max-filesize="1024 * 1024"
                :max-image-width="800"
                :min-image-width="800"
                :allow-variable="false"
                :url="accessorStringToValue(props.row, column.field) || ''"
                :upload-path="uploadPath"
                target-format="image/png"
                :uploader-document-path="accessorStringToValue(props.row, '_local.docPath')"
                @input="url => assignValueBasedOnAccessorString(props.row,column.field,url)"
              />
            </b-field>

            <!-- handle boolean input -->
            <b-switch
              v-else-if="column.boolean"
              :value="accessorStringToValue(props.row, column.field)"
              @input="v=>assignValueBasedOnAccessorString(props.row,column.field,v)"
            />

            <b-input
              v-else
              size="is-small"
              :value="accessorStringToValue(props.row, column.field)"
              :placeholder="column.label"
              @input="v=>assignValueBasedOnAccessorString(props.row,column.field,v)"
            />
          </b-field>

          <span v-else-if="!(column._derived && column._derived.isColumnGroupColumn)">
            <b-taglist v-if="column.display === 'taglist'">
              <b-tag
                v-for="(value,key) in ((column.formatter) ? column.formatter(accessorStringToValue(props.row, column.field)) : accessorStringToValue(props.row, column.field)).filter(v=>v) "
                :key="key"
              >{{ value }}</b-tag>
            </b-taglist>
            <b-taglist v-else-if="column.display === 'colored-taglist'">
              <b-tag
                v-for="(value, key) in ((column.formatter) ? column.formatter(accessorStringToValue(props.row, column.field)) : accessorStringToValue(props.row, column.field)).filter(v=>v) "
                :key="key"
                :style="`background-color: ${value.color}; color: ${getTextColor(value.color)};`"
              >{{ value.name }}</b-tag>
            </b-taglist>
            <b-taglist v-else-if="column.display === 'tag'">
              <b-tag
                v-for="(value,key) in [(column.formatter) ? column.formatter(accessorStringToValue(props.row, column.field)) : accessorStringToValue(props.row, column.field)].filter(v=>v) "
                :key="key"
              >{{ value }}</b-tag>
            </b-taglist>
            <b-taglist v-else-if="column.display === 'image'" class="image-preview">
              <b-tooltip
                v-if="accessorStringToValue(props.row, column.field)"
                position="is-top"
                multilined
                append-to-body
              >
                <div
                  :style="{backgroundImage: `url(${((column.formatter) ? column.formatter(accessorStringToValue(props.row, column.field)) : accessorStringToValue(props.row, column.field))})`}"
                  class="thumbnail-image"
                />
                <template #content>
                  <img
                    style="background-image: repeating-linear-gradient(45deg, #aaa, #aaa 10px, #ddd 10px, #ddd 20px);"
                    :src="((column.formatter) ? column.formatter(accessorStringToValue(props.row, column.field)) : accessorStringToValue(props.row, column.field))"
                    alt
                  />
                </template>
              </b-tooltip>
            </b-taglist>
            <span
              v-else
            >{{ (column.formatter) ? column.formatter(accessorStringToValue(props.row, column.field)) : accessorStringToValue(props.row, column.field) }}</span>
          </span>
        </template>
      </b-table-column>

      <b-table-column
        v-if="deleteActionCallback || $scopedSlots.actions || updateElementCallback"
        v-slot="props"
        label="Actions"
        centered
      >
        <b-field>
          <b-field>
            <p
              v-if="updateElementCallback && (localData[props.row.id]|| {}).editing"
              class="control"
            >
              <b-button
                size="is-small"
                icon-right="save"
                type="is-success"
                @click="updateElementCallback && updateElement(props.row.id)"
              />
            </p>

            <p v-if="updateElementCallback" class="control">
              <b-button
                size="is-small"
                :icon-right="(localData[props.row.id]|| {}).editing ? 'times' : 'edit'"
                @click="toggleEditElement(props.row.id)"
              />
            </p>

            <p v-if="deleteActionCallback" class="control">
              <b-button
                outlined
                type="is-danger"
                size="is-small"
                title="delete entry"
                icon-right="trash"
                @click="deleteActionCallback && deleteActionCallback(props.row.id)"
              />
            </p>
            <slot name="actions" :row="props.row" />
          </b-field>
        </b-field>
      </b-table-column>
    </b-table>

    <b-loading :is-full-page="false" :active="isAnyLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import VPaginationMixin, { FilterConfig } from '@/components/mixins/VPaginateMixin.vue'
import VButtonToggleLiveUpdate from '@/components/VButtonToggleLiveUpdate.vue'
import VFilterCategoriesDropdownView from '@/components/VFilterCategoriesDropdownView.vue'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import VFilterDropdownView from '@/components/VFilterDropdownView.vue'
import VImportExport, { typeImportExportDefinitions } from '@/components/VImportExport.vue'
import { accessorStringToValue, acessorObjectToString, assignValueBasedOnAccessorString } from '@/database/dbHelper'
import { firebase } from '@/firebase'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { BaseElementDB } from '@/modules/typeModules'
import { BaseDB } from '@/types/typeBase'
import { DeepPartial, hasDBid, objectID } from '@/types/typeGeneral'

import { mixins } from 'vue-class-component'
import { Component, Emit, Prop, PropSync, Watch } from 'vue-property-decorator'
import { FilterConfigNew } from '@/database/filterUtil'
import { getContrastColor } from '@/helpers/colorHelper'
import VTableColumnsDropdown from './VTableColumnsDropdown.vue'
import VImageUploadModal from '@/components/image/VImageUploadModal.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { diff } from 'deep-diff'
import { identicalArray } from '@/helpers/arrayHelper'

library.add(faSearch)

export interface TableColumnDefinition<T = any> {
  // provide either field or fieldAccessor (e.g. 'data.name' or {data:{name:''}})
  field?: string
  fieldAccessor?: DeepPartial<T>

  // label used in table header
  label: string

  // tooltip to shown on the label
  tooltip?: string

  // column is numeric
  numeric: boolean

  // columns is boolean
  boolean?: boolean

  // column is searchable
  searchable: boolean

  // column is sortable
  sortable: boolean

  // formats the columns value
  formatter?: (value: any) => any

  // display type. If not set, the value is displayed as text
  display?: 'taglist' | 'tag' | 'colored-taglist' | 'image'

  // column is editable
  editable?: boolean

  // table header class
  headerClass?: string

  // table cell class
  cellClass?: string

  // column is centered
  centered?: boolean

  // column is part of a column group, which can be expanded
  columnGroup?: string

  // values not supplied by the user, but are automatcially set
  _derived?: {
    // column is a special column which shows a chevron to expand the column group
    isColumnGroupColumn?: boolean
  }
}

@Component({
  components: {
    VButtonToggleLiveUpdate,
    VFilterCategoriesDropdownView,
    VInputMultiCategorySelection,
    VFilterDropdownView,
    VImportExport,
    VTableColumnsDropdown,
    VImageUploadModal
  }
})
export default class VTable extends mixins<VPaginationMixin<any>>(VPaginationMixin) {
  // #region import/export

  @Prop({ type: Array, required: false, default: () => [] })
  public importExport_importExportDefinitions!: typeImportExportDefinitions<any>

  @Prop({ type: Function, required: false })
  public importExport_validateImportedData?: (importedData: Partial<hasDBid>[]) => void

  @Prop({ type: Function, required: false })
  public importExport_getDoc?: (importedData: Partial<hasDBid>) => Promise<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>

  @Prop({ type: Function, required: false })
  public importExport_getDefaultDoc?: () => BaseDB

  @Prop({ type: Function, required: false })
  public importExport_updateDocBatch?: (docId: string, docData: any, batch: firebase.firestore.WriteBatch) => firebase.firestore.WriteBatch

  @Prop({ type: Function, required: false })
  public importExport_createDocBatch?: (docData: BaseElementDB, batch: firebase.firestore.WriteBatch) => firebase.firestore.WriteBatch

  @Prop({ type: Function, required: false })
  public importExport_beforeGetCurrentData?: (importedData: hasDBid[]) => Promise<hasDBid[]>

  // #endregion import/export

  // #region filter url query

  private updateFilterConfigFromUrlOnce = true


  @Watch('pagination_sortField')
  @Watch('pagination_sortDirection')
  private async convertSortFieldAndDirectionToQueryUrl() {
    console.debug('convertSortFieldAndDirectionToQueryUrl')

    // query object
    const newUrlQuery = {
      ...this.$route.query
    }

    // set sort field and direction
    if (this.pagination_sortField && this.pagination_sortField !== 'id')
      newUrlQuery.sortField = this.pagination_sortField

    if (this.pagination_sortDirection)
      newUrlQuery.sortDirection = this.pagination_sortDirection


    // avoid redunant navigation
    const queryChanges = diff(this.$route.query, newUrlQuery)
    if (!queryChanges)
      return

    await this.$router.replace({ query: newUrlQuery, hash: this.$route.hash })
  }


  @Watch('sortFieldSync', { immediate: true })
  @Watch('sortDirectionSync')
  private convertQueryUrlToSortField() {
    console.debug('convertQueryUrlToSortField')

    // query object
    const query = this.$route.query

    // get sort field from query
    const sortField = query.sortField
    const sortDirection = query.sortDirection as 'asc' | 'desc'

    if (['asc', 'desc'].includes(sortDirection))
      this.pagination_sortDirection = sortDirection as 'asc' | 'desc'

    if (typeof sortField === 'string')
      this.pagination_sortField = sortField
  }


  @Watch('pagination_filterConfig', { deep: true })
  private async convertFilterToQueryUrl() {
    console.debug('convertFilterToQueryUrl')

    if (this.filterConfig.length === 0) {
      console.log('no filter config')
      return
    }

    // query object
    const newUrlQuery = {
      ...this.$route.query,
      ...this.pagination_filterConfig.reduce((acc, filter) => {
        const field = acessorObjectToString(filter.fieldAccesor)
        const value = filter.in

        acc[field] = (value.length === 0 || value[0] === '') ? undefined : value

        // if array with length 0, convert to literal value
        if (acc[field] && acc[field].length === 1)
          acc[field] = acc[field][0]

        return acc
      }, {} as { [key: string]: any })
    }

    // filter out all undefined values, since they do not appear in the url, but would result in an url update
    const newQueryWithoutUndefined = Object.fromEntries(Object.entries(newUrlQuery).filter(([_, v]) => v !== undefined))

    // avoid redunant navigation
    const queryChanges = diff(this.$route.query, newQueryWithoutUndefined)
    if (!queryChanges)
      return
    // debugger

    await this.$router.replace({ query: newUrlQuery })
  }

  @Watch('filterConfig', { immediate: true, deep: true })
  @Watch('$route')
  private convertQueryUrlToFilter(oldValue?: FilterConfig<any>[], newValue?: FilterConfig<any>[]) {
    console.debug('convertQueryUrlToFilter')

    this.convertQueryUrlToSortField()

    // query object
    const query = this.$route.query

    // convert query to filter
    const newFilterConfig = cloneObject(this.filterConfig).map((filter) => {
      const field = acessorObjectToString(filter.fieldAccesor)
      let queryValue: (string | boolean | null) | (string | boolean | null)[] = query[field]

      if (!Array.isArray(queryValue))
        queryValue = [queryValue]

      queryValue = queryValue.filter((v): v is string => v !== null && v !== undefined)
        .map((v) => {
          if (v === 'false')
            return false
          else if (v === 'true')
            return true
          else if (v === 'null')
            return null
          else
            return v
        })

      // if the value in the filterconfig changed, use the new value
      const oldFilterConfigValue = Array.isArray(oldValue) ? oldValue?.find((f) => acessorObjectToString(f.fieldAccesor) === field)?.in : undefined
      const newFilterConfigValue = Array.isArray(newValue) ? newValue?.find((f) => acessorObjectToString(f.fieldAccesor) === field)?.in : undefined
      // console.debug('oldFilterConfigValue', oldFilterConfigValue)
      // console.debug('newFilterConfigValue', newFilterConfigValue)

      if (Array.isArray(oldFilterConfigValue)
        && Array.isArray(newFilterConfigValue)
        && !identicalArray(oldFilterConfigValue as any[], newFilterConfigValue as any[])
      ) {
        // filter.in = queryValue as string[]
        console.debug('external filter config changed')
      } else if (queryValue.length === 0 && filter.in.length > 0) { // if value is given in filterConfig, but not in query, use the filterConfig value
        // use filterConfig queryValue
        // filter.in = filter.in
      } else {
        // use query queryValue
        filter.in = queryValue as string[]
      }

      return filter
    })

    // // get sort field from query
    // const sortField = query.sortField
    // const sortDirection = query.sortDirection as 'asc' | 'desc'

    // if (['asc', 'desc'].includes(sortDirection))
    //   this.pagination_sortDirection = sortDirection as 'asc' | 'desc'

    // if (this.filterConfig.find(f => acessorObjectToString(f.fieldAccesor) === sortField) && typeof sortField === 'string')
    //   this.pagination_sortField = sortField

    // avoid redunant navigation
    const filterChanges = diff(this.pagination_filterConfig, newFilterConfig)
    if (!filterChanges)
      return

    this.pagination_filterConfig = newFilterConfig
  }

  // #endregion filter url query

  // #region editing
  public localData: { [key: string]: { editing: boolean } } = {}

  public toggleEditElement(id: objectID) {
    if (!(id in this.localData))
      this.$set(this.localData, id, {
        editing: false
      })

    this.localData[id].editing = !this.localData[id].editing
  }

  public endEditElement(id: objectID) {
    if (id in this.localData)
      this.localData[id].editing = false
  }

  private isUpdateLoading = false

  public async updateElement(id: string) {
    try {
      this.isUpdateLoading = true
      await this.updateElementCallback?.(id)
      this.endEditElement(id)
    } catch (error) {
      this.$helpers.notification.Error('Error updating Element [20221203]: ' + error)
    } finally {
      this.isUpdateLoading = false
    }
  }

  public externalUpdateElement() {
    const promises = []

    if (this.updateElementCallback)
    // iterate all edited rows and update them
      for (const id in this.localData)
        if (this.localData[id].editing)
          promises.push(this.updateElement(id))

    return Promise.all(promises)
  }
  // #endregion editing


  @Prop({ type: Array, required: true })
  public readonly columnDefinition!: TableColumnDefinition[]

  /** includes colum groups */
  public localColumnDefinition: (TableColumnDefinition & { field: string, columnGroup: string })[] = []

  @Prop({ type: String, required: true })
  public readonly collectionPath!: any

  public pagination_collectionPath = this.collectionPath

  @Watch('collectionReference', { immediate: true })
  private onChange_collectionReference() {
    this.refreshData(true)
  }


  @Prop({ type: Array, required: true })
  public readonly filterConfig!: FilterConfig<any>[]

  // @Watch('filterConfig', { immediate: true, deep: true })
  // private onFilterConfigChanged() {
  //   this.pagination_filterConfig = cloneObject(this.filterConfig)
  // }

  @Prop({ type: Function, required: false })
  public readonly deleteActionCallback?: (rowID: string) => void

  @Prop({ type: Function, required: false })
  public readonly updateElementCallback?: (rowID: string) => Promise<void>

  @Prop({ type: Function, required: false, default: () => [] })
  public readonly queryFilter!: () => FilterConfigNew[]

  public pagination_filter = this.queryFilter

  @Prop({ type: Function, required: false, default: (data: any) => data })
  public readonly localDocsFilter!: (docs: (any & hasDBid)[]) => (any & hasDBid)[]

  public pagination_localDocsFilter = this.localDocsFilter

  //   protected pagination_filter(query: firebase.firestore.Query<firebase.firestore.DocumentData>) {
  //   query = typedWhere<BaseResponseDB>(query, { publishingState: 'deleted' }, 'not-in', ['deleted'])
  //   query = query.orderBy('publishingState')
  //   return typedWhere<BaseResponseDB>(query, { elementID: '' }, '==', this.moduleElement.id)
  // }

  @PropSync('sortFieldAccessor', { type: String, required: true })
  public sortFieldSync!: string

  @Watch('pagination_sortField')
  private onChange_sortField() {
    this.sortFieldSync = this.pagination_sortField
  }

  @Watch('sortFieldSync', { immediate: true })
  private onChange_sortFieldSync() {
    this.pagination_sortField = this.sortFieldSync
  }

  @PropSync('sortDirection', { type: String, required: true })
  public sortDirectionSync!: 'asc' | 'desc'

  @Watch('pagination_sortDirection', { immediate: false })
  private onChange_sortDirection() {
    this.sortDirectionSync = this.pagination_sortDirection
  }

  @Watch('sortDirectionSync', { immediate: true })
  private onChange_sortDirectionSync() {
    this.pagination_sortDirection = this.sortDirectionSync
  }

  @PropSync('itemsPerPage', { type: Number, required: false, default: 20 })
  public perPageSync!: number

  @Watch('pagination_perPage', { immediate: false })
  private onChange_perPage() {
    this.perPageSync = this.pagination_perPage
  }

  @Watch('perPageSync', { immediate: true })
  private onChange_perPageSync() {
    this.pagination_perPage = this.perPageSync
  }

  @PropSync('liveUpdateOnFirstPage', { type: Boolean, required: true })
  public liveUpdateOnFirstPageSync!: boolean

  @Watch('pagination_liveUpdateOnFirstPage')
  private onChange_liveUpdateOnFirstPage() {
    this.liveUpdateOnFirstPageSync = this.pagination_liveUpdateOnFirstPage
  }

  @Watch('liveUpdateOnFirstPageSync', { immediate: true })
  private onChange_liveUpdateOnFirstPageSync() {
    this.pagination_liveUpdateOnFirstPage = this.liveUpdateOnFirstPageSync
  }

  @PropSync('selectedRowId', { type: String, required: false })
  public selectedRowIdSync!: string

  get localSelectedRow() {
    return this.pagination_paginatedData.find((d) => d.id === this.selectedRowIdSync)
  }

  set localSelectedRow(row: any) {
    this.selectedRowIdSync = row.id || ''
  }

  // #region image upload
  // uploadPath prop
  @Prop({ type: String, required: false, default: () => '' }) readonly uploadPath!: string

  public isImageUploadModalActive = false

  // #endregion image upload


  public getTextColor(hexcolor: string) {
    return getContrastColor(hexcolor)
  }

  public importExport_isLoading = false

  get isAnyLoading() {
    const anyLoading = this.pagination_isPaginationLoading
      || this.importExport_isLoading
      || this.isUpdateLoading
    this.$emit('loading', anyLoading)
    return anyLoading
  }

  public expandedColumnGroups: string[] = []

  @Watch('columnDefinition', { immediate: true })
  onUpdateColumnGroups() {
    // insert a 'special' column for each column group which shows a chevron to expand this group
    const processedColumnGroups: string[] = []

    // copy to not modify the prop
    const columnDefinition = cloneObject(this.columnDefinition)

    const targetColumnDef: (TableColumnDefinition & { field: string, columnGroup: string })[] = []

    for (let index = 0; index < columnDefinition.length; index++) {
      const colummDef = columnDefinition[index] as (TableColumnDefinition & { field: string, columnGroup: string })

      // get either field or fieldAccessor
      const field = colummDef.field || acessorObjectToString(colummDef.fieldAccessor || {})

      colummDef.field = field
      colummDef._derived = colummDef._derived || {}
      colummDef.columnGroup = colummDef.columnGroup || ''

      // process column groups
      if (colummDef.columnGroup && !processedColumnGroups.includes(colummDef.columnGroup)) {
        processedColumnGroups.push(colummDef.columnGroup)

        console.debug('column group', colummDef.columnGroup, 'found at index', index)

        targetColumnDef.push({
          ...colummDef,
          field: colummDef.columnGroup,
          label: colummDef.columnGroup,
          numeric: false,
          searchable: false,
          sortable: false,
          editable: false,
          _derived: {
            ...colummDef._derived,
            isColumnGroupColumn: true
          }
        })
      }

      targetColumnDef.push(colummDef)
    }

    this.localColumnDefinition = targetColumnDef
  }

  /**
* function to prevent splitting into more than three lines, by relacing all spaces with non-breaking spaces
* but only allow for N breaking spaces. Those must be placed in the most even position
*/
  public preventSplittingByWordLength(text: string, targetLines = 3): string {
    // replace hypthens with non-breaking hypthens
    const words = text.replace(/-/g, '\u2011').split(' ')

    // If the number of words is less than or equal to the target lines, return all non-breaking spaces
    if (words.length <= targetLines) {
      return words.join('\u00A0')
    }

    // Calculate the total number of characters in the string (excluding spaces)
    const totalChars = words.reduce((acc, word) => acc + word.length, 0)

    // The ideal chunk length to split the text evenly based on the number of lines
    const idealChunkSize = totalChars / targetLines

    // Array to store the breaking points (indexes where regular spaces will be placed)
    const breakingPoints: number[] = []
    let currentChunkSize = 0

    // Loop through the words and decide where to place the breaking spaces
    for (let i = 0; i < words.length - 1; i++) {
      currentChunkSize += words[i].length

      // Place a break point if the current chunk size exceeds the ideal chunk size
      if (breakingPoints.length < targetLines - 1 && currentChunkSize >= (breakingPoints.length + 1) * idealChunkSize) {
        breakingPoints.push(i)
      }
    }

    // Build the final string with spaces replaced as appropriate
    let result = ''
    for (let i = 0; i < words.length; i++) {
      result += words[i]

      if (i < words.length - 1) {
        // Insert regular breaking space at calculated positions, else non-breaking space
        if (breakingPoints.includes(i)) {
          result += ' ' // Regular breaking space
        } else {
          result += '\u00A0' // Non-breaking space
        }
      }
    }

    return result
  }

  get hiddenColumns() {
    if (!(this.$route.path in this.$localSettings.table.hiddenColumns)) {
      this.$set(this.$localSettings.table.hiddenColumns, this.$route.path, [])
    }

    return this.$localSettings.table.hiddenColumns[this.$route.path]
  }

  set hiddenColumns(hiddenColumns: string[]) {
    this.$localSettings.table.hiddenColumns[this.$route.path] = hiddenColumns
  }

  public dropdownPosition(column: TableColumnDefinition) {
    // open dropdown to the left if the column is at the right end of the table
    const COLUMNS_FROM_RIGHT_END = 3
    const isLastColumn = (this.localColumnDefinition.length - this.localColumnDefinition.map((c) => c.label).indexOf(column.label)) < COLUMNS_FROM_RIGHT_END

    return isLastColumn ? 'is-bottom-left' : 'is-bottom-right'
  }

  public toggleShowColumnGroup(groupName: string) {
    // toggle group name in array
    const index = this.expandedColumnGroups.indexOf(groupName)

    if (index === -1) {
      this.expandedColumnGroups.push(groupName)
    } else {
      this.expandedColumnGroups.splice(index, 1)
    }
  }

  created() {
    // this.pagination_getData(true)
  }

  /**
   * can be called from parent component to force update data. e.g. after bulk update
   */
  public refreshData(configChanged = false) {
    this.updateFilterConfigFromUrlOnce = true

    if (!this.pagination_liveUpdateActive || configChanged)
      this.pagination_getData(true)
  }

  public accessorStringToValue(obj: any, acessor: string) {
    const value = accessorStringToValue(obj, acessor)
    // console.log('acessorStringToValue', obj, acessor, value)
    return value
  }

  public assignValueBasedOnAccessorString(obj: any, acessor: string, value: any) {
    // cast to string if value is not array
    if (!Array.isArray(value))
      value = String(value)
    assignValueBasedOnAccessorString(obj, acessor, value)
  }


  // used as key to recreate table on column change
  public get columnsHash() {
    return this.columnDefinition.map((c) => c.field).join('')
  }

  @Watch('pagination_paginatedData', { immediate: true })
  @Emit('table-data')
  onPagination_paginatedDataChange() {
    return this.pagination_paginatedData
  }

  @Watch('pagination_checkedRows', { immediate: true })
  @Emit('checked-rows')
  onPagination_checkedRowsChange() {
    return this.pagination_checkedRows
  }

  /**
 * a custom sort is required since by default null values are always sorted to the end
 * this sort is kinda borrowd from buefy 9.x but the isNil direction is reversed
 */
  public customSort(a: any, b: any, isAsc: boolean, key: string) {
    const isNil = (value: any) => value === null || value === undefined

    function getValueByPath(obj: any, path: string) {
      let value = path.split('.').reduce(function (o, i) {
        return o ? o[i] : null
      }, obj)
      return value
    }

    // Get nested values from objects
    let newA = getValueByPath(a, key)
    let newB = getValueByPath(b, key)
    // sort boolean type
    if (typeof newA === 'boolean' && typeof newB === 'boolean') {
      return isAsc ? Number(newA) - Number(newB) : Number(newB) - Number(newA)
    }
    // sort null values to the bottom when in asc order
    // and to the top when in desc order
    if (!isNil(newB) && isNil(newA)) return !isAsc ? 1 : -1
    if (!isNil(newA) && isNil(newB)) return !isAsc ? -1 : 1
    if (newA === newB) return 0

    newA = (typeof newA === 'string')
      ? newA.toUpperCase()
      : newA
    newB = (typeof newB === 'string')
      ? newB.toUpperCase()
      : newB

    return isAsc
      ? newA > newB ? 1 : -1
      : newA > newB ? -1 : 1
  }
}
</script>

<style lang="scss">
.v-table {
  .table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .image-preview {
    position: relative;
    cursor: pointer;

    .b-tooltip {
      flex-grow: 1;
    }

    img.tile-image-preview {
      bottom: 2rem;
      display: none;
      position: absolute;
      max-width: 400px;
      max-height: 400px;
      padding: 1em;
      z-index: 9999;
      // border: 1px solid #e4e4e4;
      background: white;
      // add shadow
      box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
    }

    &:hover {
      img.tile-image-preview {
        display: block;
      }
    }

    .thumbnail-image {
      background-size: cover;
      max-width: 4rem;
      height: 2rem;
      width: 100%;
      border: 1px solid white;
      outline: 1px solid #dbdbdb;
      border-radius: 3px;
    }
  }

  .table.is-narrow {
    .image-preview {
      .thumbnail-image {
        height: 1.6rem;
      }
    }
  }

  button.button.column-group-header.is-text {
    padding: 0;
    height: 1.5rem;
    font-weight: bold;
    text-decoration: none;
  }
}
</style>
