import { I18nGlobalsInst } from './i18nGlobals'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { CategoryGlobalsInst } from './categoryGlobals'
import { LocalSettingsGlobalsInst } from './localSettingsGlobals'
import { BackendConfigGlobalsInst } from './backendConfigGlobals'
import { ProtectionGlobalsInst } from './protectionGlobals'
import { UserGlobalsInst } from './userGlobals'
import { PlanGlobalsInst } from './planGlobals'
import { BaseAuth } from '@/helpers/baseAuthUtil'

/**
 * register globals here to perform init
 * also register globals in backend.ts
 */

export default function initGlobals(auth: BaseAuth) {
  const disposeSnapShots: Promise<SnapshotUnbindHandle>[] = []

  console.log('init globals')

  disposeSnapShots.push(I18nGlobalsInst.init(auth.tenantID, auth.userPrivileges))
  disposeSnapShots.push(BackendConfigGlobalsInst.init(auth.tenantID, auth.userPrivileges))
  disposeSnapShots.push(CategoryGlobalsInst.init(auth.tenantID, auth.userPrivileges))
  disposeSnapShots.push(ProtectionGlobalsInst.init(auth.tenantID, auth.userPrivileges))
  disposeSnapShots.push(UserGlobalsInst.init(auth.tenantID, auth.userPrivileges))
  disposeSnapShots.push(LocalSettingsGlobalsInst.init(auth.tenantID, auth.userID))
  disposeSnapShots.push(PlanGlobalsInst.init(auth.tenantID, auth.userPrivileges))

  return Promise.all(disposeSnapShots)
}
