import { ModuleType } from '@/modules/typeModules'

import { RouteConfig, RawLocation } from 'vue-router'
import BaseModule from '@/modules/baseModule'

import { LinkModuleDB, LinkElementDB, RequiredLinkPrivileges, LinkGroupDB } from './typeLinkModule'

import { UserPrivilegeIdDB } from '@/types/typeUser'
import { Database } from '@/database/databaseSchema'
import { PageConfig } from '@/types/typeAppConfig'
import { DeepPartial } from '@/types/typeGeneral'

export default class LinkModule extends BaseModule {
  public static type: ModuleType = 'Link'
  public static displayName = 'Link'
  public static color = '#bdd933'
  public static description = 'Create internal and external links.'
  public static descriptionLong = 'Create internal links to Pages or external links to URLs.'

  public static authPrivileges: RequiredLinkPrivileges = {
    r: ['link:read'],
    w: ['link:write'],
    view: ['link:view']
  }

  public static moduleDB: LinkModuleDB = Database.defaultLinkModuleDB
  public static elementDB: LinkElementDB = Database.defaultLinkElementDB
  public static groupDB: LinkGroupDB = Database.defaultLinkGroupDB

  public static viewOptions = [
    ['button', 'Button'],
    ['tile-half', 'Tile Half'],
    ['tile-full', 'Tile Full'],
    ['tile-auto', 'Auto Tile']
  ]

  constructor() {
    super()
  }

  public static pagesForAutomaticNaming: PageConfig[] = []

  public static automaticElementNaming(element: DeepPartial<LinkElementDB>): string {
    // if not all required properties to set the name are available, return the default name
    if (
      !element.public
      || !element.public.linkType
      || !('pageID' in element.public)
      || typeof element.name !== 'string'
    ) {
      console.log('not all required properties to set the name are available')
      return this.defaultElementName
    }

    if (element.name === this.defaultElementName || element.name.startsWith('Link to')) {
      const page = this.pagesForAutomaticNaming.find((p) => p.id === (element.public?.pageID ?? ''))
      if (page && element.public.linkType === 'linktype_page') {
        return `Link to ${page.name}`
      } else if (element.public.linkType === 'linktype_url') {
        // get the domain from the link
        // const url = new URL(element.public.link.locales)
        // element.name = `Link to ${url.hostname}`
        return 'Link to url'
      }
    }
    return element.name
  }

  public static getNavigationItems(): Array<{
    to: RawLocation
    displayName: string
    requiredPrivileges?: UserPrivilegeIdDB[]
  }> {
    return [
      {
        to: { name: 'module-link-list' },
        displayName: this.displayName
      }
    ]
  }

  public static getRoutes(): RouteConfig[] {
    return [
      {
        path: 'module/link',
        name: this.routeNameList,
        component: () => import('./Backend_Module_Link_List.vue'),
        meta: {
          label: this.displayName,
          description: 'Manage your Links',
          privileges: this.authPrivileges.view,
          breadcrumbs: false,
          isFullsize: true
        },
        children: [
          {
            path: 'groups/:groupID',
            name: this.routeNameGroup,
            component: () => import('./Backend_Module_Link_Group_Single.vue'),
            meta: {
              label: 'Links Widget',
              description: 'Manage your Links Widget',
              isFullsize: true
            },
            props: true
          },
          {
            path: ':id',
            name: this.routeNameElement,
            component: () => import('./Backend_Module_Link_Single.vue'),
            meta: {
              label: 'Links',
              description: 'Manage your Links',
              breadcrumbs: false,
              isFullsize: true
            },
            props: true
          }
        ]
      }
    ]
  }
}
