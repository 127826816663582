<template>
  <!-- <div class="category-selection is-fullwidth"> -->
  <b-field group-multiline>
    <b-select
      v-for="(categoryID, index) in selectedIDs"
      :key="categoryID"
      v-model="selectedIDs[index]"
      :disabled="$props.disabled"
      placeholder="Select a category"
      @input="onSelectCategory"
    >
      <option
        v-for="option in $props.flatCategoriesTree[categoryID].parentIDCategory.Children"
        :key="option.id"
        :value="option.id"
      >{{ option.name }}</option>
    </b-select>

    <b-select
      v-if="!$props.disabled &&
        $props.flatCategoriesTree[selectedID] &&
        $props.flatCategoriesTree[selectedID].Children &&
        $props.flatCategoriesTree[selectedID].Children.length > 0 "
      placeholder="Subcategory"
      @input="onSelectCategory"
    >
      <option key="---" value />
      <option
        v-for="option in $props.flatCategoriesTree[selectedID].Children"
        :key="option.id"
        :value="option.id"
      >{{ option.name }}</option>
    </b-select>
    <b-autocomplete
      v-if="!$props.disabled"
      v-model="search"
      class="field is-expanded"
      placeholder="search Category"
      :keep-first="true"
      :open-on-focus="true"
      :data="filteredDataObj"
      field="path"
      @select="opt => onSelectCategory(opt && opt.id)"
    />
  </b-field>
  <!-- </div> -->
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'

import { CategoryFlattened } from '@/database/categoryHelper'

@Component({
  components: {},
  model: {
    prop: 'selectCategoryID',
    event: 'selected'
  },
  props: {
    flatCategoriesTree: { // tree of categoeirs
      type: Object, // CategoryFlattened
      required: true
    },
    selectCategoryID: {
      // selecetd category ID
      type: String,
      required: true,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  }
})
export default class VInputCategorySelection extends Vue {
  public search = ''
  public selectedID = ''

  public get filteredDataObj() {
    return Object.values(this.$props.flatCategoriesTree).filter(
      (option: any) =>
        option.path
        && option.path
          .toString()
          .toLowerCase()
          .indexOf(this.search.toLowerCase()) >= 0
    )
  }

  public get selectedIDs(): Array<string> {
    const flatCategoryTree = this.$props.flatCategoriesTree as { [k: string]: CategoryFlattened }
    if (!(this.selectedID in flatCategoryTree)) {
      console.warn(`selectd category ${this.selectedID} not found`)
      return []
    }

    let tempCat = flatCategoryTree[this.selectedID]
    const tempSelectedCats = []

    // add all cats going up the tree to the selection
    while (tempCat.parentCategory != null) {
      tempSelectedCats.push(tempCat.id)
      tempCat = tempCat.parentCategory as CategoryFlattened
    }

    return tempSelectedCats.reverse()
  }

  /**
   * selectCategory
   */
  public onSelectCategory(categoryID: string) {
    if (categoryID) {
      this.search = ''
      this.$emit('selected', categoryID)
    }
  }

  /**
   * mounted
   */
  public mounted() {
    // this.selectedID = this.$props.value todo done by immediate watch?
  }

  @Watch('selectCategoryID', { immediate: true })
  onValueChanged(val: string, oldVal: string) {
    this.selectedID = val
  }
}
</script>
