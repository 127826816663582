<template>
  <section class="categories-list-container">
    <div class="level">
      <b-field class="level-left">
        <b-switch v-model="displayElements" :rounded="false">Show Elements</b-switch>
        <b-switch v-show="displayElements" v-model="propagateElements" :rounded="false">Propagate</b-switch>
      </b-field>
      <b-field grouped>
        <VImportExport
          v-if="filteredVisibleCategories.length === 0"
          :create-doc-batch="importExport_createDocBatch"
          :update-doc-batch="importExport_updateDocBatch"
          :get-default-doc="importExport_getDefaultDoc"
          :import-formatter="(d)=>d"
          :get-doc="importExport_getDoc"
          :export-docs="checkedCategories"
          :import-export-definitions="importExport_importExportDefinitions"
          :validate-imported-data="importExport_validateImportedData"
          :is-loading.sync="importExport_isLoading"
          file-name="categories-export"
          @imported="$emit('updated')"
        >
          <template
            slot="exportText"
          >Export {{ checkedCategories.length || categoriesCountPublished }} Categories</template>
        </VImportExport>
        <p class="control">
          <VPrintButton :print-container-ref="$refs.tree" />
        </p>
        <b-field>
          <VRecordMeta
            position="is-bottom-left"
            :document-path="docPath"
            :record-meta="formCategoriesDoc._meta"
            :required-privileges="documentPrivileges"
          />
        </b-field>
        <b-field>
          <VButtonSettingsModal :settings="settings" :config="settingsConfig" @save="saveSettings" />
        </b-field>
      </b-field>
    </div>

    <b-field>
      <b-input v-model="filterText" placeholder="Filter" type="search" icon="search" expanded />
      <p class="control">
        <b-button @click="expandAll">Expand All</b-button>
      </p>
      <p class="control">
        <b-button @click="collapseAll">Collapse All</b-button>
      </p>
    </b-field>
    <b-button
      v-show="categoryLiquorTree.length == 0 && !isLoading"
      @click="onAddSampleData"
    >Add Sample Data</b-button>

    <LiquorTree
      v-show="categoryLiquorTree.length > 0"
      ref="tree"
      class="treeview"
      :data="categoryLiquorTree"
      :options="treeOptions"
      :filter="filterText"
      @node:dragging:start="nodeDragStart"
      @node:dragging:finish="onNodeDragFinish"
      @node:editing:stop="onEndEditingNodeText"
      @node:checked="onUpdateCheckedItems"
      @node:unchecked="onUpdateCheckedItems"
    >
      <div slot-scope="{ node }" class="tree-scope" :class="{'is-disabled': node.data.disabled}">
        <template>
          <div class="node level">
            <div class="level-left">
              <div class>
                <span class="text">{{ node.text }}</span>
                <VTagModuleElements
                  v-show="displayElements && !node.data.disabled"
                  class="module-elements-container"
                  :elements="((propagateElements && filteredVisibleCategories.length === 0) ? node.data.inheritedElements : node.data.elements)"
                />
              </div>
            </div>

            <div class="level-right">
              <span
                class="text is-small"
                style="font-size: smaller;"
              >({{ node.data.linkedAsids }} codes linked)</span>
              <b-field v-if="!node.data.disabled" class="node-actions">
                <!-- <p class="control">
                  <b-button
                    size="is-small"
                    icon-right="save"
                    type="is-success"
                    @click.stop="updateElement(props.row.id)"
                  />
                </p>-->
                <p class="control">
                  <b-button
                    class="is-small"
                    icon-right="plus"
                    title="Add child category"
                    @click.stop="addChildNode(node)"
                  />
                </p>
                <p class="control">
                  <b-button
                    size="is-small"
                    title="Rename"
                    :icon-right="(node.state('editing'))?'times':'edit'"
                    @click.stop="editNode(node)"
                  />
                </p>

                <p class="control">
                  <b-button
                    outlined
                    type="is-danger"
                    title="Delete"
                    size="is-small"
                    icon-right="trash"
                    @click.stop="onRemoveNode(node)"
                  />
                </p>
              </b-field>
            </div>
          </div>
        </template>
      </div>
    </LiquorTree>

    <div class="buttons">
      <b-button expanded class="is-fullwidth add-category" @click="()=>addChildNode()">Add Category</b-button>
    </div>

    <VCrudControl :hide-cancel="true" :hide-remove="true" :is-autosave="true" />

    <b-loading :is-full-page="false" :active="isLoading || importExport_isLoading" />
  </section>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faPlus, faEdit, faSearch, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'

import CategoryHelper from '@/database/categoryHelper'
import LiquorTree from 'liquor-tree'

import { CategoryTree, CategoryCollection, CategoryItem, CategoryCollectionDocDB } from '@/types/typeCategory.js'
import { ModuleType, ElementWithTypeAndID, PublishingState } from '@/modules/typeModules'


import { ModuleManager } from '../../modules/moduleManager'

import VImportExport, { typeImportExportDefinitions } from '@/components/VImportExport.vue'
import VModuleCompareData from '@/components/VModuleCompareData.vue'
import VTagModuleElements from '@/components/VTagModuleElements.vue'

import ComparisonResult from '../../types/typeComparisonResult'

import VRecordMeta from '@/components/VRecordMeta.vue'
import databaseSchema from '@/database/databaseSchema'
import { distinctObjects, uniqueID } from '@/database/dbHelper'
import { cloneObject } from '@/helpers/dataShapeUtil'
import VButtonSettingsModal from '@/components/VButtonSettingsModal.vue'
import { SettingsConfig, SettingsCategories } from '@/types/typeLocalSettings'
import { hasDBid } from '@/types/typeGeneral'
import firebase from 'firebase/compat/app'
import { ROOT_CATEGORY_ID } from '@/businessLogic/sharedConstants'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'

library.add(faPlus, faEdit, faSearch, faSave, faTrash)

const defaultState = {
  selected: false,
  selectable: true,
  checked: false,
  expanded: false,
  disabled: false,
  visible: true,
  indeterminate: false,
  matched: false,
  editable: true,
  dragging: false,
  draggable: true,
  dropable: true
}

interface LiquorTreeElement extends CategoryTree {
  data: {
    linkedAsids: number
    linkedElements: number
    [key: string]: any
  }
  text: string
  state: typeof defaultState
  parent?: LiquorTreeElement
  remove: () => void
  show: () => void
  hide: () => void
}

@Component({
  components: {
    LiquorTree,
    VTagModuleElements,
    VImportExport,
    VRecordMeta,
    VButtonSettingsModal
  }
})
export default class BackendCategoriesList extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  // public dataTree: CategoryTree = {} as CategoryTree

  // private categories: Array<Category> = []
  private formCategories: CategoryCollection = this.$categories
  public formCategoriesDoc: CategoryCollectionDocDB = { ...databaseSchema.COLLECTIONS.TENANTS.DATA.CATEGORIES.__EMPTY_DOC__ }
  public propagateElements = false
  public displayElements = false
  public filterText = ''
  public importExportisLoading = false

  // public categoryLiquorTree: LiquorTreeElement[] = [
  // ]
  public isLoading = false // toso use this

  public treeOptions = {
    propertyNames: {
      text: 'name',
      children: 'children',
      state: 'state'
    },
    filter: {
      emptyText: ''
    },
    multiple: true,
    dnd: true,
    checkbox: true
  }

  // #region RecordMeta
  get docPath() {
    return CategoryHelper.getCategoriesDocRef(this.$auth.tenant.id).path
  }

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.DATA.CATEGORIES.__PRIVILEGES__
  }
  // #endregion RecordMeta

  public nodeDragStart(node: LiquorTreeElement) {
    // console.log(node)

  }

  public onNodeDragFinish(targetNode: LiquorTreeElement, destinationNode: LiquorTreeElement) {
    console.log(targetNode, destinationNode)
    const tempCat = this.formCategories[targetNode.id]

    if (tempCat) {
      // let categoryUpdates: { [key: CategoryID]: DeepPartial<CategoryItem> } = {}

      // update order => not using order but the name instaed for sorting.
      // targetNode.parent?.children.forEach((siblingCats, index) => {
      //   categoryUpdates[siblingCats.id] = { order: index }
      // })

      // set new parent while keeping the new order
      // categoryUpdates[targetNode.id] = { ...categoryUpdates[targetNode.id], parentID: (targetNode.parent) ? targetNode.parent.id : '' }

      CategoryHelper.updateCategory(this.$auth.tenant.id, this.$auth.userEmail, targetNode.id, { parentID: (targetNode.parent) ? targetNode.parent.id : '' })
        .then(() => this.$helpers.notification.Success('Category updated'))
        .catch((e: string) => this.$helpers.notification.Error('Error updating Category' + e))
    } else {
      this.$helpers.notification.Error('Error updating Category, id not found ' + targetNode.id)
    }
  }

  private stringToHslColor(str: string, s: number, l: number) {
    let hash = 0

    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash)
    }

    const h = Math.abs(hash % 360)

    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)'
  }

  public expandAll() {
    (this.$refs.tree as any).expandAll()
  }

  public collapseAll() {
    const treeRef = this.$refs.tree as any

    if (!treeRef) {
      console.error('treeRef not found')
      return
    }

    treeRef.collapseAll()

    // expand the root node
    const rootNode = treeRef.find({
      id: ROOT_CATEGORY_ID
    }) as any[] | undefined

    if (rootNode && rootNode.length > 0) {
      rootNode[0].expand()
    }
  }

  private moduleElements: Array<ElementWithTypeAndID> = []


  private onSnapshotUnbindHandle: (() => void) | undefined

  public async created() {
    this.isLoading = true

    this.initSettings()

    await this.$firestoreBind('formCategoriesDoc', CategoryHelper.getCategoriesDocRef(this.$auth.tenant.id))
    // CategoryHelper.getformCategoriesSnapshot(this.$auth.tenant.id,()=>{}) // todo keep alive onsnapshot listeners
    this.isLoading = false
  }

  @Watch('displayElements', { immediate: true })
  public async onDisplayElementsChanged() {
    this.onSnapshotUnbindHandle && this.onSnapshotUnbindHandle()

    if (!this.displayElements)
      return

    this.onSnapshotUnbindHandle = ModuleManager.onSnapshotModuleElements(this.$auth.tenant.id, undefined, { includeDeleted: false }, (data) => {
      this.moduleElements = data
    }, (err) => this.$helpers.notification.Error(err))
  }

  beforeDestroy() {
    console.info('beforeDestroy')
    this.onSnapshotUnbindHandle && this.onSnapshotUnbindHandle()
  }

  public categoryLiquorTree: LiquorTreeElement[] = []

  /**
   * if catgories are filtered, the filtered categories are used, otherwise the full categories are used
   * - hide import export if filter is active
   * - deactivate drag and drop if filter is active
   * - deactivate editing if filter is active
   * - deactivate element linking popagation to avoid showing elements that are not visible for this categories
   */
  private forceRefreshCatFilter = 0
  get filteredVisibleCategories() {
    // dirty hack to keep forceRefreshCatFilter in this computed properties dependencies, as the console log is removed by webpack
    // this condition is always false
    if (this.forceRefreshCatFilter < 0) return []

    console.log(this.forceRefreshCatFilter) // since when adding a category the filter is not updated
    return CategoryHelper.getFilteredCategories(
      this.$categories,
      this.$localSettings.modules.filters.categories,
      this.$auth.user?.visibleCategories || [],
      this.$localSettings.modules.filters.categoriesIncludeParentCats,
      this.$localSettings.modules.filters.categoriesIncludeChildCats
    )
  }

  @Watch('moduleElements')
  @Watch('filteredVisibleCategories')
  @Watch('formCategories', { immediate: true })
  public createCategoryTree() {
    if (!this.formCategories)
      return []

    const tmpCatTree = CategoryHelper.buildCategoryTree(this.formCategories)

    CategoryHelper.iterateCategoryTree((cat: CategoryTree, parent) => {
      const liqCat = cat as LiquorTreeElement
      const liqParent = parent as LiquorTreeElement

      const elements = this.moduleElements
        .filter((E) => Object.values(E.reference.categoryIDs).flat().includes(cat.id))
        .map((El) => ({
          color: ModuleManager.getModuleClassByType(El.type as ModuleType).color,
          ...El
        })).filter((el) => el.publishingState === 'published')
      const inheritedElements: any = distinctObjects<ElementWithTypeAndID>([...(liqParent && liqParent.data.inheritedElements) ? liqParent.data.inheritedElements : [], ...elements], (el) => el.id)
      // const accumulatedLinkedAsids: any = ((liqParent && liqParent.data.linkedAsids) ? liqParent.data.linkedAsids : 0) + liqCat._computed.linkedAsids
      // liqCat.inheritedElements = inheritedElements
      // liqCat.elements = elements

      liqCat.data = {
        linkedAsids: liqCat._computed?.linkedAsids || 0,
        linkedElements: liqCat._computed?.linkedElements || 0,
        inheritedElements,
        elements
      }

      // keep state when changing data
      const treeRef = this.$refs.tree as any
      let existingNode
      if (treeRef) {
        existingNode = treeRef.find({
          id: cat.id
        }) as any[] | undefined
      }

      const stateClone = cloneObject(defaultState) // clone state to not mutate the original

      // is expanded if the user stiing is set or it is the root node
      stateClone.expanded = this.settings.expandByDefault || cat.id === ROOT_CATEGORY_ID
      liqCat.state = (existingNode && existingNode.length > 0) ? existingNode[0].states : stateClone

      if (this.filteredVisibleCategories.length > 0 && !this.filteredVisibleCategories.includes(cat.id)) {
        // use custom disabled implementation as otherwise parent disabled categories would also disable the children
        liqCat.data.disabled = true
        liqCat.state.draggable = false
      }
    }, tmpCatTree, null)
    //   this.categoryLiquorTree = [
    //   { text: 'Item 1q', state: { visible: false } },
    //   { text: 'Item 2w', data: { customProp: 'AAAAAAAAAAAAAAAAAAAA' } },
    //   { text: 'Item 3', state: { selected: true } },
    //   { text: 'Item 4' },
    //   {      text: 'Item 5', children: [
    //       { text: 'Item 5.1', state: { disabled: true } },
    //       { text: 'Item 5.2', state: { selectable: false } }
    //     ]    }

    //   // // and so on ...
    // ]

    this.categoryLiquorTree = tmpCatTree.children as LiquorTreeElement[]
  }

  @Watch('categoryLiquorTree')
  oncategoryLiquorTreeChanged(val: LiquorTreeElement[], oldVal: LiquorTreeElement[]) {
    console.log(oldVal)
    const treeRef = this.$refs.tree as any

    treeRef.setModel(this.categoryLiquorTree)

    // https://codepen.io/TimPietrusky/pen/rKzoGN
    // https://stackoverflow.com/a/38641281
    let collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' })

    // for correct number sorting ('2','10') '10'.localeCompare('2', undefined, {numeric: true, sensitivity: 'base'})
    treeRef.sortTree(
      (node0: LiquorTreeElement, node1: LiquorTreeElement) => {
        const t = collator.compare(node0.text, node1.text)
        return t
      }
    )

    this.onUpdateCheckedItems()

    // treeRef.sortTree(
    //   'desc',
    //   true
    // )
  }

  public onEndEditingNodeText(node: LiquorTreeElement) {
    const tmpCat = this.formCategories[node.id]

    if (!tmpCat) {
      this.$helpers.notification.Error('Category not found: ' + node.id)
    } else {
      tmpCat.name = node.text

      CategoryHelper.updateCategory(this.$auth.tenant.id, this.$auth.userEmail, node.id, tmpCat)
        .then(() => this.$helpers.notification.Success('Category updated'))
        .catch((e: string) => this.$helpers.notification.Error('Error updating Category' + e))
    }
  }

  // #region settings

  public settings: SettingsCategories = { expandByDefault: false, showElementsByDefault: false }
  public settingsConfig: SettingsConfig[] = [
    {
      title: 'Expand Categories',
      description: 'Expand all Categories by default',
      accessorKey: 'expandByDefault',
      type: 'boolean'
    },
    {
      title: 'Show Elements',
      description: 'Show Elements by default',
      accessorKey: 'showElementsByDefault',
      type: 'boolean'
    }
  ]

  public initSettings() {
    console.log('init settings')
    this.settings = cloneObject(this.$localSettings.categories)
    this.displayElements = this.settings.showElementsByDefault
  }

  public saveSettings() {
    this.$localSettings.categories = this.settings
  }

  // #endregion settings

  public mounted() {
    //
  }

  public async onAddSampleData() {
    const categoryIDs = []
    for (let i = 0; i < 20; i++) {
      categoryIDs.push(uniqueID())
    }

    const categoryDB: CategoryCollection = {
      [categoryIDs[0]]: {
        name: 'Features',
        description: '',
        publishingState: 'published',
        parentID: '',
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[1]]: {
        name: 'Exterieur',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[0],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[2]]: {
        name: 'Loader',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[1],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[3]]: {
        name: 'Floodlight',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[1],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[4]]: {
        name: 'Interieur',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[0],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[5]]: {
        name: 'Compfort',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[4],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[6]]: {
        name: 'Basic',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[4],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[7]]: {
        name: 'Telematic Pro',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[4],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[8]]: {
        name: 'Series',
        description: '',
        publishingState: 'published',
        parentID: '',
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[9]]: {
        name: 'Cranes',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[8],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[10]]: {
        name: 'C300',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[9],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[11]]: {
        name: 'C305',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[9],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[12]]: {
        name: 'Excavators',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[8],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[13]]: {
        name: 'E100 Series',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[12],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[14]]: {
        name: 'E100A',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[13],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[15]]: {
        name: 'E100B',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[13],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[16]]: {
        name: 'E200',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[12],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      },
      [categoryIDs[17]]: {
        name: 'E300',
        description: '',
        publishingState: 'published',
        parentID: categoryIDs[12],
        _computed: {
          linkedAsids: 0,
          linkedElements: 0
        }
      }
    }

    try {
      this.isLoading = true

      await CategoryHelper.addCategories(this.$auth.tenant.id, this.$auth.userEmail, categoryDB)

      this.$helpers.notification.Success('Categories added')
    } catch (error: any) {
      this.$helpers.notification.Error('Error adding Category' + error)
    } finally {
      this.isLoading = false
    }
  }

  public editNode(node: any) {
    node.startEditing()
  }

  get categoriesCountPublished() {
    return Object.values(this.$categories).filter((c) => c.publishingState === 'published').length
  }

  public checkedCategories: (CategoryItem & hasDBid)[] = []

  public onUpdateCheckedItems() {
    const checkedLiquorTreeItems = (this.$refs.tree as any).checked() as LiquorTreeElement[]
    this.checkedCategories = checkedLiquorTreeItems.length > 0
      ? checkedLiquorTreeItems.map((lti) => ({ ...this.$categories[lti.id], id: lti.id }))
      : Object.entries(this.formCategories).map(([id, cat]) => ({ ...cat, id: id }))
  }

  public onRemoveNode(node: LiquorTreeElement) {
    const checkedItems = (this.$refs.tree as any).checked() as LiquorTreeElement[]

    console.log(checkedItems)
    if (checkedItems.length > 0) {
      const catWithLinked = checkedItems.find((node) => node.data.linkedAsids > 0)
      if (catWithLinked) {
        this.$buefy.dialog.alert({
          title: `Deleting multiple Categories (${checkedItems.length})`,
          message: `It is not possible to delete a category wich is linked to ECHO Codes. The category  <b>${catWithLinked.text}</b> is linked to ${catWithLinked.data.linkedAsids} codes.`,
          type: 'is-warning',

          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        this.$buefy.dialog.confirm({
          title: `Deleting multiple Categories (${checkedItems.length})`,
          message: `${checkedItems.length} Categories are selected .Are you sure you want to <b>delete</b> the Categories <b>${checkedItems.map((node) => node.text).join(', ')}</b>? This action cannot be undone.`,
          confirmText: `Delete ${checkedItems.length} Categories`,
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            try {
              this.isLoading = true

              for (const node of checkedItems) {
                node.remove()
                await CategoryHelper.removeCategory(this.$auth.tenant.id, this.$auth.userEmail, node.id)
              }

              this.$helpers.notification.Success('Category deleted')
            } catch (error: any) {
              this.$helpers.notification.Error('Error deleting Category' + error)
            } finally {
              this.isLoading = false
            }
          }
        })
      }
    } else {
      if (node.data.linkedAsids > 0) {
        this.$buefy.dialog.alert({
          title: `Deleting Category ${node.text}`,
          message: `It is not possible to delete a category wich is linked to ECHO Codes. The category  <b>${node.text}</b> is linked to ${node.data.linkedAsids} codes.`,
          type: 'is-warning',

          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } else {
        this.$buefy.dialog.confirm({
          title: `Deleting Category ${node.text}`,
          message: `Are you sure you want to <b>delete</b> the Category <b>${node.text}</b>? This action cannot be undone.`,
          confirmText: 'Delete Category',
          type: 'is-danger',
          hasIcon: true,
          onConfirm: async () => {
            try {
              this.isLoading = true

              node.remove()
              await CategoryHelper.removeCategory(this.$auth.tenant.id, this.$auth.userEmail, node.id)

              this.$helpers.notification.Success('Category deleted')
            } catch (error: any) {
              this.$helpers.notification.Error('Error deleting Category' + error)
            } finally {
              this.isLoading = false
            }
          }
        })
      }
    }
  }

  public async addChildNode(node = { id: ROOT_CATEGORY_ID, text: 'root' } as LiquorTreeElement) {
    const name = await new Promise<string>((resolve) => {
      this.$buefy.dialog.prompt({
        message: `Add child node to ${node.text} node`,
        inputAttrs: {
          placeholder: 'Category name',
          maxlength: 200,
          name: 'category-name'
        },
        type: 'is-success',
        confirmText: 'Add',
        onConfirm: (value: string) => resolve(value)
      })
    })

    const tmpCat: Partial<CategoryItem> = {
      name: name,
      parentID: node.id || ROOT_CATEGORY_ID
    }

    this.isLoading = true
    await CategoryHelper.addCategory(this.$auth.tenant.id, this.$auth.userEmail, tmpCat)
      .then(() => this.$helpers.notification.Success('Category added'))
      .catch((e: string) => this.$helpers.notification.Error('Error adding Category' + e))
      .finally(() => this.isLoading = false)

    this.forceRefreshCatFilter++
  }


  /**
   * IMPORT EXPORT
   */

  // #region import/export
  public importExport_isLoading = false

  /**
   * convert doc property to string or object or array
   * exportFormatter(doc): object|string|[]
   *
   * apply exported doc property to doc
   * importFormatter(import: object|string|[], doc)
   */
  public importExport_importExportDefinitions: typeImportExportDefinitions<CategoryItem> = [

    {
      readOnly: false,
      exportColumnName: 'Name',
      exportFormatter: (me: CategoryItem) => me.name,
      importFormatter: (imp: string, me: CategoryItem) => me.name = String(imp)
    },

    {
      readOnly: false,
      exportColumnName: 'publishingState',

      exportFormatter: (me: CategoryItem) => me.publishingState,

      importFormatter: (imp: string, me: CategoryItem) => {
        const isPublishingState = (string: any): string is PublishingState =>
          ['draft', 'published', 'archived', 'deleted'].includes(string)


        if (!isPublishingState(imp)) {
          throw `Valid values for publishingState are ('draft' , 'published' , 'archived' , 'deleted'). The supplied value was ${imp}`
        }

        me.publishingState = imp
      }
    }, {
      readOnly: true,
      exportColumnName: 'LinkedEchoCodes',
      exportFormatter: (me: CategoryItem) => me._computed.linkedAsids
    }, {
      readOnly: true,
      exportColumnName: 'ParentCategoryName',
      exportFormatter: (me: CategoryItem) => me.parentID ? this.$getCategoryName(me.parentID) : ''
    }, {
      readOnly: false,
      exportColumnName: 'Description',
      exportFormatter: (me: CategoryItem) => me.description
    }, {
      readOnly: false,
      exportColumnName: 'ParentCategoryID',
      exportFormatter: (me: CategoryItem) => String(me.parentID),
      importFormatter: (imp: string, me: CategoryItem) => {
        // throws if not found
        // await this.dataCacheGroup.get(imp)

        me.parentID = imp
      }
    }
  ]


  public importExport_validateImportedData(importedData: Partial<hasDBid>[]) {
    return true
  }


  public importExport_getDoc(importedData: Partial<hasDBid>) {
    const catItem = this.formCategories[importedData.id || '']
    if (!catItem) throw `category with id ${importedData.id} not found`
    return { exists: true, data: () => catItem, id: importedData.id }
  }

  public importExport_getDefaultDoc() {
    return cloneObject(CategoryHelper.defaultCategoryItem)
  }

  public importExport_updateDocBatch(docId: string, docData: CategoryItem & { id?: string }, batch: firebase.firestore.WriteBatch) {
    // todo update the local doc this.formCategoriesDoc to make sure the sanity checks pass
    if ('id' in docData) delete docData.id

    return CategoryHelper
      .updateCategoryBatch(
        this.$auth.tenant.id,
        this.$auth.userEmail,
        docId,
        docData,
        this.formCategoriesDoc,
        batch)
  }


  public importExport_createDocBatch(docData: CategoryItem & { id?: string }, batch: firebase.firestore.WriteBatch) {
    if ('id' in docData) delete docData.id

    return CategoryHelper
      .addCategoryBatch(
        this.$auth.tenant.id,
        this.$auth.userEmail,
        docData,
        batch)
  }

  // #endregion import/export

  private formCategoriesToArray(): Array<any> {
    return Object.keys(this.formCategories).map((key) => ({
      id: key,
      name: this.formCategories[key].name,
      parentID: this.formCategories[key].parentID, // when enabling parent modification, make sure no reference loops are present
      publishingState: this.formCategories[key].publishingState,
      description: this.formCategories[key].description,
      linkedAsids_READONLY: this.formCategories[key]._computed?.linkedAsids || 0
    }))
  }

  public exportCategories(): Array<any> {
    return this.formCategoriesToArray()
  }

  public importCategories(importedData: Array<Partial<CategoryItem>>) {
    // const promises: Array<Promise<firebase.firestore.DocumentSnapshot>> = []

    // console.log(importedData)

    // // get all docs matching the imported importedData
    // for (const importedDataset of importedData) {
    //   promises.push(
    //     AsidManager.getDbCollectionReference()
    //       .doc(importedDataset.id)
    //       .get()
    //   )
    // }
    // //todo progress indicator
    // Promise.all(promises).then(docs => {
    //   console.table([docs, importedData])

    //   const databaseDataset: Array<AsidDB & hasDBid> = docs.map(d => {
    //     const data = d.data() as AsidDB
    //     const id = d.id
    //     return { id, ...data }
    //   })

    this.$buefy.modal.open({
      props: {
        datasets: {
          oldData: this.formCategoriesToArray(),
          newData: importedData
        }
      },
      events: {
        confirmed: async (datasets: Array<ComparisonResult>) => {
          console.log(datasets)

          // const batch = db.batch()

          this.isLoading = true
          for (const compResult of datasets) {
            console.log(compResult)
            try {
              // if (compResult.attribute === 'parent') throw 'changing the parent using an import is currently not supported'
              if (compResult.attribute === 'linkedAsids') throw 'linkedAsids is read only and can not be changed by importing'

              const catDocItem = this.formCategories[compResult.id]
              if (!catDocItem) throw `category with id ${compResult.id} not found`

              if (!(compResult.attribute in catDocItem)) throw `property ${compResult.attribute} not in item ${compResult.id}`

              catDocItem[compResult.attribute as keyof CategoryItem] = compResult.newValue as any // todo check if this works for multiple updated props at once
              // todo accumulate all changes and batch usate categories to not pollute the changelog
              await CategoryHelper.updateCategory(this.$auth.tenant.id, this.$auth.userEmail, compResult.id, catDocItem).then(() => this.$helpers.notification.Success('Category updated'))
            } catch (e: any) {
              this.$helpers.notification.Error(e.toString())
            } finally {
              //
            }
          }
          this.isLoading = false

          // batch
          //   .commit()
          //   .then(d => {
          //     this.$helpers.notification.Success(`${datasets.length} Asid codes Updated`)
          //     this.getData(true)
          //   })
          //   .catch(e => {
          //     this.$helpers.notification.Success(`Error occured while updating Asid codes: ${e.toString()}`)
          //   })
        }
      },
      parent: this,
      component: VModuleCompareData,
      hasModalCard: true
    })
    // })
  }
}
</script>


<style lang="scss">
@import '@/css/treeview.scss';

.categories-list-container {
  position: relative;

  .tree-scope.is-disabled {
    color: grey;
  }
}
</style>
