export class SortingOrderHelper {
  public static calculateOrderIndex(
    sortedArray: any[],
    currentIndex: number,
    targetIndex: number,
    orderAcessor = (arrEl: any) => arrEl.public.order as number,
    arrayIsAlreadyNewOrder = false
  ) {
    if (sortedArray.length === 1) return orderAcessor(sortedArray[0])

    if (arrayIsAlreadyNewOrder) {
      return targetIndex === 0
        ? orderAcessor(sortedArray[1]) - 100 // add before second element
        : targetIndex === sortedArray.length - 1
          ? orderAcessor(sortedArray[targetIndex - 1]) + 100 // add after second last element
          : (orderAcessor(sortedArray[targetIndex - 1]) + orderAcessor(sortedArray[targetIndex + 1])) / 2 // place inbetween before and after item
    } else {
      return targetIndex === 0
        ? orderAcessor(sortedArray[0]) - 100
        : targetIndex === sortedArray.length - 1
          ? orderAcessor(sortedArray[targetIndex]) + 100
          : (orderAcessor(sortedArray[targetIndex])
            + orderAcessor(sortedArray[targetIndex + (targetIndex > currentIndex ? 1 : -1)]))
          / 2
    }
  }

  public static checkOrder(sortedArray: any[], orderAcessor = (arrEl: any) => arrEl.public.order as number) {
    return sortedArray.every((el, i) => {
      return (
        (i == 0 || Math.abs(orderAcessor(el) - orderAcessor(sortedArray[i - 1])) > 0.0001)
        && orderAcessor(el) > -99999
        && orderAcessor(el) < 99999
      )
    })
  }

  public static getOrderToInsertAtPosition<T>(
    sortedArray: T[],
    targetIndex: number,
    orderAcessor = (arrEl: any) => (arrEl as any).public.order as number
  ) {
    return targetIndex === 0
      ? orderAcessor(sortedArray[0]) - 100
      : targetIndex >= sortedArray.length - 1
        ? orderAcessor(sortedArray[Math.min(targetIndex, sortedArray.length - 1)]) + 100
        : (orderAcessor(sortedArray[targetIndex]) + orderAcessor(sortedArray[targetIndex - 1])) / 2
  }

  public static updateOrderIndices<T>(
    sortedArray: T[],
    orderSetter = (arrEl: any, order: number) => (arrEl.public.order = order)
  ) {
    const step = 100
    const startOrderIndex = (-sortedArray.length * step) / 2

    sortedArray.forEach((el, i) => orderSetter(sortedArray[i], startOrderIndex + i * step))
    return sortedArray
  }

  public static async manageOrder<T>(
    sortedArray: T[],
    currentIndex: number,
    targetIndex: number,
    updateOrderCb: (el: T, order: number, idx: number) => Promise<any>,
    orderAcessor = (arrEl: T) => (arrEl as any).public.order as number,
    orderSetter = (arrEl: T, order: number) => ((arrEl as any).public.order = order),
    arrayIsAlreadyNewOrder = false
  ): Promise<any> {
    const newOrderNumber = SortingOrderHelper.calculateOrderIndex(
      sortedArray,
      currentIndex,
      targetIndex,
      orderAcessor,
      arrayIsAlreadyNewOrder
    )
    console.log(newOrderNumber)
    console.log('sorting oders valid: ', SortingOrderHelper.checkOrder(sortedArray, orderAcessor))

    if (!SortingOrderHelper.checkOrder(sortedArray, orderAcessor)) {
      // all orders have to be adjusted
      const updatePromises = SortingOrderHelper.updateOrderIndices(sortedArray, orderSetter).map((el, idx) =>
        updateOrderCb(el, orderAcessor(el), idx)
      )

      await Promise.all(updatePromises)

      // call again after updating indices
      if (!arrayIsAlreadyNewOrder)
        return await this.manageOrder(
          sortedArray,
          currentIndex,
          targetIndex,
          updateOrderCb,
          orderAcessor,
          orderSetter,
          false
        )
    } else {
      return await updateOrderCb(
        sortedArray[arrayIsAlreadyNewOrder ? targetIndex : currentIndex],
        newOrderNumber,
        currentIndex
      )
    }
  }

  public static async checkAndFixOrder<T>(
    sortedArray: T[],
    updateOrderCb: (el: T, order: number, idx: number) => Promise<any>,
    orderAcessor = (arrEl: T) => (arrEl as any).public.order as number,
    orderSetter = (arrEl: T, order: number) => ((arrEl as any).public.order = order)
  ): Promise<any> {
    if (!SortingOrderHelper.checkOrder(sortedArray, orderAcessor)) {
      // all orders have to be adjusted
      const updatePromises = SortingOrderHelper.updateOrderIndices(sortedArray, orderSetter).map((el, idx) =>
        updateOrderCb(el, orderAcessor(el), idx)
      )

      return Promise.all(updatePromises)
    }
  }
}
