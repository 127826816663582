<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="sanitized-editor-html" v-html="sanitizedHtml" />
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import sanitizeHtml, { IOptions } from 'sanitize-html'
import { merge } from '@/database/dbHelper'

export function sanitizeEditorHtml(html: string, sanitizeHtmlConfig?: IOptions) {
  const allowedHtml = merge(
    sanitizeHtmlConfig,
    {
      allowedTags: [
        'b', 'i', 's', 'u', 'em', 'strong',
        'a', 'p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'br', 'img', 'iframe',
        'ul', 'ol', 'li', 'table', 'td', 'tr', 'th', 'thead', 'tbody', 'colgroup', 'hr', 'span'
      ],
      allowedAttributes: {
        'a': ['href', 'target'],
        'img': ['src', 'alt', 'title', 'width'],
        'iframe': ['src'],
        'td': ['colspan'],
        '*': ['style'],
        'table': ['width', 'border', 'cellspacing', 'cellpadding']
      },
      allowedStyles: {
        '*': {
          // Match HEX and RGB
          'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
          'text-align': [/^left$/, /^right$/, /^center$/],
          // Match any number with px, em, or %
          'font-size': [/^\d+(?:px|em|%)$/]
        }
      },
      allowedClasses: {
        a: ['button']
      },
      allowedIframeHostnames: ['www.youtube.com']
    })

  return sanitizeHtml(html, allowedHtml)
}

@Component
export default class VEditorHtml extends Vue {
  @Prop({ type: String, required: true })
  public html!: string

  @Prop({ type: Object, required: false, default: () => ({}) })
  public sanitizeHtmlConfig!: IOptions

  public get sanitizedHtml() {
    return sanitizeEditorHtml(this.html, this.sanitizeHtmlConfig)
  }
}


</script>

<style lang="scss">
.sanitized-editor-html {
  overflow-x: auto;

  a {
    color: var(--widget-text-color);
    text-decoration: underline;
  }

  img {
    display: block;
    margin: auto;
  }

  strong {
    color: var(--widget-text-color);
  }

  iframe[src*='youtube'] {
    max-height: 319.5px;
    /* height: 20rem; */
    width: 100%;
    // width: 100vw;
    height: 47.25vw; /* 16:9 */
  }

  hr {
    background-color: #d8d8d8;
  }

  ul,
  ol {
    margin-block: 1em;
    margin-inline: 0;
    padding-inline-start: 1.5em;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li > p {
    margin: 0;
  }

  p {
    margin: 1rem 0;
  }

  & > p:nth-last-child(1) {
    margin-bottom: 0;
  }

  & > p:nth-child(1) {
    margin-top: 0;
  }

  table {
    width: 100%;

    td,
    th {
      /* stylelint-disable-next-line custom-property-pattern */
      border: 1px solid rgba(var(--widget-text-color_rgb), 0.3);
      border-width: 1 1 1px;
      padding: 0.1em;
      vertical-align: top;
      border-bottom-width: 1px !important;

      p {
        margin: 0.5rem;
      }
    }
  }

  // [contenteditable]:focus {
  //   outline: 0px solid transparent;
  // }
  // .editor__content {
  //   padding: 1em;
  //   background: #f7f7f7;
  //   border-radius: 0.2em;
  // }
}
</style>
