<template>
  <b-modal
    :active.sync="isFilterModalActive"
    has-modal-card
    trap-focus
    aria-role="dialog"
    aria-modal
    class="filter-modal"
    scroll="keep"
  >
    <div class="modal-card" style="min-width: 500px;">
      <header class="modal-card-head">
        <p class="modal-card-title">Set Filter</p>
      </header>

      <section class="modal-card-body">
        <!-- if there are userVisibleCategories, show an info with the categories as taglist -->
        <b-message v-if="userVisibleCategories.length > 0" type="is-info" has-icon>
          <span>You have access to the following categories. Ask your admin to change your privileges.</span>
          <b-taglist>
            <b-tag v-for="(cat, i) in userVisibleCategories" :key="i" aria-close-label="Close tag">
              <span>{{ $getCategoryName(cat) }}</span>
            </b-tag>
          </b-taglist>
        </b-message>

        <b-field label="Filter by category">
          <VInputMultiCategorySelection
            v-model="formSelectedCategories"
            style="max-width: 500px; min-width: 100px;"
            :categories-doc="getAvailableUserCategories()"
          />
        </b-field>

        <b-field>
          <template #label>
            Include parent/child categories
            <VTooltipIconHelp
              position="is-left"
              text="Also show elements which are assigned to child/parent categories"
            />
          </template>
          <b-checkbox v-model="formCheckedParent">Parent</b-checkbox>
          <b-checkbox v-model="formCheckedChild">Child</b-checkbox>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button class type="button" @click="isFilterModalActive = false">Close</b-button>
        <b-button
          v-if="$localSettings.modules.filters.categories.length > 0"
          icon-left="times"
          @click="onResetFilter()"
        >Reset Filter</b-button>
        <b-button class="is-primary" @click="onApply()">Filter</b-button>
      </footer>
    </div>
  </b-modal>
</template>

<script lang="ts">
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'
import { CategoryID } from '@/types/typeCategory'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import CategoryHelper from '@/database/categoryHelper'

library.add(faTimes)

/**
 * a modal to manage the global category filter.
 * the user filter is stored in localstorage
 * if the user has limited acess to the categories based on the visibleCategories in the user document
 * the user filter will only be applied to subcategories of the visibleCategories
 */

@Component({
  components: {
    VInputMultiCategorySelection
  }
})
export default class VModalCategoryFilter extends Vue {
  @PropSync('active', { default: false })
  public isFilterModalActive!: boolean

  public formSelectedCategories: CategoryID[] = []
  public formCheckedParent = false
  public formCheckedChild = false

  public onApply() {
    this.$localSettings.modules.filters.categories = this.formSelectedCategories
    this.$localSettings.modules.filters.categoriesIncludeParentCats = this.formCheckedParent
    this.$localSettings.modules.filters.categoriesIncludeChildCats = this.formCheckedChild
    this.isFilterModalActive = false
  }

  public onResetFilter() {
    this.$localSettings.modules.filters.categories = []
    this.isFilterModalActive = false
  }

  @Watch('$localSettings.modules.filters.categories', { immediate: true })
  private onChangeGlobalCategoryFilter() {
    this.formSelectedCategories = this.$localSettings.modules.filters.categories
  }

  @Watch('$localSettings.modules.filters.categoriesIncludeParentCats', { immediate: true })
  private onChangeGlobalCategoryFilterIncludeParent() {
    this.formCheckedParent = this.$localSettings.modules.filters.categoriesIncludeParentCats
  }

  @Watch('$localSettings.modules.filters.categoriesIncludeChildCats', { immediate: true })
  private onChangeGlobalCategoryFilterIncludeChild() {
    this.formCheckedChild = this.$localSettings.modules.filters.categoriesIncludeChildCats
  }

  get userVisibleCategories() {
    return this.$auth?.user?.visibleCategories || []
  }

  public getAvailableUserCategories() {
    return CategoryHelper.getAvailableUserCategoriesCollection(this.$categories, this.$auth?.user?.visibleCategories)
  }
}

</script>


<style lang="scss">
@import '@/variables.scss';
@import '@/mixins.scss';

.filter-modal {
  .modal-card {
    overflow: visible;

    .modal-card-body {
      overflow: visible;
    }
  }
}
</style>
