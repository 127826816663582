<template>
  <section>
    <!-- <b-field>
      <template slot="label">
        sticker template
        <VTooltipIconHelp
          text="Changes the QR error correction level according to QR code specification"
        />
      </template>
    </b-field>-->

    <div
      class="echo-code-container"
      :style="{width: (formPreviewWidth) ? codeConfig.width+'mm' : '14em'}"
    >
      <VEchoCode
        :show-print="formShowPrint"
        :code-config="codeConfig"
        :asid="formSampleAsid"
        :base-url="baseUrl"
      />
    </div>

    <b-field group-multiline grouped>
      <b-field v-if="!disableModifyErrorCorrectionLevel">
        <template slot="label">
          error correction
          <VTooltipIconHelp
            text="Changes the QR error correction level according to QR code specification"
          />
        </template>
        <b-select
          v-model="codeConfig.errorCorrectionLevel"
          :disabled="disabled"
          placeholder="Error Correction"
        >
          <option
            v-for="option in ERROR_CORRECTION_LEVELS"
            :key="option.key"
            :value="option.key"
          >{{ option.value }}</option>
        </b-select>
      </b-field>

      <b-field>
        <template slot="label">
          custom template
          <VTooltipIconHelp :text="`enter a custom sticker template url`" />
        </template>
        <b-select
          v-model="formTemplateUrlSelector"
          placeholder="Default"
          :disabled="formTemplateUrlUpload !== '' || disabled"
        >
          <option value="ymyvCodeEchoCodeLogo">Default Template</option>
          <option value="pureCodeEchoCodeLogo">No Text</option>
          <option value="textOneLineEchoCodeLogo">Text Single Line</option>
          <option value="textTwoLineEchoCodeLogo">Text Double Line</option>
          <!-- <option value="_default_7">SVGTemplate2</option> -->
          <option value="custom">Custom</option>
        </b-select>
      </b-field>

      <b-field v-if="formTemplateUrlSelector == 'custom'" label="Template File">
        <VFormUploadFileView
          v-model="formTemplateUrlUpload"
          :file-validator="onValidateFile"
          :upload-path="uploadPath"
          :uploader-document-path="documentPath"
          :loading.sync="isLoading"
        />
      </b-field>

      <b-field>
        <template slot="label">
          custom text
          <VTooltipIconHelp :text="`enter a custom text which is displayed on the sticker`" />
        </template>
        <b-field>
          <b-input v-model="codeConfig.customText" :disabled="disabled" placeholder="line 1" />
          <b-input v-model="codeConfig.customText2" :disabled="disabled" placeholder="line 2" />
        </b-field>
      </b-field>

      <b-field v-if="!disableCustomLogo">
        <template slot="label">
          custom color
          <VTooltipIconHelp
            :text="`Select a custom background color. The color may vary when printed.`"
          />
        </template>
        <VInputColorpicker v-model="codeConfig.color" color-format="hex" />
      </b-field>

      <b-field v-if="!disabled && !disableCustomLogo">
        <template slot="label">
          custom logo
          <VTooltipIconHelp
            :text="`enter a custom logo url. The logo will be placed on the sticker if the template has a logo area`"
          />
        </template>

        <VFormUploadFileView
          v-model="codeConfig.logoUrl"
          :uploader-document-path="documentPath"
          :file-validator="onValidateFile"
          :upload-path="uploadPath"
          :loading.sync="isLoading"
        />
      </b-field>
    </b-field>

    <b-field v-if="!disableModifyWidth" grouped>
      <b-field>
        <template slot="label">
          width
          <VTooltipIconHelp :text="`enter a custom text which is displayed on the sticker`" />
        </template>
        <b-field>
          <b-numberinput
            v-model="codeConfig.width"
            :disabled="disabled"
            controls-position="compact"
            max="300"
            min="10"
          />
          <p class="control">
            <span class="button is-static">[mm]</span>
          </p>
        </b-field>
      </b-field>

      <b-field>
        <template slot="label">
          preview width
          <VTooltipIconHelp
            :text="`Scale the template above to the given width. Actual width may vary based on the device you are viewing this page on.`"
          />
        </template>
        <b-field>
          <b-switch v-model="formPreviewWidth" :rounded="false" />
        </b-field>
      </b-field>
      <b-field>
        <template slot="label">
          preview print lines
          <VTooltipIconHelp :text="`Displays printing related sticker template features`" />
        </template>
        <b-field>
          <b-switch v-model="formShowPrint" :rounded="false" />
        </b-field>
      </b-field>
    </b-field>

    <b-loading :is-full-page="false" :active.sync="isLoading" />
  </section>
</template>

<script lang="ts">
import { Component, Vue, Watch, Model, Prop } from 'vue-property-decorator'


import AsidManager from '@/database/asidManager'
import BackendConfigManager from '@/database/backendConfigManager'
import AdminManager from '@/database/adminManager'
import VEchoCode from '@/components/VEchoCode.vue'
import VInputColorpicker from '@/components/VInputColorpicker.vue'
import { CodeConfig } from '@/types/typeBackendConfig'

import VImageUploadModal from '@/components/image/VImageUploadModal.vue'
import VFormUploadFileView from '@/components/VFormUploadFileView.vue'
import TenantManager from '@/database/tenantManager'
import databaseSchema from '@/database/databaseSchema'


@Component({
  components: {
    VEchoCode,
    VImageUploadModal,
    VFormUploadFileView,
    VInputColorpicker

  }
})
export default class BackendConfigureSticker extends Vue {
  public PRESELECTED_TEMPLATE_ID = 'ymyvCodeEchoCodeLogo'


  public AsidManager = AsidManager
  public formSampleAsid = AsidManager.createID('a')

  public isImageUploadModalActive = false
  public formFile: any = null

  public formPreviewWidth = false
  public formShowPrint = false
  public formSvgText = ''
  public formTemplateUrlSelector = this.PRESELECTED_TEMPLATE_ID
  public formTemplateUrlUpload = ''

  public isLoading = false

  get uploadPath() {
    return (this.$auth.tenant?.id)
      ? TenantManager.getConfigUploadPath(this.$auth.tenant.id)
      : AdminManager.getUploadPath('/codeTemplates')
  }

  get documentPath() {
    return (this.$auth.tenant?.id)
      ? databaseSchema.COLLECTIONS.TENANTS.DATA.BACKEND_CONFIG.__DOCUMENT_PATH__(this.$auth.tenant.id)
      : databaseSchema.COLLECTIONS.ADMIN.CODE_CONFIG.__DOCUMENT_PATH__()
  }

  public supportedFiletypes = ['image/svg+xml']
  public maxFileSize = 1024 * 1024


  public ERROR_CORRECTION_LEVELS = [
    { key: 'L', value: 'Low (7%)' },
    { key: 'M', value: 'Medium (15%)' },
    { key: 'Q', value: 'Quartile (25%)' },
    { key: 'H', value: 'High (30%)' }
  ]


  @Model('input', { type: Object, required: true, default: (): CodeConfig => ({ ...BackendConfigManager.defaultDocDB.codes[0] }) })
  public codeConfig!: CodeConfig

  @Prop({ type: Boolean, required: false, default: () => false })
  public disabled!: boolean

  @Prop({ type: String, required: false, default: () => '' })
  readonly baseUrl!: string

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly disableModifyWidth!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly disableModifyErrorCorrectionLevel!: boolean

  @Prop({ type: Boolean, required: false, default: () => false })
  readonly disableCustomLogo!: boolean

  @Watch('codeConfig', { immediate: true })
  private onCodeConfigChanged() {
    console.log('here')

    if (this.codeConfig.svgTemplateUrl === '') {
      this.formTemplateUrlSelector = this.PRESELECTED_TEMPLATE_ID
      this.formTemplateUrlUpload = ''
    } else if (!this.codeConfig.svgTemplateUrl.startsWith('http')) {
      this.formTemplateUrlSelector = this.codeConfig.svgTemplateUrl
      this.formTemplateUrlUpload = ''
    } else if (this.codeConfig.svgTemplateUrl.length > 0) {
      this.formTemplateUrlSelector = 'custom'
      this.formTemplateUrlUpload = this.codeConfig.svgTemplateUrl
    }

    this.onSelectedTemplateChanged()
  }

  @Watch('formTemplateUrlSelector')
  @Watch('formTemplateUrlUpload')
  private onSelectedTemplateChanged() {
    if (this.formTemplateUrlSelector === 'custom') {
      this.codeConfig.svgTemplateUrl = this.formTemplateUrlUpload
    } else {
      this.codeConfig.svgTemplateUrl = this.formTemplateUrlSelector
    }
  }

  public onValidateFile(file: File) {
    return true
  }
}
</script>

<style lang="scss">
.echo-code-container {
  max-width: 100%;
  margin: auto;
  width: 14em;
  padding: 1em 0;
}
</style>
