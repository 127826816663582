<template>
  <nav class="breadcrumb" aria-label="breadcrumbs">
    <ol class="breadcrumb">
      <router-link
        v-for="(item, index) in matchedRoutes"
        :key="item.name"
        :to="{ name: item.name }"
        tag="li"
        :index="index"
      >
        <a>{{ showName(item) | lowercase }}</a>
      </router-link>
    </ol>
  </nav>

</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class VBreadcrumb extends Vue {
  get matchedRoutes() {
    return this.$route.matched
  }

  get route() {
    return this.$route
  }

  public showName(item: any) {
    return (item.meta && item.meta.label) || item.name
  }
}
</script>
