<template>
  <nav
    class="module-element-list-container"
    :class="{'is-expanded': $localSettings.modules.expandSidebar}"
  >
    <b-icon
      icon="angle-double-left"
      class="sidebar-toggle"
      size="small"
      @click.native="$localSettings.modules.expandSidebar = !$localSettings.modules.expandSidebar"
    />
    <router-link :to="toListLink(Module)" class="title is-3">{{ label || $route.meta.label }}</router-link>
    <h3 class="subtitle">{{ description || $route.meta.description }}</h3>
    <slot name="module-menu-body" :props="$props">
      <VModuleMenuBody v-bind="[$props, $attrs]">
        <!-- pass slots -->
        <template v-for="(index, name) in $slots" #[name]>
          <slot :name="name" />
        </template>

        <!-- pass slots -->
        <template v-for="(index, name) in $scopedSlots" #[name]="data">
          <slot :name="name" v-bind="data" />
        </template>
      </VModuleMenuBody>
    </slot>
  </nav>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { SlickList, SlickItem, HandleDirective } from 'vue-slicksort'


import { ModuleType } from './typeModules'
import { ModuleManager } from './moduleManager'
import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLock, faEllipsisV, faEllipsisH, faChevronDown, faChevronRight, faAngleDoubleLeft, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons'
import BaseModule from './baseModule'

import VModalCategoryFilter from '@/components/VModalCategoryFilter.vue'

import VModuleMenuBody from './VModuleMenuBody.vue'
import { RouteConfig } from 'vue-router'


library.add(faLock, faTimes, faEllipsisV, faEllipsisH, faChevronDown, faChevronRight, faAngleDoubleLeft, faFilter)

@Component({
  components: {
    SlickList,
    SlickItem,
    VInputMultiCategorySelection,
    VModalCategoryFilter,
    VModuleMenuBody
  },
  directives: {
    handle: HandleDirective
  }
})
export default class VModuleMenu extends Vue {
  public isLoading = false


  @Prop({ type: String, required: false, default: () => '' })
  readonly label!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly description!: string

  @Prop({ type: String, required: false, default: () => '' })
  readonly moduleType!: ModuleType

  @Prop({ type: Function, required: false, default: (module: typeof BaseModule) => ({ name: module.routeNameList }) })
  readonly toListLink!: (module: typeof BaseModule) => RouteConfig


  public Module!: typeof BaseModule


  @Watch('moduleType', { immediate: true })
  public async onRouteChanged() {
    this.Module = ModuleManager.getModuleClassByType(this.moduleType)
  }
}
</script>


<style lang="scss">
@import '@/variables.scss';
@import '@/mixins.scss';

nav.module-element-list-container {
  flex-direction: column;
  -ms-flex-negative: 0;
  // flex-shrink: 0;
  padding: 2rem 1rem;
  background: #e9e9e9;
  position: relative;
  height: 100%;
  align-items: stretch;
  display: flex;
  width: 100%;

  .menu-tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    overflow: hidden;

    nav a {
      padding: 0.5rem 0.3rem;
    }

    .tab-content {
      flex-grow: 1;
      padding: 1rem 0 0;

      // height: stretch;

      .tab-item {
        flex-grow: 1;
        display: flex;
        height: 10rem;
      }
    }
  }

  @include from($tablet) {
    width: 16rem;

    &.is-expanded {
      width: 26rem;
      // width: auto;
      // max-width: 26rem;
    }
  }

  .sidebar-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: all 0.2s linear;
    transform: rotate(180deg);
  }

  &.is-expanded {
    .sidebar-toggle {
      transform: rotate(0deg);
      // left: 8px;
    }
  }
}
</style>
