import { render, staticRenderFns } from "./Backend_Asid_List.vue?vue&type=template&id=dfb72cf2"
import script from "./Backend_Asid_List.vue?vue&type=script&lang=ts"
export * from "./Backend_Asid_List.vue?vue&type=script&lang=ts"
import style0 from "./Backend_Asid_List.vue?vue&type=style&index=0&id=dfb72cf2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports