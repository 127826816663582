<template>
  <section>
    <b-dropdown
      ref="dropdown"
      :append-to-body="true"
      v-bind="$attrs"
      aria-role="menu"
      scrollable
      max-height="300"
      class="categrory-filter"
      trap-focus
      :triggers="['focus']"
      @active-change="(a) => dropdownIsOpen = a"
    >
      <template #trigger>
        <b-field>
          <b-field class="has-addons">
            <p class="control">
              <b-button
                class="filter-button"
                icon-left="filter"
                size="is-small"
                @click="onOpenFilter"
              >
                Filter
                <span v-if="filterConfig.in.length > 0">
                  <b-tag
                    rounded
                    size="is-small"
                    :type="{
                      'is-warning': !isUserSelectionSubsetOfPresets
                    }"
                  >{{ filterConfig.in.length }}</b-tag>
                </span>
                <span v-else-if="filterConfig.presets && filterConfig.presets.length > 0">
                  <b-tag rounded size="is-small">({{ filterConfig.presets.length }})</b-tag>
                </span>
              </b-button>
            </p>
            <p v-if="filterConfig.in.length > 0" class="control">
              <b-button
                class="filter-button"
                icon-left="times"
                size="is-small"
                @click="onResetFilter"
              />
            </p>
          </b-field>
        </b-field>
      </template>
      <section class="filter-dropdown-body">
        <b-dropdown-item custom aria-role="listitem">
          <!-- if there are filterConfig.presets, show a b-message informing that a preset filter is active -->
          <b-message
            v-if="filterConfig.presets && filterConfig.presets.length > 0"
            type="is-info"
            size="is-small"
            :closable="true"
          >
            Filter preset active:
            <b-taglist
              :tags="filterConfig.presets"
              :categories-doc="$categories"
              size="is-small"
              rounded
            >
              <b-tag
                v-for="catID in filterConfig.presets.slice(0, showMorePresets ? filterConfig.presets.length : 10)"
                :key="catID"
                size="is-small"
              >{{ $getCategoryName(catID) }}</b-tag>

              <!-- if more than 10 cats, show show more buttons -->
              <b-tag
                v-if="filterConfig.presets.length > 10 && !showMorePresets"
                size="is-small"
                @click="showMorePresets = !showMorePresets"
              >... {{ filterConfig.presets.length - 10 }} more</b-tag>
              <b-tag
                v-else-if="filterConfig.presets.length > 10 && showMorePresets"
                size="is-small"
                @click="showMorePresets = !showMorePresets"
              >show less</b-tag>
            </b-taglist>
          </b-message>
        </b-dropdown-item>

        <b-dropdown-item custom aria-role="listitem">
          <VInputMultiCategorySelection
            v-model="formSelectedCategories"
            :categories-doc="getAvailableUserCategories()"
            :show-close-button="true"
          />
        </b-dropdown-item>

        <b-dropdown-item custom aria-role="listitem">
          <b-message
            v-if="!isUserSelectionSubsetOfPresets"
            type="is-warning"
            size="is-small"
            :closable="true"
          >Your selection is not a subset of the preset filter, it is ignored.</b-message>
        </b-dropdown-item>

        <b-dropdown-item custom aria-role="listitem">
          <b-field label>
            <b-checkbox v-model="formIncludeChildCats">
              Include child categories
              <VTooltipIconHelp
                position="is-left"
                text="Also shown elements which are assigned to child categories"
              />
            </b-checkbox>
          </b-field>
        </b-dropdown-item>

        <b-dropdown-item custom aria-role="listitem">
          <b-field grouped class="buttons">
            <b-button type="is-primary" @click="onApplyFilter">Apply Filter</b-button>
            <b-button @click="onResetFilter">Reset Filter</b-button>
          </b-field>
        </b-dropdown-item>
      </section>
      <!-- <b-loading :is-full-page="false" :active="isLoading" :can-cancel="false" /> -->
    </b-dropdown>
  </section>
</template>

<script lang="ts">
import { Component, Vue, PropSync } from 'vue-property-decorator'

import VInputMultiCategorySelection from '@/components/VInputMultiCategorySelection.vue'

import { acessorObjectToString } from '@/database/dbHelper'
import { CategoryID } from '@/types/typeCategory'
import CategoryHelper from '@/database/categoryHelper'
import { FilterConfig } from './mixins/VPaginateMixin.vue'


@Component({
  components: {
    VInputMultiCategorySelection
  },
  inheritAttrs: false,
  props: {
    default: {
      type: Boolean,
      required: false,
      default: () => true
    }
  }
})
export default class VFilterCategoriesDropdownView extends Vue {
  public isLoading = false
  public dropdownIsOpen = false
  public showMorePresets = false


  @PropSync('config', { type: Object })
  public filterConfig!: FilterConfig<any>

  public formSelectedCategories: CategoryID[] = []
  public formIncludeChildCats = false


  get propertyAcessorPath() {
    return acessorObjectToString(this.filterConfig.objAcessor)
  }


  public onApplyFilter() {
    this.filterConfig.in = (this.formIncludeChildCats)
      ? CategoryHelper.getAllChildCategoriesArray(this.formSelectedCategories, this.$categories)
      : this.formSelectedCategories
    ; (this.$refs.dropdown as any).toggle()
  }

  public onResetFilter() {
    this.filterConfig.in = []
    this.formSelectedCategories = []
    if (this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }

  public onOpenFilter() {
    // update formSelectedCategories based on filterConfig.in
    this.formSelectedCategories = this.filterConfig.in as CategoryID[]

    if (!this.dropdownIsOpen) {
      (this.$refs.dropdown as any).toggle()
    }
  }

  public getAvailableUserCategories() {
    return CategoryHelper.getAvailableUserCategoriesCollection(this.$categories, this.$auth?.user?.visibleCategories)
  }

  get isUserSelectionSubsetOfPresets() {
    const presets = this.filterConfig.presets || []

    return presets.length > 0
      ? this.formSelectedCategories.every((catID) => presets.includes(catID))
      : true
  }
}
</script>

<style lang="scss">
.b-table .table th .th-wrap .filter-button .icon {
  margin-left: calc(-0.375em - 1px);
}

.filter-dropdown-body {
  min-width: 25em;

  .filter-dropdown-tabs {
    span.tag.is-small.is-rounded {
      height: 1.4em;
    }
  }
}

.categrory-filter {
  .dropdown-content {
    overflow: visible !important;
  }
}
</style>
