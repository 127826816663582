<template>
  <main class="column backend-dashboard">
    <!-- <div class="level">
            <div class="level-left">
              <div class="level-item">
                <div class="title">Dashboard</div>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <button type="button" class="button is-small">March 8, 2017 - April 6, 2017</button>
              </div>
            </div>
    </div>-->

    <div class="columns is-multiline">
      <div class="column">
        <div class="box">
          <div class="heading">Available ECHO Codes</div>
          <div class="title">{{ planAvailableAsidSlots - planActivatedAsids }}</div>
          <div class="level">
            <div class="level-item">
              <div class>
                <div class="heading">Slot Count</div>
                <div class="title is-5">{{ planAvailableAsidSlots }}</div>
              </div>
            </div>
            <div class="level-item">
              <div class>
                <div class="heading">Active Codes</div>
                <div class="title is-5">{{ planActivatedAsids }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="heading">Interactions used</div>
          <div
            class="title"
          >{{ Math.floor(planTotalUsedInteractions / planAvailableInteractions * 10000)/100 }}%</div>
          <div class="level">
            <div class="level-item">
              <div class>
                <div class="heading">Available</div>
                <div class="title is-5">{{ planAvailableInteractions }}</div>
              </div>
            </div>
            <div class="level-item">
              <div class>
                <div class="heading">interactions total</div>
                <div class="title is-5">{{ planTotalUsedInteractions }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="heading">Storage Used</div>
          <div class="title" :title="rawStorageTotal">{{ planStorageQuotaTotal }}</div>
          <div class="level">
            <div class="level-item">
              <div class>
                <div class="heading">App uploads</div>
                <div class="title is-5">{{ planStorageQuotaApp }}</div>
              </div>
            </div>
            <div class="level-item">
              <div class>
                <div class="heading">backend upl.</div>
                <div class="title is-5">{{ planStorageQuotaBackend }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box">
          <div class="heading">ECHO CODE Scans total</div>
          <div class="title">{{ totalAppSessions }}</div>
          <div class="level">
            <div class="level-item">
              <div class>
                <div class="heading">last month</div>
                <div class="title is-5">{{ lastMonthAppSessions }}</div>
              </div>
            </div>
            <div class="level-item">
              <div class>
                <div class="heading">current month</div>
                <div class="title is-5">
                  {{ currentMonthAppSessions }}
                  <span
                    v-if="currentMonthAppSessions > lastMonthAppSessions"
                  >&uarr;</span>
                </div>
              </div>
            </div>
            <!-- <div class="level-item">
              <div class>
                <div class="heading">Success %</div>
                <div class="title is-5">+ 28%</div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
      <div class="column">
        <div class="box current-box">
          <div class="heading">Scans in Selected Date Range</div>
          <div class="title">{{ customRangeAppSessions }}</div>
          <div class="level">
            <div class="level-item">
              <div class>
                <div class="heading">interactions</div>
                <div class="title is-5">{{ customRangeInteractions }}</div>
              </div>
            </div>
            <div class="level-item">
              <div class>
                <div class="heading">activations</div>
                <div class="title is-5">{{ customRangeActivations }}</div>
              </div>
            </div>
            <!-- <div class="level-item">
              <div class>
                <div class="heading">Success %</div>
                <div class="title is-5">+ 28%</div>
              </div>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="panel">
          <p class="panel-heading level">
            <span class="level-left">
              ECHO Code Scans & Interactions
              <b-tag
                type="is-primary"
                closable
                class="selected-time"
                close-type="is-primary"
                close-icon-type="is-dark"
                attached
                close-icon="redo"
                aria-close-label="Close tag"
                @close="setTimeRange(-1, 'M', 'today')"
              >{{ selectedTimeRange }}</b-tag>
            </span>
            <span class="level-right">
              <b-button
                type="is-text"
                size="is-small"
                @click="setTimeRange(-1, 'M', 'today')"
              >last 30 days</b-button>

              <b-button
                type="is-text"
                size="is-small"
                @click="setTimeRange(0, 'M', 'boundary')"
              >this month</b-button>

              <b-button
                type="is-text"
                size="is-small"
                @click="setTimeRange(-1, 'M', 'boundary')"
              >last month</b-button>

              <!-- this year -->
              <b-button
                type="is-text"
                size="is-small"
                @click="setTimeRange(0,'y','boundary')"
              >this year</b-button>

              <!-- dropdown with more options -->
              <b-dropdown position="is-bottom-left" trigger="is-hoverable">
                <template #trigger>
                  <b-button icon-left="ellipsis-h" size="is-small" />
                </template>
                <b-dropdown-item @click="setTimeRange(-1,'w','today')">
                  <b-icon size="is-small" icon="calendar-week" />last week
                </b-dropdown-item>
                <b-dropdown-item @click="setTimeRange(-1,'d','today')">
                  <b-icon size="is-small" icon="calendar-day" />yesterday
                </b-dropdown-item>
                <b-dropdown-item @click="setTimeRange(0,'d','boundary')">
                  <b-icon size="is-small" icon="calendar-day" />today
                </b-dropdown-item>
                <b-dropdown-item custom>
                  <b-datepicker
                    range
                    :mobile-native="false"
                    class="show-pointer"
                    @input="(date) => onCustomTimeRange(date)"
                  >
                    <template #trigger>
                      <b-icon size="is-small" icon="calendar-alt" />custom range
                    </template>
                  </b-datepicker>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </p>
          <div class="panel-block">
            <div class="chart-container">
              <VueApexCharts
                v-if="!isLoading"
                ref="time-range-chart-1"
                :height="300"
                :options="timeSeriesAppSessionsAndInteractions_chartOptions"
                :series="timeSeriesAppSessionsAndInteractions"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="column is-6">
        <div class="panel">
          <p class="panel-heading">latest {{ latestAssignedAsids.length }} ECHO Codes</p>
          <div class="panel-block">
            <VTimeSeriesGraph v-if="!isLoading" :series="timeSeriesAsids" />
          </div>
        </div>
      </div>-->
      <div class="column is-6">
        <div class="panel">
          <p class="panel-heading level">
            <span class="level-left">
              Module Interactions per Day
              <b-tag
                type="is-primary"
                closable
                class="selected-time"
                close-type="is-primary"
                close-icon-type="is-dark"
                attached
                close-icon="redo"
                aria-close-label="Close tag"
                @close="setTimeRange(-1, 'M', 'today')"
              >{{ selectedTimeRange }}</b-tag>
            </span>
          </p>
          <div class="panel-block">
            <!-- <VTimeSeriesGraph
              v-if="!isLoading"
              :series="timeSeriesInteractionsPerModulePerDay"
              :color="timeSeriesInteractionsPerModuleColors"
              is-date-time
            />-->
            <div class="chart-container">
              <VueApexCharts
                v-if="!isLoading"
                ref="time-range-chart-2"
                :height="300"
                :options="timeSeriesInteractionsPerModulePerDay_chartOptions"
                :series="timeSeriesInteractionsPerModulePerDay"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="column is-6">
        <div class="panel">
          <p class="panel-heading">All Module Interactions per Time</p>
          <div class="panel-block">
            <VTimeSeriesGraph
              v-if="!isLoading"
              :series="timeSeriesInteractionsPerModulePerTime"
              :color="timeSeriesInteractionsPerModuleColors"
              :x-axis-categories="Array.from(Array(24).keys()).flatMap(i=>[`${i}:00`,`${i}:30`])"
            />
          </div>
        </div>
      </div>

      <div class="column is-12">
        <div class="panel">
          <p class="panel-heading level">
            <span class="level-left">
              Responses per Category and Module
              <b-tag
                type="is-primary"
                closable
                class="selected-time"
                close-type="is-primary"
                close-icon-type="is-dark"
                attached
                close-icon="redo"
                aria-close-label="Close tag"
                @close="setTimeRange(-1, 'M', 'today')"
              >{{ selectedTimeRange }}</b-tag>
            </span>
            <span class="level-right">
              <b-button
                type="is-text"
                size="is-small"
                @click="setTimeRange(-1, 'M', 'today')"
              >last 30 days</b-button>

              <b-button
                type="is-text"
                size="is-small"
                @click="setTimeRange(0, 'M', 'boundary')"
              >this month</b-button>

              <b-button
                type="is-text"
                size="is-small"
                @click="setTimeRange(-1, 'M', 'boundary')"
              >last month</b-button>

              <!-- this year -->
              <b-button
                type="is-text"
                size="is-small"
                @click="setTimeRange(0,'y','boundary')"
              >this year</b-button>

              <!-- dropdown with more options -->
              <b-dropdown position="is-bottom-left" trigger="is-hoverable">
                <template #trigger>
                  <b-button icon-left="ellipsis-h" size="is-small" />
                </template>
                <b-dropdown-item @click="setTimeRange(-1,'w','today')">
                  <b-icon size="is-small" icon="calendar-week" />last week
                </b-dropdown-item>
                <b-dropdown-item @click="setTimeRange(-1,'d','today')">
                  <b-icon size="is-small" icon="calendar-day" />yesterday
                </b-dropdown-item>
                <b-dropdown-item @click="setTimeRange(0,'d','boundary')">
                  <b-icon size="is-small" icon="calendar-day" />today
                </b-dropdown-item>
                <b-dropdown-item @click="setTimeRange(0,'y','boundary')">
                  <b-icon size="is-small" icon="calendar-alt" />this year
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </p>
          <div class="panel-block loading-container">
            <div class="chart-container">
              <VResponseStatisticsTable :categories="categories" :responses="responses" />
            </div>

            <b-loading :active="responsesLoading" :can-cancel="false" :is-full-page="false" />
          </div>
        </div>
      </div>

      <div class="column is-12">
        <div class="panel">
          <p class="panel-heading">Latest {{ responsesForm.length }} Form Responses</p>
          <div class="panel-block">
            <div class="is-fullwidth">
              <VResponseTimelineView
                v-if="!isLoading"
                :responses-form="responsesForm"
                :module-elements-with-type="formElementsTypeAndID"
                :show-asid-info="true"
              />

              <!-- load more button -->
              <div class="has-text-centered">
                <b-button
                  v-if="responsesForm.length >= limit"
                  class="load-more-button"
                  @click="loadMoreResponses"
                >Load more</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="column is-6">
        <div class="panel">
          <p class="panel-heading">Something Else</p>
          <div class="panel-block">
            <figure class="image is-16x9">
              <img src="https://placehold.it/1280x720" />
            </figure>
          </div>
        </div>
      </div>-->
    </div>

    <b-loading :active="isLoading" :can-cancel="false" :is-full-page="true" />
  </main>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator'
import moment from 'dayjs'

import { ModuleManager } from '@/modules/moduleManager'
import { getStatisticsForDateRange, getStatisticsForMonth, getStatisticsPerDay, getStatisticsPerTime } from '@/helpers/statisticsHelper'
import SessionManager from '@/database/sessionManager'


import AsidManager from '@/database/asidManager'

import VTimeSeriesGraph from '@/components/VTimeSeriesGraph.vue'
import VResponseStatisticsTable from '@/components/VResponseStatisticsTable.vue'
import { AsidDB } from '@/types/typeAsid'
import { timeSeriesPreparationHelper, typedOrderBy, typedWhere } from '@/database/dbHelper'


import { BaseResponseDB, BaseModuleDB, ElementWithTypeAndID, ModuleStatistics, ModuleType, BaseElementDB } from '@/modules/typeModules'
import { hasDBid } from '@/types/typeGeneral'
import { Statistics } from '@/types/typeBase'
import VueApexCharts from 'vue-apexcharts'
import { AppSessionDB } from '@/types/typeAppSession'
import VResponseTimelineView from '@/components/VResponsesTimelineView.vue'
import { FormElementDB, FormResponseDB } from '@/modules/form/typeFormModule'
import FormModule from '@/modules/form/formModule'
import StorageManager from '@/helpers/StorageManager'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { PlanDB } from '@/types/typePlan'
import { cloneObject } from '@/helpers/dataShapeUtil'
import databaseSchema from '@/database/databaseSchema'
import db from '@/firebase'
import ApexCharts from 'apexcharts'
import { throttle } from 'throttle-debounce'
import { DataCache } from '@/helpers/dataCache'
import { handlePromiseError } from '@/helpers/notificationHelper'

@Component({
  components: {
    VTimeSeriesGraph,
    VueApexCharts,
    VResponseTimelineView,
    VResponseStatisticsTable
  }
})
export default class BackendDashboard extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public isLoading = false
  public moduleDBs: BaseModuleDB[] = []

  public minDateUnixTimestamp = 0
  public maxDateUnixTimestamp = 0
  visibleRangeSums: any

  public categories: { id: string, name: string, parent: string | null }[] = [
    // { id: 'root', name: 'Root', parent: null },
    // { id: 'vehicles', name: 'Vehicles', parent: 'root' },
    // { id: 'cars', name: 'Cars', parent: 'vehicles' },
    // { id: 'sedans', name: 'Sedans', parent: 'cars' },
    // { id: 'trucks', name: 'Trucks', parent: 'vehicles' },
    // { id: 'construction', name: 'Construction', parent: 'root' },
    // { id: 'excavators', name: 'Excavators', parent: 'construction' },
    // { id: 'cranes', name: 'Cranes', parent: 'construction' }
  ]


  public responses: {
    type: ModuleType
    name: string
    categories: string[]
  }[] = [
      // { type: 'form', name: 'Survey', categories: ['root', 'vehicles', 'cars'] },
      // { type: 'form', name: 'Service Request', categories: ['root', 'vehicles', 'trucks'] },
      // { type: 'form', name: 'Service Request 2', categories: ['root', 'vehicles', 'trucks'] },
      // { type: 'form', name: 'Service Request 3', categories: ['root', 'vehicles', 'trucks'] },
      // { type: 'file', name: 'Manual.pdf', categories: ['root', 'construction', 'vehicles', 'cranes'] },
      // { type: 'file', name: 'Tutorial.mp4', categories: ['root', 'construction', 'excavators'] },
      // { type: 'link', name: 'Website', categories: ['root', 'construction'] }
    ]

  private moduleElementDataCache = new DataCache<BaseElementDB & hasDBid>(async (key) => {
    const [type, id] = key.split(':') as [ModuleType, string]
    if (id == 'NO_ELEMENT_ID') return { name: 'no Element' } as any
    return await ModuleManager.getModuleClassByType(type).getElement(this.$auth.tenant.id, id)
  })

  private prepareCategories() {
    this.categories = this.$categoriesListWithID.map((c) => ({
      id: c.id,
      name: c.name,
      parent: c.parentID
    }))
  }

  public responsesLoading = false

  private async getAllResponses() {
    this.responsesLoading = true

    try {
      const activeModule = await ModuleManager.getActivatedModules(this.$auth.tenant.id)
      const allResponses = await Promise.all(activeModule.map(async (module) => {
        let rspsQuery = module.getResponsesQuery(this.$auth.tenant.id)

        // use end of day, as the visualisation in the chart is only date wise, and if the day is included but only upt to e.g. 9:00, the graph will count the whole day events
        const minStartOfDay = moment(this.minDateUnixTimestamp).startOf('day').toDate()
        const maxEndOfDay = moment(this.maxDateUnixTimestamp).endOf('day').toDate()

        // log human readable min max date
        console.log('min', moment(minStartOfDay).format('DD.MM.YYYY HH:mm:ss'))
        console.log('max', moment(maxEndOfDay).format('DD.MM.YYYY HH:mm:ss'))


        rspsQuery = typedWhere<BaseResponseDB>(rspsQuery, { _meta: { dateCreated: {} } }, '>=', minStartOfDay)
        rspsQuery = typedWhere<BaseResponseDB>(rspsQuery, { _meta: { dateCreated: {} } }, '<=', maxEndOfDay)

        rspsQuery = rspsQuery.limit(1000)

        const moduleType = module.type

        return { moduleType, responses: await rspsQuery.get() }
      }))

      const elementPromises: Promise<BaseElementDB & hasDBid>[] = []

      const preparedResponses = allResponses
        .flatMap((r) => r.responses.docs.map((d) => ({ type: r.moduleType, ...d.data() as BaseResponseDB, id: d.id, elementName: '' })))

      preparedResponses.forEach((r) => {
        const elementPromise = this.moduleElementDataCache.get(`${r.type}:${r.public.elementID}`)
        elementPromises.push(elementPromise)
      })

      await Promise.all(elementPromises)

      for (const r of preparedResponses) {
        r.elementName = (await this.moduleElementDataCache.get(`${r.type}:${r.public.elementID}`)).name
      }

      this.responses = preparedResponses.map((r) =>
        ({
          type: r.type,
          name: r.elementName,
          categories: r.categoryIDs
        })
      )
    } catch (error) {
      this.$helpers.notification.Error(error)
    } finally {
      this.responsesLoading = false
    }
  }

  public onCustomTimeRange(dates: Date[]) {
    console.log('custom time range')
    this.updateDateRange(dates[0].getTime(), dates[1].getTime())
  }

  /**
   *
   * @param range - number of days, months, weeks or years to go back
   * @param unit - unit of time to go back
   * @param pivot - today or boundary. today will set the max date to today, boundary will set the max date to the last day of the range
   */
  public setTimeRange(range: number, unit: 'y' | 'M' | 'w' | 'd' = 'd', pivot: 'today' | 'boundary' = 'today', pivotDate = new Date()) {
    let minDate = 0
    let maxDate = 0

    const startDate = pivot === 'today' ? moment(pivotDate).add(range, unit) : moment(pivotDate).add(range, unit).startOf(unit)
    const endDate = pivot === 'today' ? moment(pivotDate) : startDate.clone().endOf(unit)

    minDate = startDate.toDate().getTime()
    maxDate = endDate.toDate().getTime()

    this.updateDateRange(minDate, maxDate)
  }

  public get selectedTimeRange(): string {
    return `${moment(this.minDateUnixTimestamp).format('DD.MM.YYYY')} - ${moment(this.maxDateUnixTimestamp).format('DD.MM.YYYY')}`
  }

  // @Watch('minDateUnixTimestamp')
  // @Watch('maxDateUnixTimestamp')
  // @Watch('isLoading', { immediate: true })
  // dont use the watchers as the double update cancels the animation
  private updateDateRange(min: number, max: number) {
    this.minDateUnixTimestamp = min
    this.maxDateUnixTimestamp = max

    const chartRef_1 = this.$refs['time-range-chart-1'] as any
    const chartRef_2 = this.$refs['time-range-chart-2'] as any

    const chartRefs = [chartRef_1, chartRef_2]

    if (!chartRefs || this.isLoading) return

    chartRefs.forEach((chart) => {
      void (chart as ApexCharts)
        .updateOptions({
          xaxis: {
            min: this.minDateUnixTimestamp,
            max: this.maxDateUnixTimestamp
          }
        })
    })

    handlePromiseError(this.getAllResponses())
  }

  private throttleUpdateDateRange = throttle<(min: number, max: number) => void>(2000, (min, max) => {
    console.log('throttle', min, max)
    this.updateDateRange(min, max)
  }, { noLeading: false, noTrailing: false })


  private handleChartZoomChange(xaxis: { max: number, min: number }) {
    this.throttleUpdateDateRange(xaxis.min, xaxis.max)
  }


  public async created() {
    try {
      this.isLoading = true
      await this.initActivatedModules()
      await this.initPlanData()
      await this.initAsidGraphs()
      await this.initLoadResponses()

      this.prepareCategories()
      await this.getAllResponses()
    } catch (error: unknown) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading = false
    }
  }

  public mounted() {
    this.setTimeRange(-1, 'M', 'today')
  }

  get filteredModuleDBs() {
    return this.moduleDBs
      .filter((mod) => !['Html', 'I18n', 'Data', 'Ci'].includes(mod.public.type))
  }


  // #region latest Form Responses
  public responsesForm: Array<hasDBid & FormResponseDB> = []
  public formElements: (FormElementDB)[] = []

  public get formElementsTypeAndID(): ElementWithTypeAndID[] {
    return this.formElements.map((fe) => ({
      type: 'Form',
      id: (fe as any).id,
      ...fe
    }))
  }

  public limit = 5

  public async loadMoreResponses() {
    this.limit += 10
    await this.loadResponses()
  }

  private async loadResponses() {
    await this.$firestoreBind('responsesForm',
      typedOrderBy<FormResponseDB>(
        FormModule
          .getResponsesDbReference(this.$auth.tenant.id)
          .limit(this.limit)
        , { _meta: { dateCreated: '' as any } }, 'desc'),
      { wait: true })
  }

  private async initLoadResponses() {
    await this.loadResponses()

    await this.$firestoreBind('formElements', FormModule.getElementsQuery(this.$auth.tenant.id), { wait: true })
  }
  // #endregion latest Form Responses

  // ASID GRAPH
  public latestAssignedAsids: Array<AsidDB & hasDBid> = []
  public latestActivatedAsids: Array<AsidDB & hasDBid> = []
  public latestInteractions: Array<BaseResponseDB & hasDBid> = []


  get timeSeriesAsids() {
    const groupedDataActivated = timeSeriesPreparationHelper(
      this.latestActivatedAsids.filter((a) => a.dateActivated),
      (obj) => obj.dateActivated.toDate()
    )

    const groupedDataAssigned = timeSeriesPreparationHelper(
      this.latestAssignedAsids.filter((a) => a.dateAssigned),
      (obj) => obj.dateAssigned.toDate()
    )


    return [
      {
        name: 'Activated Echo Codes',
        // data: [Date.now(),Date.now(),Date.now(),Date.now()]
        data: [...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 30].map((t) => ({ x: moment(groupedDataActivated[0]?.x, 'MM/DD/YYYY').add(t, 'days'), y: 0 })), ...groupedDataActivated]
      }, {
        name: 'Assigned Echo Codes',
        // data: [Date.now(),Date.now(),Date.now(),Date.now()]
        data: groupedDataAssigned
      }
    ]
  }


  get timeSeriesInteractions() {
    const groupedDataInteractions = timeSeriesPreparationHelper(
      this.latestInteractions.filter((a) => a._meta.dateCreated !== undefined),
      (obj) => obj._meta.dateCreated.toDate()
    )

    return [
      {
        name: 'Interactions',
        // data: [Date.now(),Date.now(),Date.now(),Date.now()]
        data: [...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 30].map((t) => ({ x: moment(groupedDataInteractions[0]?.x, 'MM/DD/YYYY').add(t, 'days'), y: 0 })), ...groupedDataInteractions]
      }
    ]
  }

  // #region timeSeriesInteractionsPerModulePerDay

  get interactionsPerModule(): { name: string, statistics: Statistics<ModuleStatistics> }[] {
    return this.filteredModuleDBs.map((mdb) => ({
      name: mdb.public.type,
      statistics: mdb._computed.statistics
    }))
  }


  get timeSeriesInteractionsPerModulePerDay() {
    return this.interactionsPerModule
      .map((ipm) => {
        return {
          name: ipm.name,
          data: getStatisticsPerDay(ipm.statistics).map((d) => ({ x: d.date, y: d.events.rc }))
        }
      })
  }

  private get timeSeriesInteractionsPerModulePerDay_visibleCount() {
    const statisitcsForDateRange = this.interactionsPerModule.map((ipm, i) => {
      const statisticsForRange = getStatisticsForDateRange(ipm.statistics, new Date(this.minDateUnixTimestamp), new Date(this.maxDateUnixTimestamp))
      return statisticsForRange.reduce((acc, curr) => acc + (curr.events.rc || 0), 0)
    })

    return statisitcsForDateRange
  }

  public get timeSeriesInteractionsPerModulePerDay_chartOptions() {
    return {
      colors: this.timeSeriesInteractionsPerModuleColors,
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      chart: {
        type: 'area', // bar has super bad performance
        // type: 'bar',
        stacked: false,
        height: 300,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          tools: {
            show: true,
            autoSelected: 'zoom',
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            // disable reset home view
            reset: false
          }
        },
        events: {
          zoomed: (chartContext: any, { xaxis }: any): void => {
            this.handleChartZoomChange(xaxis)
          },
          scrolled: (chartContext: any, { xaxis }: any): void => {
            this.handleChartZoomChange(xaxis)
          }
        }
      },
      legend: {
        show: true,
        formatter: (seriesName: any, opts: any) => {
          const seriesIndex = opts.seriesIndex
          return [seriesName, ' - ', this.timeSeriesInteractionsPerModulePerDay_visibleCount[seriesIndex]]
        }
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            // console.log(val)

            return (val || 0).toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
        // title: {
        //   // text: 'Price'
        // }
      },
      xaxis: {
        type: 'datetime',
        min: moment(new Date()).subtract(1, 'month').toDate().getTime(),
        max: moment(new Date()).toDate().getTime()
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
      }
    }
  }

  // #endregion timeSeriesInteractionsPerModulePerDay

  // #region statistics

  get customRangeInteractions() {
    const statistics = this.planDB._computed?.statistics
    if (!statistics) return 0
    const statisticsForRange = getStatisticsForDateRange(statistics, new Date(this.minDateUnixTimestamp), new Date(this.maxDateUnixTimestamp))
    return statisticsForRange.reduce((acc, curr) => acc + (curr.events.ti || 0), 0)
  }

  private appSessionStatistics: AppSessionDB | null = null

  get customRangeAppSessions() {
    const statistics = this.appSessionStatistics?._computed?.statistics
    if (!statistics) return 0
    const statisticsForRange = getStatisticsForDateRange(statistics, new Date(this.minDateUnixTimestamp), new Date(this.maxDateUnixTimestamp))
    return statisticsForRange.reduce((acc, curr) => acc + (curr.events.c || 0) + (curr.events.u || 0), 0)
  }

  get customRangeActivations() {
    const statistics = this.planDB._computed?.statistics
    if (!statistics) return 0
    const statisticsForRange = getStatisticsForDateRange(statistics, new Date(this.minDateUnixTimestamp), new Date(this.maxDateUnixTimestamp))
    return statisticsForRange.reduce((acc, curr) => acc + (curr.events.ac || 0), 0)
  }

  get totalAppSessions() {
    const totalEvents = this.appSessionStatistics?._computed?.statistics?.date?.e
    return (totalEvents?.c || 0) + (totalEvents?.u || 0)
  }

  get lastMonthAppSessions() {
    const currentYear = new Date().getUTCFullYear()
    const currentMonth = new Date().getUTCMonth() + 1

    const year = (currentMonth === 1) ? currentYear - 1 : currentYear
    const lastMonth = (currentMonth === 1) ? 12 : currentMonth - 1

    const statistics = this.appSessionStatistics?._computed?.statistics
    if (!statistics) return 0
    const events = getStatisticsForMonth(statistics, year, lastMonth)
    return (events.c || 0) + (events.u || 0)
  }

  get currentMonthAppSessions() {
    const currentYear = new Date().getUTCFullYear()
    const currentMonth = new Date().getUTCMonth() + 1

    const statistics = this.appSessionStatistics?._computed?.statistics
    if (!statistics) return 0
    const events = getStatisticsForMonth(statistics, currentYear, currentMonth)
    return (events.c || 0) + (events.u || 0)
  }

  // #endregion statistics


  // #region timeSeriesAppSessionsAndInteractions
  get timeSeriesAppSessionsAndInteractions() {
    return [{
      name: 'Sessions',
      data: this.appSessionStatistics?._computed?.statistics
        && getStatisticsPerDay(this.appSessionStatistics._computed.statistics)
          .map((d) => ({ x: d.date, y: (d.events.c || 0) + (d.events.u || 0) }))
    },
    {
      name: 'Interactions',
      data: this.planDB._computed.statistics
        && getStatisticsPerDay(this.planDB._computed.statistics)
          .map((d) => ({ x: d.date, y: (d.events.ti || 0) }))
    }
    ]
  }

  private get timeSeriesAppSessionsAndInteractions_visibleCount() {
    return [this.customRangeAppSessions, this.customRangeInteractions]
  }

  public get timeSeriesAppSessionsAndInteractions_chartOptions() {
    return {
      states: {
        active: {
          filter: {
            type: 'none' /* none, lighten, darken */
          }
        }
      },
      colors: ['#9368B7', '#AA3E98', '#9297C4', '#84C7D0', '#75DDDD'],
      chart: {
        type: 'area',
        // type: 'bar',
        stacked: false,
        height: 300,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          tools: {
            show: true,
            autoSelected: 'zoom',
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            // disable reset home view
            reset: false
          }
        },

        events: {
          zoomed: (chartContext: any, { xaxis }: any): void => {
            this.handleChartZoomChange(xaxis)
          },
          scrolled: (chartContext: any, { xaxis }: any): void => {
            this.handleChartZoomChange(xaxis)
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      legend: {
        show: true,
        formatter: (seriesName: any, opts: any) => {
          const seriesIndex = opts.seriesIndex
          // return `${seriesName}: ${this.timeSeriesInteractionsPerModulePerDay_visibleCount[seriesIndex]}`
          return [seriesName, ' - ', this.timeSeriesAppSessionsAndInteractions_visibleCount[seriesIndex]]
        }
      },
      // markers: {
      //   size: 0
      // },
      // title: {
      //   // text: 'Assigned Codes',
      //   align: 'left'
      // },
      // fill: {
      //   type: 'gradient',
      //   gradient: {
      //     shadeIntensity: 1,
      //     inverseColors: false,
      //     opacityFrom: 0.5,
      //     opacityTo: 0,
      //     stops: [0, 90, 100]
      //   }
      // },
      yaxis: {
        labels: {
          formatter: function (val: number) {
            // console.log(val)

            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
        // title: {
        //   // text: 'Price'
        // }
      },
      xaxis: {
        type: 'datetime',
        min: moment(new Date()).subtract(1, 'month').toDate().getTime(),
        max: moment(new Date()).toDate().getTime()
        // max: moment(new Date()).add(1,'week').toDate().getTime()
        // min: moment(new Date()).startOf('month').toDate().getTime(),
        // max: moment(new Date()).endOf('month').toDate().getTime()
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val: number) {
            return val.toFixed(0)
            // return (val / 1000000).toFixed(0)
          }
        }
      }
    }
  }

  // #endregion timeSeriesAppSessionsAndInteractions


  get timeSeriesInteractionsPerModulePerTime() {
    return this.interactionsPerModule.map((ipm) => {
      const statisticsData = getStatisticsPerTime(ipm.statistics)
      // hydrate statistiscs with all possible time, since apexcharts needs all entries when providing data
      const data = Array.from(Array(24).keys())
        .flatMap((i) => [`${String(i).padStart(2, '0')}:00`, `${String(i).padStart(2, '0')}:30`])
        .map((time) => {
          const entryForTime = statisticsData.find((sd) => sd.time === time)
          return { time, events: { rc: entryForTime?.events.rc || 0 } }
        })
      return {
        name: ipm.name,
        data: data.map((d) => ({ x: d.time, y: d.events.rc || 0 }))
      }
    })
  }


  public async initAsidGraphs() {
    try {
      await this.$firestoreBind('appSessionStatistics',
        SessionManager.getStatisticsDbDocReference(this.$auth.tenant.id)
      )

      // await this.$firestoreBind('latestAssignedAsids',
      //   typedWhere<AsidDB>(
      //     typedWhere<AsidDB>(
      //       typedOrderBy<AsidDB>(AsidManager.getDbCollectionReference(), { dateAssigned: null as Timestamp }, 'desc')
      //       , { dateAssigned: null as Timestamp }, '!=', null)
      //     , { tenantID: null }, '==', this.$auth.tenant.id).limit(1000)
      // )
      // await this.$firestoreBind('latestActivatedAsids',
      //   typedWhere<AsidDB>(
      //     typedWhere<AsidDB>(
      //       typedOrderBy<AsidDB>(AsidManager.getDbCollectionReference(), { dateActivated: null as Timestamp }, 'desc')
      //       , { dateActivated: null as Timestamp }, '!=', null)
      //     , { tenantID: null }, '==', this.$auth.tenant.id).limit(1000)
      // )

      // await this.$firestoreBind('latestInteractions',
      //   typedWhere<AsidDB>(
      //     typedOrderBy<AsidDB>(db.collectionGroup(databaseSchema.COLLECTIONS.TENANTS.MODULES.RESPONSES.__NAME__), { _meta: { dateCreated: undefined } }, 'desc')
      //     , { tenantID: null }, '==', this.$auth.tenant.id).limit(1000)
      // )
    } catch (error: any) {
      this.$helpers.notification.Error(error.toString())
    }
  }
  /// / ASID GRAPH

  // ACTIVE_MODULES
  public timeSeriesInteractionsPerModuleColors: string[] = []

  public async initActivatedModules() {
    return new Promise<void>((resolve, reject) => {
      this.$unbindHandle(ModuleManager.onSnapshotActivatedModules(this.$auth.tenant.id, this.$auth.userPrivileges, (modules) => {
        console.log('ouou')
        this.moduleDBs = modules
        this.timeSeriesInteractionsPerModuleColors = this.filteredModuleDBs.map((m) => ModuleManager.getModuleClassByType(m.public.type).color)

        resolve()
      }, reject))
    })
  }
  /// / ACTIVE_MODULES


  // PLAN_DATA
  public planActivatedAsids = 0
  public planAvailableAsidSlots = 0
  public planTotalUsedInteractions = 0

  public planAvailableInteractions = 0

  public planStorageQuotaTotal = ''
  public rawStorageTotal = ''
  public planStorageQuotaApp = ''
  public planStorageQuotaBackend = ''

  private planDB: PlanDB = cloneObject(databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__EMPTY_DOC__)

  private async initPlanData() {
    return this.$bindSnapshot<PlanDB>('planDB',
      db.doc(databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__DOCUMENT_PATH__(this.$auth.tenant.id)),
      (data) => {
        this.planActivatedAsids = data._computed.activatedAsids
        this.planAvailableAsidSlots = data.availableAsidSlots
        this.planTotalUsedInteractions = data._computed.totalUsedInteractions
        this.planStorageQuotaTotal = StorageManager.returnFileSize(data._computed.totalUsedStorageQuota)
        this.rawStorageTotal = String(data._computed.totalUsedStorageQuota) + ' bytes'
        this.planStorageQuotaApp = StorageManager.returnFileSize(data._computed.totalUsedAppStorageQuota)
        this.planStorageQuotaBackend = StorageManager.returnFileSize(data._computed.totalUsedBackendStorageQuota)
        console.log(data)

        this.planAvailableInteractions = AsidManager.getAvailableInteractionsCount(data)

        return data
      }
    )
  }
  /// / PLAN_DATA


  // ASID_STATS

  /// / ASID_STATS
}
</script>

<style lang="scss">
.backend-dashboard {
  .loading-container {
    position: relative;
  }

  .selected-time {
    &.tags.inline-tags {
      margin-bottom: -0.5rem;
    }

    span.icon.has-text-dark {
      color: #fff !important;
      padding-right: 0.6rem;
    }
    margin-left: 1rem;
  }

  .current-box {
    box-shadow: inset 0 0 0 5px #575c6b;
  }

  .panel-heading.level {
    margin-bottom: 0;

    button.button.is-small.is-text {
      height: 1.2rem;
    }
  }

  .level {
    .level-item {
      flex-grow: 0 !important;
      justify-content: space-between;
    }
  }

  .chart-container {
    width: 100%;
  }
}
</style>
