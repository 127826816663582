<template>
  <b-field grouped group-multiline class="module-elements-container">
    <b-taglist
      v-for="(els,type,i) in groupElementsByType(elements)"
      :key="type+'_'+i"
      class="element-taglist"
      attached
    >
      <VTagModuleElement v-for="element in els" :key="element.id" :element="element" />
    </b-taglist>
  </b-field>
</template>

<script lang="ts">

import { ModuleType, BaseElementDB } from '../modules/typeModules'
import VTagModuleElement from '@/components/VTagModuleElement.vue'
import { groupBy } from '../database/dbHelper'

export default {
  name: 'VTagModuleElements',
  components: { VTagModuleElement },
  props: {
    elements: {
      type: Array, // Module Element with id and type
      required: true
    },
    groupElementsByType: {
      type: Function,
      default(elements: Array<BaseElementDB & { type: ModuleType }>) {
        let tmpElementsByType: { [key: string]: typeof elements } = {}
        tmpElementsByType = groupBy(elements.filter((e) => e.publishingState === 'published'), (el) => el.type)
        return tmpElementsByType
      }
    }
  }
}
</script>

<style lang="scss">
.module-elements-container {
  // display: flow-root;

  &.field.is-grouped.is-grouped-multiline:last-child {
    margin-bottom: 0;
  }
}

.element-taglist.has-addons.tags {
  display: inline-flex;

  // &:not(:last-child) {
  margin-bottom: 0;
  margin-right: 0.4em;
  // }

  .tag {
    margin-right: 0.1rem;
    margin-bottom: 0.1rem;
  }

  span:not(:last-child) .tag {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    border-right: 1px solid #ffffff4f;
  }

  span:not(:first-child) .tag {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  // :not(:hover) {
  //   .tag {
  //     // display: none;

  //     :first-child {
  //       display: flex;
  //       border-radius: 4px;

  //       ::after {
  //         content: '...';
  //         display: inline-block;
  //         margin-left: 6px;
  //         border-left: 1px solid #0000002b;
  //         height: 2em;
  //         padding: 0em 0 0 0.5em;
  //       }
  //     }
  //   }
  // }
}
</style>
