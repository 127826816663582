<template>
  <div class="modal-card" style="width: auto;">
    <header class="modal-card-head">
      <p class="modal-card-title">Select Tenant</p>
    </header>
    <section class="modal-card-body">
      Your Account is assigned to multiple Tenants. Select the one you want to login to.
      <b-field label="Tenant">
        <b-select v-model="selectedTenant" placeholder="Select Tenant" expanded @input="onSelect">
          <option v-for="tenant in tenants" :key="tenant.name" :value="tenant">
            {{ tenant.name }} .
            - {{ formatPadded(tenant._number) }}
          </option>
        </b-select>
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <div class="is-right">
        <button class="button" type="button" @click="onClose">Cancel</button>
        <button class="button is-success" @click="onSelect()">Select</button>
      </div>
    </footer>
  </div>
</template>


<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TenantDB } from '../types/typeTenant'
import TenantManager from '../database/tenantManager'
import { hasDBid } from '@/types/typeGeneral'

@Component({

})
export default class VFormLogin extends Vue {
  public selectedTenant: TenantDB & hasDBid | null = null

  @Prop({ type: Array, required: true, default: () => [] })
  readonly tenants!: Array<TenantDB & hasDBid>

  public onClose() {
    (this.$parent as any).close()
  }

  public formatPadded(number: number) {
    return TenantManager.formatPaddedNumber(number)
  }

  public async onSelect() {
    this.$emit('selected', this.selectedTenant)
    this.onClose()
  }
}
</script>
