<template>
  <section class="qr-code-scanner-container">
    <p v-if="error" class="error">
      <b-message
        title="Warning"
        type="is-warning"
        :closable="!reloadSolveable"
        aria-close-label="Close message"
      >
        <span>{{ error }}</span>
        <button v-if="reloadSolveable" class="button is-warning is-pulled-right" @click="reload">
          <span class="icon">
            <font-awesome-icon icon="sync-alt" />
          </span>
          <span>Reload</span>
        </button>
      </b-message>
    </p>

    <qrcode-stream
      v-if="!isDestroyed"
      v-show="!error"
      :track="paintFoundQRCodeRectangle"
      :camera="camera"
      :paused="camera"
      @detect="onDetect"
      @init="onInit"
    />

    <qrcode-drop-zone @detect="onDetect" @init="logErrors" @dragover="e => (dragover = e)">
      <div
        :class="{ 'highlight-dragover': dragover }"
        class="drop-area clickable notification image-upload"
        @click="$refs.file.$el.click()"
      >
        <span>Drop image of ECHO Code here or click to</span>
        <span class="button">upload</span>
        <span>one.</span>
      </div>
    </qrcode-drop-zone>

    <qrcode-capture ref="file" style="display: none;" :multiple="false" @detect="onDetect" />
  </section>
</template>


<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import AsidManager from '@/database/asidManager'

@Component({
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture
  }
})
export default class VAsidScanner extends Vue {
  public error = ''
  public reloadSolveable = true
  public result = ''
  public isLoading = false
  public isDestroyed = false
  public dragover = false
  public noStreamApiSupport = false

  public camera = 'auto'


  async onDecode(res: string) {
    await this.parseResult(res)
  }

  private async parseResult(res: string) {
    this.result = res
    if (!res) {
      this.$helpers.notification.Warn('[2102121501] No ECHO Code found')
      return
    }
    try {
      const parsedUrl = new URL(res)
      const extractedAsidString = AsidManager.extractAsidFromString(parsedUrl.pathname)
      console.log(extractedAsidString)
      console.log(AsidManager.getRegex())

      if (extractedAsidString) {
        this.isLoading = true
        // this.camera = 'off'

        // pretend it's taking really long
        // await this.timeout(500)
        // todo check if valid asid from server

        this.$helpers.notification.InfoToast(`ECHO Code ${extractedAsidString} detected.`)


        // wait 2 secs
        await new Promise((resolve) => setTimeout(resolve, 1000))

        // this.camera = 'auto'
        this.$emit('asidDetected', extractedAsidString)
        // this.$router.push({ name: 'asid', params: { asid: matchedRegex[0] } })

        // pause to reset the detection, otherwise the same code can not be detected again
        this.camera = 'off'
        // wait next tick
        await this.$nextTick()
        this.camera = 'auto'

        this.isLoading = false
      } else {
        throw new Error()
      }
    } catch {
      this.error = `ECHO Code not valid [${res}]`
      this.reloadSolveable = false
    } finally {
      this.isLoading = false
    }
  }

  async onDetect(promise: any) {
    try {
      const { content } = await promise

      await this.parseResult(content)
    } catch (error: any) {
      if (error.name === 'DropImageFetchError') {
        this.error = '[2102121455] Sorry, you can\'t load cross-origin images :/'
      } else if (error.name === 'DropImageDecodeError') {
        this.error = '[2102121456] Ok, that\'s not an image. That can\'t be decoded.'
      } else {
        this.error = '[2102121457] ' + error.message
      }
    }
  }

  logErrors(promise: any) {
    promise.catch(console.error)
  }

  public paintFoundQRCodeRectangle(location: {
    cornerPoints: [
      { x: number, y: number },
      { x: number, y: number },
      { x: number, y: number },
      { x: number, y: number }
    ]
  }[], ctx: CanvasRenderingContext2D) {
    if (location.length < 1) return

    const topLeftCorner = location[0].cornerPoints[0],
      bottomLeftCorner = location[0].cornerPoints[1],
      bottomRightCorner = location[0].cornerPoints[2],
      topRightCorner = location[0].cornerPoints[3]

    ctx.strokeStyle = 'green' // instead of red
    ctx.lineWidth = 10

    ctx.beginPath()
    ctx.moveTo(topLeftCorner.x, topLeftCorner.y)
    ctx.lineTo(bottomLeftCorner.x, bottomLeftCorner.y)
    ctx.lineTo(bottomRightCorner.x, bottomRightCorner.y)
    ctx.lineTo(topRightCorner.x, topRightCorner.y)
    ctx.lineTo(topLeftCorner.x, topLeftCorner.y)
    ctx.closePath()

    ctx.stroke()
  }

  async reload() {
    this.isDestroyed = true

    await this.$nextTick()

    this.isDestroyed = false
  }

  async onInit(promise: Promise<any>) {
    this.isLoading = true

    try {
      await promise
      this.error = ''
    } catch (error: any) {
      this.reloadSolveable = false
      if (error.name === 'NotAllowedError') {
        this.error = '[2102121410] You need to grant camera access permisson'
        this.reloadSolveable = true
      } else if (error.name === 'NotFoundError') {
        this.error = '[2102121420] No camera on this device'
      } else if (error.name === 'NotSupportedError') {
        this.error = '[2102121430] Secure context required (HTTPS, localhost)'
      } else if (error.name === 'NotReadableError') {
        this.error = '[2102121440] Is the camera already in use?'
      } else if (error.name === 'OverconstrainedError') {
        this.error = '[2102121450] Installed cameras are not suitable'
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.error = '[2102121400] Stream API is not supported in this browser'
        this.noStreamApiSupport = true
      } else {
        this.error = `${error.name}: ${error.message}`
      }
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
.qr-code-scanner-container {
  //
}
</style>
