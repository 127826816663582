<template>
  <div class="modal-card changelog-diff" style="width: auto;">
    <header class="modal-card-head">
      <p class="modal-card-title">Compare</p>
    </header>
    <section class="modal-card-body">
      <div v-if="!isLoading" class="columns">
        <div class="column is-one-third">
          <b-field label="compare Revision with">
            <b-field>
              <b-radio-button v-model="compareAgainst" expanded native-value="current">
                <span>current data</span>
              </b-radio-button>

              <b-radio-button v-model="compareAgainst" expanded native-value="previous">
                <span>previous dataset</span>
              </b-radio-button>
            </b-field>
          </b-field>
          <b-menu>
            <b-menu-list label="Revisions">
              <b-menu-item
                :class="'current-revision'"
                icon="file-alt"
                :active="compareWithDocIndex === -1"
                @click="compareWithDocIndex = -1"
              >
                <template slot="label">
                  Updated by
                  <b>{{ currentDoc._meta.modifiedByUserId }}</b>
                  at
                  {{ currentDoc._meta.dateUpdated.toDate() }}
                </template>
              </b-menu-item>
              <b-menu-item
                v-for="(doc, i) in changelogDocs"
                :key="i"
                icon="file-alt"
                :active="compareWithDocIndex === i"
                @click="compareWithDocIndex = i"
              >
                <template slot="label">
                  Updated by
                  <b>{{ doc._meta.modifiedByUserId }}</b>
                  at
                  {{ doc._meta.dateUpdated.toDate() }}
                </template>
              </b-menu-item>
            </b-menu-list>
          </b-menu>
        </div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="column" v-html="changelogDiffHtml" />
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">Close</button>
      <button
        v-if="!disallowResetToRevision"
        class="button is-warning"
        :disabled="compareWithDocIndex == -1"
        @click="onResetToRevision"
      >reset to revision</button>
    </footer>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch, Prop } from 'vue-property-decorator'
import db from '@/firebase'
import RecordMeta from '@/types/typeRecordMeta'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons'

import * as Diff2Html from 'diff2html'
import * as Diff from 'diff'
import 'diff2html/bundles/css/diff2html.min.css'
import { merge } from '@/database/dbHelper'
import BaseManager from '@/database/baseManager'
import databaseSchema from '@/database/databaseSchema'

library.add(faFileAlt)

@Component({
  components: {}
})
export default class VModuleCompareChangelogModal extends Vue {
  @Prop({ type: String, required: false, default: () => '' })
  public documentPath!: string

  @Prop({ type: Boolean, required: false, default: () => false })
  public disallowResetToRevision!: boolean

  public compareWithDocIndex: number = 0
  public isLoading = false
  public compareAgainst = 'current'

  public currentDoc: any = null

  get changelogDiffHtml() {
    if (this.changelogDocs.length > 0) {
      // sort object keys for better comparison results
      // Spec http://www.ecma-international.org/ecma-262/6.0/#sec-json.stringify
      const replacer = (key: any, value: any) => {
        return value instanceof Object && !(value instanceof Array)
          ? Object.keys(value)
            .sort()
            .reduce((sorted, key) => {
              (sorted as any)[key] = value[key]
              return sorted
            }, {})
          : value
      }

      // Usage

      // JSON.stringify({c: 1, a: { d: 0, c: 1, e: {a: 0, 1: 4}}}, replacer);

      // const newDoc = (this.compareWithDocIndex - 1 < 0) ? this.currentDoc : this.changelogDocs[this.compareWithDocIndex - 1]
      let newDoc, oldDoc
      // compare with last change
      if (this.compareAgainst === 'previous') {
        newDoc = (this.compareWithDocIndex < 0) ? this.currentDoc : this.changelogDocs[this.compareWithDocIndex]
        oldDoc = (this.compareWithDocIndex + 1 > this.changelogDocs.length - 1) ? {} as any : this.changelogDocs[this.compareWithDocIndex + 1]
      } else { // compare with current doc
        newDoc = this.currentDoc
        oldDoc = (this.compareWithDocIndex < 0) ? this.currentDoc : this.changelogDocs[this.compareWithDocIndex]
      }

      // to clone docs
      newDoc = merge({}, newDoc)
      oldDoc = merge({}, oldDoc)

      // dont compare meta, may confuse user
      if (newDoc._meta)
        delete newDoc._meta
      if (oldDoc._meta)
        delete oldDoc._meta

      if (newDoc._computed)
        delete newDoc._computed
      if (oldDoc._computed)
        delete oldDoc._computed


      const newData = JSON.stringify(newDoc, replacer, 2)
      const oldData = JSON.stringify(oldDoc, replacer, 2)
      const diff = Diff.createPatch(this.documentPath, oldData, newData)

      return Diff2Html.html(diff, {
        drawFileList: false,
        matching: 'lines',
        outputFormat: 'line-by-line'
      })
    } else {
      return ''
    }
  }

  public changelogDocs: { _meta: RecordMeta }[] = []
  @Watch('documentPath', { immediate: true })
  async onDocPathChanged() {
    this.isLoading = true
    try {
      this.currentDoc = (await db.doc(this.documentPath).get()).data()

      let query = db.doc(this.documentPath).collection('Changelog').orderBy('_meta.dateUpdated', 'desc').limit(10)
      // if document path is asid path, add where query for tenant id
      if (this.documentPath.match(databaseSchema.COLLECTIONS.ASID.__DOCUMENT_PATH__('.*'))) {
        query = query.where('tenantID', '==', this.$auth.tenant.id)
      }

      const changelogDocs = (await query.get()).docs
      const changeLogData = changelogDocs.map((doc) => doc.data() as { _meta: RecordMeta })
      console.log(changeLogData)

      this.changelogDocs = changeLogData
    } catch (error: any) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading = false
    }
  }

  async onResetToRevision() {
    this.isLoading = true

    try {
      if (!this.changelogDocs[this.compareWithDocIndex])
        throw ('could not find revision')

      await BaseManager.updateDoc(db.doc(this.documentPath), this.$auth.userEmail, this.changelogDocs[this.compareWithDocIndex]);

      (this.$parent as any).close()
    } catch (error: any) {
      this.$helpers.notification.Error(error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss">
.changelog-diff {
  .current-revision {
    border: 1px solid #e3e3e3;
  }

  .d2h-file-diff {
    overflow-x: scroll;
    overflow-y: scroll;
    max-height: 70vh;
  }

  .d2h-code-wrapper {
    position: relative;
  }
}
</style>
