<template>
  <main class="column">
    <section class="hero is-large is-warning">
      <div class="hero-body">
        <p class="title">404 Page Not Found</p>
        <p class="subtitle">the page {{ url }} was not found</p>
      </div>
    </section>
  </main>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {

  }
})
export default class BackendPageNotFound extends Vue {
  public get url() {
    return location.href
  }
}
</script>
