<template>
  <!-- show a notification if the required privileges are missing, otherwise show the slot content -->
  <div v-if="!$auth.userHasAllPrivilege(requiredPrivileges)">
    <b-message type="is-warning">
      <span>You don't have the required privileges to view this content.</span>

      <p>
        <span>Required additional privileges:</span>
        <b-taglist>
          <b-tag v-for="privilege in missingPrivileges" :key="privilege">{{ privilege }}</b-tag>
        </b-taglist>
      </p>
      <p v-if="missingPrivilegesExplanation">{{ missingPrivilegesExplanation }}</p>
    </b-message>
  </div>
  <div v-else>
    <slot />
  </div>
</template>

<script lang="ts">
import { UserPrivilegeIdDB } from '@/types/typeUser'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({

})
export default class VPrivilegeNotification extends Vue {
  @Prop({ type: Array, required: true })
  public readonly requiredPrivileges!: UserPrivilegeIdDB[]

  @Prop({ type: String, default: '' })
  public readonly missingPrivilegesExplanation = ''

  public get missingPrivileges() {
    return this.requiredPrivileges.filter((privilege) => !this.$auth.userHasPrivilege(privilege))
  }
}
</script>
