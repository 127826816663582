<template functional>
  <b-tooltip :type="props.type" multilined :label="props.text" :position="props.position" animated>
    <b-icon class="help-icon" size="is-small" icon="flask" />
  </b-tooltip>
</template>

<script lang="ts">
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFlask } from '@fortawesome/free-solid-svg-icons'

library.add(faFlask)

export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'is-dark'
    },
    text: {
      type: String,
      required: true
    },
    position: {
      type: String,
      required: false,
      default: 'is-top'
    }
  }
}
</script>

<style lang="scss">
.help-icon {
  display: inline-block;

  &.icon svg {
    height: inherit;
  }
}
</style>
