import Vue from 'vue'

import BaseGlobals from './baseGlobals'

import { VueConstructor } from 'vue/types/umd'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'

import localstorage from '@/helpers/localstorage'
import { LocalSettingsGlobal, LocalSettingsPerTenant } from '@/types/typeLocalSettings'
import deepmerge from 'deepmerge'
import { objectID } from '@/types/typeGeneral'
import { userID } from '@/types/typeUser'
import { TenantID } from '@/types/typeTenant'
import { assetAttributeValue } from '@/types/typeAsid'

class LocalSettingsGlobals extends BaseGlobals {
  private tenantSpecificLocalSettingsKey = (tenantID: TenantID, userID: userID) =>
    `localSettings--${tenantID}--${userID}`

  private globalLocalSettingsKey = 'localSettingsGlobal'

  constructor() {
    super()

    // just init the observable here, as the key to get the data from localstorage is dependent on the tenant and user
    this.obersvableDataLocalSettingsPerTenant = Vue.observable(deepmerge(this.defaultSettingsPerTenant, {}))

    // the global data is independent of the tenant and user and may be used before the user is logged in
    this.obersvableDataLocalSettingsGlobal = Vue.observable(
      deepmerge(
        this.defaultSettingsGlobal,
        localstorage.get<LocalSettingsGlobal>(this.globalLocalSettingsKey, this.defaultSettingsGlobal, Object)
      )
    )

    // init them here, as they are independant of the tenant and user
    this.initGlobalSettings()
  }

  private defaultSettingsGlobal: LocalSettingsGlobal = {
    login: {
      selectedTenantID: ''
    }
  }

  private defaultSettingsPerTenant: LocalSettingsPerTenant = {
    modules: {
      hideReferencesInSidebar: false,
      collapsedGroups: [],
      collapsedDisplayGroups: [],
      groupedBy: '',
      sortBy: '',
      filter: '',
      filters: {
        categories: [],
        categoriesIncludeChildCats: false,
        categoriesIncludeParentCats: false
      },
      expandSidebar: false
    },
    mainMenu: {
      collapseSidebar: false
    },
    asidShow: {
      expandElements: false,
      filters: {
        hideVisits: false,
        responseModules: []
      }
    },
    categories: { expandByDefault: false, showElementsByDefault: false },
    asidActivate: {
      persistCategory: true,
      displayQRCodeScanner: true,
      displayBarcodeScanner: false,
      displayActivateAnyButton: false,
      persistedCategories: []
    },
    asidlist: {
      sortField: 'dateActivated',
      sortDirection: 'desc',
      filter: {
        categories: []
      }
    },
    appPreview: {
      width: 30,
      ciElementId: '',
      includeDraft: false,
      references: {
        asidID: 'previ-ew111-echo1-code1',
        categoryIDs: [],
        identifierValue: {
          i1: '',
          i2: '',
          i3: '',
          i4: '',
          i5: '',
          i6: ''
        }
      },
      assetAttributeValue: {
        ...(Object.fromEntries(
          Array.from(Array(200).keys()) /// 0..49
            .map((i) => ['a' + (i + 1), null])
        ) as assetAttributeValue)
      }
    },
    table: {
      hiddenColumns: {}
    }
  }

  private obersvableDataLocalSettingsPerTenant!: LocalSettingsPerTenant
  private obersvableDataLocalSettingsGlobal!: LocalSettingsGlobal

  // init is only called in backend
  init(tenantID: objectID, userID: userID): Promise<SnapshotUnbindHandle> {
    return new Promise((resolve) => {
      const tenantSpecificLocalSettingsKey = this.tenantSpecificLocalSettingsKey(tenantID, userID)

      const localStorageTenantSpecificData = localstorage.get<LocalSettingsPerTenant>(
        tenantSpecificLocalSettingsKey,
        this.defaultSettingsPerTenant,
        Object
      )

      // iterate over all data from obersvableDataLocalSettingsPerTenant which is filled to the default data at this point
      // override it with the data from localstorage if it exists
      // this preserves the structure of the default data and ensures reactivity
      // => actually iterate over the data in lcalstorage and merge it with the default data
      // => this is as there might be dynamic keys in the data, like table/<url> which are not present in the default data (stores the config per table and url)
      const recursiveMerge = (target: any, source: any) => {
        for (const key in source) {
          if (Object.prototype.hasOwnProperty.call(source, key)) {
            if (Array.isArray(source[key])) {
              if (!target[key]) Vue.set(target, key, [])

              target[key] = source[key]
            } else if (typeof source[key] === 'object') {
              // if is null, set null
              if (source[key] === null) {
                Vue.set(target, key, null)
              } else if (!target[key]) {
                Vue.set(target, key, {})
              }

              recursiveMerge(target[key], source[key])
            } else {
              if (!target[key]) Vue.set(target, key, {})

              target[key] = source[key]
            }
          }
        }
      }

      recursiveMerge(this.obersvableDataLocalSettingsPerTenant, localStorageTenantSpecificData)

      const dataPertenant = this.obersvableDataLocalSettingsPerTenant
      const v = new Vue({
        created() {
          this.$watch(
            () => dataPertenant,
            (value) => {
              console.log('local settings value changed to:', value)
              localstorage.set(tenantSpecificLocalSettingsKey, value, Object)
            },
            { deep: true, immediate: true }
          )
        }
      })

      resolve(() => {
        v.$destroy()
      })
    })
  }

  private initGlobalSettings() {
    const dataGlobal = this.obersvableDataLocalSettingsGlobal
    const globalLocalSettingsKey = this.globalLocalSettingsKey

    const v = new Vue({
      created() {
        this.$watch(
          () => dataGlobal,
          (value) => {
            console.log('global settings value changed to:', value)
            localstorage.set(globalLocalSettingsKey, value, Object)
          },
          { deep: true, immediate: true }
        )
      }
    })

    return () => {
      v.$destroy()
    }
  }

  public get localSettings() {
    return this.obersvableDataLocalSettingsPerTenant
  }

  public get localSettingsGlobal() {
    return this.obersvableDataLocalSettingsGlobal
  }
}

export const LocalSettingsGlobalsInst = new LocalSettingsGlobals()

declare module 'vue/types/vue' {
  interface Vue {
    $localSettings: LocalSettingsPerTenant // per tenant
    $localSettingsGlobal: LocalSettingsGlobal // global
  }
}

export default {
  install(Vue: VueConstructor, options: any) {
    Vue.prototype.$localSettings = LocalSettingsGlobalsInst.localSettings
    Vue.prototype.$localSettingsGlobal = LocalSettingsGlobalsInst.localSettingsGlobal
  }
}
