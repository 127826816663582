<template>
  <section class="is-limited-width-content">
    <div v-if="!isLoading" class="level record-meta">
      <div class="level-left" />
      <VRecordMeta
        position="is-bottom-left"
        class="level-right"
        :record-meta="plan._meta"
        :document-path="documentPath"
        :required-privileges="documentPrivileges"
        disallow-reset-to-revision
      />
    </div>

    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">PlanDB</h3>
        <span class="setting-description">Change your subscription plan</span>
      </div>

      <div class="column is-three-quarters setting-box card">
        You are currently in
        <b-tag class="is-primary">{{ planType }}</b-tag>
        PlanDB. It expires on {{ planExpires.toLocaleDateString() }}.
        <b-field label="Change" expanded>
          <a
            style="margin-left: 20px;"
            class="button is-small"
            :href="'mailto:purchase@echoprm.com?subject=Change%20Plan%20-%20(TENANT_NAME%2C%20Nr%3A%20TENANT_NR)&body=Hello%2C%0D%0A%0D%0Aplease%20activate%20my%20tenant%20account%20and%20change%20plan%20to%20....%0D%0A%0D%0ABest%20Regards%2C%0D%0A%0D%0A(TENANT_NAME%2C%20Nr%3A%20TENANT_NR)'.replaceAll('TENANT_NR', formTenantNumber).replaceAll('TENANT_NAME', formName)"
          >Change PlanDB</a>
        </b-field>
      </div>
    </div>
    <hr />

    <b-notification
      v-if="planType.toLowerCase() === 'test_plan'"
      :closable="false"
      type="is-warning is-light"
      aria-close-label="Close notification"
      role="alert"
    >You are currently on free plan. Change your plan to configure your PRM Modules.</b-notification>
    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">PRM Modules</h3>
        <span class="setting-description">Manage active PRM Modules</span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <div class="label">
          Widget Modules
          <VTooltipIconHelp position="is-top" text="PRM Modules that render App Widgets" />
        </div>

        <div class="module-list-container">
          <div
            v-for="mod in widgetModules"
            :key="mod.type"
            :style="getTileStyle(mod)"
            class="box module"
          >
            <div>
              <h1 class="title is-5" :style="getTileColor(mod)">
                {{ mod.type }}
                <a
                  v-if="!activeModules.includes(mod)"
                  style="margin-left: 20px;"
                  class="button is-small"
                  :href="'mailto:purchase@echoprm.com?subject=Activate%20PRM%20Module&body=Hello%2C%0D%0A%0D%0APlease%20send%20us%20(TENANT_NAME%2C%20Nr%3A%20TENANT_NR)%20a%20quote%20for%20enabling%20the%20module%20MODULE_NAME.%0D%0A%0D%0ABest%20Regards%2C%0D%0A%0D%0A'.replaceAll('MODULE_NAME', mod.type).replaceAll('TENANT_NR', formTenantNumber).replaceAll('TENANT_NAME', formName)"
                >Request Activation</a>
                <a
                  v-else
                  :href="'mailto:purchase@echoprm.com?subject=Deactivate%20Module%20-%20(TENANT_NAME%2C%20Nr%3A%20TENANT_NR)&body=Hello%2C%0D%0A%0D%0APlease%20deactivate%20the%20module%20MODULE_NAME.%0D%0A%0D%0ABest%20Regards%2C%0D%0A%0D%0A(TENANT_NAME%2C%20Nr%3A%20TENANT_NR)'.replaceAll('MODULE_NAME', mod.type).replaceAll('TENANT_NR', formTenantNumber).replaceAll('TENANT_NAME', formName)"
                  style="margin-left: 20px;"
                  class="button is-small"
                >Request Deactivation</a>
              </h1>
              <div class="description" :style="getTileColor(mod)">{{ mod.description }}</div>
            </div>
          </div>
        </div>

        <div class="label">
          Global Modules
          <VTooltipIconHelp position="is-top" text="Provide functionality across pages." />
        </div>

        <div class="module-list-container">
          <div
            v-for="mod in nonWidgetModules"
            :key="mod.type"
            :style="getTileStyle(mod)"
            class="box module"
          >
            <div>
              <h1 class="title is-5" :style="getTileColor(mod)">
                {{ mod.type }}
                <a
                  v-if="!activeModules.includes(mod)"
                  style="margin-left: 20px;"
                  class="button is-small"
                  :href="'mailto:purchase@echoprm.com?subject=Activate%20PRM%20Module&body=Hello%2C%0D%0A%0D%0APlease%20send%20us%20(TENANT_NAME%2C%20Nr%3A%20TENANT_NR)%20a%20quote%20for%20enabling%20the%20module%20MODULE_NAME.%0D%0A%0D%0ABest%20Regards%2C%0D%0A%0D%0A'.replaceAll('MODULE_NAME', mod.type).replaceAll('TENANT_NR', formTenantNumber).replaceAll('TENANT_NAME', formName)"
                >Request Activation</a>
                <a
                  v-else
                  :href="'mailto:purchase@echoprm.com?subject=Deactivate%20Module%20-%20(TENANT_NAME%2C%20Nr%3A%20TENANT_NR)&body=Hello%2C%0D%0A%0D%0APlease%20deactivate%20the%20module%20MODULE_NAME.%0D%0A%0D%0ABest%20Regards%2C%0D%0A%0D%0A(TENANT_NAME%2C%20Nr%3A%20TENANT_NR)'.replaceAll('MODULE_NAME', mod.type).replaceAll('TENANT_NR', formTenantNumber).replaceAll('TENANT_NAME', formName)"
                  style="margin-left: 20px;"
                  class="button is-small"
                >Request Deactivation</a>
              </h1>

              <div class="description" :style="getTileColor(mod)">{{ mod.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <b-notification
      v-if="planType.toLowerCase() === 'test_plan'"
      :closable="false"
      type="is-warning is-light"
      aria-close-label="Close notification"
      role="alert"
    >You are currently on free plan. Change your plan to purchase ECHO Codes.</b-notification>
    <div class="columns setting">
      <div class="column setting-description-box">
        <h3 class="title is-5">ECHO Codes</h3>
        <span class="setting-description">Purchase ECHO codes.</span>
      </div>

      <div class="column is-three-quarters setting-box card">
        <b-field grouped>
          <b-field
            expanded
            :label="`Codes activated ${ activated } out of ${ availableAsidSlots }`"
          >
            <b-progress
              :value="activated"
              :max="availableAsidSlots"
              show-value
            >{{ activated / availableAsidSlots * 100 }} %</b-progress>
          </b-field>

          <b-field
            expanded
            :label="`Interactions ${ totalUsedInteractions } out of ${ availableInteractions }`"
          >
            <b-progress
              :value="totalUsedInteractions"
              :max="availableInteractions"
              show-value
            >{{ totalUsedInteractions / availableInteractions * 100 }} %</b-progress>
          </b-field>
        </b-field>

        <!-- <b-field label="ECHO Codes" expanded>
          <b-button @click="isModalRequestAsidCodesActive = true">Request Codes</b-button>
        </b-field>-->
      </div>
    </div>

    <!-- <div class="buttons is-right" style="margin-top:50px;">
      <b-button type="is-success" @click="onUpdate">Update</b-button>
      <b-button type="is-danger" @click="initialize">Cancel</b-button>
    </div>-->
    <hr />
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
    <VModalCreateAsidCodes
      :active="isModalRequestAsidCodesActive"
      :tenant="tenant"
      :backend-config="backendConfig"
    />
  </section>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'

import VFormConfigureSticker from '@/components/VFormConfigureSticker.vue'
import VRecordMeta from '@/components/VRecordMeta.vue'
import VModalCreateAsidCodes from '@/components/VModalCreateAsidCodes.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCalendarDay } from '@fortawesome/free-solid-svg-icons'
import color from 'color'


import { Address } from '@/types/typeTenant'

import { plans } from '../../businessLogic/plans'
import TenantManager from '@/database/tenantManager'


import { ModuleManager } from '@/modules/moduleManager'
import BaseModule from '@/modules/baseModule'
import AsidManager from '@/database/asidManager'
import BackendConfigManager from '@/database/backendConfigManager'
import { PlanDB, Plans } from '@/types/typePlan'
import { BackendConfigDB } from '@/types/typeBackendConfig'
import databaseSchema from '@/database/databaseSchema'
import { SnapshotUnbindHandle } from '@/types/typeDbHelper'
import { ValueOf } from '@/types/typeGeneral'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import { handlePromiseError } from '@/helpers/notificationHelper'

library.add(faCalendarDay)

@Component({
  components: {
    VFormConfigureSticker,
    VRecordMeta,
    VModalCreateAsidCodes
  },
  props: {}
})
export default class BackendConfigBilling extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public isLoading: boolean = false
  public isModalRequestAsidCodesActive: boolean = false

  public activeStep = 0
  public plan: PlanDB = { ...TenantManager.defaultPlanDB }
  private additionalInteractions: number = 0

  @Watch('isModalRequestAsidCodesActive')
  private onTenantDocUpdate() {
    if (!this.isModalRequestAsidCodesActive) this.activeStep = 0
  }

  public tenant = { ...TenantManager.defaultDocDB, id: '' }

  get documentPath() {
    return TenantManager.getDbPlanDocReference(this.$auth.tenant.id).path
  }

  get documentPrivileges() {
    return databaseSchema.COLLECTIONS.TENANTS.DATA.PLAN.__PRIVILEGES__
  }

  public planExpires = new Date()
  public planType: string = ''
  // used for form controls
  public formAvailablePlans: Array<{ key: string, value: ValueOf<Plans> }> = Object.keys(plans).map((p) => ({
    key: p,
    value: plans[p as keyof Plans]
  })) // Enum to string array

  public formName: string = ''
  public formMasterDataPhone: string = ''
  public formMasterDataEmail: string = ''


  public formMasterDatabillingAddress: Address = {} as Address
  public formMasterDatashippingAddress: Address = {} as Address

  // PRM Modules

  public modules = ModuleManager.moduleClasses
  public activeModules: typeof ModuleManager.moduleClasses = []

  public getTileStyle(module: typeof BaseModule) {
    return {
      backgroundImage: `linear-gradient( 155deg, ${module.color} 10%, ${color(module.color).rotate(-20).lighten(0.1)} 100%)`,
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  public getTileColor(module: typeof BaseModule) {
    return {
      color: (color(module.color).isDark()) ? '#fff' : '#000'
    }
  }

  get widgetModules() {
    return this.modules.filter((M) => M.hasWidget)
  }

  get nonWidgetModules() {
    return this.modules.filter((M) => !M.hasWidget)
  }

  private getActivatedModulesSnapshotHandle: (() => void) | undefined
  public async createdPrmModules() {
    this.isLoading = true
    this.getActivatedModulesSnapshotHandle = ModuleManager.onSnapshotActivatedModuleClasses(this.$auth.tenant.id, undefined, (modules) => {
      console.log('ouou')
      this.activeModules = modules
      this.isLoading = false
    })
  }

  beforeDestroy() {
    console.info('beforeDestroy')
    this.getActivatedModulesSnapshotHandle && this.getActivatedModulesSnapshotHandle()
  }

  /// / PRM Modules

  // ASID Codes

  public formQuantity = 0

  public activated = 0
  public availableAsidSlots = 0
  public totalUsedInteractions = 0
  public availableInteractions = 0

  public backendConfig: BackendConfigDB = { ...BackendConfigManager.defaultDocDB }
  public formSelectedCodeConfig = { ...BackendConfigManager.defaultDocDB.codes[0] }

  private onActivatedAsidsHandle?: SnapshotUnbindHandle = undefined
  public async createdAsidCodes() {
    try {
      this.isLoading = true
      await this.$bindSnapshot('backendConfig', BackendConfigManager.getDbDocReference(this.$auth.tenant.id))
    } catch (error) {
      this.$helpers.notification.Error('Error loading Backend Config: ' + error)
    } finally {
      this.isLoading = false
    }

    const tenant = this.$auth.tenant
    this.formSelectedCodeConfig = this.backendConfig.codes[0]

    // await this.$firestoreBind('asids', AsidManager.getDbCollectionReference().where('Tenant', '==', tenant.id))
    // await this.$firestoreBind('categoriesDoc', CategoryHelper.getCategoriesDoc(this.$auth.tenant.id))
    this.onActivatedAsidsHandle = AsidManager.onPlanData(tenant.id, (data) => {
      this.activated = data._computed.activatedAsids
      this.availableAsidSlots = data.availableAsidSlots
      this.totalUsedInteractions = data._computed.totalUsedInteractions
      this.additionalInteractions = data.additionalInteractions
      this.availableInteractions = AsidManager.getAvailableInteractionsCount(data)
    }, (e) => {
      this.$helpers.notification.Error('Error loading Plan: ' + e)
    })
  }
  /// / ASID Codes


  public get formTenantNumber() {
    return (this.$auth.tenant._number > 0) ? TenantManager.formatPaddedNumber(this.$auth.tenant._number) : ''
  }

  public async onUpdate() {
    // TenantManager.update(this.$auth.tenant.id, this.$auth.userEmail, {
    //   name: this.formName,
    //   masterData: {
    //     phone: this.formMasterDataPhone,
    //     email: this.formMasterDataEmail,
    //     billingAddress: this.formMasterDatabillingAddress,
    //     shippingAddress: this.formMasterDatashippingAddress
    //   }
    // })
    //   .then(() => this.$helpers.notification.Success('Masterdata updated'))
    //   .catch((e) => this.$helpers.notification.Error('Error updating Masterdata' + e))
  }

  public created() {
    handlePromiseError(async () => {
      await this.initialize()
      await this.createdPrmModules()
      await this.createdAsidCodes()
    })
  }

  public async initialize() {
    this.isLoading = true

    try {
      await this.$bindSnapshot('tenant', TenantManager.getDbDocReference(this.$auth.tenant.id))

      // const tmpDefaultTenantMasterData = TenantManager.defaultDocDB.masterData
      const tmpTenantData = this.tenant
      // const tmpTenantMasterData = this.$helpers.merge(tmpDefaultTenantMasterData, tmpTenantData.masterData as MasterData)

      const planDoc = await TenantManager.getDbPlanDocReference(this.$auth.tenant.id).get()
      const planData = planDoc.data() as PlanDB
      this.plan = planData
      if (!planDoc.exists || !planData) {
        this.$helpers.notification.Error(`plan ${this.$auth.tenant.id} not found`)
        return
      }

      this.planExpires = planData.expires.toDate()
      this.planType = planData.type

      this.formName = tmpTenantData.name
      // this.formMasterDataPhone = tmpTenantMasterData.phone
      // this.formMasterDataEmail = tmpTenantMasterData.email
      // this.formMasterDatabillingAddress = tmpTenantMasterData.billingAddress
      // this.formMasterDatashippingAddress = tmpTenantMasterData.shippingAddress
    } catch (error) {
      this.$helpers.notification.Error('Error loading Tenant: ' + error)
    } finally {
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/variables.scss';

.spacer {
  margin: 4em;
}

.module-list-container {
  display: flex;
  flex-wrap: wrap;

  .box.module {
    display: flex;
    margin: 0.5em;
    height: 8em;
    width: calc(100% - 1em);

    @include tablet {
      width: calc(50% - 1em);
    }
  }
}
</style>
