<template>
  <div class="response-visualization">
    <table class="table">
      <thead>
        <tr>
          <th rowspan="2">
            Category
            <b-field class="category-collapse-actions">
              <p class="control">
                <b-button
                  icon-left="angle-double-down"
                  title="Expand All Categories"
                  size="is-small"
                  @click="toggleAllCategories(true)"
                />
              </p>
              <p class="control">
                <b-button
                  size="is-small"
                  icon-left="angle-double-up"
                  title="Collapse All Categories"
                  @click="toggleAllCategories(false)"
                />
              </p>

              <b-dropdown>
                <template slot="trigger">
                  <b-button
                    size="is-small"
                    icon-left="ellipsis-h"
                    title="Expand All Leaf Categories"
                  />
                </template>
                <b-dropdown-item @click="isHideEmpty = !isHideEmpty">
                  <b-icon :icon="isHideEmpty ? 'eye' : 'eye-slash'" />
                  {{ isHideEmpty ? 'Show' : 'Hide' }} Empty Categories
                </b-dropdown-item>

                <!-- toggle elements -->
                <b-dropdown-item @click="toggleAllSubtypes">
                  <b-icon :icon="!showSubtypes[responseTypes[0]] ? 'eye' : 'eye-slash'" />
                  {{ showSubtypes[responseTypes[0]] ? 'Hide' : 'Show' }} Elements
                </b-dropdown-item>

                <b-dropdown-item @click="doShowAggregatedCounts = !doShowAggregatedCounts">
                  <b-icon :icon="'calculator'" />
                  Show {{ !doShowAggregatedCounts ? 'Aggregated' : 'Individual' }} Counts
                </b-dropdown-item>
              </b-dropdown>
            </b-field>
          </th>

          <th rowspan="2">Total Responses</th>

          <th
            v-for="type in responseTypes"
            :key="type"
            class="clickable"
            :rowspan="showSubtypes[type] ? 1 : 2"
            :colspan="showSubtypes[type] ? subtypeMap[type]?.size || 1 : 1"
            :style="getBackgroundStyle(type)"
            @click="showSubtypes[type] = !showSubtypes[type]"
          >
            <b-icon
              v-if="subtypeMap[type]?.size"
              :icon="showSubtypes[type] ? 'chevron-down' : 'chevron-right'"
              size="is-small"
            />
            {{ capitalize(type) }} Responses
          </th>
        </tr>

        <tr>
          <template v-for="type in responseTypes">
            <th
              v-for="subtype in subtypeMap[type] || ['none']"
              v-show="showSubtypes[type]"
              :key="subtype+'_'+type"
              class="subtypes"
              :style="getBackgroundStyle(type)"
            >{{ subtype }}</th>
          </template>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="category in visibleCategories"
          :key="category.id"
          @click="toggleCategory(category)"
        >
          <td class="clickable">
            <span>
              <b-icon
                v-if="category.hasChildren"
                :icon="category.expanded ? 'chevron-down' : 'chevron-right'"
                size="is-small"
              />
              <div v-else class="spacer" />
              {{ '&nbsp;'.repeat(category.level * 3) }}
              {{ category.name }}
              <span
                v-if="!category.visible"
              >({{ getTotalResponses(category.id) }})</span>
            </span>
          </td>

          <td>{{ getTotalResponses(category.id, (ROOT_CATEGORY_ID === category.id) || doShowAggregatedCounts || !category.expanded) }}</td>

          <template v-for="type in responseTypes">
            <template v-if="showSubtypes[type]">
              <td
                v-for="subtype in subtypeMap[type] || ['none']"
                :key="subtype + category.id"
                class="count-cell subtypes"
              >{{ ((ROOT_CATEGORY_ID === category.id) || doShowAggregatedCounts || !category.expanded) ? responseCounts[category.id][type].subtypesAggregated[subtype] || 0 : responseCounts[category.id][type].subtypes[subtype] || 0 }}</td>
            </template>
            <template v-else>
              <td
                :key="type + category.id"
                class="count-cell"
              >{{ ((ROOT_CATEGORY_ID === category.id) || doShowAggregatedCounts || !category.expanded) ? responseCounts[category.id][type].aggregatedCount : responseCounts[category.id][type].count }}</td>
            </template>
          </template>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { ROOT_CATEGORY_ID } from '@/businessLogic/sharedConstants'
import { intersectSome } from '@/helpers/arrayHelper'
import { cloneObject } from '@/helpers/dataShapeUtil'
import { ModuleManager } from '@/modules/moduleManager'
import { ModuleType } from '@/modules/typeModules'
import { CategoryID } from '@/types/typeCategory'
import color from 'color'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

interface ProcessedCategory {
  id: string
  name: string
  parent: string | null
  hasChildren: boolean
  level: number
  visible: boolean
  expanded: boolean
}

interface Category {
  id: string
  name: string
  parent: string | null
}

interface Response {
  type: ModuleType
  name: string
  categories: string[]
}

@Component
export default class ResponseVisualization extends Vue {
  @Prop({ type: Array, required: true }) categories!: Category[]
  @Prop({ type: Array, required: true }) responses!: Response[]

  public responseTypes: ModuleType[] = ['Form', 'File', 'Link', 'Custom', 'Service']

  public ROOT_CATEGORY_ID = ROOT_CATEGORY_ID

  public doShowAggregatedCounts = false
  public isHideEmpty = false

  /**
   * responseCounts[categoryId][responseType].count
   * responseCounts[categoryId][responseType].subtypes[subtype]
   *
   * the aggregated count is the sum of all sub categories including the category itself
   */
  public responseCounts: {
    [key: string]: {
      [key: string]: { count: number, aggregatedCount: number, subtypes: { [key: string]: number }, subtypesAggregated: { [key: string]: number } }
    }
  } = {}

  // what subtypes are visible
  public showSubtypes: { [key: string]: boolean } = {}

  // ehat subtypes are available for each type
  public subtypeMap: { [key: string]: Set<string> } = {}

  public processedCategories: ProcessedCategory[] = []

  @Watch('categories', { immediate: true })
  @Watch('responses', { immediate: true })
  onCategoriesChange() {
    this.initSubtypes()
    this.initializeData()
  }

  initSubtypes() {
    this.responseTypes.forEach((type) => {
      this.$set(this.showSubtypes, type, false)
    })
  }

  created() {
    // this.initializeData()
  }

  get visibleCategories() {
    return this.processedCategories
      .filter((category) => category.visible)
      // process hide empty
      .filter((category) => {
        if (this.isHideEmpty) {
          const totalRspCount = this.getTotalResponses(category.id, this.doShowAggregatedCounts)
          return totalRspCount > 0 || category.parent === ''
        }
        return true
      })
  }


  public getBackgroundStyle(type: ModuleType) {
    const module = ModuleManager.getModuleClassByType(type)
    return {
      backgroundImage: `linear-gradient( 155deg, ${color(module.color).lighten(0.4)} 10%, ${color(module.color).rotate(-15).lighten(0.45)} 100%)`,
      color: '#000'
    }
  }

  initializeData() {
    this.subtypeMap = this.responses.reduce((map, response) => {
      if (!map[response.type]) map[response.type] = new Set()
      map[response.type].add(response.name)
      return map
    }, {} as typeof this.subtypeMap)

    this.responseCounts = this.categories.reduce((map, category) => {
      map[category.id] = this.responseTypes.reduce((typeMap, type) => {
        typeMap[type] = { count: 0, aggregatedCount: 0, subtypes: {}, subtypesAggregated: {} }
        this.subtypeMap[type]?.forEach((subtype) => (typeMap[type].subtypes[subtype] = 0))
        this.subtypeMap[type]?.forEach((subtype) => (typeMap[type].subtypesAggregated[subtype] = 0))
        return typeMap
      }, {} as typeof this.responseCounts[string])
      return map
    }, {} as typeof this.responseCounts)

    this.responses.forEach((response) => {
      response.categories.forEach((category) => {
        if (!this.responseCounts[category]) {
          // console.error(`Category ${category} not found`)
          return
        } else if (!this.responseCounts[category][response.type]) {
          // console.error(`Type ${response.type} not found in category ${category}`)
          return
        }

        // if the response type is not in the responsesTypes array, skip it
        if (!this.responseTypes.includes(response.type)) return

        this.responseCounts[category][response.type].count += 1
        this.responseCounts[category][response.type].aggregatedCount += 1
        this.responseCounts[category][response.type].subtypes[response.name] += 1
        this.responseCounts[category][response.type].subtypesAggregated[response.name] += 1
      })
    })

    // set aggregated count for parent categories
    // traverse the categories from the bottom up
    // return an array of all children of a category, all the way down to the leafs
    const traverse = (categoryId: string): CategoryID[] => {
      const category = this.categories.find((cat) => cat.id === categoryId)
      if (!category) return []

      const allChildIDs: CategoryID[] = []
      const directChildren = this.categories.filter((cat) => cat.parent === categoryId)
      directChildren.forEach((child) => {
        const leafChildIDs = traverse(child.id)
        allChildIDs.push(...leafChildIDs)
      })

      this.responseTypes.forEach((type) => {
        // to get the aggregated count we need to check the amount of children that have any of the child categories or the category itself.
        // if we would just accumulate the count of the children, we would count the same response multiple times if they had multiple categories
        // like this:
        // category1
        //   - category2
        //     - response1
        //     - response2
        //   - response1
        //   - response2
        // in this case response1 and response2 would be counted twice

        this.responseCounts[categoryId][type].aggregatedCount += this.responses
          .filter((rsp) => rsp.type === type)
          .filter((rsp) => intersectSome(rsp.categories, allChildIDs)).length

        this.subtypeMap[type]?.forEach((subtype) => {
          this.responseCounts[categoryId][type].subtypesAggregated[subtype] += this.responses
            .filter((rsp) => rsp.type === type)
            .filter((rsp) => rsp.name === subtype)
            .filter((rsp) => intersectSome(rsp.categories, allChildIDs)).length
        })
      })

      return [...allChildIDs, categoryId]
    }

    traverse(ROOT_CATEGORY_ID)

    this.processCategories()
  }

  /**
   * add levels and set initial visibility and expanded state
   */
  private processCategories() {
    const oldCategoryState = cloneObject(this.processedCategories)

    this.processedCategories = []

    // find all categories with their parent
    const rootCatgories = this.categories.filter((cat) => cat.parent === '')

    const addLevel = (categoryId: string, level: number) => {
      const category = this.categories.find((cat) => cat.id === categoryId)
      if (!category) return

      this.processedCategories.push({
        ...category,
        level,
        visible: level === 0,
        expanded: oldCategoryState.find((cat) => cat.id === categoryId)?.expanded || false, // keep the old state if it exists
        hasChildren: this.categories.some((cat) => cat.parent === categoryId)
      })


      const children = this.categories.filter((cat) => cat.parent === categoryId)
      children.forEach((child) => addLevel(child.id, level + 1))
    }

    rootCatgories.forEach((cat) => addLevel(cat.id, 0))

    // expand all if there was no old state
    if (oldCategoryState.length === 0) {
      this.toggleAllCategories(true)
    }

    this.setVisibilityOfCategoryTreeBasedOnExpanded()
  }


  toggleCategory(category: ProcessedCategory) {
    category.expanded = !category.expanded
    this.setVisibilityOfChildren(category, category.expanded)
  }

  private setVisibilityOfCategoryTreeBasedOnExpanded() {
    // find all root categories
    const rootCategories = this.processedCategories.filter((cat) => cat.parent === '')

    rootCategories.forEach((cat) => this.setVisibilityOfChildren(cat, cat.expanded))
  }

  setVisibilityOfChildren(category: ProcessedCategory, visibility: boolean) {
    this.processedCategories = this.processedCategories.map((cat) => {
      if (cat.parent === category.id) {
        cat.visible = visibility

        if ((visibility && cat.expanded) || !visibility)
          this.setVisibilityOfChildren(cat, visibility)
      }
      return cat
    })
  }

  toggleAllCategories(expand: boolean) {
    this.processedCategories = this.processedCategories.map((category) => {
      category.expanded = expand
      category.visible = expand || category.parent === ''
      return category
    })
  }

  toggleLeafCategories() {
    this.processedCategories = this.processedCategories.map((category) => {
      const isLeaf = !this.processedCategories.some((cat) => cat.parent === category.id)
      category.visible = isLeaf
      return category
    })
  }

  toggleAllSubtypes() {
    const stateOfFirstSubtype = this.showSubtypes[this.responseTypes[0]]

    this.responseTypes.forEach((type) => {
      this.$set(this.showSubtypes, type, !stateOfFirstSubtype)
    })
  }

  capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  getIndentedName(name: string, level: number) {
    return '&nbsp;'.repeat(level * 4) + name
  }

  getTotalResponses(categoryId: string, aggregated = false) {
    return this.responseTypes.reduce((sum, type) =>
      sum + (aggregated ? this.responseCounts[categoryId][type].aggregatedCount : this.responseCounts[categoryId][type].count),
    0
    )
  }
}
</script>

<style lang="scss">
.response-visualization {
  overflow-x: auto;

  .category-collapse-actions {
    display: inline-flex;
  }

  .clickable {
    cursor: pointer;
  }

  .table {
    width: 100%;

    th.subtypes {
      z-index: 1 !important;
    }

    th,
    td {
      padding: 10px;
      text-align: left;
      // border: 1px solid #ddd;
      // white-space: nowrap; /* Prevents content from wrapping */
    }

    thead th:first-child {
      position: sticky;
      background-color: #fff;
      left: 0;
      z-index: 2; /* Ensure it stays above other cells but below headers */
    }

    tbody td:first-child {
      position: sticky;
      left: 0;
      background-color: #f8f8f8;
      z-index: 1; /* Ensure it stays above other cells but below headers */
      width: auto; /* Ensures the column width fits its content */
      min-width: max-content; /* Ensures the column does not shrink smaller than its content */
      white-space: nowrap;
    }
  }

  th.subtypes {
    font-size: smaller;
  }

  .hidden {
    display: none;
  }

  // as the leaf categories dont have a chevron, we need to add a spacer to align the text
  .spacer {
    width: 1rem;
    height: 1rem;
    display: inline-block;
  }
}
</style>
