import {
  FormElementDB,
  FormResponseDB,
  FormQuestionResponse,
  FormQuestionResponsesByQeustionId,
  ResponsePerQuestionSummary,
  SurveyJs,
  FormResponsePublicData,
  FormElementPublicData
} from './typeFormModule'
import sanitizeHtml from 'sanitize-html'
import { locale, LocalizedField, LocalizedFieldLocales } from './../../types/typeI18n'
import { hasDBid } from '@/types/typeGeneral'
import { getLocalString } from './../../helpers/i18nUtil'
import { sortElementsPublicData } from '../../shared/appDataHelper'

export interface questionAndResponsesObj {
  type: string
  name: string
  title: LocalizedField
  items:
  | {
    name: string
    title: LocalizedField
    imageLink?: LocalizedField
    responses: any[]
  }[]
  comments: string[]
}

export interface questionAndResponsesObj2Dim extends Omit<questionAndResponsesObj, 'items'> {
  items: {
    name: string
    title: LocalizedField
    responses: any[]
  }[][]
}

export default class FormHelper {
  public static responsePerQuestionSummaryToCSV(rpqs: ResponsePerQuestionSummary): string {
    const csv: (string | number)[][] = [
      ['title: ', rpqs.title.replace(/,/g, ';')],
      ['description: ', rpqs.description.replace(/,/g, ';')],
      ['response count:', rpqs.accumulatedRsps.reduce((acc, curr) => acc + curr.count, 0)],
      ['name', 'title', 'count'],
      ...rpqs.accumulatedRsps.map((r) => [(r.name + '').replace(/,/g, ';'), r.title.replace(/,/g, ';'), r.count]),
      ['comments:'],
      ...rpqs.comments.map((c) => [String(c).replace(/,/g, ';')])
    ]

    return csv.map((row) => row.join(',')).join('\n')
  }

  /**
   * Exports responses into multiple lines of string format
   *
   * [{
   * rspKey: 'Question Title'
   * rspValue: 'Item1, Item3, Item4' // question response
   * }]
   *
   * @param responses
   * @param elements
   * @returns
   */
  public static getStringSerializedResponse(
    responses: FormResponseDB[],
    preferredLocales: locale[]
  ): {
      rspKey: string
      rspValue: string
      fileUrl: string
      imageUrl: string
    }[] {
    const questionsAndResponses = responses
      .sort((a, b) => sortElementsPublicData(a._appDataSnapshotInterpolated.elements[0], b._appDataSnapshotInterpolated.elements[0]))
      .map((rsp) => {
        const element = rsp._appDataSnapshotInterpolated.elements[0]
        if (!element) return []

        return (
          FormHelper.getQuestionsAndResponsesAndHeader(rsp as FormResponseDB, element) || []
        )
      })

    return questionsAndResponses
      .flatMap((qAndRs) => {
        return qAndRs.flatMap((questionAndResponses) => {
          let rspValue = ''
          let rspObjects: { imageUrl: string, rspValue: string, fileUrl: string }[] = []

          switch (questionAndResponses.question.type) {
            case 'multipletext':
              rspValue = FormHelper.getQuestionAndResponsesObj_MultipleText(
                questionAndResponses.question as unknown as SurveyJs.MultipletextQ,
                questionAndResponses.responses
              )
                .items.map((v) => `${getLocalString(v.title, preferredLocales, 'auto')}:${v.responses}`)
                .join(', ')
              break
            case 'boolean': {
              const questionAndResponsesObj = FormHelper.getQuestionAndResponsesObj_Boolean(
                questionAndResponses.question as unknown as SurveyJs.BooleanQ,
                questionAndResponses.responses
              )

              rspValue = questionAndResponsesObj.items
                .filter((v) => v.responses.length > 0)
                .map((v) => `${getLocalString(v.title, preferredLocales, 'auto')}`)
                .join(', ')

              rspValue += questionAndResponsesObj.comments
                .filter((v) => v)
                .map((v) => `comment: ${v}`)
                .join(', ')
              break
            }
            case 'rating': {
              const questionAndResponsesObj = FormHelper.getQuestionAndResponsesObj_Rating(
                questionAndResponses.question as unknown as SurveyJs.RatingQ,
                questionAndResponses.responses
              )

              rspValue = questionAndResponsesObj.items
                .filter((v) => v.responses.length > 0)
                .map((v) => `${getLocalString(v.title, preferredLocales, 'auto')}`)
                .join(', ')

              rspValue += questionAndResponsesObj.comments
                .filter((v) => v)
                .map((v) => `comment: ${v}`)
                .join(', ')
              break
            }
            case 'matrixdropdown':
            case 'matrixdynamic':
            case 'matrix': {
              const questionAndResponsesObj = FormHelper.getQuestionAndResponsesObj_Matrix(
                questionAndResponses.question as unknown as SurveyJs.MatrixQ,
                questionAndResponses.responses
              )

              rspValue = questionAndResponsesObj.items
                .map(
                  ([row, col]) =>
                    `${row && getLocalString(row.title, preferredLocales, 'auto')}:${col && getLocalString(col.title, preferredLocales, 'auto')
                    }`
                )
                .join(', ')

              rspValue += questionAndResponsesObj.comments
                .filter((v) => v)
                .map((v) => `comment: ${v}`)
                .join(', ')
              break
            }
            case 'file': {
              const comment = questionAndResponses.responses[0].comment ? ` comment: ${questionAndResponses.responses[0].comment}` : ''

              rspObjects = (questionAndResponses.responses[0].data || [{ imageUrl: '', rspValue: comment, fileUrl: '' }])
                .map((v: { name: string, content: string, type: string }) => {
                  const isImageFile = (file: any) => file.type.startsWith('image')
                  return {
                    imageUrl: isImageFile(v) ? v.content : '',
                    fileUrl: isImageFile(v) ? '' : v.content,
                    rspValue: v.name + comment
                  }
                })

              break
            }
            case 'comment':
            case 'expression':
            case 'text':
              rspValue = questionAndResponses.responses[0].data
              break
            case 'radiogroup':
            case 'dropdown':
            case 'ranking':
            case 'checkbox': {
              const qAndRCheckB = FormHelper.getQuestionAndResponsesObj_CheckboxDropdownRadiogroup(
                questionAndResponses.question as unknown as SurveyJs.CheckboxQ,
                questionAndResponses.responses
              )
              rspValue = qAndRCheckB.items
                .filter((v) => v.responses.length > 0)
                .map((v) => `${getLocalString(v.title, preferredLocales, 'auto')}`)
                .join(', ')

              rspValue += qAndRCheckB.comments
                .filter((v) => v)
                .map((v) => `comment: ${v}`)
                .join(', ')
              break
            }
            case 'imagepicker': {
              const qAndRCheckB = FormHelper.getQuestionAndResponsesObj_CheckboxDropdownRadiogroup(
                questionAndResponses.question as unknown as SurveyJs.ImagePickerQ,
                questionAndResponses.responses
              )

              const comment = questionAndResponses.responses[0].comment ? ` comment: ${questionAndResponses.responses[0].comment}` : ''

              rspObjects = qAndRCheckB.items
                .filter((v) => v.responses.length > 0)
                .map((v) => {
                  return {
                    imageUrl: v.imageLink ? getLocalString(v.imageLink, preferredLocales, 'auto') : '',
                    rspValue: getLocalString(v.title, preferredLocales, 'auto') + comment,
                    fileUrl: ''
                  }
                })
              break
            }
            case 'signaturepad': {
              const file = questionAndResponses.responses[0].data as string

              rspObjects = [{ imageUrl: file, rspValue: 'Signature', fileUrl: '' }]

              break
            }

            case 'image':
            case 'panel':
            case 'html': {
              rspValue = ''
              break
            }

            default: {
              const type: never = questionAndResponses.question.type

              rspValue = `unknown question type: ${type}`
              break
            }
          }

          if (rspObjects.length === 0) {
            rspObjects = [{ imageUrl: '', rspValue, fileUrl: '' }]
          }

          return rspObjects.map((rspObj) => {
            return {
              rspKey: getLocalString(questionAndResponses.question.title, preferredLocales, 'auto'),
              rspValue: sanitizeHtml(rspObj.rspValue, { allowedTags: [], allowedAttributes: {}, disallowedTagsMode: 'discard' }),
              imageUrl: sanitizeHtml(rspObj.imageUrl, { allowedTags: [], allowedAttributes: {}, disallowedTagsMode: 'discard' }),
              fileUrl: sanitizeHtml(rspObj.fileUrl, { allowedTags: [], allowedAttributes: {}, disallowedTagsMode: 'discard' })
            }
          })
        })
      })
  }

  /**
   * Transforms [ data: {q1,q2,q2} ] to {q1: {data: []}}
   * Array of responses with many questions to responses grouped by question
   * {
   *  q1: [
   *    {data:any, reponse, asid, element}
   *  ]
   * }
   */
  public static groupResponsesByQuestionID<T extends FormQuestionResponse>(
    responses: T[]
  ): FormQuestionResponsesByQeustionId<T> {
    const responsesPerQuestion: { [key: string]: any[] } = {}
    responses.forEach((response) => {
      Object.keys(response.data).forEach((questionID) => {
        if (!responsesPerQuestion[questionID]) responsesPerQuestion[questionID] = []
        responsesPerQuestion[questionID].push({ ...response, ...response.data[questionID] })
      })
    })
    return responsesPerQuestion
  }

  public static traverseForm(
    container: Array<SurveyJs.ContainerOrPage>,
    questions: { [key: string]: SurveyJs.Question & { order: number } },
    order: number = 0,
    nestingLevel: number = 1,
    elementCallback?: (element: SurveyJs.Container) => void
  ) {
    let i = 1
    for (const obj of container) {
      if (elementCallback) elementCallback(obj as SurveyJs.Container)
      order += (i / nestingLevel) * 100
      i++
      if ('type' in obj && obj.type !== 'panel') {
        questions[obj.name] = { ...(obj as SurveyJs.Question), order }
      } else if ('elements' in obj && obj.elements) {
        this.traverseForm(obj.elements, questions, order, nestingLevel + 1, elementCallback)
      }
    }
  }

  public static getQuestionsFromFormElement(element: FormElementDB) {
    const questions: { [key: string]: SurveyJs.Question & { order: number } } = {}
    // todo remove pages when refactored
    if (element.public.questions && element.public.questions.length > 0)
      this.traverseForm(element.public.questions, questions)

    return questions
  }

  public static getQuestionsFromQuestionContainer(questionContainer: SurveyJs.Page[]) {
    const questions: { [key: string]: SurveyJs.Question & { order: number } } = {}
    // todo remove pages when refactored
    if (questionContainer && questionContainer.length > 0) this.traverseForm(questionContainer, questions)

    return questions
  }

  public static getQuestionResponses(
    responses: (FormResponseDB & hasDBid)[],
    element: FormElementDB
  ): Array<{ question: SurveyJs.Question, responses: (FormResponseDB & hasDBid & FormQuestionResponse)[] }> {
    return this.getQuestionResponsesForQuestionContainer(
      responses.map((re) => ({ ...re.public, ...re })) as (FormResponseDB & hasDBid & FormResponsePublicData)[],
      element.public.questions
    )
  }

  public static getQuestionResponsesForQuestionContainer<T extends FormResponsePublicData>(
    responses: T[],
    questionContainer: SurveyJs.Page[]
  ): Array<{ question: SurveyJs.Question, responses: (FormQuestionResponse & T)[] }> {
    const responsesByQuestionID = this.groupResponsesByQuestionID(responses)
    const questionsByQuestionID = this.getQuestionsFromQuestionContainer(questionContainer)
    // console.log(responsesByQuestionID, questionsByQuestionID)

    /**
     *
     *  questionsByQuestionID: {
     *    q1: {type:'', title:'', items:[]}
     *    q2: {type:'', title:'', items:[]}
     *  }
     *  responsesByQuestionID: {
     *    q1: {data: any[], comment: ''}[]
     *    q2: {data: any[], comment: ''}[]
     *  }
     *
     * [
     *  {
     *    question: {name: 'q1', type:'', title:'', items:[]}
     *    responses: {data: any[], comment: ''}[]
     *  },
     *  {
     *    question: {name: 'q2', type:'', title:'', items:[]}
     *    responses: {data: any[], comment: ''}[]
     *  }
     * ]
     */
    return Object.keys(responsesByQuestionID)
      .map((questionID) => ({
        question: questionsByQuestionID[questionID],
        responses: responsesByQuestionID[questionID]
      }))
      .filter((obj) => obj.question !== undefined)
      .sort((a, b) => a.question.order - b.question.order) // filter to check that the corelation question was found
  }

  /**
   * in surveyjs when 'default' is set, actually 'en' is used
   * @param localozedText
   */
  public static convertI18nLocaleToSurveyJsDefaultLocale(localizedText: LocalizedFieldLocales): LocalizedFieldLocales {
    // not needed anymore
    // if (localizedText.en) {
    //   localizedText.default = localizedText.en
    //   delete localizedText.en
    // }
    return localizedText
  }

  private static convertSurveyJsDefaultLocaleToI18nLocale(localizedText: LocalizedFieldLocales): LocalizedFieldLocales {
    // not needed anymore
    // if (localizedText.default) {
    //   localizedText.en = localizedText.default
    //   delete localizedText.default
    // }
    return localizedText
  }

  public static getLocalizedTextObject(text: SurveyJs.LocalizedText): LocalizedField {
    return {
      locales: typeof text === 'object' ? this.convertSurveyJsDefaultLocaleToI18nLocale(text) : { en: text + '' },
      _ltType: true,
      _hidden: false
    }
  }

  public static getChoiceObject(choice: SurveyJs.LocalizedItem): SurveyJs.ItemObject {
    if (typeof choice !== 'object') {
      return {
        value: choice + '',
        text: choice + ''
      }
    }
    return choice
  }

  public static getLocalizedChoiceObject(choice: SurveyJs.LocalizedItem) {
    const text = typeof choice === 'object' ? choice.text || choice.value : choice + ''
    const value = typeof choice === 'object' ? choice.value : choice + ''

    return {
      name: value,
      title: this.getLocalizedTextObject(text)
    }
  }

  public static getLocalizedMultipleTextItemObject(item: SurveyJs.MultipletextQ['items'][0]) {
    const text = typeof item === 'object' ? item.title || item.name : item + ''
    const value = typeof item === 'object' ? item.name : item + ''

    return {
      name: value,
      title: this.getLocalizedTextObject(text)
    }
  }

  public static getNameAndTitle(data: { name: string, title?: SurveyJs.LocalizedText }) {
    return {
      name: data.name,
      title: this.getLocalizedTextObject(data.title || data.name)
    }
  }

  public static getQuestionHeader(question: SurveyJs.Question) {
    return {
      type: question.type,
      ...this.getNameAndTitle(question)
    }
  }

  public static getQuestionsAndResponsesAndHeader(rsp: FormResponseDB, element: FormElementPublicData) {
    return this.getQuestionsAndResponsesAndHeaderFromQuestionContainer(rsp.public, element.questions)
  }

  public static getQuestionsAndResponsesAndHeaderFromQuestionContainer(
    rsp: FormResponsePublicData,
    questionContainer: SurveyJs.Page[]
  ) {
    const questionRsps = FormHelper.getQuestionResponsesForQuestionContainer([rsp], questionContainer)
    return questionRsps.map((qAndRsp) => ({
      ...qAndRsp,
      question: {
        ...qAndRsp.question,
        ...FormHelper.getQuestionHeader(qAndRsp.question)
      }
    }))
  }

  /**
   * =================================
   * Question Response Objects
   * =================================
   *
   * The question response objects accumulate all response for a given question and choice
   * Format:
   * {
   *  title: 'Question Title',
   *  description: 'Question Description',
   *  items: [
   *   {
   *    name: 'choice1',
   *    title: 'Choice 1',
   *    responses: ['response1', 'response2']
   *   },
   *  ],
   * comments: ['comment1', 'comment2']
   * type: 'radiogroup'
   * }
   */

  public static getQuestionAndResponsesObj_MultipleText(
    question: SurveyJs.MultipletextQ,
    responses: Array<FormQuestionResponse>
  ): questionAndResponsesObj {
    /**
     *"responses": {
     *     "data": {
     *         "text1": "132",
     *         "text2": "123"
     *    }
     * }[]
     */
    const respMap: { [key: string]: string[] } = {}
    responses
      .map((r) => r.data as { [key: string]: string })
      .forEach((rsp) => {
        Object.entries(rsp).forEach(([name, value]) => {
          if (!(name in respMap)) respMap[name] = []
          respMap[name].push(value)
        })
      })

    return {
      ...this.getQuestionHeader(question),
      items: question.items?.map((it) => ({
        ...it,
        ...this.getNameAndTitle(it),
        responses: respMap[it.name]
      })),
      comments: (responses.filter((r) => r.comment) as Array<{ comment: string }>).map((r) => r.comment)
    }
  }

  public static getQuestionAndResponsesObj_Rating(
    question: SurveyJs.RatingQ,
    responses: Array<FormQuestionResponse>
  ): questionAndResponsesObj {
    // {'1':['1','1'], 'other':['other']}
    const respMap: { [key: string]: string[] } = {}
    responses
      .map((r) => r.data as string)
      .forEach((rsp) => {
        if (!(rsp in respMap)) respMap[rsp] = []
        respMap[rsp].push(rsp)
      })

    const t = {
      ...this.getQuestionHeader(question),
      items: (question.rateValues
        ? question.rateValues // {0:1,1:2,3:{value:4,text:"vier"}}
        : question.rateMax
          ? [...Array(question.rateMax - (question.rateMin || 0) + 1).keys()].slice(1)
          : [...Array(6).keys()].slice(1)
      ) // remove index 0
        .map((v) => this.getLocalizedChoiceObject(v))
        .map((item) => {
          return {
            ...item,
            name: item.name + '',
            responses: respMap[item.name + ''] || []
          }
        }),
      comments: (responses.filter((r) => r.comment) as Array<{ comment: string }>).map((r) => r.comment)
    }
    return t
  }

  public static getQuestionAndResponsesObj_CheckboxDropdownRadiogroup(
    question: SurveyJs.CheckboxQ | SurveyJs.ImagePickerQ | SurveyJs.DropDownQ | SurveyJs.RankingQ,
    responses: Array<FormQuestionResponse>
  ): questionAndResponsesObj {
    // {'1':['1','1'], 'other':['other']}
    // Use Map to preserve the order of the responses
    const respMap = new Map<string, string[]>()
    responses
      .flatMap((r) => [r.data].flat(2)) // ['item1'], [['item1']] => ['item1']
      .forEach((rsp) => {
        if (!respMap.has(rsp)) respMap.set(rsp, [])
        respMap.get(rsp)!.push(rsp)
      })

    let t: Partial<questionAndResponsesObj> = {
      ...this.getQuestionHeader(question),
      comments: (responses.filter((r) => r.comment) as Array<{ comment: string }>).map((r) => r.comment)
    }

    if ('echoDataSource' in question && question.echoDataSource === 'data-module') {
      t = {
        ...t,
        items: Array.from(respMap.keys()).map((k) => ({
          ...this.getLocalizedChoiceObject(k),
          name: k + '',
          responses: respMap.get(k) || []
        }))
      }
    } else {
      const items = question.choices.map((v) => ({
        ...this.getLocalizedChoiceObject(v),
        ...(question.type === 'imagepicker' && {
          imageLink: this.getLocalizedTextObject((v as any).imageLink)
        })
      }))

      if (question.type === 'ranking') {
      // Sort items based on the order of responses
        const responseOrder = responses.map((r) => r.data).flat(2)
        items.sort((a, b) => {
          const indexA = responseOrder.indexOf(a.name)
          const indexB = responseOrder.indexOf(b.name)
          return (indexA !== -1 ? indexA : Number.MAX_SAFE_INTEGER) - (indexB !== -1 ? indexB : Number.MAX_SAFE_INTEGER)
        })
      }

      t = {
        ...t,
        items: items.map((item) => ({
          ...item,
          name: item.name + '',
          responses: respMap.get(item.name + '') || []
        }))
      }
    }

    return t as questionAndResponsesObj
  }

  public static getQuestionAndResponsesObj_Matrix(
    question: SurveyJs.MatrixQ,
    responses: Array<FormQuestionResponse>
  ): questionAndResponsesObj2Dim {
    const rows = question.rows.map((v) => this.getLocalizedChoiceObject(v))
    const cols = question.columns.map((v) => this.getLocalizedChoiceObject(v))
    const t = {
      ...this.getQuestionHeader(question),
      rows,
      cols,
      responses: responses.map((r) => r.data),
      items: responses
        .map((r) => r.data)
        .map((o) =>
          Object.entries(o).map(([row, col]) => {
            let matchingRow = rows.find((r) => r.name === row)
            let matchingCol = cols.find((r) => r.name === col)

            if (!matchingRow)
              matchingRow = {
                name: 'missing row',
                title: { locales: { en: 'missing row' }, _ltType: true, _hidden: false }
              }
            if (!matchingCol)
              matchingCol = {
                name: 'missing col',
                title: { locales: { en: 'missing col' }, _ltType: true, _hidden: false }
              }

            matchingCol.name = String(matchingCol.name)
            matchingRow.name = String(matchingRow.name)

            return [
              matchingRow as { name: string, title: LocalizedField },
              matchingCol as { name: string, title: LocalizedField }
            ]
          })
        )
        .flat(1),
      comments: (responses.filter((r) => r.comment) as Array<{ comment: string }>).map((r) => r.comment)
    }
    return t as any // todo embed the responses in the items or figure a more sensible schema
  }

  public static getQuestionAndResponsesObj_Boolean(
    question: SurveyJs.BooleanQ,
    responses: Array<FormQuestionResponse>
  ): questionAndResponsesObj {
    const rsps = responses.map((r) => r.data)

    return {
      ...this.getQuestionHeader(question),
      items: [
        {
          ...this.getNameAndTitle({
            name: 'labelTrue',
            title: question.labelTrue || 'true'
          }),
          responses: rsps.filter((r) => r === true)
        },
        {
          ...this.getNameAndTitle({
            name: 'labelFalse',
            title: question.labelFalse || 'false'
          }),
          responses: rsps.filter((r) => r === false)
        }
      ],
      comments: (responses.filter((r) => r.comment) as Array<{ comment: string }>).map((r) => r.comment)
    }
  }
}
