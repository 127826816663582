<template>
  <div ref="viewer" class="pdf-viewer-wrapper" />
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

import WebViewer, { WebViewerInstance } from '@pdftron/webviewer'
import { handlePromiseError } from '@/helpers/notificationHelper'

@Component({
  components: {

  }
})
export default class VPdfViewer extends Vue {
  @Prop({ type: String, required: true, default: () => '' })
  public url!: string

  @Prop({ type: String, required: false, default: 'light' })
  public theme!: 'light' | 'dark'

  @Prop({ type: Number, required: false, default: 1 })
  public initialPage!: number

  @Prop({ type: Boolean, required: false, default: false })
  public isSinglePage!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isDisableNavigation!: boolean

  public vColor = 'green'

  private viewerInstance: WebViewerInstance | null = null

  public async mounted() {
    const $viewerEl = (this.$refs as any).viewer as HTMLElement

    this.viewerInstance = await WebViewer({
      path: process.env.BASE_URL + 'public/pdf-lib',
      licenseKey: 'echo PRM GmbH:OEM:ECHO PRM::B+:AMS(20260410):3057A0A31FB79AD0933352184F716F0F2292DC771CCFAEF28FAF2CEE7AC235F5C7',
      // initialDoc: this.url.trim(), // replace with your own PDF file
      disabledElements: this.isSinglePage ? ['header', 'toolsHeader'] : [],
      fullAPI: true,
      css: process.env.BASE_URL + 'public/pdf-lib/custom-pdf-viewer.css'
    }, $viewerEl)

    //     WebViewer({
    //   path: process.env.BASE_URL + 'public/pdf-lib',
    //   licenseKey: 'ECHO PRM GmbH (echoprm.com):OEM:ECHO PRM::B+:AMS(20240410):12B525530457460A0360B13AC9A2535860614FC90ECBFD75C514ACF28FAF2C6DF941B6F5C7',
    //   initialDoc: this.url.trim(), // replace with your own PDF file
    //   disabledElements: this.isSinglePage ? ['header', 'toolsHeader'] : [],
    //   css: process.env.BASE_URL + 'public/pdf-lib/custom-pdf-viewer.css'
    // }, $viewerEl)

    this.updateSettings()
  }

  // update settings, tho other parameters are static
  @Watch('url', { immediate: true })
  private updateSettings() {
    if (!this.viewerInstance) return

    this.viewerInstance.UI.loadDocument(this.url.trim())

    const iframeDoc = this.viewerInstance.UI.iframeWindow.document

    if (this.isSinglePage) {
      const $App = iframeDoc.querySelector<HTMLElement>('.App')
      $App?.style?.setProperty?.('--document-background-color', 'transparent')
    }


    // hide the ribbons
    // this.viewerInstance.UI.disableElements(['ribbons'])
    // set the default toolbar group to the Shapes group
    // this.viewerInstance.UI.setToolbarGroup('toolbarGroup-Shapes')
    this.viewerInstance.UI.disableFeatures([
      this.viewerInstance.UI.Feature.Annotations,
      this.viewerInstance.UI.Feature.Download
    ])

    this.viewerInstance.UI.disableElements(['saveAsButton'])

    // Set language
    // this.viewerInstance.UI.setLanguage(this.$i18n.activeLocales[0] || 'en')
    if (this.isDisableNavigation || this.isSinglePage) {
      this.viewerInstance.UI.disableElements(['pageNavOverlay'])
      this.viewerInstance.UI.disableFeatures([this.viewerInstance.UI.Feature.PageNavigation])
    }

    if (this.isSinglePage)
      this.viewerInstance.UI.setLayoutMode(this.viewerInstance.UI.LayoutMode.Single)
    // this.viewerInstance.Core.documentViewer.setCurrentPage(5)


    this.viewerInstance.Core.documentViewer.addEventListener('pageComplete', (pageNum: number, $canvas: HTMLElement) => {
      // hide box shadow if is single page
      if (this.isSinglePage) {
        // const UIEvents = this.viewerInstance.UI.Events

        const $pages = iframeDoc.querySelectorAll<HTMLElement>('.pageContainer')
        $pages.forEach(($page) => {
          $page.style.setProperty('box-shadow', 'none')
          $page.style.setProperty('background-color', 'none')
        })
      }
    })

    const viewerInstance = this.viewerInstance
    this.viewerInstance.Core.documentViewer.addEventListener('documentLoaded', () => {
      if (this.viewerInstance === null) return

      this.viewerInstance.Core.documentViewer.setCurrentPage(this.initialPage, false)

      // workaround to ignre the pdfs internal start page https://community.apryse.com/t/document-jumps-back-to-first-page/3665/3
      const { Actions } = this.viewerInstance.Core
      const onTriggered = Actions.GoTo.prototype.onTriggered
      Actions.GoTo.prototype.onTriggered = function (...args) {
        const [target, event] = args
        console.log('GoTo', target, event)

        // target is incorrectly not typed as Document
        if (target as any === viewerInstance.Core.documentViewer.getDocument() && event.name === 'Open')
          return

        onTriggered.apply(this, args)
      }

      const disableZoom = false
      if (disableZoom) {
        this.viewerInstance.UI.setMinZoomLevel(this.viewerInstance.UI.getZoomLevel())
        this.viewerInstance.UI.setMaxZoomLevel(this.viewerInstance.UI.getZoomLevel())

        let FitMode = this.viewerInstance.UI.FitMode
        this.viewerInstance.UI.setFitMode(FitMode.FitWidth)
      }
    })

    // at this point, the viewer is 'ready'
    // call methods from instance, documentViewer and annotationManager as needed
    this.viewerInstance.UI.setTheme(this.theme)

    this.onLocaleChanged()

    // this.docViewer = this.viewerInstance.Core.documentViewer;
    // this.annotManager = this.viewerInstance.Core.annotationManager;

    // you can also access major namespaces from the instance as follows:
    // const Tools = this.viewerInstance.Core.Tools;
    // const Annotations = this.viewerInstance.Core.Annotations;

    // now you can access APIs through `this.instance`

    // or listen to events from the viewer element
    // this.docViewer.addEventListener('documentLoaded', () => {
    //    // call methods relating to the loaded document
    // });
  }

  get mainLocale() {
    return this.$i18n.appActiveLocales[0]?.split('-')?.[0] || 'en' // todo proper setting locale
  }

  @Watch('mainLocale')
  private onLocaleChanged() {
    if (this.viewerInstance === null) return

    console.log('setLanguage PDF viewer', this.mainLocale)

    handlePromiseError(this.viewerInstance.UI.setLanguage(this.mainLocale))
  }

  @Watch('initialPage')
  private onInitialPageChanged() {
    if (this.viewerInstance === null) return

    this.viewerInstance.Core.documentViewer.setCurrentPage(this.initialPage, false)
  }
}
</script>

<style lang="scss">
.pdf-viewer-wrapper {
  // .App { doesnt work inside iframe
  //   --document-background-color: v-bind(vColor);
  // }

  // hide the white box when two scrollbars meet
  ::-webkit-scrollbar-corner {
    background: rgb(0 0 0 / 0%);
  }
}
</style>
