<template>
  <div>
    <header>
      <b-notification
        :active.sync="globalNotificationActive"
        :type="`is-${globalNotification.type}`"
        aria-close-label="Close notification"
      >
        <VEditorHtml :html="globalNotification.text" />
      </b-notification>
      <!-- not accepted terms warning notification -->
      <b-notification
        v-if="$auth.user && $auth.tenantID && doShowTermsNotAcceptedNotice"
        type="is-warning"
        aria-close-label="Close notification"
        role="alert"
      >The terms of service or data processing agreement have not been accepted. An admin must accept {{ termsAcceptDeadline }} to continue using the backend.</b-notification>
      <TheMainMenu />
    </header>

    <section class="main-section">
      <div v-if="$auth.user && $auth.tenantID" class="container is-fullhd main-container">
        <b-loading :active="isLoading" is-full-page />
        <template v-if="!isLoading">
          <div class="sidebar-bg" />
          <aside
            class="menu sidebar"
            :class="{'is-collapsed': $localSettings.mainMenu.collapseSidebar}"
          >
            <b-icon
              icon="angle-double-left"
              class="sidebar-toggle"
              size="small"
              @click.native="$localSettings.mainMenu.collapseSidebar = !$localSettings.mainMenu.collapseSidebar"
            />
            <div class="sidebar-content">
              <TheSideMenu />
            </div>
          </aside>
          <VBackendAppPreviewSplitpane :hide-preview="!route.meta.showPreview">
            <template #main-content>
              <div class="main-view">
                <div :class="{'is-fullsize': route.meta.isFullsize }" class="main-view-container">
                  <header
                    v-if="!route.meta.isFullsize && (route.meta.breadcrumbs !== false || route.meta.label || route.meta.description)"
                    class="main-header"
                  >
                    <VBreadcrumb v-if="route.meta.breadcrumbs !== false" />
                    <h1 v-if="route.meta.label" class="title">{{ route.meta.label }}</h1>
                    <h2 v-if="route.meta.description" class="subtitle">{{ route.meta.description }}</h2>
                    <b-button
                      v-if="route.meta.helpSection"
                      class="help-button"
                      @click="isHelpCenterComponentModalActive = !isHelpCenterComponentModalActive"
                    >Help</b-button>
                  </header>
                  <div v-if="insufficientPermission">
                    <section class="hero is-large is-warning">
                      <div class="hero-body">
                        <p class="title">403 Insufficient Privileges</p>
                        <p
                          class="subtitle"
                        >You do not have any of the required privileges ({{ $route.meta.privileges.join(', ') }}) to access the page {{ $route.name }}</p>
                        <p>
                          Add any of those privileges to your user to view this page:
                          <b-taglist>
                            <b-tag
                              v-for="(privilege, key) in $route.meta.privileges"
                              :key="key"
                            >{{ privilege }}</b-tag>
                          </b-taglist>
                        </p>
                        <br />
                        <br />
                        <p>Reload the page after changing privileges.</p>
                      </div>
                    </section>
                  </div>
                  <router-view v-else />
                </div>
              </div>
            </template>
          </VBackendAppPreviewSplitpane>
        </template>
      </div>
      <div v-else-if="!$auth.user" class="not-logged-in">
        <div class="columns">
          <div class="column">
            <VFormLoginView class="login-section" />
          </div>
          <!-- <div class="column">
            <VFormRegisterView class="registration-section" />
          </div>-->
        </div>
      </div>
      <div v-else-if="$auth.user" class="not-assigned">
        <h2 class="title is-3">Welcome {{ $auth.user.name }}</h2>

        <b-notification
          type="is-warning"
          has-icon
          aria-close-label="Close notification"
          role="alert"
        >
          <h3 class="title is-4">Successfully logged in</h3>
          <h4 class="subtitle">
            We could not load a tenant. This can have the following reasons:
            <br />
            <div class="content">
              <ul>
                <li>Your account is not assigned to a tenant yet. Ask your company to assign your account to their tenant.</li>
                <li>Your tenant has not accepted the TOS. Ask the admin user of your tenant to accept the TOS.</li>
                <li>
                  Access to ECHO PRM is blocked by your companies firewall. Ask your IT to allow the following domains:
                  <br />www.youtube-nocookie.com
                  <br />www.google.com
                  <br />firestore.googleapis.com
                  <br />firebasestorage.googleapis.com
                  <br />www.googleapis.com
                  <br />www.gstatic.com
                  <br />fonts.gstatic.com
                  <br />backend.echoprm.com
                  <br />app.echoprm.com
                  <br />echoprm.com
                </li>
              </ul>
            </div>
          </h4>
        </b-notification>
      </div>
    </section>
    <b-modal
      v-if="isHelpCenterComponentModalActive"
      :width="1500"
      :active.sync="isHelpCenterComponentModalActive"
      has-modal-card
      scroll="keep"
      class="help-center-modal"
    >
      <div class="help-center modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">Help Center</p>
        </header>
        <section class="modal-card-body">
          <VHelpCenterView :section="route.meta.helpSection" />
        </section>
        <footer class="modal-card-foot">
          <b-button
            class="button is-pulled-right"
            type="is-text"
            @click="isHelpCenterComponentModalActive = false"
          >Close</b-button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationCircle, faEye, faEyeSlash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


import TheMainMenu from '@/components/TheMainMenu.vue'
import TheSideMenu from '@/components/TheSideMenu.vue'
import VFormLoginView from '@/components/VFormLoginView.vue'
import VFormRegisterView from '@/components/VFormRegisterView.vue'
import VBackendAppPreviewSplitpane from '@/components/VBackendAppPreviewSplitpane.vue'
import AdminNotificationManager from '@/database/adminNotificationManager'
import databaseSchema from '@/database/databaseSchema'

import * as Sentry from '@sentry/browser'
import VEditorHtml from '@/components/global/VEditorHtml.vue'
import initGlobals from '@/modules/_globals/allGlobals'
import VCustomVueFireBindMixin from '@/components/mixins/VCustomVueFireBindMixin.vue'
import { mixins } from 'vue-class-component'
import TosManager from '@/database/tosManager'

library.add(faExclamationCircle, faExclamationTriangle, faEye, faEyeSlash) // used in forms with error


Vue.filter('truncate', function (text: string = '', stop: number = 0, clamp: string) {
  return text.slice(0, stop) + (stop < text.length ? clamp || '…' : '')
})
Vue.filter('truncateMid', function (text: string = '', length: number, clamp: string = '...') {
  return length < text.length
    ? text.substring(0, length / 2) + clamp + text.substring(text.length - length / 2, text.length)
    : text
})


@Component({
  components: {
    TheMainMenu,
    TheSideMenu,
    VFormRegisterView,
    VBackendAppPreviewSplitpane,
    VFormLoginView,
    VEditorHtml
  }
})
export default class Backend extends mixins<VCustomVueFireBindMixin>(VCustomVueFireBindMixin) {
  public isHelpCenterComponentModalActive = false
  public isLoading: boolean = true

  get route() {
    return this.$route
  }

  get auth() {
    return this.$auth
  }

  get insufficientPermission() {
    return this.$route.meta?.privileges?.length > 0
      && this.$auth.user
      && !this.$auth.userHasSomePrivilege(this.$route.meta?.privileges)
  }

  public termsAcceptDeadline = ''
  public doShowTermsNotAcceptedNotice = false

  public checkTermsStatus() {
    const DAYY_UNTIL_DEADLINE = 7 * 3
    const termsAccepted = TosManager.areTOSAccepted(this.$auth.tenant.terms, 'terms')
    const odpAccepted = TosManager.areTOSAccepted(this.$auth.tenant.odp, 'odp')

    const acceptWithinDays = Math.min(
      termsAccepted ? Infinity : TosManager.daysUntilToAcceptTOS(this.$auth.tenant.terms),
      odpAccepted ? Infinity : TosManager.daysUntilToAcceptTOS(this.$auth.tenant.odp)
    )

    this.doShowTermsNotAcceptedNotice = (!termsAccepted || !odpAccepted) && acceptWithinDays <= DAYY_UNTIL_DEADLINE

    if (acceptWithinDays <= 0) this.termsAcceptDeadline = 'today'
    else if (acceptWithinDays === 1) this.termsAcceptDeadline = 'tomorrow'
    else this.termsAcceptDeadline = `within ${acceptWithinDays} days`
  }

  public globalNotification = databaseSchema.COLLECTIONS.ADMIN.NOTIFICATION.__EMPTY_DOC__.backendNotification
  public globalNotificationActive = false

  @Watch('$auth.tenant')
  private onTenantChange() {
    Sentry.setTag('tenant_number', this.$auth.tenant._number)
    Sentry.setTag('tenant_name', this.$auth.tenantName)
    Sentry.setTag('tenant_id', this.$auth.tenantID)
  }

  public mounted() {
    let interval: any = undefined
    AdminNotificationManager.onSnapshot((notification) => {
      this.globalNotification = notification.backendNotification

      clearInterval(interval)

      const applyShow = () => {
        this.globalNotificationActive = this.globalNotification.show
      }
      interval = setInterval(applyShow, this.globalNotification.reshowEverySeconds * 1000)
      applyShow()
    }, (e) => console.error(e))
  }


  @Watch('$auth.user', { immediate: true })
  @Watch('$auth.tenantID')
  public async onUserChanged() {
    this.isLoading = true
    if (this.$auth.user && this.$auth.tenantID) {
      try {
        // dispose all snapshots if logging in as another user
        this.$disposeSnapshots()
        // init all globals and wait for loaded
        this.$unbindHandle(await initGlobals(this.$auth))

        this.checkTermsStatus()
      } catch (error) {
        this.$helpers.notification.Error(error)
      } finally {
        this.isLoading = false
      }
    } else {
      // displose all snapshots if logging out
      this.$disposeSnapshots()
    }
  }


  @Watch('$auth.user', { immediate: true })
  @Watch('$auth.tenantID')
  public onUserOrTenantChanged() {
    if (process.env.NODE_ENV === 'development') return // no chat in local env to reduce external networking

    // convert date to berlin timezone
    // function convertTZ(date: Date) {
    //   return new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Berlin' })
    // }

    // get hour of day in berlin timezone
    // const dateInBerlin = new Date(convertTZ(new Date()))

    // const hoursInBerlin = dateInBerlin.getHours()

    // only show during 9 to 4pm berlin time and not on weekends
    // const isBusinessHours = hoursInBerlin >= 9 && hoursInBerlin <= 16 && dateInBerlin.getDay() !== 0 && dateInBerlin.getDay() !== 6

    // if (isBusinessHours)
    //   this.$freshchat.showWidget()
    // else
    //   this.$freshchat.hideWidget()

    // if (this.$auth.tenantID && this.$auth.userID)
    //   this.$freshchat.setExternalId(this.$auth.tenantID + this.$auth.userID)
    // else if (this.$auth.userID) // if not assigned or selected a tenant yet
    //   this.$freshchat.setExternalId(this.$auth.userID)

    // this.$freshchat.setUserProperties({
    //   firstName: this.$auth.userName,
    //   lastName: this.$auth.tenantName, // reusing last name as company name, since FreshChat does not allow for custom fields in our plan
    //   email: this.$auth.userEmail,
    //   tenantID: this.$auth.tenantID,
    //   userName: this.$auth.userName
    // })
  }
}
</script>

<style lang="scss">
@import '@/variables.scss';
@import '@/mixins.scss';

html::-webkit-scrollbar {
  display: none;
}

.table-wrapper {
  overflow: auto;
}

.main-section {
  position: relative;
  overflow: hidden;

  height: calc(100vh - 3.25rem);

  @include until($desktop) {
    & {
      height: initial;
      overflow: initial;
    }

    .sidebar {
      width: 100%;
      order: 2;
    }

    .container.main-container {
      flex-direction: column;
    }
  }

  .sidebar-bg {
    position: absolute;
    bottom: 0;
    right: 100%;
    left: -100%;
    top: 0;
    background: #f5f5f5;
    z-index: -1;
  }

  .main-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;

    .main-view {
      flex-grow: 1;
      overflow-x: auto;
      position: relative;

      .main-view-container {
        padding: 2rem;

        height: 100%;
        overflow-y: auto;

        @include until($desktop) {
          padding: 1rem;
        }

        &.is-fullsize {
          padding: 0;
          height: 100%;
        }

        .module {
          @include from($desktop) {
            height: 100%;
          }

          .level-left {
            height: 100%;

            div.element-name-html {
              max-width: 9em;
            }
          }

          .level {
            align-items: flex-start;
          }

          .some-padding {
            padding: 1em;
          }
        }
      }
    }

    .sidebar {

      padding: 3rem 1rem 1rem;
      background: #f5f5f5;

      @include from($desktop) {
        &,
        &.is-collapsed:hover {
          width: 15rem;
          position: relative;
          transition: width 0.1s;
          flex-shrink: 0;
        }

        &.is-collapsed {
          width: 40px;
        }

        &.is-collapsed:not(:hover) .sidebar-content {
          // display: none;
          opacity: 0;
        }
      }

      @include until($desktop) {
        width: 100%;
        // padding: 3rem 1rem;

        .sidebar-toggle {
          display: none;
        }
      }

      .sidebar-content,
      &.is-collapsed:hover .sidebar-content {
        display: block;
        // width: 200px;
        opacity: 1;
        transition: opacity 0.1s;
      }

      .sidebar-toggle {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        transition: all 0.2s linear;
      }

      section.side-menu a:not(.is-active):hover {
        background: #e5e5e5;
      }
    }

    .is-collapsed .sidebar-toggle {
      transform: rotate(180deg);
      left: 8px;
    }
  }

  .not-assigned {
    height: 77vh;
    padding: 7em;
  }

  .not-logged-in {
    overflow: hidden;
    height: 800px;
    min-height: 100vh;

    .columns {
      height: 100%;
    }

    .columns > .column:first-of-type {
      background: #f5f5f5;
    }

    .login-section,
    .registration-section {
      max-width: 430px;
      margin: auto;
      margin-top: 3em;
      padding: 4em;
    }

    .registration-section button {
      margin-top: 1em;
    }

    $breakpoint-tablet: 768px;
    @media (max-width: $breakpoint-tablet) {
      & {
        height: 100%;
      }

      .login-section,
      .registration-section {
        margin-top: 0;
      }
    }
  }

  @media screen and (width <= 1471px) {
    .main-container {
      max-width: 1344px;
      width: auto;
    }
  }

  .main-header {
    border-bottom: 2px solid #f5f5f5;
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    position: relative;

    button.button.help-button {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

.help-center-modal {
  .modal-card-body {
    width: 80vw;
  }
}
</style>
