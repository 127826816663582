<template>
  <Splitpanes
    class="splitpane"
    @resized="($event[1] && $event[1].size) ? $localSettings.appPreview.width = $event[1].size : ''"
  >
    <Pane class="splitpane-main-content" min-size="30">
      <slot name="main-content" />
    </Pane>

    <Pane
      v-if="!hidePreview"
      :size="$localSettings.appPreview.width"
      class="module-single-page-preview-container"
    >
      <VBackendAppPreview />
    </Pane>
  </Splitpanes>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'

import vModuleMenu from '../modules/VModuleMenu.vue'
import VBackendAppPreview from '@/components/VBackendAppPreview.vue'

@Component({
  components: {
    vModuleMenu, VBackendAppPreview, Splitpanes, Pane
  }
})
export default class VBackendAppPreviewSplitpane extends Vue {
  @Prop({ type: Boolean, required: false, default: false })
  public hidePreview!: boolean

  public async created() {
    //
  }
}
</script>

<style lang="scss">
@import '@/variables.scss';

.splitpane {
  overflow-x: auto;

  /* Media query for mobile phone screens */
  @media screen and (width <= 768px) {
    /* Hide the second splitpanes__pane */
    .module-single-page-preview-container,
    .splitpanes__splitter {
      display: none;
    }
  }

  &.splitpanes--vertical > .splitpanes__splitter {
    width: 7px;
    // border-left: 1px solid #eee;
    margin-left: -1px;

    background-color: #d6d6d6;
    box-sizing: border-box;
    position: relative;
    -ms-flex-negative: 0;
    flex-shrink: 0;

    min-width: 1px;
    cursor: col-resize;
    touch-action: none;

    &:hover {
      background-color: $primary;

      &::before,
      &::after {
        background-color: #fff;
      }
    }

    &::before {
      margin-left: -2px;
    }

    &::after {
      margin-left: 1px;
    }

    &::before,
    &::after {
      transform: translateY(-50%);
      width: 1px;
      height: 30px;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: #00000087;
      transition: background-color 0.3s;
    }
  }

  .splitpane-main-content {
    align-items: stretch;
    // min-height: 100%;
    flex-shrink: 1; // to prevent infinite with bug
    flex-grow: 1; // to prevent infinite with bug
    display: flex;
    // min-width: 500px;
  }
}
</style>
