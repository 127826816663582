<template>
  <section class="side-menu">
    <div v-if="$auth.user" class="user-account media">
      <Avatar class="profile-picture media-left" :username="$auth.user.name || ''" :size="40" />
      <router-link :to="{ name: 'user-single' }">
        <div class="media-content">
          <div class="name fit">
            <b>{{ $auth.user.name }}</b>
          </div>
          <div class="email">{{ $auth.user.email }}</div>
        </div>
      </router-link>
    </div>

    <!-- <p
      v-if="$auth.userPrivileges.includes($auth.privileges.STATISTICS_READ_VIEW)"
      class="menu-label"
    >General</p>
    <ul v-if="$auth.userPrivileges.includes($auth.privileges.STATISTICS_READ_VIEW)" class="menu-list">
      <li>
        <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
      </li>
    </ul>-->

    <p
      v-if="$auth.userPrivileges.some(p=>[
        $auth.privileges.DASHBOARD_VIEW
      ].includes(p))"
      class="menu-label"
    >General</p>
    <ul class="menu-list">
      <!-- <li v-if="$auth.userPrivileges.includes($auth.privileges.DASHBOARD_VIEW)"> -->
      <li>
        <router-link :to="{ name: 'dashboard' }">
          Dashboard
          <b-tag type="is-success" size="is-small" class="experimental-tag">
            <VTooltipIconExperimental
              position="is-top"
              text="New feature in Experimental stage. The functionality will improve in future versions."
            />
          </b-tag>
        </router-link>
      </li>
    </ul>

    <p
      v-if="$auth.userPrivileges.some(p=>[
        $auth.privileges.CODE_ACTIVATE_VIEW,
        $auth.privileges.CODE_LIST_VIEW
      ].includes(p))"
      class="menu-label"
    >echo Codes</p>
    <ul class="menu-list">
      <li v-if="$auth.userPrivileges.includes($auth.privileges.CODE_LIST_VIEW)">
        <router-link :to="{ name: 'manage-asids' }">List Codes</router-link>
      </li>
      <li v-if="$auth.userPrivileges.includes($auth.privileges.CODE_ACTIVATE_VIEW)">
        <router-link :to="{ name: 'activate-asid' }">Activate Code</router-link>
      </li>
      <!-- <li v-if="$auth.userPrivileges.includes($auth.privileges.CODE_PURCHASE)">
        <a>Purchase Codes</a>
      </li>-->
    </ul>

    <p
      v-if="$auth.userPrivileges.includes($auth.privileges.CATEGORY_MANAGE_VIEW)"
      class="menu-label"
    >Categories</p>
    <ul
      v-if="$auth.userPrivileges.includes($auth.privileges.CATEGORY_MANAGE_VIEW)"
      class="menu-list"
    >
      <li>
        <router-link :to="{ name: 'manage-categories' }">Manage Categories</router-link>
      </li>
    </ul>

    <p
      v-if="Object.keys(moduleNavigationItems).length > 0 || $auth.userPrivileges.includes($auth.privileges.MODULES_MANAGE_VIEW)"
      class="menu-label"
    >Modules</p>
    <ul class="menu-list">
      <li v-if="$auth.userPrivileges.includes($auth.privileges.MODULES_MANAGE_VIEW)">
        <router-link :to="{ name: 'manage-modules' }">Manage Modules</router-link>
      </li>
      <li v-if="$auth.userPrivileges.includes($auth.privileges.WIDGETS_MANAGE_VIEW)">
        <router-link :to="{ name: 'manage-widgets' }">App Layout</router-link>
      </li>
    </ul>
    <!-- <b-menu>
      <b-menu-list v-if="Object.keys(moduleNavigationItems).length > 0" label="Menu">
        <b-menu-item
          v-for="(moduleNavItems, moduleName) in moduleNavigationItems"
          :key="moduleName"
        >
          <template slot="label" slot-scope="props">
            {{ moduleName }}
            <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-down' : 'menu-up'" />
          </template>
          <b-menu-item
            v-for="moduleNavItem in moduleNavItems"
            :key="moduleNavItem.displayName"
            :label="moduleNavItem.displayName"
            tag="router-link"
            :to="moduleNavItem.to"
          />
          <b-menu-item label="Account data" />
        </b-menu-item>
        <b-menu-item label="Account data" />
      </b-menu-list>
    </b-menu>-->

    <ul class="menu-list">
      <div v-for="(module, moduleName) in moduleNavigationItems" :key="moduleName">
        <!-- <a>{{ moduleName }}</a>
        <ul>-->
        <li v-for="moduleNavItem in module.navItems" :key="moduleNavItem.displayName">
          <span class="module-color" />
          <router-link
            :style="{borderLeft: `.3em solid ${module.color}`, borderRadius: '0 2px 2px 0'}"
            :to="moduleNavItem.to"
          >{{ moduleNavItem.displayName }}</router-link>
        </li>
        <!-- </ul> -->
      </div>
    </ul>

    <ul class="menu-list sticky-bottom">
      <!-- todo configuration privilege -->
      <li>
        <router-link :to="{ name: 'helpcenter' }">Help</router-link>
      </li>
      <li
        v-if="$auth.userPrivileges.some(p=>[
          $auth.privileges.USER_MANAGE_VIEW,
          $auth.privileges.APP_CONFIG_VIEW,
          $auth.privileges.BACKEND_CONFIG_VIEW
        ].includes(p))"
      >
        <router-link :to="{ name: 'settings-overview' }">Settings</router-link>
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import { Component, Watch } from 'vue-property-decorator'

import { RawLocation } from 'vue-router'
import Avatar from 'vue-avatar'
import vAuth from '@/components/mixins/VAuth.vue'


import { ModuleManager } from '@/modules/moduleManager'

import { mixins } from 'vue-class-component'
import { intersectSome } from '@/helpers/arrayHelper'
import { ModuleType } from '@/modules/typeModules'
import VCustomVueFireBindMixin from './mixins/VCustomVueFireBindMixin.vue'


@Component({
  components: { Avatar }
})
export default class TheSideMenu extends mixins(vAuth, VCustomVueFireBindMixin) {
  public moduleNavigationItems: { type: ModuleType, color: string, navItems: Array<{ to: RawLocation, displayName: string }> }[] = []

  public async created() {
    // await AuthHelper.onUser((U) => {
    //   this.username = U.name
    // })
  }


  @Watch('$auth.user', { immediate: true })
  public async onUserChanged() {
    this.$unbindHandle(ModuleManager
      .onSnapshotActivatedModuleClasses(this.$auth.tenant.id, this.$auth.userPrivileges, (Ms) => {
        this.moduleNavigationItems = []

        Ms
          .filter((M) => intersectSome(M.authPrivileges.view, this.$auth.userPrivileges))
          .forEach((M) => {
            let moduleNavigationItems = M.getNavigationItems()
            // filter nav items which require non matching privileges
            moduleNavigationItems = moduleNavigationItems.filter((NI) => !NI.requiredPrivileges || this.$auth.userHasAllPrivilege(NI.requiredPrivileges))

            this.moduleNavigationItems.push({ type: M.type, color: M.color, navItems: moduleNavigationItems })
          }
          )
      }, (e) => {
        this.$helpers.notification.Error(e)
      }, 'sideMenu'))
  }


  private mounted() {
    // fitty('.fit')
  }
}
</script>

<style lang="scss">
@import '@/variables.scss';
@import '@/mixins.scss';

.sidebar-content {
  overflow: unset;
  height: 100%;

  .side-menu {

    display: flex;
    flex-direction: column;
    height: inherit;

    overflow-y: auto;
    overflow-x: hidden;

    .experimental-tag {
      height: 1.6em;
      line-height: initial;
      text-align: center;

      .b-tooltip.is-multiline.is-medium .tooltip-content {
        width: 172px;
      }
    }

    .menu-list a {
      @media only screen and (height <= 50em) {
        padding: 0.3em 0.75em;
      }
    }

    .sticky-bottom {
      margin-top: auto;
    }

    .user-account {
      margin-bottom: 2em;

      .name {
        width: 100%;
        // font-size: 1.2em;
      }

      .email {
        width: 9em;
        white-space: nowrap;
      }

      .media-content {
        min-width: 0;

        & > * {
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    @include scrollbars(8px, $primary, #e0e0e0);
  }
}
</style>
