<template>
  <section>
    <div v-for="(formUser, index) in formUsers" :key="formUser.id" class="userbox box">
      <b-field grouped group-multiline>
        <b-button
          outlined
          type="is-danger"
          icon-right="trash"
          style="margin-right: 10px;"
          @click="formRemoveUser(index)"
        />

        <b-field>
          <b-tooltip
            label="The api user name can't be changed. Add a new api user instead"
            position="is-top"
          >
            <b-input v-model="formUser.apiUserName" placeholder="Username" disabled />
          </b-tooltip>
        </b-field>

        <b-field expanded class="user-roles-container">
          <VInputUserRolesTags
            v-model="formUsers[index].privileges"
            :hide-views="true"
            @selected="valueChanged(formUser.id)"
          />
        </b-field>
      </b-field>
    </div>

    <b-field grouped position="is-right">
      <b-button @click="formAddUser()">add Api User</b-button>
    </b-field>

    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false" />
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'


import VInputUserRolesTags from '@/components/VInputUserRolesTags.vue'
import { ApiUserReferenceDB } from '@/types/typeUser'


import { DebounceInstance, debounce } from 'vue-debounce'
import { hasDBid, objectID } from '@/types/typeGeneral'
import ApiUserManager from '@/database/apiUserManager'


@Component({
  components: { VInputUserRolesTags }
})
export default class VInputMultiApiUserManagement extends Vue {
  @Prop({ type: Boolean, required: false, default: () => true })
  readonly autosave!: boolean

  @Prop({ type: String, required: true })
  readonly tenantId!: string

  public isLoading = false

  public formUsers: Array<ApiUserReferenceDB & hasDBid> = []

  private userIDsChanged: any = {}


  public formAddUser() {
    if (this.formUsers.length >= 10) {
      this.$helpers.notification.Error('you may only add 10 api users')
      return
    }

    this.$buefy.dialog.prompt({
      message: 'Api User Name',
      type: 'is-success',
      inputAttrs: {
        value: 'Api User ' + (this.formUsers.length + 1),
        maxlength: 300
      },
      trapFocus: true,
      onConfirm: async (value) => {
        this.isLoading = true
        try {
          const rsp = await ApiUserManager.add(this.tenantId, value)
          this.$buefy.dialog.confirm({
            title: 'API Key',
            message: `Safe your API key <br>
                    
                    <div class="field has-addons">
  <div class="control is-expanded">
    <input class="input" value="${rsp.apiKey}" type="text" id="copy-value" >
  </div>
  <div class="control">
    <button class="button" onclick='
    var textBox = document.getElementById("copy-value");
    textBox.select();
    document.execCommand("copy");'>
      Copy
    </button>
  </div>
  
</div>
<br>
                    you can not view it again ater closing this dialogue`,
            confirmText: 'I have saved the key',
            type: 'is-success'
            // onConfirm: () => this.$buefy.toast.open('User agreed')
          })
        } catch (error: any) {
          this.$helpers.notification.Error('Error creating api user ' + error)
        } finally {
          this.isLoading = false
        }
      }
    })
  }


  private debounceInstance?: DebounceInstance<any>
  public async valueChanged(userId: objectID) {
    this.userIDsChanged[userId] = true

    if (!this.debounceInstance)
      this.debounceInstance = debounce(async () => {
        if (this.autosave && !this.isLoading) await this.save()
      }, 5000)

    await this.debounceInstance()
  }

  public async save() {
    this.isLoading = true
    for (const userId in this.userIDsChanged) {
      await ApiUserManager.update(this.tenantId, this.$auth.userEmail, userId, { privileges: this.formUsers.find((el) => el.id === userId)?.privileges })
        .then(() => this.$helpers.notification.Success('user updated ' + userId))
        .catch((e) => this.$helpers.notification.Error('Error updating user' + e))
    }
    this.isLoading = false
    this.userIDsChanged = {}
  }

  public formRemoveUser(index: number) {
    const tmpUser = this.formUsers[index]

    this.$buefy.dialog.confirm({
      title: 'Deleting Api User',
      message: `Are you sure you want to <b>delete ${tmpUser.apiUserName} 
        </b> account? This action cannot be undone.`,
      confirmText: 'Delete Account',
      type: 'is-danger',
      hasIcon: true,
      onConfirm: () => {
        ApiUserManager.remove(this.tenantId, tmpUser.id)
          .then(() => this.$helpers.notification.Success('api user removed ' + tmpUser.id))
          .catch((e) => this.$helpers.notification.Error('Error removing user' + e))
      }
    })
  }

  public async initialise() {
    this.isLoading = true

    await this.$firestoreBind('formUsers', ApiUserManager.getDbCollectionReference(this.tenantId), { wait: true })

    this.isLoading = false
  }

  public async created() {
    await this.initialise()
  }
}
</script>

<style scoped>
.user-roles-container {
  /* margin-bottom: 30px; */
  width: 100%;
}
</style>
